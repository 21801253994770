import {ROLES} from "../../../constants/ROLES";

export function SetRoleUtil(role: number): string {
  let roleName = ''
  switch (role) {
    case 0:
      roleName = ROLES.GUEST;
      break;
    case 1:
      roleName = ROLES.BAYER;
      break;
    case 2:
      roleName = ROLES.CONTENT_MANAGER;
      break;
    case 3:
      roleName = ROLES.WEBMASTER;
      break;
    case 4:
      roleName = ROLES.ADMIN;
      break;
    default:
      roleName = ROLES.GUEST;
  }
  return roleName;
}
