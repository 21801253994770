import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ProcessService} from "@/main/autoreg/services/process.service";
import {ToastrService} from "ngx-toastr";
import {
  autoRegProcessPlayActions, autoRegProcessPlayErrorActions, autoRegProcessPlayFailureActions,
  autoRegProcessPlaySuccessActions
} from "@/main/autoreg/registration/store/actions/autoRegProcessPlay.actions";

@Injectable()
export class AutoRegProcessPlayEffect {
  autoRegPlayEffect$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegProcessPlayActions),
    switchMap(({request}) => {
      return this.processService.playProcess(request).pipe(
        map((response: DefaultResponseType) => {
          let pid = Number(response.details.msg.match(/\d+/g).join(""));
          if (response.status === 'Success') {
            return autoRegProcessPlaySuccessActions({response, pid})
          } else if (response.status === 'Failure') {
            return autoRegProcessPlayFailureActions({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegProcessPlayErrorActions({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegProcessPlayErrorActions({error}));
          } else {
            return of(autoRegProcessPlayErrorActions({error}));
          }
        })
      );
    })
  ))

  autoRegPlayEffectSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegProcessPlaySuccessActions),
      tap((response: {response: DefaultResponseType}): void => {
        if (response.response.details.msg) {
          this.toaStr.success(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegPlayEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegProcessPlayFailureActions),
      tap((response: {response: DefaultResponseType}): void => {
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegPlayEffectError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegProcessPlayErrorActions),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private processService: ProcessService,
              private toaStr: ToastrService
  ) {
  }
}
