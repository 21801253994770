import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/registration/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const autoRegProcessPauseActions = createAction(
  ActionType.AUTO_REG_PROCESS_PAUSE,
  props<{request: { pid: number }}>()
)

export const autoRegProcessPauseSuccessActions = createAction(
  ActionType.AUTO_REG_PROCESS_PAUSE_SUCCESS,
  props<{response: DefaultResponseType, pid}>()
)

export const autoRegProcessPauseFailureActions = createAction(
  ActionType.AUTO_REG_PROCESS_PAUSE_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegProcessPauseErrorActions = createAction(
  ActionType.AUTO_REG_PROCESS_PAUSE_ERROR,
  props<{error: HttpErrorResponse}>()
)
