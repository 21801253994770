<div class="autoRegItem" [formGroup]="autoRegItemForm">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0 process ">
        <div class="card-header">
          <h3 class="card-title">Аккаунт {{autoRegItem.login}}</h3>
        </div>
        <div class="card-body pb-0">
          <div class="row">
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>ID AdsPower</mat-label>
                <input matInput placeholder="ID AdsPower"
                       formControlName="ap_id"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       [readonly]="redaction || type === 'card'">
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Название группы</mat-label>
                <input matInput placeholder="Название группы"
                       formControlName="group_name"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       [readonly]="redaction || type === 'card'">
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="w-100">
                <mat-label>Состояние</mat-label>
                <mat-select
                  appClipboardInput
                  [disabled]="redaction"
                  [class.disabled]="redaction"
                  [class.noClipBoard]="!redaction"
                  formControlName="state"
                  [value]="autoRegItem.state"
                >
                  <mat-option *ngIf="autoRegItem.state" [value]="autoRegItem.state">{{autoRegItem.state}}</mat-option>
                  <ng-container *ngIf="type !== 'cart'">
                    <mat-option *ngIf="autoRegItem.state !== 'Готов'" [value]="'Готов'">Готов</mat-option>
                    <mat-option *ngIf="autoRegItem.state !== 'Используется'" [value]="'Используется'">Используется</mat-option>
                    <mat-option *ngIf="autoRegItem.state !== 'Ошибка'" [value]="'Ошибка'">Ошибка</mat-option>
                    <mat-option *ngIf="autoRegItem.state !== 'Заблокирован'" [value]="'Заблокирован'">Заблокирован</mat-option>
                    <mat-option *ngIf="autoRegItem.state !== 'Мусор'" [value]="'Мусор'">Мусор</mat-option>
                    <mat-option *ngIf="autoRegItem.state !== 'Добавлен в FB Tool'" [value]="'Добавлен в FB Tool'">Добавлен в FB Tool</mat-option>
                    <mat-option *ngIf="autoRegItem.state !== 'Селфи'" [value]="'Селфи'">Селфи</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>IP_PORT</mat-label>
                <input matInput placeholder="IP_PORT"
                       formControlName="ap_port"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       [readonly]="redaction || type === 'card'">
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Владелец</mat-label>
                <input matInput placeholder="Владелец"
                       formControlName="owner"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       [readonly]="type === 'card'">
              </mat-form-field>

            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Этап</mat-label>
                <input matInput placeholder="Этап"
                       formControlName="step"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       readonly>
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>ID Расширения</mat-label>
                <input matInput placeholder="ID Расширения"
                       formControlName="extension_id"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       [readonly]="redaction || type === 'card'">
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Логин</mat-label>
                <input matInput placeholder="Логин"
                       formControlName="login"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       readonly>
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Пароль</mat-label>
                <input matInput placeholder="Пароль"
                       formControlName="password"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       [readonly]="redaction || type === 'card'">
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Двухфакторная аутентификация</mat-label>
                <input matInput placeholder="Двухфакторная аутентификация"
                       formControlName="twofa"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       [readonly]="redaction || type === 'card'">
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Почта</mat-label>
                <input matInput placeholder="Почта"
                       formControlName="email"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       [readonly]="redaction || type === 'card'">
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Пароль от почты</mat-label>
                <input matInput placeholder="Пароль от почты"
                       formControlName="email_pass"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       [readonly]="redaction || type === 'card'">
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Имя</mat-label>
                <input matInput placeholder="Имя"
                       formControlName="name"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       [readonly]="redaction || type === 'card'">
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Фамилия</mat-label>
                <input matInput placeholder="Фамилия"
                       formControlName="surname"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       [readonly]="redaction || type === 'card'">
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Дата рождения</mat-label>
                <input matInput placeholder="Дата рождения"
                       formControlName="str_birthday"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       [readonly]="redaction || type === 'card'">
              </mat-form-field>
            </div>
            <div class="col-3 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Дата регистрации</mat-label>
                <input matInput placeholder="Дата регистрации"
                       formControlName="date"
                       appClipboardInput
                       [class.disabled]="redaction"
                       [class.noClipBoard]="!redaction"
                       readonly>
              </mat-form-field>
            </div>
            <div class="col-6 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Токен</mat-label>
                <textarea matInput placeholder="Токен..."
                          formControlName="token"
                          appClipboardInput
                          rows="1"
                          [class.disabled]="redaction"
                          [class.noClipBoard]="!redaction"
                          [readonly]="redaction || type === 'card'"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="col-6 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>UA</mat-label>
                <textarea matInput placeholder="UA..."
                          formControlName="ua"
                          appClipboardInput
                          rows="1"
                          [class.disabled]="redaction"
                          [class.noClipBoard]="!redaction"
                          [readonly]="redaction || type === 'card'"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="col-12 ">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Cookie</mat-label>
                <textarea matInput placeholder="Cookie..."
                          formControlName="cookie"
                          appClipboardInput
                          rows="1"
                          [class.disabled]="redaction"
                          [class.noClipBoard]="!redaction"
                          [readonly]="redaction || type === 'card'"
                ></textarea>
              </mat-form-field>
            </div>

            <div class="col-4"
              [class.col-6]="type === 'cart'"
            >
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена
              </button>
            </div>

            <div *ngIf="type !== 'cart'" class="col-4 ">
              <button class="w-100" mat-raised-button color="accent"
                      (click)="redaction = !redaction">
                <span *ngIf="!redaction">Отменить режим редактирования</span>
                <span *ngIf="redaction">Редактировать</span>
              </button>
            </div>
            <div *ngIf="type !== 'cart'" class="col-4" >

              <button class="w-100" mat-raised-button color="primary"
                      [disabled]="!changeForm || (buttonLoader$ | async)"
                      (click)="updateAutoReg()"
              >
                <span *ngIf="!(buttonLoader$ | async)">Сохранить</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>

            <div *ngIf="type === 'cart'" class="col-6" >
              <button class="w-100" mat-raised-button color="primary"
                      [disabled]="(buttonLoader$ | async)"
                      (click)="restore()"
              >
                <span *ngIf="!(buttonLoader$ | async)">Восстановить</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
