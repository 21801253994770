export enum TypeBoxEnum {
  BLOCKED = 'blocked',
  WITH_AP = 'withAp',
  CHECK = 'check',
  NO_TWOFA = 'not_2fa',
  COOKIE_TOKEN = 'cookieToken',
  NO_OWNER = 'noOwner',
  READY = 'completedAcc',
  CHECKPOINT = 'checkpoint',
  COUNT = 'count',
  REGISTERED_TODAY = 'today',
}
