export function UpdateAutoRegItemUtil(defaultArray, responseArray) {
  return defaultArray.map((autoRegAccItem) => {
    const updatedItem = {...autoRegAccItem};
    if (autoRegAccItem.login === responseArray.login) {
      for (let prop in autoRegAccItem) {
        if (prop in responseArray && autoRegAccItem[prop] !== responseArray[prop]) {
          updatedItem[prop] = responseArray[prop];
        }
      }
      return updatedItem;
    }
    return autoRegAccItem;
  })
}
