import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {RequestCreateAPType} from "@/main/autoreg/accounts/store/type/requestCreateAP.type";

export const autoRegCreateProfileAPAction = createAction(
  ActionType.AUTO_REG_CREATE_PROFILE_AD,
  props<{data: {name: string, ap_port: number, device_name: number}}>()
)

export const autoRegCreateProfileAPActionSuccess = createAction(
  ActionType.AUTO_REG_CREATE_PROFILE_AD_SUCCESS,
  props<{response: DefaultResponseType, data: any}>()
)

export const autoRegCreateProfileAPActionFailure = createAction(
  ActionType.AUTO_REG_CREATE_PROFILE_AD_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegCreateProfileAPActionError = createAction(
  ActionType.AUTO_REG_CREATE_PROFILE_AD_ERROR,
  props<{error: HttpErrorResponse}>()
)
