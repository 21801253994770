import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from "@/auth/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {}

  canActivate(): boolean {
    let isLoggedId = !!this.authService.getCookie('bonds');
    if (!isLoggedId) {
      this.router.navigate(['/login']);
    }
    return isLoggedId;
  }
}
