import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap, of, switchMap, tap, withLatestFrom} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {CartService} from "@/main/cart/services/cart.service";
import {
  restoreAccAction, restoreAccErrorAction,
  restoreAccFailureAction,
  restoreAccSuccessAction
} from "@/main/cart/store/actions/restoreAcc.action";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {select, Store} from "@ngrx/store";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {selectedTrSelector} from "@/main/cart/store/selectors";
import {UpdateStateRequestType} from "@/main/autoreg/accounts/store/type/requestSetOwnerSystem.type";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class RestoreAccEffect {
  restoreAccEffect$ = createEffect(() => this.actions$.pipe(
    ofType(restoreAccAction),
    withLatestFrom(this.store.pipe(select(selectedTrSelector))),
    mergeMap(([{request}, selected]) => {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      let requestData: UpdateStateRequestType;
      if (request) {
        requestData = request;
      } else {
        requestData = {
          accounts: []
        }
        requestData.accounts = selected.map(item => {
          return {
            login: item.login,
            state: 'Востановлен',
            checkpoint: item.checkpoint ? item.checkpoint : 'Yes'
          }
        })
      }

      return this.cartService.restoreAcc(requestData).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
            this.store.dispatch(ButtonLoaderAction({load: false}))
            return restoreAccSuccessAction({response, request: requestData})
          } else if (response.status === 'Failure') {
            this.store.dispatch(ButtonLoaderAction({load: false}))
            return restoreAccFailureAction({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.store.dispatch(ButtonLoaderAction({load: false}))
          if(error.status === 400 || 422) {
            return of(restoreAccErrorAction({error}));
          } else if (error.status === 500 || 501) {
            return of(restoreAccErrorAction({error}));
          } else {
            return of(restoreAccErrorAction({error}));
          }
        })
      );
    })
  ))

  restoreAccSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(restoreAccSuccessAction),
      tap((response: {response: DefaultResponseType}): void => {
        this.toaStr.success('Автореги восстановлены')
      })
    ), {dispatch: false}
  )

  restoreAccFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(restoreAccFailureAction),
      tap((response: {response: DefaultResponseType}): void => {
        console.error('Данные для корзины не получены')
      })
    ), {dispatch: false}
  )

  restoreAccError$ = createEffect(
    () => this.actions$.pipe(
      ofType(restoreAccErrorAction),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          console.error('Данные для корзины не получены')
        } else if (error.status === 500 || 501) {
          console.error('Данные для корзины не получены. Ошибка сервера')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private cartService: CartService,
              private store: Store,
              private toaStr: ToastrService
  ) {
  }
}
