import {Component, OnInit} from '@angular/core';
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {downloadEmailsAction} from "@/main/autoreg/registration/store/actions/downloadEmails.action";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {buttonLoaderSelector} from "@/ui/store/selectors";

@Component({
  selector: 'app-add-emails',
  templateUrl: './add-emails.component.html',
  styleUrls: ['./add-emails.component.scss']
})
export class AddEmailsComponent implements OnInit {

  public file: File;

  public buttonLoader$: Observable<boolean>;

  constructor(private store: Store) {
  }

  public ngOnInit() {
    this.initValue();
  }

  public eventFile(event: any): void {
    this.file = event.target.files[0];
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}));
  }

  public downloadEmails(): void {
    if (this.file) {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(downloadEmailsAction({file: this.file}))
    }
  }

  private initValue():void {
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
  }

}
