import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { debounceTime, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {RequestSignupType, ResponseSignupType} from "@/auth/store/type/signup.type";
import {LoginRequestType} from "@/auth/store/type/loginRequestType";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
              private router: Router) {}

  public login(data: LoginRequestType): Observable<DefaultResponseType> {
    const headers: HttpHeaders = new HttpHeaders(
      {
        'Content-Type': 'application/x-www-form-urlencoded',
        'accept':'application/json'
      })
    const body: string = `username=${encodeURIComponent(data.email)}&password=${encodeURIComponent(data.password)}`;
    return this.http.post<DefaultResponseType>(environment.api + 'auth/login',  body, { headers: headers, withCredentials: true})
  }

  public logout(): Observable<DefaultResponseType> {
    const headers: HttpHeaders = new HttpHeaders({
      'accept': 'application/json'
    })
    return this.http.post<DefaultResponseType>(environment.api + 'auth/logout', '', { headers: headers, withCredentials: true })
  }

  public register(data: RequestSignupType): Observable<ResponseSignupType> {
    return this.http.post<ResponseSignupType>(environment.api + 'auth/register', data)
  }

  public getCookie(name): string {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  public removeCookie(name): void {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    this.router.navigate(['/login'])
  }
}
