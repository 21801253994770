import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {MatSliderDragEvent} from "@angular/material/slider";
import {Observable, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {
  autoRegStatusSelector,
  regConsumablesSelector,
  regerSelector,
  updateProcessSelector
} from "@/main/autoreg/registration/store/selectors";
import {getRegAction} from "@/main/autoreg/registration/store/actions/getReg.action";
import {getConsumablesAction} from "@/main/autoreg/registration/store/actions/getConsumables.action";
import {ConsumablesType} from "@/main/autoreg/registration/store/type/consumables.type";
import {updateRegerAction} from "@/main/autoreg/registration/store/actions/updateReger.action";
import {autoRegAccItemsSelector, registeredToDaySelector} from "@/main/autoreg/accounts/store/selectors";
import {RegerStartType, RegerState, RegerType} from "@/main/autoreg/registration/type/reger.type";
import {environment} from "../../../../../../environments/environment";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";

@Component({
  selector: 'app-registration-process',
  templateUrl: './registration-process.component.html',
  styleUrls: ['./registration-process.component.scss']
})
export class RegistrationProcessComponent implements OnInit, OnDestroy {

  @Output() dragEnd: EventEmitter<MatSliderDragEvent>;
  @ViewChild('addEmailsComponent') addEmailsComponent: TemplateRef<ElementRef>;

  public reger$!: Observable<RegerType>;
  public autoregs$: Observable<AutoRegItemType[]>;
  public consumables$: Observable<ConsumablesType>;
  public registeredToDay$: Observable<number>;
  public updateProcessStatus$: Observable<boolean>;
  private windowsProfile = environment.windows_profile;

  public value: number;
  public formatLabel(value: number): string {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }
    return `${value}`;
  }

  private popUpStatus$: Observable<string | boolean>;

  private dialogRef: MatDialogRef<any> | null = null;
  private subscriptionList: Subscription[] = [];

  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store,
              private matDialog: MatDialog) {}

  public ngOnInit(): void {
    this.initValue();
    this.store.dispatch(getRegAction({windowsProfile: this.windowsProfile}))
    this.store.dispatch(getConsumablesAction());
    this.sub = this.popUpStatus$.subscribe(data => this.closePopUp(data))
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(item => item.unsubscribe())
  }

  public startReger(count: number): void {
    const requestStart: RegerType = {
      pids: null,
      windows_profile: this.windowsProfile,
      state: RegerState.START,
      processes: count
    }
    this.store.dispatch(updateRegerAction({reger: requestStart}))
  }

  public stopReger(count: number, pids: number): void {
    const requestStop: RegerType = {
      pids: pids,
      windows_profile: this.windowsProfile,
      state: RegerState.STOP,
      processes: count
    }
    this.store.dispatch(updateRegerAction({reger: requestStop}))
  }

  public addEmails(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.dialogRef = this.matDialog.open(this.addEmailsComponent);
  }

  private closePopUp(value): void {
    if (value === 'Закрыть' && this.dialogRef) {
      this.dialogRef.close()
    }
  }

  private initValue(): void {
    this.reger$ = this.store.pipe(select(regerSelector));
    this.consumables$ = this.store.pipe(select(regConsumablesSelector));
    this.autoregs$ = this.store.pipe(select(autoRegAccItemsSelector));
    this.updateProcessStatus$ = this.store.pipe(select(updateProcessSelector));
    this.popUpStatus$ = this.store.pipe(select(autoRegStatusSelector));
  }
}
