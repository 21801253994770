import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/registration/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const getConsumablesAction = createAction(
  ActionType.GET_CONSUMABLES
)

export const getConsumablesActionSuccess = createAction(
  ActionType.GET_CONSUMABLES_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const getConsumablesActionFailure = createAction(
  ActionType.GET_CONSUMABLES_FAILURE,
  props<{response: DefaultResponseType}>()
)
export const getConsumablesActionError = createAction(
  ActionType.GET_CONSUMABLES_ERROR,
  props<{error: HttpErrorResponse}>()
)
