import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {AutoGptService} from "@/main/auto-gpt/services/auto-gpt.service";
import {ToastrService} from "ngx-toastr";
import {
  getMessageChatAction, getMessageChatActionError, getMessageChatActionFailure,
  getMessageChatActionSuccess
} from "@/main/auto-gpt/store/actions/getMessageChat.action";

@Injectable()
export class GetMessageEffect {
  getMessageEffect = createEffect(() => this.actions$.pipe(
    ofType(getMessageChatAction),
    switchMap(() => {
      return this.chatService.getMessage().pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return getMessageChatActionSuccess({response})
          } else if (response.status === 'Failure') {
            return getMessageChatActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400 || 422) {
            return of(getMessageChatActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(getMessageChatActionError({error}));
          } else {
            return of(getMessageChatActionError({error}));
          }
        })
      );
    })
  ))

  constructor(private actions$: Actions,
              private chatService: AutoGptService,
              private toaStr: ToastrService
  ) {
  }
}
