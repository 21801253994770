import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActionsRoutingModule } from './actions-routing.module';
import {ImkloModule} from "@/main/actions/imklo/imklo.module";
import {KeitaroModule} from "@/main/actions/keitaro/keitaro.module";
import {ScenumModule} from "@/main/actions/scenum/scenum.module";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ActionsRoutingModule,
    ImkloModule,
    KeitaroModule,
    ScenumModule
  ]
})
export class ActionsModule { }
