import {Injectable} from "@angular/core";
import {act, Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap, of, tap, withLatestFrom} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {AutoRegService} from "@/main/autoreg/services/auto-reg.service";
import {ToastrService} from "ngx-toastr";
import {
  autoRegSetOwnerAction, autoRegSetOwnerActionFailure,
  autoRegSetOwnerActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegSetOwner.action";
import {AutoRegSetOwnerSystemAction} from "@/main/autoreg/accounts/store/actions/autoRegSetOwnerSystem.action";
import {UpdateStateRequestType} from "@/main/autoreg/accounts/store/type/requestSetOwnerSystem.type";
import {select, Store} from "@ngrx/store";
import {accUseOwnerSelector} from "@/main/autoreg/accounts/store/selectors";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";

@Injectable()
export class AutoRegSetOwnerEffect {
  autoRegSetOwnerEffect$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegSetOwnerAction),

    withLatestFrom(this.store.pipe(select(accUseOwnerSelector))),

    mergeMap(([action, selectItem]) => {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      let requestData = {
        user_id: action.id,
        logins: []
      }

      if (!action.login) {
        requestData.logins = selectItem.map(item => item.login);
      } else if (action.login) {
        requestData.logins.push(action.login);
      }

      return this.autoRegService.setOwner(requestData).pipe(
        map((response: DefaultResponseType) => {
          const logins = response.data as string[];
          let requestSystem: UpdateStateRequestType = {
            accounts: []
          }

          if (action.id !== 0) {
            requestSystem.accounts = logins.map(login => {
              return {
                login: login,
                state: 'Используется',
                checkpoint: 'No'
              }
            })
          } else {
            if (!action.login) {
              let data = selectItem.filter(item => item.owner_id !== null);
              requestData.logins = data.map(item => {
                return item.login;
              });
              requestSystem.accounts = logins.map((login, index) => {
                return {
                  login: login,
                  state: data[index].state === 'Используется' ? 'Готов' : data[index].state,
                  checkpoint: 'No'
                }
              })
            } else if (action.login) {
              requestSystem.accounts = logins.map((login, index) => {
                return {
                  login: action.login,
                  state: action.state === 'Используется' ? 'Готов' : action.state,
                  checkpoint: 'No'
                }
              })
            }
          }

          this.store.dispatch(AutoRegSetOwnerSystemAction({id: action.id, request: requestSystem}))
          return autoRegSetOwnerActionSuccess({response, logins: logins, name: action.name, id: action.id})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(autoRegSetOwnerActionFailure({error}));
        })
      );
    })
  ))

  autoRegSetOwnerSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegSetOwnerActionSuccess),
      tap(({response, logins, name, id}): void => {
        if (response.status === 'Success') {
          if (response.details.msg) {
            this.store.dispatch(ButtonLoaderAction({load: false}))
            if (response.data.length === 0) {
              this.toaStr.info(`Не удалось присвоить автореги. Вероятно все заняты.`)
            } else if (response.data.length !== 0 && id !== 0) {
              this.toaStr.info(`Автореги ${logins.toString()} присвоены владельцу ${name}`)
            } else if (response.data.length !== 0 && id === 0) {
              this.toaStr.error(`У авторегов ${logins.toString()} удален владелец`)
            }

          } else {
            this.toaStr.warning('Нет уведомления. Сообщить Максиму')
          }
        } else if (response.status === 'Failure') {
          this.store.dispatch(ButtonLoaderAction({load: false}))
          if (response.details.msg) {
            this.toaStr.error(response.details.msg)
          } else {
            this.toaStr.error('Нет уведомления. Сообщить Максиму')
          }
        }
      })
    ), {dispatch: false}
  )

  autoRegSetOwnerFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegSetOwnerActionFailure),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private autoRegService: AutoRegService,
              private toaStr: ToastrService,
              private store: Store
  ) {
  }
}
