import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const autoRegGetProxyAction = createAction(
  ActionType.GET_PROXY_FROM_FB_TOOL,
  props<{apiKey?: string}>()
)

export const autoRegGetProxyActionSuccess = createAction(
  ActionType.GET_PROXY_FROM_FB_TOOL_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const autoRegGetProxyActionFailure = createAction(
  ActionType.GET_PROXY_FROM_FB_TOOL_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegGetProxyActionError = createAction(
  ActionType.GET_PROXY_FROM_FB_TOOL_ERROR,
  props<{error: HttpErrorResponse}>()
)
