
<div *ngIf="type !== 'cart'" class="context-menu"
     [class.active]="currentAcc"
     [ngStyle]="contextMenuStyle"
     (mouseleave)="closeContextMenu()"
>
  <ng-container *ngIf="(user$ | async) as user">
    <button class="context-menu-btn fb-tools-btn mx-1"
            [disabled]="currentAcc?.state !== 'Используется' || currentAcc?.owner_id !== user.id"
            [class.disabled]="currentAcc?.state !== 'Используется' || currentAcc?.owner_id !== user.id"
            (click)="openFbTools()"
    >
      <img src="assets/img/fb-tool.png" alt="fb-tool">
    </button>
  </ng-container>

  <ng-container *ngIf="(user$ | async) as user">
    <button class="mx-1 set-owner context-menu-btn"
            [disabled]="currentAcc?.state !== 'Готов' && currentAcc?.state !== 'Проверка закончена' && currentAcc?.state !== 'Relogin выполнен'"
            [class.disabled]="currentAcc?.state !== 'Готов' && currentAcc?.state !== 'Проверка закончена' && currentAcc?.state !== 'Relogin выполнен'"
            (click)="setOwner()"
    >
      <i class="fa-solid fa-user-tag"></i>
    </button>
  </ng-container>

  <button class="mx-1 delete-owner context-menu-btn"
          [disabled]="currentAcc?.owner_id === null"
          [class.disabled]="currentAcc?.owner_id === null"
          (click)="deleteOwner()"
  >
    <i class="fa-solid fa-user-xmark"></i>
  </button>
  <button class="vision mx-1 context-menu-btn"
          (click)="openAutoRegAcc()"
  >
    <i class="fa-solid fa-book-open"></i>
  </button>
  <button class="relogin mx-1 context-menu-btn"
          [disabled]="currentAcc?.ap_id === null || (integrationToScenum$ | async) || currentAcc?.state === 'Фарм'"
          [class.disabled]="currentAcc?.ap_id === null || (integrationToScenum$ | async) || currentAcc?.state === 'Фарм'"
          (click)="reLoginProcess()"
  >
    <i class="fa-solid fa-rotate-right"></i>
  </button>
  <button class="deleted mx-1 context-menu-btn"
          (click)="deletedAutoRegItem()"
  >
    <i class="fa-solid fa-trash-can"></i>
  </button>
  <button class="add-ap mx-1 context-menu-btn"
          [disabled]="currentAcc?.ap_id === null"
          [class.disabled]="currentAcc?.ap_id === null"
          (click)="createProfileFromAdsPower()"
  >
    <i class="fa-solid fa-address-card"></i>
  </button>
  <button class="check mx-1 context-menu-btn"
          [disabled]="(integrationToScenum$ | async) || currentAcc?.state === 'Фарм'"
          [class.disabled]="(integrationToScenum$ | async) || currentAcc?.state === 'Фарм'"
          (click)="checkAcc()"
  >
    <i class="fa-solid fa-circle-exclamation"></i>
  </button>

  <button class="add-to-group mx-1 context-menu-btn"
          (click)="addToGroup()"
  >
    <i class="fa-solid fa-user-plus"></i>
  </button>

  <button class="checked-completed mx-1 context-menu-btn"
          *ngxPermissionsOnly="['ADMIN']"
          (click)="setStateAccCompleted()"
  >
    <i class="fa-solid fa-user-check"></i>
  </button>
<!--  <mat-checkbox-->
<!--    [checked]="currentAcc!.checked"-->
<!--    (change)="checked()"-->
<!--    class="example-margin checkbox" color="primary"></mat-checkbox>-->
</div>

<div *ngIf="type === 'cart'" class="context-menu"
     [class.active]="currentAcc"
     [ngStyle]="contextMenuStyle"
     (mouseleave)="closeContextMenu()"
>
  <button class="vision mx-1 context-menu-btn"
          (click)="openAutoRegAccCart()"
  >
    <i class="fa-solid fa-book-open"></i>
  </button>

  <button class="restore mx-1 context-menu-btn"
          (click)="restore()"
  >
    <i class="fa-solid fa-trash-can-arrow-up"></i>
  </button>
</div>

<div *ngIf="type === 'emails'" class="context-menu"
     [class.active]="currentEmail"
     [ngStyle]="contextMenuStyle"
     (mouseleave)="closeContextMenu()"
>
  <button class="hand mx-1 context-menu-btn"
          (click)="updateEmailStatus()"
  >
    <i class="fa-solid fa-hand"></i>
  </button>
</div>
