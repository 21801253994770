import { Pipe, PipeTransform } from '@angular/core';
import {UsersType} from "@/users/store/type/users.type";

@Pipe({
  name: 'getUserRole'
})
export class GetUserRolePipe implements PipeTransform {

  transform(arr: UsersType[], arg): UsersType[] {
    return arr.filter(item => item.role === arg);
  }

}
