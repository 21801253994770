import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {autoRegChangeSettingsActions} from "@/main/autoreg/accounts/store/actions/autoRegChangeSettings.action";
import {select, Store} from "@ngrx/store";
import {AutoRegFilterType} from "@/main/autoreg/accounts/store/type/AutoRegFilter.type";
import {Observable} from "rxjs";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {columnsSelector, tableSettingsSelector} from "@/main/autoreg/accounts/store/selectors";

@Component({
  selector: 'app-settings-col',
  templateUrl: './settings-col.component.html',
  styleUrls: ['./settings-col.component.scss']
})

export class SettingsColComponent implements OnInit {

  @Output() dataPopUp: EventEmitter<AutoRegFilterType> = new EventEmitter();

  public columns$: Observable<string[]>;
  public tableState$: Observable<string[]>;

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
  }

  public updateSettings(value: string): void {
    this.store.dispatch(autoRegChangeSettingsActions({ value }));
  }

  public setDefaultSettings(value): void {
    this.store.dispatch(autoRegChangeSettingsActions({ value }));
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  private initValue(): void {
    this.columns$ = this.store.pipe(select(columnsSelector));
    this.tableState$ = this.store.pipe(select(tableSettingsSelector));
  }
}
