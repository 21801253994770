import {createAction, props} from "@ngrx/store";

import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";

export const autoRegFbGroupAction = createAction(
  ActionType.AUTO_REG_FB_GROUP,
  props<{apiKey?: string}>()
)

export const autoRegFbGroupActionSuccess = createAction(
  ActionType.AUTO_REG_FB_GROUP_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const autoRegFbGroupActionFailure = createAction(
  ActionType.AUTO_REG_FB_GROUP_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegFbGroupActionError = createAction(
  ActionType.AUTO_REG_FB_GROUP_ERROR,
  props<{error: HttpErrorResponse}>()
)
