import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {PopUpType} from "@/shared/types/popUp.type";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Observable, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {popUpSelector, usersSelector} from "@/users/store/selectors";
import {UsersType} from "@/users/store/type/users.type";
import {ContextMenuEventType} from "@/shared/types/contextMenuEvent.type";
import {PopUpAction} from "@/users/store/actions/popUp.action";
import {deleteUsersAction} from "@/users/store/actions/deleteUsers.action";
import {activateUserAction} from "@/users/store/actions/activateUser.action";
import {GetUsersAction} from "@/users/store/actions/getUsers.action";

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit, OnDestroy {

  @ViewChild('popup') popupElement: TemplateRef<ElementRef>
  @ViewChild('redactionUser') redactionUserElement: TemplateRef<ElementRef>
  @ViewChild('registrationComponent') registrationElement!: TemplateRef<ElementRef>

  public users$: Observable<UsersType[]>;
  public popUp$: Observable<boolean>;

  public type: string;
  public nameUser: string;
  public user: UsersType;
  public contextMenuStyle: any;

  private popUpType = PopUpType;
  private dialogRef: MatDialogRef<any>;
  private subscriptionList: Subscription[] = [];
  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store,
              private matDialog: MatDialog) {
  }

  public ngOnInit(): void {
    this.initValue();
    this.sub = this.popUp$.subscribe(data => {
      if(!data) {
        this.closePopUp();
      }
    })
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe())
  }

  public updateList(): void {
    this.store.dispatch(GetUsersAction());
  }

  public contextMenuEvent($event: string | boolean): void {
    if ($event) {
      switch ($event) {
        case ContextMenuEventType.ACTIVATED_USER:
          this.activated(this.user.username)
          break;

        case ContextMenuEventType.DELETED_USER:
          this.deleted(this.user.username)
          break;

        case ContextMenuEventType.BLOCKED_USER:
          this.lock(this.user.username)
          break;

        case ContextMenuEventType.REDACTION_USER:
          this.redaction()
          break;
      }
    }
  }

  public registration(): void {
    this.store.dispatch(PopUpAction({statePopUp: true}))
    this.dialogRef = this.matDialog.open(this.registrationElement);
  }

  public activated(name): void {
    this.nameUser = name;
    this.store.dispatch(PopUpAction({statePopUp: true}))
    this.type = this.popUpType.ACTIVATED_ACC;
    this.dialogRef = this.matDialog.open(this.popupElement)
  }

  public deleted(name): void {
    this.nameUser = name;
    this.store.dispatch(PopUpAction({statePopUp: true}))
    this.type = this.popUpType.DELETED_ACC_USER;
    this.dialogRef = this.matDialog.open(this.popupElement)
  }

  public lock(name): void {
    this.nameUser = name;
    this.store.dispatch(PopUpAction({statePopUp: true}))
    this.type = this.popUpType.BLOCKED_ACC_USER;
    this.dialogRef = this.matDialog.open(this.popupElement)
  }

  public redaction(): void {
    this.store.dispatch(PopUpAction({statePopUp: true}))
    this.dialogRef =  this.matDialog.open(this.redactionUserElement);
  }

  public popUpActions(action): void {
    if (!action.constellation) {
      this.closePopUp();
    } else if (action.constellation && this.type === this.popUpType.ACTIVATED_ACC) {
      this.store.dispatch(activateUserAction({id: this.user.id}))

    } else if (action.constellation && this.type === this.popUpType.DELETED_ACC_USER) {
      this.store.dispatch(deleteUsersAction({id: this.user.id}))
    }
  }

  public onRightClick($event: MouseEvent, user: UsersType) {
    $event.preventDefault();
    this.contextMenuStyle = {
      'left.px': $event.clientX + window.pageXOffset - 90,
      'top.px': $event.clientY + window.pageYOffset - 140,
    }
    this.user = user;
  }

  private closePopUp(): void {
    if(this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private initValue(): void {
    this.users$ = this.store.pipe(select(usersSelector));
    this.popUp$ = this.store.pipe(select(popUpSelector));
  }
}
