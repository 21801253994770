import {Component, OnInit, Output, EventEmitter, OnDestroy, ViewChild, TemplateRef, ElementRef} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AuthService} from "@/auth/services/auth.service";
import {ToastrService} from "ngx-toastr";
import {select, Store} from "@ngrx/store";
import {registerAction} from "@/auth/store/actions/register.action";
import {Observable, Subscription} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";
import {PopUpAction} from "@/users/store/actions/popUp.action";
import {buttonLoaderSelector} from "@/ui/store/selectors";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {

  @ViewChild('popup') popupElement: TemplateRef<ElementRef>
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();

  public roles: {id: number, role: string}[] = [];
  public registrationForm: UntypedFormGroup;

  public buttonLoader$: Observable<boolean>;

  private subscriptionList: Subscription[] = [];
  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initialForm();
    this.initValue();
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe());
  }

  public cancel(): void {
    this.store.dispatch(PopUpAction({statePopUp: false}))
  }

  public registration(): void {
    if (this.registrationForm.value.name
      && this.registrationForm.value.email
      && this.registrationForm.value.password
      && this.registrationForm.value.role || this.registrationForm.value.role === 0) {
      const request = {
        username: this.registrationForm.value.name,
        email: this.registrationForm.value.email,
        password: this.registrationForm.value.password,
        role_id: this.registrationForm.value.role,
        is_active: false
      }

      this.store.dispatch(registerAction({ request }))
    }
  }

  private initialForm(): void {
    this.registrationForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', Validators.required),
      role: new UntypedFormControl(0, Validators.required)
    });
  }

  private initValue(): void {
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
    this.roles = [
      { id: 0, role: 'Гость' },
      { id: 1, role: 'Баинг'},
      { id: 2, role: 'Контент-менеджер'},
      { id: 3, role: 'Вебмастер'},
      { id: 4, role: 'Администратор'}
    ]
  }
}
