import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";

import {
  autoRegAccActions, autoRegAccActionsFailure,
  autoRegAccActionsSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegAcc.actions";
import {AutoRegService} from "@/main/autoreg/services/auto-reg.service";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {Store} from "@ngrx/store";
import {pageLoaderAction} from "@/ui/store/actions/pageLoader.action";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {getAutoRegsAction} from "@/main/autoreg/registration/store/actions/getAutoRegs.action";

@Injectable()
export class AutoRegAccEffect {
  autoRegAcc$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegAccActions),
    switchMap(() => {
      this.store.dispatch(pageLoaderAction({load: true}))
      return this.autoRegService.getAutoRegData()
        .pipe(
          map((response: DefaultResponseType) => {
            let authorID = response.details.author.id;
            let username = response.details.author.username;
            let responseData = response.data.map((item, index) => {
              let cookie = this.getCookie(item);
              return {
                ...item,
                checked: false,
                id: index + 1,
                type: '',
                group_name: '',
                action_status: this.setActionStatus(item.state, item.action_status),
                profile_id: this.setProfileID(cookie, item.profile_id),
                mismatchCookieAndId: this.getMismatchCookieAndId(cookie, item.profile_id)
              }
            });

            if (response.details.role === 1) {
              responseData = responseData.filter(item => item.owner_id === authorID || item.owner_id === null);
            }
            this.store.dispatch(getAutoRegsAction({autoregs: responseData}))
            return autoRegAccActionsSuccess({response, responseData, currentUserID: authorID})
          }),
          catchError((error: HttpErrorResponse) => {
            return of(autoRegAccActionsFailure({error}));
          })
        );
    })
  ))


  autoRegAccSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegAccActionsSuccess),
      tap((response: { response: DefaultResponseType }): void => {
        this.store.dispatch(pageLoaderAction({load: false}))
        if (response.response.status === 'Failure') {
          console.error('Автореги не получены')
        }
      })
    ), {dispatch: false}
  )

  autoRegAccFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegAccActionsFailure),
      tap(({error}): void => {
        this.store.dispatch(pageLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          console.error('Автореги не получены')
        } else if (error.status === 500 || 501) {
          console.error('Автореги не получены.Ошибка сервера')
        }
      })
    ), {dispatch: false}
  )

  private setActionStatus(state, action_status): string {
    if (action_status) {
      return action_status;
    } else if ((state === 'Готов' || state === 'Используется'
      || state === 'Relogin выполнен'
      || state === 'Проверка закончена')
      && action_status === null) {
      return 'Завершен'
    } else if (action_status === null) {
      return null;
    }
  }

  private getCookie(account: AutoRegItemType): number | null {
    let cookieArr = [];
    let valueId = 0;
    if (account.cookie !== null && account.cookie !== 'Not set') {
      try {
        cookieArr = JSON.parse(account.cookie);
      } catch (e) {
        console.log(e);
      }
    }

    if (cookieArr.length !== 0) {
      cookieArr.forEach(cookieItem => {
        if (cookieItem.name === 'c_user') {
          if (cookieItem.value) {
            valueId = cookieItem.value
          } else if (cookieItem.value === 0) {
            valueId = null;
          }
        }
      })
    }

    return valueId;
  }

  private setProfileID(cookie, profileId: number): number {
    if (profileId && cookie) {
      return cookie;
    } else if (!profileId && !cookie) {
      return null;
    } else if (profileId && profileId === -1 && cookie) {
      return cookie;
    } else if (profileId && profileId === -1 && !cookie) {
      return null;
    } else if (!cookie && profileId) {
      return profileId
    } else if (!profileId && cookie) {
      return cookie
    }
  }

  private getMismatchCookieAndId(cookie, profileId): boolean {
    if (cookie !== null && profileId !== null) {
      return cookie === profileId
    }
    return false;
  }

  constructor(private actions$: Actions,
              private autoRegService: AutoRegService,
              private store: Store
  ) {
  }
}
