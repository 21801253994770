import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KeitaroRoutingModule } from './keitaro-routing.module';
import { KeitaroComponent } from './keitaro.component';
import { AffiliateComponent } from './affiliate/affiliate.component';
import { BotsComponent } from './bots/bots.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { FlowsComponent } from './flows/flows.component';
import { ClicksComponent } from './clicks/clicks.component';
import { ConversionsComponent } from './conversions/conversions.component';
import { DomainsComponent } from './domains/domains.component';
import { GroupsComponent } from './groups/groups.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { IpComponent } from './ip/ip.component';
import { OffersComponent } from './offers/offers.component';
import { TraficComponent } from './trafic/trafic.component';
import { UsersComponent } from './users/users.component';
import { LogsComponent } from './logs/logs.component';
import { ReportsComponent } from './reports/reports.component';
import { CleanComponent } from './clean/clean.component';


@NgModule({
  declarations: [
    KeitaroComponent,
    AffiliateComponent,
    BotsComponent,
    CampaignsComponent,
    FlowsComponent,
    ClicksComponent,
    ConversionsComponent,
    DomainsComponent,
    GroupsComponent,
    IntegrationsComponent,
    IpComponent,
    OffersComponent,
    TraficComponent,
    UsersComponent,
    LogsComponent,
    ReportsComponent,
    CleanComponent
  ],
  imports: [
    CommonModule,
    KeitaroRoutingModule
  ]
})
export class KeitaroModule { }
