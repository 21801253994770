<button class="sort-button" mat-icon-button [matMenuTriggerFor]="menu" >
  <i class="fa-solid fa-arrow-down-wide-short"></i>
</button>

<mat-menu #menu="matMenu" xPosition="before">
  <button mat-menu-item class="menu-sort-item"
          [class.active]="(filterValue$ | async).sortValue === 'group'"
          (click)="sort('group')"
  >
    <span class="icon"><i class="fa-solid fa-check"></i></span>
    <span>Сначала с группой</span>
  </button>

  <button mat-menu-item class="menu-sort-item"
          [class.active]="(filterValue$ | async).sortValue === 'dateNew'"
          (click)="sort('dateNew')"
  >
    <span class="icon"><i class="fa-solid fa-check"></i></span>
    <span>Сначала новые</span>
  </button>

  <button mat-menu-item class="menu-sort-item"
          [class.active]="(filterValue$ | async).sortValue === 'dateOld'"
          (click)="sort('dateOld')"
  >
    <span class="icon"><i class="fa-solid fa-check"></i></span>
    <span>Сначала старые</span>
  </button>

</mat-menu>


