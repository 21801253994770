import {AppState} from "@/store/state";
import {createSelector} from "@ngrx/store";
import {ChatGPT} from "@/main/auto-gpt/store/index";

export const chatGptFeatureSelector = (state: AppState) => state.chatGPT
export const chatsSelector = createSelector(
  chatGptFeatureSelector,
  (chatGPT: ChatGPT) => chatGPT.chats
)

export const messagesSelector = createSelector(
  chatGptFeatureSelector,
  (chatGPT: ChatGPT) => chatGPT.messages
)

export const chatCurrentSelector = createSelector(
  chatGptFeatureSelector,
  (chatGPT: ChatGPT) => chatGPT.currentChat
)

export const messagesCurrentSelector = createSelector(
  chatGptFeatureSelector,
  (chatGPT: ChatGPT) => chatGPT.currentMessages
)
