import {Directive, HostListener, Input} from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Directive({
  selector: '[appClipboardCookie]'
})
export class ClipboardCookieDirective {

  @Input() cookie: string;
  constructor(private toaStr: ToastrService) {
  }

  @HostListener('dblclick', ['$event.target'])
  onClick(element: HTMLInputElement) {
    const value = this.cookie;
    const input = document.createElement('input');
    input.style.position = 'fixed';
    input.style.opacity = '0';
    input.value = value;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    this.toaStr.success('Содержимое ячейки скопировано в буфер обмена')
    document.body.removeChild(input);
  }

}
