import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {StateDataType} from "@/main/autoreg/stats/store/type/stateData.type";
import {statisticsStateSelector} from "@/main/autoreg/stats/store/selectors";

@Component({
  selector: 'app-state-register-acc',
  templateUrl: './state-register-acc.component.html',
  styleUrls: ['./state-register-acc.component.scss']
})
export class StateRegisterAccComponent implements OnInit, OnDestroy {

  public dataCharts;

  public data$: Observable<StateDataType>;

  private subscriptionList: Subscription[] = [];
  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
    this.sub = this.data$.subscribe(data => {
      this.initCharts(data.state, data.count, data.color);
    })
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe())
  }

  private initValue(): void {
    this.data$ = this.store.pipe(select(statisticsStateSelector));
  }

  private initCharts(state, count, colors): void {
    this.dataCharts = {
      doughnutChartLabels: state,
      doughnutChartData: [
        {
          data: count,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1
        }
      ],
      doughnutChartType: 'doughnut'
    }
  }
}
