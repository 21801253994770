import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const AutoRegGetGroupAction = createAction(
  ActionType.AUTO_REG_GET_GROUP,
  props<{update?: boolean}>()
)

export const AutoRegGetGroupActionSuccess = createAction(
  ActionType.AUTO_REG_GET_GROUP_SUCCESS,
  props<{response: DefaultResponseType, update: boolean}>()
)

export const AutoRegGetGroupActionFailure = createAction(
  ActionType.AUTO_REG_GET_GROUP_FAILURE,
  props<{error: HttpErrorResponse}>()
)
