import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/users/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const getMeAction = createAction(
  ActionType.GET_ME
)

export const getMeActionSuccess = createAction(
  ActionType.GET_ME_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const getMeActionFailure = createAction(
  ActionType.GET_ME_FAILURE,
  props<{error: HttpErrorResponse}>()
)
