<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-6 d-flex align-items-center">
                <div class="dt-buttons btn-group flex-nowrap">
                  <button
                    class="action_btn download-button"
                    [class.active]="(autoRegSelected$ | async).length !== 0"
                    (click)="downloadTable()"
                  >
                    <i class="fa-solid fa-file-arrow-down"></i>
                    <span class="count"
                          *ngIf="(autoRegSelected$ | async).length !== 0"
                    >{{(autoRegSelected$ | async).length}}</span>
                  </button>

                  <button
                    class="action_btn create-group-button"
                    (click)="createGroup()"
                  >
                    <i class="fa-solid fa-people-group"></i>
                  </button>

                  <button
                    class="action_btn deleted-group"
                    (click)="deletedGroup()"
                    *ngxPermissionsOnly="['ADMIN']"
                  >
                    <i class="fa-solid fa-delete-left"></i>
                  </button>

                  <button
                    class="action_btn fb-tools"
                    [class.active]="(accFbTool$ | async).length === 0 || (accFbTool$ | async).length > 40"
                    [class.no-active]="(accFbTool$ | async).length > 40"
                    [disabled]="(accFbTool$ | async).length === 0 || (accFbTool$ | async).length > 40"
                    (click)="openFbToolsItems()"
                  >
                    <img src="assets/img/fb-tool.png" alt="fb-tools">
                    <span class="count"
                          *ngIf="(accFbTool$ | async).length !== 0"
                    >{{(accFbTool$ | async).length}}</span>
                  </button>

                  <button class="action_btn re-login"
                          [class.active]="(accToReLogin$ | async).length === 0 || (accToReLogin$ | async).length > 40 || (integrationToScenum$ | async)"
                          [class.no-active]="(accToReLogin$ | async).length > 40"
                          [disabled]="(accToReLogin$ | async).length === 0 || (accToReLogin$ | async).length > 40 || (integrationToScenum$ | async)"
                          (click)="reLoginProcess('group')"
                  >
                    <i class="fa-solid fa-rotate-right"></i>
                    <span class="count"
                          *ngIf="(accToReLogin$ | async).length !== 0"
                    >{{(accToReLogin$ | async).length}}</span>
                  </button>

                  <button
                    class="action_btn add-owner"
                    [class.active]="(accUseOwner$ | async).length === 0 || (accUseOwner$ | async).length > 40"
                    [class.no-active]="(accUseOwner$ | async).length > 40"
                    [disabled]="(accUseOwner$ | async).length === 0 || (accUseOwner$ | async).length > 40"
                    *ngIf="(currentUser$ | async) as currentUser"
                    (click)="setOwner(currentUser.role, currentUser.id, currentUser.username)"
                  >
                    <i class="fa-solid fa-user-tag"></i>
                    <span class="count"
                          *ngIf="(accUseOwner$ | async).length !== 0"
                    >{{(accUseOwner$ | async).length}}</span>
                  </button>

                  <button
                    class="action_btn deleted-owner"
                    [class.active]="(accDeleteOwnerItems$ | async).length === 0"
                    [disabled]="(accDeleteOwnerItems$ | async).length === 0"
                    (click)="deletedOwner('group')"
                  >
                    <i class="fa-solid fa-user-xmark"></i>
                    <span class="count"
                          *ngIf="(accDeleteOwnerItems$ | async).length !== 0"
                    >{{(accDeleteOwnerItems$ | async).length}}</span>
                  </button>

                  <button
                    class="action_btn delete-button"
                    [class.active]="(autoRegSelected$ | async).length === 0"
                    [disabled]="(autoRegSelected$ | async).length === 0"
                    (click)="deletedGroupAcc()"
                  >
                    <i class="fa-solid fa-trash"></i>
                    <span class="count"
                          *ngIf="(autoRegSelected$ | async).length !== 0"
                    >{{(autoRegSelected$ | async).length}}</span>
                  </button>

                  <button
                    class="action_btn create-button"
                    [class.active]="(accIsCreateAP$ | async).length === 0 || (accIsCreateAP$ | async).length > 40"
                    [disabled]="(accIsCreateAP$ | async).length === 0 || (accIsCreateAP$ | async).length > 40"
                    [class.no-active]="(accIsCreateAP$ | async).length > 40"
                    (click)="createProfileFromAdsPowerItems()"
                  >
                    <i class="fa-solid fa-address-card"></i>
                    <span class="count"
                          *ngIf="(accIsCreateAP$ | async).length !== 0"
                    >{{(accIsCreateAP$ | async).length}}</span>
                  </button>

                  <button
                    class="action_btn examination-button"
                    [class.active]="(accCheckItems$ | async).accounts.length === 0 || (accCheckItems$ | async).accounts.length > 40 || (integrationToScenum$ | async)"
                    [disabled]="(accCheckItems$ | async).accounts.length === 0 || (accCheckItems$ | async).accounts.length > 40 || (integrationToScenum$ | async)"
                    [class.no-active]="(accCheckItems$ | async).accounts.length > 40"
                    (click)="checkAccounts(popUpType.CHECK_ACC_ITEMS)"
                  >
                    <i class="fa-solid fa-circle-exclamation"></i>
                    <span class="count"
                          *ngIf="(accCheckItems$ | async).accounts.length !== 0"
                    >{{(accCheckItems$ | async).accounts.length}}</span>
                  </button>

                  <button
                    class="action_btn add-to-group"
                    [class.active]="(accAddToGroupItems$ | async).length === 0"
                    [disabled]="(accAddToGroupItems$ | async).length === 0"
                    (click)="addToGroupAutoReg(popUpType.ADD_TO_GROUP_ITEMS)"
                  >
                    <i class="fa-solid fa-user-plus"></i>
                    <span class="count"
                          *ngIf="(accAddToGroupItems$ | async).length !== 0"
                    >{{(accAddToGroupItems$ | async).length}}</span>
                  </button>

                  <button
                    *ngxPermissionsOnly="['ADMIN']"
                    class="action_btn completed-button"
                    [class.active]="(autoRegSelected$ | async).length === 0"
                    [disabled]="(autoRegSelected$ | async).length === 0"
                    (click)="accChangeStateToCompleted()"
                  >
                    <i class="fa-solid fa-user-check"></i>
                    <span class="count"
                          *ngIf="(autoRegSelected$ | async).length !== 0"
                    >{{(autoRegSelected$ | async).length}}</span>
                  </button>

                  <button
                    class="action_btn garbage-button"
                    [class.active]="(autoRegSelected$ | async).length === 0"
                    [disabled]="(autoRegSelected$ | async).length === 0"
                    (click)="accChangeStateToGarbage()"
                  >
                    <i class="fa-solid fa-recycle"></i>
                    <span class="count"
                          *ngIf="(autoRegSelected$ | async).length !== 0"
                    >{{(autoRegSelected$ | async).length}}</span>
                  </button>

                </div>
              </div>
              <div class="col-md-6">

                <div id="example1_filter" class="dataTables_filter d-flex align-items-center justify-content-end">

                  <app-search [type]="'autoreg'"></app-search>

                  <app-filter-users></app-filter-users>

                  <app-filter-group></app-filter-group>

                  <app-sort></app-sort>

                  <app-date-picker-filter></app-date-picker-filter>

                  <app-filter-state></app-filter-state>

                  <app-additional-filters></app-additional-filters>

                  <button class="settings-button"
                          (click)="openSettingsCol()"
                  >
                    <i class="fa-solid fa-gear"></i>
                  </button>

                  <button class="update-button"
                          (click)="update()"
                  >
                    <i class="fa-solid fa-arrows-rotate"></i>
                  </button>

                  <button class="clear-button"
                          (click)="clearLocalStorage()"
                  >
                    <i class="fa-solid fa-broom"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row mt-2 mx-2">
                <app-check-filters></app-check-filters>
            </div>
          </div>

          <div class="card-body table-two" appScroll [permissions]="scrollElement">
            <table class="table table-bordered auto-reg-table mb-5">

              <thead (mousedown)="scrollElement = false">
                <tr cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropColumnTable($event)">
                  <th>
                    <input class="choose-all" type="checkbox"
                           [checked]="countCheckAccInCurrentPage$ | async"
                           (change)="onCheckbox(0)">
                  </th>
                  <th *ngFor="let nameColumn of columns$ | async" cdkDrag>{{ nameColumn | setColumnName }}</th>
                </tr>
              </thead>

              <tbody>
              <tr *ngFor="let item of autoRegAccItems; let i = index"
                  [class.tr-active]=item.checked
                  [class.tr-active-fb]="(accUseOwner$ | async).includes(item)"
                  [class.select-tr]="currentAcc && (currentAcc.id === item.id && selectTr)"
                  (contextmenu)="onRightClick($event, item)"
              >
                <td class="no-hover checkbox">
                  <input type="checkbox" (change)="onCheckbox(item.id)" [checked]="item.checked">
                </td>

                <td appClipboard *ngFor="let nameColumn of columns">

                  <ng-container *ngIf="nameColumn !== 'step'
                    && nameColumn !== 'state'
                    && nameColumn !== 'owner_id'
                    && nameColumn !== 'profile_id'
                    && nameColumn !== 'type'
                    && nameColumn !== 'checkpoint'
                    && nameColumn !== 'token'
                    && nameColumn !== 'ua'
                    && nameColumn !== 'cookie'
                    && nameColumn !== 'date'
                    && nameColumn !== 'action_status'
                    && nameColumn !== 'twofa'
                    ">
                    <span *ngIf="item[nameColumn]">{{item[nameColumn]}}</span>
                    <span *ngIf="!item[nameColumn]"> - </span>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'twofa'">
                    <span *ngIf="item[nameColumn] && item[nameColumn] !== 'Not set'">{{item[nameColumn]}}</span>
                    <span *ngIf="item[nameColumn] && item[nameColumn] === 'Not set'"> - </span>
                    <span *ngIf="!item[nameColumn]"> - </span>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'step'">
                    <span *ngIf="!item[nameColumn]"> - </span>
                    <span *ngIf="item[nameColumn]" class="step"
                          appBgColorStep
                          [step]="item[nameColumn]"
                    >{{item[nameColumn]}}</span>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'state'">
                    <span *ngIf="!item[nameColumn]"> - </span>
                    <span *ngIf="item[nameColumn]" class="step"
                          appBgColorState
                          [state]="item[nameColumn]"
                    >{{item[nameColumn]}}</span>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'owner_id'">
                    <ng-container *ngIf="(users$ | async) as users">
                      <span>{{item[nameColumn] | setOwner:users}}</span>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'profile_id'">
                      <span *ngIf="item[nameColumn] !== null"
                            [class.text-warning]="item.mismatchCookieAndId"
                      >{{item[nameColumn]}}</span>
                      <span *ngIf="item[nameColumn] === null">
                        <i class="fa-regular fa-clock"></i>
                      </span>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'action_status'">
                      <span *ngIf="item[nameColumn] !== null && item[nameColumn] !== 'Not set'"
                            appTextColor
                            [text]="item[nameColumn]"
                      >{{item[nameColumn]}}</span>
                    <span *ngIf="item[nameColumn] === null || item[nameColumn] === 'Not set'">
                        <i class="fa-regular fa-clock"></i>
                      </span>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'group_id'">
                    <ng-container *ngIf="(autoRegGroup$ | async) as group">
                      <span>{{item[nameColumn] | setGroup:group }}</span>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'type'">
                    <span>{{item[nameColumn] | setType }}</span>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'checkpoint'">
                    <span *ngIf="item[nameColumn] === null">
                      <i class="fa-regular fa-clock"></i>
                    </span>
                    <span *ngIf="item[nameColumn] === 'No'">
                      <i class="fa-solid fa-circle-check text-success"></i>
                    </span>
                    <span *ngIf="item[nameColumn] === 'Yes'">
                      <i class="fa-solid fa-triangle-exclamation text-danger"></i>
                    </span>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'token'">
                    <span *ngIf="item[nameColumn] === null">
                      <i class="fa-solid fa-triangle-exclamation text-danger"></i>
                    </span>
                    <span *ngIf="item[nameColumn] === 'Не удалось найти токен, перейдите в Ads Manager и попробуйте снова!'">
                      {{item[nameColumn]}} <i class="fa-solid fa-triangle-exclamation text-danger"></i>
                    </span>
                    <span *ngIf="item[nameColumn] !== null && item[nameColumn] !== 'Не удалось найти токен, перейдите в Ads Manager и попробуйте снова!'">{{item.token}}</span>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'ua'">
                    <span *ngIf="item[nameColumn] === null"><i class="fa-solid fa-triangle-exclamation text-danger"></i></span>
                    <span *ngIf="item[nameColumn] !== null">{{item[nameColumn]}}</span>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'cookie'">
                    <span *ngIf="!item[nameColumn]" class="cookie"><i class="fa-solid fa-triangle-exclamation text-danger"></i></span>
                    <span *ngIf="item[nameColumn]" class="cookie"><i class="fa-solid fa-circle-check text-success"></i></span>
                  </ng-container>

                  <ng-container *ngIf="nameColumn === 'date'">
                    <span>{{item[nameColumn] ? (item[nameColumn] | date: 'dd.MM.YYYY') : 'Отсутсвует'}}</span>
                  </ng-container>

                </td>

              </tr>
              <tr *ngIf="autoRegAccItems?.length === 0">
                <td colspan="19">Данных нет</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer clearfix">
            <mat-paginator *ngIf="(pageNumbers$ | async).length > 1"
                           class="demo-paginator"
                           (page)="handlePageEvent($event)"
                           [length]="(autoRegAccItems$ | async).length"
                           [pageSize]="pageSize$ | async"
                           [disabled]="disabled"
                           [showFirstLastButtons]="showFirstLastButtons"
                           [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                           [hidePageSize]="hidePageSize"
                           [pageIndex]="currentPage$ | async"
                           aria-label="Выбрать страницу">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #popup>
  <app-popup
    [type]="typePopUp"
    [account]="currentAcc"
    (dataPopUp)="popUpActions($event)"
  ></app-popup>
</ng-template>

<ng-template #fbTools>
  <app-fb-tools
    [autoRegItem]="currentAcc"
    (dataPopUp)="popUpActions($event)"
  ></app-fb-tools>
</ng-template>

<ng-template #fbToolsItems>
  <app-fb-tools-items
    (dataPopUp)="popUpActions($event)"
  ></app-fb-tools-items>
</ng-template>

<ng-template #reLogin>
  <app-relogin
    [account]="currentAcc"
    [type]="typePopUp"
    (dataPopUp)="popUpActions($event)"
  ></app-relogin>
</ng-template>

<ng-template #autoRegItem>
  <app-auto-reg-item
    [autoRegItem]="currentAcc"
  ></app-auto-reg-item>
</ng-template>

<ng-template #settingsCol>
  <app-settings-col></app-settings-col>
</ng-template>

<ng-template #createProfileAP>
  <app-create-profile
    [type]="typePopUp"
    [account]="currentAcc"
  ></app-create-profile>
</ng-template>

<ng-template #checkAcc>
  <app-check-accounts
    [account]="currentAcc"
    [type]="typePopUp"
  ></app-check-accounts>
</ng-template>

<ng-template #deletedAcc>
  <app-delete-acc
    [type]="typePopUp"
    [account]="currentAcc"
    (deletePopUp)="popUpActions($event)"
  ></app-delete-acc>
</ng-template>

<ng-template #createGroupElement>
  <app-create-group
  ></app-create-group>
</ng-template>

<ng-template #deletedGroupElement>
  <app-deleted-group
    (deletedGroupEvent)="popUpActions($event)"
  ></app-deleted-group>
</ng-template>

<ng-template #setOwnerElement>
  <app-set-owner
    [type]="typePopUp"
    [account]="currentAcc"
  ></app-set-owner>
</ng-template>

<ng-template #addToGroupElement>
  <app-add-to-group
    [type]="typePopUp"
    [account]="currentAcc"
    (addToGroupEvent)="popUpActions($event)"
  ></app-add-to-group>
</ng-template>

<app-context-menu
  [contextMenuStyle]="contextMenuStyle"
  [currentAcc]="currentAcc"
  (contextMenuEvent)="contextMenuEvent($event)"
></app-context-menu>

