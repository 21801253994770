import {Directive, ElementRef, Input, OnInit} from '@angular/core';


@Directive({
  selector: '[appReplaceNull]'
})
export class ReplaceNullDirective implements OnInit {

  @Input() value: string;
  constructor(private elementRef: ElementRef) { }

  public ngOnInit(): void {
    if (this.value === null) {
      this.elementRef.nativeElement.style.innerText = ' - '
    }
  }

}
