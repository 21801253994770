<div class="row"  *ngIf="(reger$ | async) && (consumables$ | async)">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <table class="table table-sm table-one">
          <thead>
          <tr>
            <th>Состояние</th>
            <th>Количество процессов</th>
            <th>Действия</th>
            <th>Зарегистрировано сегодня</th>
            <th>SMS Activate</th>
            <th>Израильских номеров</th>
            <th>Американских номеров</th>
            <th>Почты</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <span *ngIf="(reger$ | async)!.state === 'Start'" class="text-success">Запущен</span>
              <span *ngIf="(reger$ | async)!.state === 'Stop'" class="text-danger pause-reg">Стоп</span>
            </td>
            <td>
              <mat-slider class="w-100" min="1" max="3" step="1"
                          [disabled]="(reger$ | async)!.state === 'Start'"
                          showTickMarks
                          discrete
                          [displayWith]="formatLabel">
                <input matSliderThumb
                       #inputElement
                       [value]="(reger$ | async)!.processes">
              </mat-slider>
            </td>
            <td class="actions">
              <ng-container *ngIf="reger$ | async as reger">
                <button class="play-process"
                        *ngIf="reger.state === 'Stop' && !(updateProcessStatus$ | async)"
                        (click)="startReger(+inputElement.value)"
                ><i class="fa-solid fa-play text-success"></i></button>
                <span class="d-flex justify-content-center"
                      *ngIf="updateProcessStatus$ | async"
                ><mat-spinner diameter="20"></mat-spinner></span>
                <button class="stop-process"
                        *ngIf="reger.state === 'Start' && !(updateProcessStatus$ | async)"
                        (click)="stopReger(+inputElement.value, reger.pids)"
                ><i class="fa-solid fa-stop"></i></button>
              </ng-container>
            </td>
            <td>{{registeredToDay$ | async}}</td>
            <td>{{(consumables$ | async).sa_balance}}</td>
            <td>{{(consumables$ | async).sa_il_numbers}}</td>
            <td><span class="am-numbers">{{(consumables$ | async).sa_usa_numbers}}</span></td>
            <td>
              {{(consumables$ | async).email_count}}
              <button class="btn-add-email"
              (click)="addEmails()"
              ><i class="fa-solid fa-circle-plus"></i></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


<ng-template #addEmailsComponent>
  <app-add-emails></app-add-emails>
</ng-template>
