<div class="date-picker-component">
  <mat-form-field class="date-range mt-3" appearance="fill">
    <mat-date-range-input
      [min]="(data$ | async).minDate"
      [max]="(data$ | async).maxDate"
      [formGroup]="rangeForm"
      [rangePicker]="picker">
      <input  matStartDate formControlName="start" placeholder="Start date">
      <input  matEndDate formControlName="end" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker>
      <mat-datepicker-actions>
        <button mat-button matDatepickerCancel>Отмена</button>
        <button mat-raised-button color="primary" matDatepickerApply
                (click)="updateDate()"
        >Установить</button>
      </mat-datepicker-actions>
    </mat-date-range-picker>

    <mat-error *ngIf="rangeForm.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="rangeForm.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>

  <button class="btn-date-picker" mat-icon-button (click)="picker.open()">
    <i class="fa-solid fa-calendar-days"></i>
  </button>
</div>

