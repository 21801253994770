<div class="registration">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0 process">
        <div class="card-header">
          <h3 class="card-title">Регистрация</h3>
        </div>
        <div class="card-body pb-0" [formGroup]="registrationForm">
          <div class="row">

            <div class="col-12">
              <mat-form-field class="example-full-width">
                <mat-label>Имя</mat-label>
                <input matInput placeholder="Имя" formControlName="name">
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field class="example-full-width">
                <mat-label>Пароль</mat-label>
                <input matInput placeholder="Пароль" formControlName="password">
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field class="example-full-width">
                <mat-label>Электронная почта</mat-label>
                <input matInput placeholder="Электронная почта" formControlName="email">
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Роль</mat-label>
                <mat-select formControlName="role">
                  <mat-option *ngFor="let role of roles" [value]="role.id">
                    {{role.role}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-6">
              <button mat-raised-button color="warn" class="w-100"
                      (click)="cancel()"
              >Отмена
              </button>
            </div>
            <div class="col-6">
              <button mat-raised-button color="primary" class="w-100"
                      (click)="registration()"
                      [disabled]="(buttonLoader$ | async) || registrationForm.invalid"
              >
                <span *ngIf="!(buttonLoader$ | async)">Зарегистрировать</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





