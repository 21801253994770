import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {AutoRegFilterType} from "@/main/autoreg/accounts/store/type/AutoRegFilter.type";
import {select, Store} from "@ngrx/store";
import {
  autoRegAccSortOther,
  autoRegDeletedFilter
} from "@/main/autoreg/accounts/store/actions/autoRegAccSort.action";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {autoRegAccItemsSelector, autoRegFilterSelector} from "@/main/autoreg/accounts/store/selectors";
import {FiltersAutoRegType} from "@/main/autoreg/accounts/store/type/filtersAutoReg.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Output() dataPopUp: EventEmitter<AutoRegFilterType> = new EventEmitter();
  public autoRegAccItems$: Observable<AutoRegItemType[]>;
  public filter = FiltersAutoRegType;

  public filterValue$: Observable<AutoRegFilterType>;

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  //фильтрация по twf
  public otherFilter(checkbox): void {
    this.store.dispatch(autoRegAccSortOther({checkbox}))
  }

  //обнуление фильтров
  public deletedFilters(): void {
    this.store.dispatch(autoRegDeletedFilter())
  }

  private initValue(): void {
    this.filterValue$ = this.store.pipe(select(autoRegFilterSelector));
    this.autoRegAccItems$ = this.store.pipe(select(autoRegAccItemsSelector))
  }
}
