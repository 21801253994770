import {createAction, props} from "@ngrx/store";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ActionType} from "@/users/store/action.type";

export const GetUsersAction = createAction(
  ActionType.GET_USERS,
)

export const GetUsersActionSuccess = createAction(
  ActionType.GET_USERS_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const GetUsersActionFailure = createAction(
  ActionType.GET_USERS_FAILURE,
  props<{error: HttpErrorResponse}>()
)
