import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/registration/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const getRegAction = createAction(
  ActionType.GET_REGER,
  props<{windowsProfile: string}>()
)

export const getRegActionSuccess = createAction(
  ActionType.GET_REGER_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const getRegActionFailure = createAction(
  ActionType.GET_REGER_FAILURE,
  props<{response: DefaultResponseType}>()
)
export const getRegActionError = createAction(
  ActionType.GET_REGER_ERROR,
  props<{error: HttpErrorResponse}>()
)
