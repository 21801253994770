import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/emails/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const changeStatusAction = createAction(
  ActionType.CHANGE_STATUS,
  props<{email: string, status: string}>()
)

export const changeStatusSuccessAction = createAction(
  ActionType.CHANGE_STATUS_SUCCESS,
  props<{response: DefaultResponseType, email: string, status: string}>()
)

export const changeStatusErrorAction = createAction(
  ActionType.CHANGE_STATUS_ERROR,
  props<{error: HttpErrorResponse}>()
)
