import {Component, Input} from '@angular/core';

@Component({
  selector: 'action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent {

  @Input() message: string;
  @Input() disabled: boolean;
  @Input() icon?: string;

}
