import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {ContextMenuEventType} from "@/shared/types/contextMenuEvent.type";
import {restrictionsForFBTools} from "../../../../constants/restrictionsForFBTools";
import {Observable, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {UsersType} from "@/users/store/type/users.type";
import {currentUserSelector} from "@/users/store/selectors";
import {ContextMenuType} from "@/shared/types/contextMenu.type";
import { integrationToScenumSelector} from "@/main/autoreg/registration/store/selectors";
import {IEmail} from "@/main/emails/store/types/email.interface";

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit, OnDestroy {

  @Input() contextMenuStyle;
  @Input() currentAcc: AutoRegItemType;
  @Input() currentEmail: IEmail;
  @Input() type?: ContextMenuType;
  @Output() contextMenuEvent: EventEmitter<ContextMenuEventType | boolean> = new EventEmitter();

  public disabledButton: boolean = false;
  public fbToolButton: boolean = true;
  public currentUserRole: string;

  public integrationToScenum$: Observable<boolean>; //Флаг для определения наличия процесса интеграции
  public user$: Observable<UsersType>;

  private typeEvent = ContextMenuEventType;
  private subscriptionList: Subscription[] = [];

  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }


  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
    if(this.currentAcc?.state) {
      this.fbToolButton = this.getRestrictFbTools(this.currentAcc.state);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe())
  }

  public getRestrictFbTools(state): boolean {
    return restrictionsForFBTools(state, this.currentUserRole)
  }

  public closeContextMenu(): void {
    this.contextMenuStyle = { 'display': 'none' }
    this.contextMenuEvent.emit(false)
  }

  public openFbTools(): void {
    this.contextMenuEvent.emit(this.typeEvent.OPEN_FB_TOOLS)
  }

  public openAutoRegAcc(): void {
    this.contextMenuEvent.emit(this.typeEvent.OPEN_ACC)
  }

  public reLoginProcess(): void {
    this.contextMenuEvent.emit(this.typeEvent.RE_LOGIN_ACC)
  }

  public deletedAutoRegItem(): void {
    this.contextMenuEvent.emit(this.typeEvent.DELETED)
  }

  public createProfileFromAdsPower(): void {
    this.contextMenuEvent.emit(this.typeEvent.CREATE_ACC_AP)
  }

  public checkAcc(): void {
    this.contextMenuEvent.emit(this.typeEvent.CHECK_ACC)
  }

  public checked(): void {
    this.contextMenuEvent.emit(this.typeEvent.CHECKED)
  }

  public addToGroup(): void {
    this.contextMenuEvent.emit(this.typeEvent.ADD_TO_GROUP)
  }

  public setStateAccCompleted(): void {
    this.contextMenuEvent.emit(this.typeEvent.CHANGE_AUTO_REG_STATE_ITEM)
  }

  public setOwner(): void {
    this.contextMenuEvent.emit(this.typeEvent.SET_OWNER)
  }

  public deleteOwner(): void {
    this.contextMenuEvent.emit(this.typeEvent.DELETE_OWNER)
  }

  public openAutoRegAccCart(): void {
    this.contextMenuEvent.emit(this.typeEvent.OPEN_CART_ACC)
  }

  public restore(): void {
    this.contextMenuEvent.emit(this.typeEvent.RESTORE_ACC)
  }

  public updateEmailStatus(): void {
    this.contextMenuEvent.emit(this.typeEvent.UPDATE_STATUS_EMAIL)
  }

  private initValue(): void {
    this.user$ = this.store.pipe(select(currentUserSelector));
    this.integrationToScenum$ = this.store.pipe(select(integrationToScenumSelector));
  }
}
