export enum ActionType {
  AUTO_REG_ITEMS = '[AutoReg] Auto-reg-items',
  AUTO_REG_ITEMS_SUCCESS = '[AutoReg] Auto-reg-items-success',
  AUTO_REG_ITEMS_FAILURE = '[AutoReg] Auto-reg-items-failure',

  AUTO_REG_ITEM_DELETED = '[AutoReg] Auto-reg-deleted',
  AUTO_REG_ITEM_DELETED_SUCCESS = '[AutoReg] Auto-deleted-success',
  AUTO_REG_ITEM_DELETED_FAILURE = '[AutoReg] Auto-deleted-failure',
  AUTO_REG_ITEM_DELETED_ERROR = '[AutoReg] Auto-deleted-error',

  AUTO_REG_ITEM_UPDATE = '[AutoReg] Auto-reg-update',
  AUTO_REG_ITEM_UPDATE_SUCCESS = '[AutoReg] Auto-reg-update-success',
  AUTO_REG_ITEM_UPDATE_FAILURE = '[AutoReg] Auto-reg-update-failure',
  AUTO_REG_ITEM_UPDATE_ERROR = '[AutoReg] Auto-reg-update-error',

  AUTO_REG_ITEM_RE_LOGIN = '[AutoReg] Re-login',
  AUTO_REG_ITEM_RE_LOGIN_SUCCESS = '[AutoReg] Re-login-success',
  AUTO_REG_ITEM_RE_LOGIN_FAILURE = '[AutoReg] Re-login-failure',
  AUTO_REG_ITEM_RE_LOGIN_ERROR = '[AutoReg] Re-login-error',

  AUTO_REG_SORT_DATE = '[AutoReg] Auto-reg-sort-date',
  AUTO_REG_SORT_STEP = '[AutoReg] Auto-reg-sort-step',
  AUTO_REG_SORT_OTHER = '[AutoReg] Auto-reg-sort',
  AUTO_REG_SORT_OWNER = '[AutoReg] Auto-reg-sort-owner',
  AUTO_REG_SORT_OWNER_FAILURE = '[AutoReg] Auto-reg-sort-owner-failure',
  AUTO_REG_DELETED_FILTERS = '[AutoReg] Auto-reg-deleted-filters',
  AUTO_REG_FILTER_GROUP = '[AutoReg] Auto-reg-filter-group',

  AUTO_REG_SAVE_TO_EXEL = '[AutoReg] Auto-reg-save-to-exel',
  AUTO_REG_DOWNLOAD_TABLE = '[AutoReg] Auto-reg-download-table',
  AUTO_REG_DOWNLOAD_TABLE_ITEMS = '[AutoReg] Auto-reg-download-table-items',

  //Выделение строк таблицы
  AUTO_REG_TR_CHECKED = '[AutoReg] Auto-reg-tr-checked',

  //Поиск по строкам таблицы
  AUTO_REG_SEARCH = '[AutoReg] Search',

  //Получение групп и кол-ва запросов для fb-tools
  AUTO_REG_FB_GROUP = '[AutoReg] FB-group',
  AUTO_REG_FB_GROUP_SUCCESS = '[AutoReg] FB-group-success',
  AUTO_REG_FB_GROUP_FAILURE = '[AutoReg] FB-group-failure',
  AUTO_REG_FB_GROUP_ERROR = '[AutoReg] FB-group-error',

  //Добавление авторегов fb-tools
  AUTO_REG_FB_ADD = '[AutoReg] FB-add',
  AUTO_REG_FB_ADD_SUCCESS = '[AutoReg] FB-add-success',
  AUTO_REG_FB_ADD_FAILURE = '[AutoReg] FB-add-failure',
  AUTO_REG_FB_ADD_ERROR = '[AutoReg] FB-add-error',

  //Добавление группы авторегов fb-tools
  AUTO_REG_FB_ADD_GROUP = '[AutoReg] FB-add-group',
  AUTO_REG_FB_ADD_GROUP_SUCCESS = '[AutoReg] FB-add-group-success',
  AUTO_REG_FB_ADD_GROUP_FAILURE = '[AutoReg] FB-add-group-failure',
  AUTO_REG_FB_ADD_GROUP_ERROR = '[AutoReg] FB-add-group-error',

  //Обновление данных о владельце авторегов
  AUTO_REG_UPDATE = '[AutoReg] Auto-reg-update',
  AUTO_REG_OWNER_UPDATE_SUCCESS = '[AutoReg] Owner-update-success',
  AUTO_REG_OWNER_UPDATE_FAILURE = '[AutoReg] Owner-update-failure',
  AUTO_REG_OWNER_UPDATE_ERROR = '[AutoReg] Owner-update-error',

  //Удаление группы авторегов
  AUTO_REG_DELETED_ITEMS = '[AutoReg] Group-deleted',
  AUTO_REG_DELETED_ITEMS_SUCCESS = '[AutoReg] Group-deleted-success',
  AUTO_REG_DELETED_ITEMS_FAILURE = '[AutoReg] Group-deleted-failure',
  AUTO_REG_DELETED_ITEMS_ERROR = '[AutoReg] Group-deleted-error',

  //открытие/закрытие настроек
  CHANGE_SETTINGS_ITEM = '[AutoReg] Auto-reg-change-settings',

  //фильтры
  AUTO_REG_FILTERS = '[AutoReg] Filters',
  AUTO_REG_FILTERS_DATE_INTERVAL = '[AutoReg] Filters-date-interval ',

  //Пагинация
  AUTO_REG_CHANGE_PAGE = '[AutoReg] Change-page',

  AUTO_REG_CHANGED_ACC = '[AutoReg] Changed-acc',
  AUTO_REG_CHANGED_ACC_SUCCESS = '[AutoReg] Changed-acc-success',
  AUTO_REG_CHANGED_ACC_FAILURE = '[AutoReg] Changed-acc-failure',
  AUTO_REG_CHANGED_ACC_ERROR = '[AutoReg] Changed-acc-error',

  AUTO_REG_SELECTED_ITEM_AP = '[AutoReg] Selected-profile-AP',
  AUTO_REG_CHECK_ACC_PARAMS = '[AutoReg] Check-acc-params',

  AUTO_REG_CREATE_PROFILE_AD = '[AutoReg] Create-profile-AP',
  AUTO_REG_CREATE_PROFILE_AD_SUCCESS = '[AutoReg] Create-profile-AP-success',
  AUTO_REG_CREATE_PROFILE_AD_FAILURE = '[AutoReg] Create-profile-AP-failure',
  AUTO_REG_CREATE_PROFILE_AD_ERROR = '[AutoReg] Create-profile-AP-error',

  AUTO_REG_CREATE_PROFILE_AD_ITEM = '[AutoReg] Create-profile-AP-item',
  AUTO_REG_CREATE_PROFILE_AD_ITEM_SUCCESS = '[AutoReg] Create-profile-AP-item-success',
  AUTO_REG_CREATE_PROFILE_AD_ITEM_FAILURE = '[AutoReg] Create-profile-AP-item-failure',
  AUTO_REG_CREATE_PROFILE_AD_ITEM_ERROR= '[AutoReg] Create-profile-AP-item-error',

  AUTO_REG_CHARTS_DATA = '[AutoReg] Charts-get-data',
  AUTO_REG_CHARTS_UPDATE_DATE = '[AutoReg] Charts-set-date',
  AUTO_REG_CHARTS_BAR_UPDATE_DATE = '[AutoReg] Charts-set-bar-date',
  AUTO_REG_CHARTS_INTERVAL_DATE = '[AutoReg] Charts-set-interval-date',

  AUTO_REG_UPDATE_SELFIE = '[AutoReg] Update-selfie',
  AUTO_REG_UPDATE_SELFIE_SUCCESS = '[AutoReg] Update-selfie-success',
  AUTO_REG_UPDATE_SELFIE_FAILURE = '[AutoReg] Update-selfie-failure',
  AUTO_REG_UPDATE_SELFIE_ERROR = '[AutoReg] Update-selfie-error',

  AUTO_REG_GET_GROUP = '[AutoReg] Get-group',
  AUTO_REG_GET_GROUP_SUCCESS = '[AutoReg] Get-group-success',
  AUTO_REG_GET_GROUP_FAILURE = '[AutoReg] Get-group-failure',

  AUTO_REG_DELETED_GROUP = '[AutoReg] Deleted-group',
  AUTO_REG_DELETED_GROUP_SUCCESS = '[AutoReg] Deleted-group-success',
  AUTO_REG_DELETED_GROUP_FAILURE = '[AutoReg] Deleted-group-failure',

  AUTO_REG_CREATE_GROUP = '[AutoReg] Create-group',
  AUTO_REG_CREATE_GROUP_SUCCESS = '[AutoReg] Create-group-success',
  AUTO_REG_CREATE_GROUP_FAILURE = '[AutoReg] Create-group-failure',

  AUTO_REG_ADD_TO_GROUP = '[AutoReg] Add-to-group',
  AUTO_REG_ADD_TO_GROUP_SUCCESS = '[AutoReg] Add-to-group-success',
  AUTO_REG_ADD_TO_GROUP_FAILURE = '[AutoReg] Add-to-group-failure',

  AUTO_REG_SET_OWNER = '[AutoReg] Set-owner',
  AUTO_REG_SET_OWNER_SUCCESS = '[AutoReg] Set-owner-success',
  AUTO_REG_SET_OWNER_FAILURE = '[AutoReg] Set-owner-failure',

  AUTO_REG_SET_OWNER_SYSTEM = '[AutoReg] Set-owner-system',
  AUTO_REG_SET_OWNER_SUCCESS_SYSTEM_SUCCESS = '[AutoReg] Set-owner-system-success',
  AUTO_REG_SET_OWNER_FAILURE_SYSTEM_FAILURE = '[AutoReg] Set-owner-system-failure',

  GET_PROXY_FROM_FB_TOOL = '[Autoreg] Get-proxy-fb',
  GET_PROXY_FROM_FB_TOOL_SUCCESS = '[Autoreg] Get-proxy-fb-success',
  GET_PROXY_FROM_FB_TOOL_FAILURE = '[Autoreg] Get-proxy-fb-failure',
  GET_PROXY_FROM_FB_TOOL_ERROR = '[Autoreg] Get-proxy-fb-failure',

  TABLE_COLUMN_DROP = '[Autoreg] Table-column-drop',

  UPDATE_TO_LOCAL_STORAGE_GROUP_PROXY_FB = '[Autoreg] Update-group-proxy-fb',

  SET_PROCESSES = '[Autoreg] Set-processes'
}
