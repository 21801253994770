import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, tap, concatMap, from, delay} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {FbToolsService} from "@/main/autoreg/services/fb-tools.service";
import {ToastrService} from "ngx-toastr";
import {
  autoRegFbAddGroupAction, autoRegFbAddGroupActionFailure,
  autoRegFbAddGroupActionSuccess, autoRegFbAddGroupAddActionError
} from "@/main/autoreg/accounts/store/actions/autoRegFbAddGroup.action";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {Store} from "@ngrx/store";

@Injectable()
export class AutoRegFbAddGroupEffect {
  autoRegFbAddGroupEffect = createEffect(() => this.actions$.pipe(
    ofType(autoRegFbAddGroupAction),
    concatMap(({ request }) => from(request)
      .pipe(
      concatMap(item => this.fbToosService.setFbToolsGroup(item)
        .pipe(
        delay(5000),
        map((response: DefaultResponseType) => {
          let data = request;
          if (response.status === 'Success') {
            return autoRegFbAddGroupActionSuccess({ response, data })
          } else if (response.status === 'Failure') {
            return autoRegFbAddGroupActionFailure({ response })
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegFbAddGroupAddActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegFbAddGroupAddActionError({error}));
          } else {
            return of(autoRegFbAddGroupAddActionError({error}));
          }
        })
      ))
    ))
  ));

  autoRegFbAddGroupSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegFbAddGroupActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.data.message) {
          this.toaStr.success(response.response.data.message)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegFbAddGroupFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegFbAddGroupActionFailure),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegFbAddGroupError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegFbAddGroupAddActionError),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private fbToosService: FbToolsService,
              private toaStr: ToastrService,
              private store: Store
  ) {
  }
}
