import {COLOR, STATE} from "../../../../constants/COLORS_STATE";

export function SetBgStateUtil(state: any): string {
  let color: COLOR = COLOR.NO_COLOR;
  switch (state) {
    case STATE.REGISTERED:
      color = COLOR.REGISTERED;
      break;

    case STATE.REGISTERED_OK:
      color = COLOR.REGISTERED_OK;
      break;

    case STATE.ERROR:
      color = COLOR.ERROR;
      break;

    case STATE.NEEDS_VERIFICATION:
      color = COLOR.NEEDS_VERIFICATION;
      break;

    case STATE.VERIFICATION:
      color = COLOR.VERIFICATION;
      break;

    case STATE.VERIFICATION_OK:
      color = COLOR.VERIFICATION_OK;
      break;

    case STATE.CREATE_PROFILE:
      color = COLOR.CREATE_PROFILE;
      break;

    case STATE.CREATE_PROFILE_OK:
      color = COLOR.CREATE_PROFILE_OK;
      break;

    case STATE.RE_LOGIN:
      color = COLOR.RE_LOGIN;
      break;

    case STATE.RE_LOGIN_OK:
      color = COLOR.RE_LOGIN_OK;
      break;

    case STATE.SYNC_TO_SCENUM:
      color = COLOR.SYNC_TO_SCENUM;
      break;

    case STATE.SYNC_TO_SCENUM_OK:
      color = COLOR.SYNC_TO_SCENUM_OK;
      break;

    case STATE.DATA_RECONCILIATION:
      color = COLOR.DATA_RECONCILIATION;
      break;

    case STATE.DATA_IS_CHECKED:
      color = COLOR.DATA_IS_CHECKED;
      break;

    case STATE.SELFIE:
      color = COLOR.SELFIE;
      break;

    case STATE.FARM:
      color = COLOR.FARM;
      break;

    case STATE.COMPLETED:
      color = COLOR.COMPLETED;
      break;

    case STATE.CHECKPOINT:
      color = COLOR.CHECKPOINT;
      break;

    case STATE.USED:
      color = COLOR.USED;
      break;

    case STATE.BLOCKED:
      color = COLOR.BLOCKED;
      break;

    case STATE.SYNC:
      color = COLOR.SYNC;
      break;

    case STATE.ADD_TO_FB_TOOL:
      color = COLOR.ADD_TO_FB_TOOL;
      break;

    case STATE.RESTORE:
      color = COLOR.RESTORE;
      break;

    case STATE.GARBAGE:
      color = COLOR.GARBAGE;
      break;
  }

  return color;
}
