import {createAction, props} from "@ngrx/store";
import {ActionsType} from "@/main/auto-gpt/store/actions.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const deleteChatsAction = createAction (
  ActionsType.CHAT_GPT_DELETE,
  props<{id: number}>()
)

export const deleteChatsActionSuccess = createAction (
  ActionsType.CHAT_GPT_DELETE_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const deleteChatsActionFailure = createAction (
  ActionsType.CHAT_GPT_DELETE_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const deleteChatsActionError = createAction (
  ActionsType.CHAT_GPT_DELETE_ERROR,
  props<{error: HttpErrorResponse}>()
)
