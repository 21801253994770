export enum ActionsType {
  CHAT_GPT_GET = '[Chat] Get',
  CHAT_GPT_GET_SUCCESS = '[Chat] Get-success',
  CHAT_GPT_GET_FAILURE = '[Chat] Get-failure',
  CHAT_GPT_GET_ERROR = '[Chat] Get-error',

  CHAT_GPT_SET = '[Chat] Set',
  CHAT_GPT_SET_SUCCESS = '[Chat] Set-success',
  CHAT_GPT_SET_FAILURE = '[Chat] Set-failure',
  CHAT_GPT_SET_ERROR = '[Chat] Set-error',

  CHAT_GPT_DELETE = '[Chat] Delete',
  CHAT_GPT_DELETE_SUCCESS = '[Chat] Delete-success',
  CHAT_GPT_DELETE_FAILURE = '[Chat] Delete-failure',
  CHAT_GPT_DELETE_ERROR = '[Chat] Delete-error',

  CHAT_GPT_UPDATE = '[Chat] Update',
  CHAT_GPT_UPDATE_SUCCESS = '[Chat] Update-success',
  CHAT_GPT_UPDATE_FAILURE = '[Chat] Update-failure',
  CHAT_GPT_UPDATE_ERROR = '[Chat] Update-error',

  CHAT_GPT_MESSAGE_GET = '[Chat] Message-get',
  CHAT_GPT_MESSAGE_GET_SUCCESS = '[Chat] Message-get-success',
  CHAT_GPT_MESSAGE_GET_FAILURE = '[Chat] Message-get-failure',
  CHAT_GPT_MESSAGE_GET_ERROR = '[Chat] Message-get-error',

  CHAT_GPT_MESSAGE_SET = '[Chat] Message-set',
  CHAT_GPT_MESSAGE_SET_SUCCESS = '[Chat] Message-set-success',
  CHAT_GPT_MESSAGE_SET_FAILURE = '[Chat] Message-set-failure',
  CHAT_GPT_MESSAGE_SET_ERROR = '[Chat] Message-set-error',

  CHAT_GPT_CHECKED = '[Chat] Checked-chat',
}
