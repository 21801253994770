import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, search: string): any {
    // if (!search.trim()) {
    //   return value;
    // }
    //
    // return value.filter(item => {
    //   return item.name.toLowerCase().includes(search.toLowerCase())
    //     || item.surname.toLowerCase().includes(search.toLowerCase())
    //     || item.email.toLowerCase().includes(search.toLowerCase())
    //     || item.password.toLowerCase().includes(search.toLowerCase())
    //     || item.login.toLowerCase().includes(search.toLowerCase())
    //     || item.owner.toLowerCase().includes(search.toLowerCase())
    //     || item.step.toLowerCase().includes(search.toLowerCase())
    //     || item.token.toLowerCase().includes(search.toLowerCase())
    // })
  }

}
