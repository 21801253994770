<div class="fb-tools-card">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0 process">
        <div class="card-header">
          <h3 class="card-title">FB-Tool</h3>
        </div>
        <div class="card-body pb-0" [formGroup]="fbToolForm">
          <div class="row">
              <div class="col-8 my-2">
                <h4 *ngIf="requestCount$ | async">
                  Доступно <span class="text-danger">{{requestCount$ | async}}</span> запросов на FB-Tools</h4>
                <h4 *ngIf="(requestCount$ | async) === 0"
                    class="text-danger">Доступных запросов на FB-Tools нет</h4>
              </div>
          </div>
          <div class="row">
            <div class="col-12 my-2">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Данные в формате FB-Tool</mat-label>
                <textarea matInput placeholder="Данные в формате FB-Tool..."
                          formControlName="fbToolData"
                          appClipboardInput
                          readonly
                          rows="7"
                ></textarea>
              </mat-form-field>
            </div>

              <div class="col-12 my-2">
                <mat-form-field class="example-full-width w-100" appearance="fill">
                  <mat-label>Добавить автоматически</mat-label>
                  <mat-select formControlName="group">
                    <mat-option *ngFor="let item of fbGroup$ | async"  [value]="item.id">{{item.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-12 my-2">
                <mat-form-field class="example-full-width w-100" appearance="fill">
                  <mat-label>Прокси</mat-label>
                  <mat-select formControlName="proxy">
                    <mat-option *ngFor="let item of fbToolProxy$ | async" [value]="item.id">{{item.proxy}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            <div class="col-4 my-2">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена</button>
            </div>

            <div class="col-4 my-2">
              <button class="w-100" mat-raised-button color="accent"
                      (click)="saveToExel()"
              >Сохранить в Excel
              </button>
            </div>

            <div class="col-4 my-2">
              <button class="w-100" mat-raised-button color="primary"
                      [disabled]="(requestCount$ | async) === 0 || fbToolForm.invalid || (buttonLoader$ | async)"
                      (click)="okConstellation()"
              >
                <span *ngIf="!(buttonLoader$ | async)">Подтвердить</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
