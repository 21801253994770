export function SetCheckParamsUtil(nameParam: string, defaultParams: string[]): string[] {
  let resultArray = defaultParams;
  const index = resultArray.indexOf(nameParam);
  if (nameParam === 'update' && index === -1) {
    resultArray = ['update']
  } else if (nameParam === 'update' && index !== -1) {
    resultArray = [...resultArray.slice(0, index), ...resultArray.slice(index + 1)]
  } else if (nameParam !== 'update' && index === -1) {
    if (resultArray.includes('update')) {
      const index = resultArray.indexOf('update');
      resultArray = [...resultArray.slice(0, index), ...resultArray.slice(index + 1)]
    }
    resultArray = [...resultArray, nameParam]
  } else if (nameParam !== 'update' && index !== -1) {
    resultArray = [...resultArray.slice(0, index), ...resultArray.slice(index + 1)]
  }
  return resultArray;
}
