import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {PopUpType} from "@/shared/types/popUp.type";
import {select, Store} from "@ngrx/store";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {Observable} from "rxjs";
import {autoRegGroupSelector} from "@/main/autoreg/accounts/store/selectors";
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {buttonLoaderSelector} from "@/ui/store/selectors";

@Component({
  selector: 'app-add-to-group',
  templateUrl: './add-to-group.component.html',
  styleUrls: ['./add-to-group.component.scss']
})
export class AddToGroupComponent implements OnInit {

  @Output() addToGroupEvent: EventEmitter<any> = new EventEmitter();
  @Input() account: AutoRegItemType;
  @Input() type: string;

  public groupName: string;
  public typePopUP = PopUpType;
  public dataPopUp = {
    constellation: false,
    group: ''
  }

  public buttonLoader$: Observable<boolean>;
  public group$: Observable<AutoRegGroupType[]>;

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
  }
  public add(): void {
    if (this.groupName) {
      this.dataPopUp.group = this.groupName;
      this.dataPopUp.constellation = true;
      this.addToGroupEvent.emit(this.dataPopUp);
    }
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  private initValue(): void {
    this.group$ = this.store.pipe(select(autoRegGroupSelector));
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
  }
}
