import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  downloadTableData, saveToExelAutoReg
} from "@/main/autoreg/accounts/store/actions/autoRegAccTable.actions";
import {tap, withLatestFrom} from "rxjs";
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import {select, Store} from "@ngrx/store";
import {
  accFbToolSelector,
  autoDownloadData
} from "@/main/autoreg/accounts/store/selectors";
import {TableDownloadType} from "@/main/autoreg/accounts/store/type/tableDownload.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {getFormatToFbToolsUtil} from "@/main/autoreg/utils/getFormatToFbTools.util";
import {ToastrService} from "ngx-toastr";


@Injectable()
export class TableEffects {
  downloadTableDataEffect$ = createEffect(() =>
      this.actions$.pipe(
        ofType(downloadTableData),
        withLatestFrom(this.store.pipe(select(autoDownloadData))),
        tap(async ([action, items]) => {
          let dataToSave = items;

          if (action.user) {
            dataToSave = dataToSave.filter(item => {
              if (item.owner_id === action.user && (item.state === 'Используется' || item.state === 'Добавлен в FB Tool')) {
                return item;
              }
            });
          }

          const maxLength = 32766; // Максимальная длина текстового поля для Excel
          dataToSave = this.truncateTextFields(dataToSave, maxLength);

          this.save(dataToSave, `Автореги ${this.getDate()}`)
        })
      ),
    { dispatch: false }
  );

  downloadTableDataEffectItems$ = createEffect(() =>
      this.actions$.pipe(
        ofType(saveToExelAutoReg),
        withLatestFrom(this.store.pipe(select(accFbToolSelector))),
        tap(([action, items]) => {
          let dataToDownload: TableDownloadType[] = this.getDataToExcel(items);
          if (action.data) {
            dataToDownload = this.getDataToExcel(action.data);
          }

          this.save(dataToDownload, `Данные авторегов для добавления в FB-Tools ${this.getDate()}`)
        })
      ),
    {dispatch: false}
  );

  private save(data, name): void {
    try {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
      const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
      const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
      saveAs(blob, `${name}.xlsx`);
    } catch (e) {
      console.log(e.message);
    }
  }

  private truncateTextFields(data, maxLength): AutoRegItemType[] {
    for (const item of data) {
      for (const key in item) {
        let element = item[key] !== null ? item[key].toString() : ''
        if (typeof element === 'string' && element.length > maxLength) {
          this.tosTr.info(`Перед скачиванием обновите метаданные у авторега login: ${item.login}`)
          element = element.substring(0, maxLength); // Укоротить текст до maxLength символов
        }
      }
    }
    return data;
  }

  private getDataToExcel(data: AutoRegItemType[]): TableDownloadType[] {
    return data.map((item, index) => {
      return {
        'Номер': index + 1,
        'Имя': item.name,
        'Фамилия': item.surname,
        'UA': item.ua,
        'cookie': item.cookie,
        'Пароль': item.password,
        'FBTools': getFormatToFbToolsUtil(item)
      }
    });
  }

  private getDate(): string {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    return `${day}.${month}.${year} ${hours}-${minutes}`;
  }


  constructor(private actions$: Actions,
              private store: Store,
              private tosTr: ToastrService) {
  }
}
