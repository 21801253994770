import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const autoRegSetOwnerAction = createAction(
  ActionType.AUTO_REG_SET_OWNER,
  props<{id: number, name: string, state?: string, login?: string}>()
)

export const autoRegSetOwnerActionSuccess = createAction(
  ActionType.AUTO_REG_SET_OWNER_SUCCESS,
  props<{response: DefaultResponseType, logins: string[], name: string, id?: number}>()
)

export const autoRegSetOwnerActionFailure = createAction(
  ActionType.AUTO_REG_SET_OWNER_FAILURE,
  props<{error: HttpErrorResponse}>()
)
