import {Component, OnInit} from '@angular/core';
import {FiltersAutoRegType} from "@/main/autoreg/accounts/store/type/filtersAutoReg.type";
import {Observable} from "rxjs";
import {AutoRegFilterType} from "@/main/autoreg/accounts/store/type/AutoRegFilter.type";
import {select, Store} from "@ngrx/store";
import {autoRegFilterSelector} from "@/main/autoreg/accounts/store/selectors";
import {autoRegAccSortOther} from "@/main/autoreg/accounts/store/actions/autoRegAccSort.action";

@Component({
  selector: 'app-additional-filters',
  templateUrl: './additional-filters.component.html',
  styleUrls: ['./additional-filters.component.scss']
})
export class AdditionalFiltersComponent implements OnInit {

  public filter = FiltersAutoRegType;
  public filterValue$: Observable<AutoRegFilterType>;

  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initValue()
  }

  public otherFilter(checkbox: string): void {
    this.store.dispatch(autoRegAccSortOther({checkbox}))
  }

  private initValue(): void {
    this.filterValue$ = this.store.pipe(select(autoRegFilterSelector));
  }
}
