import {Component} from '@angular/core';
import {TypeBoxEnum} from "@/main/autoreg/stats/store/type/typeBox.enum";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  public boxType = TypeBoxEnum;

}
