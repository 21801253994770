import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {UsersService} from "@/users/services/users.service";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {GetUsersAction, GetUsersActionFailure, GetUsersActionSuccess} from "@/users/store/actions/getUsers.action";
import {SetRoleUtil} from "@/auth/utils/setRole.util";

@Injectable()
export class GetUsersEffect {
  getUsersEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetUsersAction),
    switchMap(() => {
      return this.usersService.getUsers().pipe(
        map((response: DefaultResponseType) => {
          response.data = response.data.map(item => {
            return {
              ...item,
              role: SetRoleUtil(item.role_id)
            }
          })
          return GetUsersActionSuccess({response})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(GetUsersActionFailure({ error }));
        })
      );
    })
  ))

  getUsersEffectSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(GetUsersActionSuccess),
      tap(({response}) => {
        if (response.status === 'Failure') {
          console.log('Список пользователей не получен')
        }
      })
    ), {dispatch: false}
  )

  getUsersEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(GetUsersActionFailure),
      tap(() => {
        console.error('Список пользователей не получен')
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private usersService: UsersService) {
  }
}
