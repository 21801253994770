import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {SortOwnerType} from "@/main/autoreg/accounts/store/type/sortOwner.type";



export const autoRegAccSort = createAction(
  ActionType.AUTO_REG_SORT_DATE,
  props<{ value: string }>()
)

export const autoRegAccSortState = createAction(
  ActionType.AUTO_REG_SORT_STEP,
  props<{ stateValue: string }>()
)

export const autoRegAccSortOther = createAction(
  ActionType.AUTO_REG_SORT_OTHER,
  props<{ checkbox: string }>()
)

export const autoRegAccSortOwner = createAction(
  ActionType.AUTO_REG_SORT_OWNER,
  props<{ owner: SortOwnerType }>()
)

export const autoRegAccSortOwnerFailure = createAction(
  ActionType.AUTO_REG_SORT_OWNER_FAILURE,
  props<{ owner: string }>()
)

export const autoRegDeletedFilter = createAction(
  ActionType.AUTO_REG_DELETED_FILTERS
)

export const autoregFilterGroup = createAction(
  ActionType.AUTO_REG_FILTER_GROUP,
  props<{group: number}>()
)



