import {Component, OnDestroy, OnInit} from '@angular/core';
import {FiltersAutoRegType} from "@/main/autoreg/accounts/store/type/filtersAutoReg.type";
import {select, Store} from "@ngrx/store";
import {
    autoRegAccSortOther,
    autoRegAccSortOwner,
    autoRegAccSortState, autoregFilterGroup
} from "@/main/autoreg/accounts/store/actions/autoRegAccSort.action";
import {Observable, Subscription} from "rxjs";
import {AutoRegFilterType} from "@/main/autoreg/accounts/store/type/AutoRegFilter.type";
import {
    autoRegFilterCheckOwnerSelector,
    autoRegFilterSelector,
    autoRegFilterStepSelector, autoRegGroupSelector
} from "@/main/autoreg/accounts/store/selectors";
import {usersSelector} from "@/users/store/selectors";
import {UsersType} from "@/users/store/type/users.type";
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";

@Component({
    selector: 'app-check-filters',
    templateUrl: './check-filters.component.html',
    styleUrls: ['./check-filters.component.scss']
})
export class CheckFiltersComponent implements OnInit, OnDestroy {

    public filterType = FiltersAutoRegType;
    public users: UsersType[];

    public groups$: Observable<AutoRegGroupType[]>;
    public users$: Observable<UsersType[]>;
    public filter$: Observable<AutoRegFilterType>;
    public filterStep$: Observable<string[]>
    public checkUsers$: Observable<string[]>

    private subscriptionList: Subscription[] = [];

    set sub(sub: Subscription) {
        this.subscriptionList.push(sub);
    }

    constructor(private store: Store) {
    }

    public ngOnInit(): void {
        this.initValue();
        this.sub = this.users$.subscribe(data => {
            if (data && data.length !== 0) {
                this.users = data;
            }
        })
    }

    public ngOnDestroy(): void {
        this.subscriptionList.forEach(s => s.unsubscribe())
    }

    public filterStepRemove(stateValue): void {
        this.store.dispatch(autoRegAccSortState({stateValue}))
    }

    public otherFilterRemove(checkbox): void {
        this.store.dispatch(autoRegAccSortOther({checkbox}))
    }

    public groupFilterRemove(group: number): void {
        this.store.dispatch(autoregFilterGroup({group}))
    }

    public checkOwnerRemove(owner: string): void {
        this.store.dispatch(autoRegAccSortOwner({owner: owner}))
    }

    private initValue(): void {
        this.filter$ = this.store.pipe(select(autoRegFilterSelector));
        this.filterStep$ = this.store.pipe(select(autoRegFilterStepSelector));
        this.checkUsers$ = this.store.pipe(select(autoRegFilterCheckOwnerSelector));
        this.users$ = this.store.pipe(select(usersSelector));
        this.groups$ = this.store.pipe(select(autoRegGroupSelector));
    }
}
