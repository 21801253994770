import {createAction, props} from "@ngrx/store";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";


export const autoRegDeletedGroupAccActions = createAction(
  ActionType.AUTO_REG_DELETED_ITEMS,
  props<{port: number}>()
)

export const autoRegDeletedGroupAccActionsSuccess = createAction(
  ActionType.AUTO_REG_DELETED_ITEMS_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const autoRegDeletedGroupAccActionsFailure = createAction(
  ActionType.AUTO_REG_DELETED_ITEMS_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegDeletedGroupAccActionsError = createAction(
  ActionType.AUTO_REG_DELETED_ITEMS_ERROR,
  props<{error: HttpErrorResponse}>()
)
