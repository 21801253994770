import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export function filteredGroupUtil(defaultArray, selectedArray, filtersGroup, defaultValue?: boolean): AutoRegItemType[] {
    let defArr = defaultValue ? defaultArray : selectedArray;
    let newArray = [];
    defArr.forEach(item => {
        filtersGroup.forEach(filter => {
            if (item.group_id === filter) {
                newArray.push(item);
            }
        })
    })
    return newArray;
}
