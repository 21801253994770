import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {RegerService} from "@/main/autoreg/services/reger.service";
import {
  getRegAction, getRegActionError,
  getRegActionFailure,
  getRegActionSuccess
} from "@/main/autoreg/registration/store/actions/getReg.action";
import {pageLoaderAction} from "@/ui/store/actions/pageLoader.action";
import {Store} from "@ngrx/store";

@Injectable()
export class GetRegEffect {
  getRegEffect$ = createEffect(() => this.actions$.pipe(
    ofType(getRegAction),
    switchMap(({windowsProfile}) => {
      this.store.dispatch(pageLoaderAction({load: true}))
      return this.regService.getReger(windowsProfile).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return getRegActionSuccess({response})
          } else if (response.status === 'Failure') {
            return getRegActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400 || 422) {
            return of(getRegActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(getRegActionError({error}));
          } else {
            return of(getRegActionError({error}));
          }
        })
      );
    })
  ))

  getRegEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(getRegActionFailure),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(pageLoaderAction({load: false}))
        console.error(`Регистратор не загружен. Ошибка: ${response.response.details.msg}`)
      })
    ), {dispatch: false}
  )

  getRegEffectError$ = createEffect(
    () => this.actions$.pipe(
      ofType(getRegActionError),
      tap(({error}): void => {
        this.store.dispatch(pageLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          console.error(`Регистратор не загружен. Ошибка`)
        } else if (error.status === 500 || 501) {
          console.error(`Регистратор не загружен. Ошибка сервера`)
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private regService: RegerService,
              private store: Store
  ) {
  }
}
