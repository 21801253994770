import {createAction, props} from "@ngrx/store";
import {AutoRegAddFbToolsType} from "@/main/autoreg/accounts/store/type/autoRegAddFbTools.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";

export const autoRegFbAddAction = createAction(
  ActionType.AUTO_REG_FB_ADD,
  props<{request: AutoRegAddFbToolsType}>()
)

export const autoRegFbAddActionSuccess = createAction(
  ActionType.AUTO_REG_FB_ADD_SUCCESS,
  props<{response: DefaultResponseType, request: AutoRegAddFbToolsType}>()
)

export const autoRegFbAddActionFailure = createAction(
  ActionType.AUTO_REG_FB_ADD_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegFbAddActionError = createAction(
  ActionType.AUTO_REG_FB_ADD_FAILURE,
  props<{error: HttpErrorResponse}>()
)
