import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {autoRegProcessItemsActions} from "@/main/autoreg/registration/store/actions/AutoRegProcessItems.actions";
import {AutoRegGetGroupAction} from "@/main/autoreg/accounts/store/actions/autoRegGetGroup.action";


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})

export class RegistrationComponent implements OnInit {

  constructor(private store: Store) {}

  public ngOnInit(): void {

  }
}
