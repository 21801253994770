import { Component } from '@angular/core';

@Component({
  selector: 'app-keitaro',
  templateUrl: './keitaro.component.html',
  styleUrls: ['./keitaro.component.scss']
})
export class KeitaroComponent {

}
