import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {
  autoRegFbGroupAction, autoRegFbGroupActionError, autoRegFbGroupActionFailure,
  autoRegFbGroupActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegFbGroup.action";
import {FbToolsService} from "@/main/autoreg/services/fb-tools.service";


@Injectable()
export class AutoRegFbGroupEffect {
  autoRegFbGroupEffect$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegFbGroupAction),
    switchMap(({apiKey}) => {
      return this.fbToosService.getFbToolsGroup(apiKey).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return autoRegFbGroupActionSuccess({response})
          } else if (response.status === 'Failure') {
            return autoRegFbGroupActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegFbGroupActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegFbGroupActionError({error}));
          } else {
            return of(autoRegFbGroupActionError({error}));
          }
        })
      );
    })
  ))

  autoRegFbGroupFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegFbGroupActionFailure),
      tap((response: {response: DefaultResponseType}): void => {
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.warning('Группы для FB-Tools не получены. Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegFbGroupError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegFbGroupActionError),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private fbToosService: FbToolsService,
              private toaStr: ToastrService
  ) {
  }
}
