import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AffiliateComponent} from "@/main/actions/keitaro/affiliate/affiliate.component";
import {BotsComponent} from "@/main/actions/keitaro/bots/bots.component";
import {CampaignsComponent} from "@/main/actions/keitaro/campaigns/campaigns.component";
import {CleanComponent} from "@/main/actions/keitaro/clean/clean.component";
import {ClicksComponent} from "@/main/actions/keitaro/clicks/clicks.component";
import {ConversionsComponent} from "@/main/actions/keitaro/conversions/conversions.component";
import {DomainsComponent} from "@/main/actions/keitaro/domains/domains.component";
import {FlowsComponent} from "@/main/actions/keitaro/flows/flows.component";
import {GroupsComponent} from "@/main/actions/keitaro/groups/groups.component";
import {IntegrationsComponent} from "@/main/actions/keitaro/integrations/integrations.component";
import {IpComponent} from "@/main/actions/keitaro/ip/ip.component";
import {LogsComponent} from "@/main/actions/keitaro/logs/logs.component";
import {OffersComponent} from "@/main/actions/keitaro/offers/offers.component";
import {ReportsComponent} from "@/main/actions/keitaro/reports/reports.component";
import {TraficComponent} from "@/main/actions/keitaro/trafic/trafic.component";
import {UsersComponent} from "@/main/actions/keitaro/users/users.component";

const routes: Routes = [
  {
    path: 'affiliate',
    component: AffiliateComponent
  },
  {
    path: 'bots',
    component: BotsComponent
  },
  {
    path: 'campaigns',
    component: CampaignsComponent
  },
  {
    path: 'clean',
    component: CleanComponent
  },
  {
    path: 'clicks',
    component: ClicksComponent
  },
  {
    path: 'conversions',
    component: ConversionsComponent
  },
  {
    path: 'domains',
    component: DomainsComponent
  },
  {
    path: 'flows',
    component: FlowsComponent
  },
  {
    path: 'groups',
    component: GroupsComponent
  },
  {
    path: 'integrations',
    component: IntegrationsComponent
  },
  {
    path: 'ip',
    component: IpComponent
  },
  {
    path: 'logs',
    component: LogsComponent
  },
  {
    path: 'offers',
    component: OffersComponent
  },
  {
    path: 'reports',
    component: ReportsComponent
  },
  {
    path: 'trafic',
    component: TraficComponent
  },
  {
    path: 'users',
    component: UsersComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KeitaroRoutingModule { }
