import {AutoRegProcessItemsType} from "@/main/autoreg/registration/store/type/autoRegProcessItems.type";
import {AutoRegNewProcessType} from "@/main/autoreg/registration/store/type/autoRegNewProcess.type";
import {NewFarmType} from "@/main/autoreg/registration/store/type/newFarm.type";
import {DataForChartsProcessedType} from "@/main/autoreg/accounts/store/type/dataForChartsProcessed.type";
import {ConsumablesType} from "@/main/autoreg/registration/store/type/consumables.type";
import {RegerType} from "@/main/autoreg/registration/type/reger.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export interface AutoRegInterface {
  autoRegProcess: AutoRegProcessItemsType,
  autoRegs: AutoRegItemType[],
  autoRegNewProcess: AutoRegNewProcessType | null,
  autoRegNewFarm: NewFarmType | null,
  autoRegStatus: string | boolean,
  dataForCharts: DataForChartsProcessedType,
  reger: RegerType;
  regConsumables: ConsumablesType,
  addToProcess: boolean,

  updateProcess: boolean, //флаг для лоадера при запуске или остановке процесса
  integrationToScenum: boolean //флаг для блокировки кнопок, если запущен процесс интеграции
}

export const initialState: AutoRegInterface = {
  autoRegProcess: [],
  autoRegs: [],
  autoRegNewProcess: null,
  autoRegNewFarm: null,
  autoRegStatus: true,
  dataForCharts: {
    activeProcessed: 0
  },
  reger: null,
  regConsumables: null,
  addToProcess: false,

  updateProcess: false,
  integrationToScenum: false
}
