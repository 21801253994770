import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from "@/main/pages/dashboard/dashboard.component";
import {Page404Component} from "@/main/pages/errors/page404/page404.component";
import {Page500Component} from "@/main/pages/errors/page500/page500.component";
import {AutoregComponent} from "@/main/autoreg/autoreg.component";
import {AutoGptComponent} from "@/main/auto-gpt/auto-gpt.component";
import {AdminRootComponent} from "@/main/pages/admin-root/admin-root.component";
import {ProfileComponent} from "@/main/pages/profile/profile.component";
import {CartComponent} from "@/main/cart/cart.component";
import {EmailsComponent} from "@/main/emails/emails.component";



const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
  },
  {
    path: 'admin',
    component: AdminRootComponent,
  },
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'cart',
    component: CartComponent,
  },
  {
    path: 'auto-gpt',
    component: AutoGptComponent,
  },
  {
    path: 'emails',
    children: [
      { path: '', loadChildren: ()  => import('./emails/emails.module').then(m => m.EmailsModule) },
    ]
  },
  {
    path: 'actions',
    children: [
      { path: '', loadChildren: ()  => import('./actions/actions.module').then(m => m.ActionsModule) },
    ]
  },
  {
    path: 'stats',
    children: [
      { path: '', loadChildren: ()  => import('./stats/stats.module').then(m => m.StatsModule) },
    ]
  },
  {
    path: 'tools',
    children: [
      { path: '', loadChildren: ()  => import('./tools/tools.module').then(m => m.ToolsModule) },
    ]
  },
  {
    path: 'autoreg',
    component: AutoregComponent,
    children: [
      { path: '', loadChildren: ()  => import('./autoreg/autoreg.module').then(m => m.AutoregModule) },
    ]
  },
  {
    path: '404',
    component: Page404Component
  },
  {
    path: '500',
    component: Page500Component
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule {
}
