<mat-accordion>
  <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="title">Новый чат</span>
      </mat-panel-title>
      <mat-panel-description class="d-flex justify-content-end">
<!--        <i class="fa-solid fa-plus"></i>-->
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div [formGroup]="formNewChat">

      <mat-form-field class="w-100">
        <mat-label>Название</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>

      <mat-form-field class="w-100">
        <mat-label>Модель</mat-label>
        <input matInput formControlName="model">
      </mat-form-field>
    </div>

    <mat-action-row>
      <button mat-stroked-button color="primary"
              [disabled]="formNewChat.invalid"
              (click)="createChat()">Создать</button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>
