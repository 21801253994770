import {createReducer, on} from "@ngrx/store";
import {initialState} from "@/ui/store/index";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {pageLoaderAction} from "@/ui/store/actions/pageLoader.action";

export const UIReducer = createReducer(
  initialState,
  on(ButtonLoaderAction, (state, {load}) => {
    return {
      ...state,
      buttonLoader: load
    }
  }),
  on(pageLoaderAction, (state, {load}) => {
    return {
      ...state,
      pageLoader: load
    }
  })
)
