import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {CartService} from "@/main/cart/services/cart.service";
import {
  getCartDataAction, getCartDataErrorAction,
  getCartDataFailureAction,
  getCartDataSuccessAction
} from "@/main/cart/store/actions/getCartData.action";

@Injectable()
export class GetCartDataEffect {
  getCartDataEffect$ = createEffect(() => this.actions$.pipe(
    ofType(getCartDataAction),
    switchMap(() => {
      return this.cartService.getCartData().pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return getCartDataSuccessAction({response})
          } else if (response.status === 'Failure') {
            return getCartDataFailureAction({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(getCartDataErrorAction({error}));
          } else if (error.status === 500 || 501) {
            return of(getCartDataErrorAction({error}));
          } else {
            return of(getCartDataErrorAction({error}));
          }
        })
      );
    })
  ))

  getCartDataFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(getCartDataFailureAction),
      tap((response: {response: DefaultResponseType}): void => {
        console.error('Данные для корзины не получены')
      })
    ), {dispatch: false}
  )

  getCartDataError$ = createEffect(
    () => this.actions$.pipe(
      ofType(getCartDataErrorAction),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          console.error('Данные для корзины не получены')
        } else if (error.status === 500 || 501) {
          console.error('Данные для корзины не получены. Ошибка сервера')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private cartService: CartService
  ) {
  }
}
