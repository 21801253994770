import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export interface CartState {
  cartData: AutoRegItemType[], //Данные корзины
  selectedTr: AutoRegItemType[], //Отмеченные аккаунты
  currentPageData: AutoRegItemType[], //Данные текущей страницы
  pageSize: number, //Количество записей на текущей странице
  currentPage: number //Номер текущей страницы,
  countCheckAccInCurrentPage: boolean, //флаг, который проверяет, все ли записи страницы отмечены
}

export const initialState: CartState = {
  cartData: [],
  selectedTr: [],
  currentPageData: [],
  pageSize: 30,
  currentPage: 0,
  countCheckAccInCurrentPage: false,
}

