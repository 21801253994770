import {Component, Input} from '@angular/core';
import {Store} from "@ngrx/store";
import {autoRegSearchAction} from "@/main/autoreg/accounts/store/actions/autoRegSearch.action";
import {searchEmailAction} from "@/main/emails/store/actions/searchEmail.action";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent  {

  @Input() type: 'autoreg' | 'email';

  constructor(private store: Store) {
  }

  public search(searchText: string): void {
    if (this.type === 'autoreg') {
      this.store.dispatch(autoRegSearchAction({textSearch: searchText}))
    } else if (this.type === 'email') {
      this.store.dispatch(searchEmailAction({searchText}))
    }
  }
}
