import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {StatisticsService} from "@/main/autoreg/services/statistics.service";
import {
  GetStatisticsAllAction, GetStatisticsAllActionFailure,
  GetStatisticsAllActionSuccess
} from "@/main/autoreg/stats/store/actions/getStatissticsAll.action";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class GetStatisticsAllEffect {
  getStatisticsAllEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetStatisticsAllAction),
    switchMap(({firstRequest}) => {
      return this.statisticsService.getStatisticsAll().pipe(
        map((response: DefaultResponseType) => {
          return GetStatisticsAllActionSuccess({response, firstRequest})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(GetStatisticsAllActionFailure({error}));
        })
      );
    })
  ))

  getStatisticsAllSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(GetStatisticsAllActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        if (response.response.status === 'Failure') {
          if (response.response.details.msg) {
            this.toaStr.error(response.response.details.msg)
          } else {
            this.toaStr.error('Данные статистики не загружены. Нет уведомления. Сообщить Максиму')
          }
        }
      })
    ), {dispatch: false}
  )

  getStatisticsAllFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(GetStatisticsAllActionFailure),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          this.toaStr.error('Данные статистики не загружены')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Данные статистики не загружены. Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private statisticsService: StatisticsService,
              private toaStr: ToastrService
  ) {
  }
}
