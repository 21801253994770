import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {
  setChatsAction, setChatsActionError,
  setChatsActionFailure,
  setChatsActionSuccess
} from "@/main/auto-gpt/store/actions/setChats.action";
import {AutoGptService} from "@/main/auto-gpt/services/auto-gpt.service";

@Injectable()
export class SetChatsEffect {
  setChatsEffect = createEffect(() => this.actions$.pipe(
    ofType(setChatsAction),
    switchMap(({request}) => {
      return this.chatService.setChat(request).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return setChatsActionSuccess({response})
          } else if (response.status === 'Failure') {
            return setChatsActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400 || 422) {
            return of(setChatsActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(setChatsActionError({error}));
          } else {
            return of(setChatsActionError({error}));
          }
        })
      );
    })
  ))

  constructor(private actions$: Actions,
              private chatService: AutoGptService,
              private toaStr: ToastrService
  ) {
  }
}
