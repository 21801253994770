import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {StatisticsService} from "@/main/autoreg/services/statistics.service";
import {
  getStatisticsAction,
  getStatisticsActionFailure,
  getStatisticsActionSuccess
} from "@/main/autoreg/stats/store/actions/getStatistics.action";
import {GetDateFormatToRequestUtil} from "@/main/autoreg/stats/utils/getDateFormatToRequest.util";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class GetStatisticsEffect {
  getStatisticsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(getStatisticsAction),
    switchMap(({dateEnd, dateStart, intervalValue}) => {
      let start: Date = new Date();
      let end: Date = new Date();
      if (intervalValue) {
        start.setDate(dateEnd.getDate() - intervalValue);
      } else if (dateStart) {
        start = dateStart;
        end = dateEnd;
      }
      return this.statisticsService.getStatistics(GetDateFormatToRequestUtil(start), GetDateFormatToRequestUtil(end)).pipe(
        map((response: DefaultResponseType) => {
          return getStatisticsActionSuccess({response})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(getStatisticsActionFailure({error}));
        })
      );
    })
  ))

  getStatisticsSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(getStatisticsActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
         if (response.response.status === 'Failure') {
          if (response.response.details.msg) {
            this.toaStr.error(response.response.details.msg)
          } else {
            this.toaStr.error('Данные статистики не загружены. Нет уведомления. Сообщить Максиму')
          }
        }
      })
    ), {dispatch: false}
  )

  getStatisticsFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(getStatisticsActionFailure),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          this.toaStr.error('Данные статистики не загружены')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Данные статистики не загружены. Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private statisticsService: StatisticsService,
              private toaStr: ToastrService
  ) {
  }
}
