import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AutoRegService} from "@/main/autoreg/services/auto-reg.service";
import {select, Store} from "@ngrx/store";
import {catchError, map, mergeMap, of, tap, withLatestFrom} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {selectedTrAutoReg} from "@/main/autoreg/accounts/store/selectors";
import {AutoRegDeletedRequestType} from "@/main/autoreg/accounts/store/type/autoRegDeletedRequest.type";
import {HttpErrorResponse} from "@angular/common/http";
import {
  autoRegDeletedGroupAccActions,
  autoRegDeletedGroupAccActionsError,
  autoRegDeletedGroupAccActionsFailure,
  autoRegDeletedGroupAccActionsSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegDeletedGroupAcc.actions";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";

@Injectable()
export class AutoRegDeletedGroupAccEffect {
  autoRegDeletedGroup$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegDeletedGroupAccActions),
    withLatestFrom(this.store.pipe(select(selectedTrAutoReg))),
    mergeMap(([action, select]) => {
      let request: AutoRegDeletedRequestType = {
        accounts: []
      }
      request.accounts = select.map(item => {
        return {
          login: item.login,
          ap_id: item.ap_id,
          group_name: item.group_name === null ? 'lorem' :  item.group_name,
          extension_id: item.extension_id,
          ap_port: action.port === 0 ? item.ap_port : action.port
        }
      })
      return this.autoRegService.accDeleted(request).pipe(
        map((response: DefaultResponseType) => {
          let account = request;
          if (response.status === 'Success') {
            return autoRegDeletedGroupAccActionsSuccess({response})
          } else if (response.status === 'Failure') {
            return autoRegDeletedGroupAccActionsFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegDeletedGroupAccActionsError({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegDeletedGroupAccActionsError({error}));
          } else {
            return of(autoRegDeletedGroupAccActionsError({error}));
          }
        })
      );
    })
  ))

  autoRegDeletedGroupSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegDeletedGroupAccActionsSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.success(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegDeletedGroupFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegDeletedGroupAccActionsFailure),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegDeletedGroupError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegDeletedGroupAccActionsError),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private autoRegService: AutoRegService,
              private toaStr: ToastrService,
              private store: Store
  ) {
  }
}
