import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export function TrCheckedUtil(currentPage: AutoRegItemType[], id: number): AutoRegItemType[] {
  let page = currentPage;
  if (id === 0) {
    let check = page.find(item => item.checked);
    let nocheck = page.filter(item => item.checked);
    if (check) {
      page = currentPage.map(item => {
        return {...item, checked: false}
      }).map(item => {
        return {...item, checked: true}
      })
    } else {
      page = currentPage.map(item => {
        return {...item, checked: !item.checked}
      })
    }
    if (nocheck.length === currentPage.length) {
      page = currentPage.map(item => {
        return {...item, checked: !item.checked}
      })
    }
  } else if (id !== 0) {
    page = currentPage.map(item => {
      if (item.id === id) {
        return {...item, checked: !item.checked}
      }
      return item;
    })
  }
  return page;
}
