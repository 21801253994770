import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {ActionStatus, ActionStatusColor} from "../../../constants/ACTION_STATUS_COLORS";

@Directive({
  selector: '[appTextColor]'
})
export class TextColorDirective implements OnInit {

  @Input() text: string;
  constructor(private elementRef: ElementRef) { }

  public ngOnInit(): void {
    this.setColorText(this.text)
  }

  private setColorText(text): void {
    let element = this.elementRef.nativeElement;

    switch (text) {
      case ActionStatus.STOP:
        element.style.color = ActionStatusColor.STOP;
        break;

      case ActionStatus.UNDEFINED:
        element.style.color = ActionStatusColor.UNDEFINED;
        break;

      case ActionStatus.ERROR:
        element.style.color = ActionStatusColor.ERROR;
        break;

      case ActionStatus.COMPLETED:
        element.style.color = ActionStatusColor.COMPLETED;
        break;

      case ActionStatus.COMPLETED_ERROR:
        element.style.color = ActionStatusColor.COMPLETED_ERROR;
        break;

      default:
        element.style.color = 'black';
        break;
    }

  }

}
