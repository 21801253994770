import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export function AutoRegSetOwnerUtil(array: AutoRegItemType[], logins: {login: string, state: string, checkpoint: string}[], id: number, stateAutoreg: string): AutoRegItemType[] {
  let newData: AutoRegItemType[] = [];
  array.forEach(account => {
    let accountItem = {...account};

    logins.forEach(login => {
      let userId = id;
      if (userId === 0 || stateAutoreg === 'Готов' || stateAutoreg === 'Мусор') {
        userId = null;
      }
      if (account.login === login.login) {
        accountItem.owner_id = userId;
        accountItem.checkpoint = login.checkpoint
        accountItem.state = login.state
      }
    });
    newData.push(accountItem);
  });
  return newData;
}
