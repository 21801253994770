import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setStatus'
})
export class SetStatusPipe implements PipeTransform {

  transform(value: boolean): string {
    let status = 'Нет'
    switch (value) {
      case value:
        status = 'Да'
        break;
      case !value:
        status = 'Нет'
        break;
    }

    return status;
  }

}
