import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToolsRoutingModule } from './tools-routing.module';
import {SharedModule} from "@/shared/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { AddCardComponent } from './add-card/add-card.component';
import { GenDocsComponent } from './gen-docs/gen-docs.component';
import { GenWhiteComponent } from './gen-white/gen-white.component';


@NgModule({
  declarations: [
    AddCardComponent,
    GenDocsComponent,
    GenWhiteComponent
  ],
  imports: [
    CommonModule,
    ToolsRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ToolsModule { }
