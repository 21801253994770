<div class="create-group">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0 process">
        <div class="card-header">
          <h3 class="card-title">Создать группу</h3>
        </div>
        <div class="card-body pb-0" [formGroup]="createGroup">
          <div class="row">

            <div class="col-12">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Название</mat-label>
                <input type="text" matInput placeholder="Название" formControlName="name"
                [(ngModel)]="name"
                >
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field class="example-full-width w-100">
                <mat-label>ID Scenum</mat-label>
                <input type="text" matInput placeholder="ID Scenum" formControlName="scenum_id">
              </mat-form-field>
            </div>


            <div class="col-6 my-2">
              <mat-form-field class="example-full-width w-100" appearance="fill">
                <mat-label>Устройство</mat-label>
                <mat-select formControlName="device">
                  <mat-option *ngFor="let item of devices" [value]="item.value">{{item.device}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-6 my-2">
              <mat-form-field class="example-full-width w-100" appearance="fill">
                <mat-label>Тип</mat-label>
                <mat-select formControlName="type">
                  <mat-option [value]="'autoreg'">Автореги</mat-option>
                  <mat-option [value]="'farm'">Фармы</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-6">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Номер пачки</mat-label>
                <input type="number" max="6" min="1" matInput placeholder="Количество" formControlName="count">
              </mat-form-field>
            </div>

            <div class="col-6">
              <mat-form-field class="example-full-width w-100">
                <input matInput [matDatepicker]="picker" formControlName="date">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>


            <div class="col-6">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена</button>
            </div>

            <div class="col-6">
              <button class="w-100" mat-raised-button color="primary"
                      [disabled]="createGroup.invalid || (buttonLoader$ | async)"
                      (click)="create()"
              >
                <span *ngIf="!(buttonLoader$ | async)">Создать</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
