<div class="context-menu"
     [class.active]="user"
     [ngStyle]="contextMenuStyle"
     (mouseleave)="closeContextMenu()"
>
  <button class="active text-success mx-1"
          *ngIf="!user?.is_active"
          (click)="activated()"
  >
    <i class="fa-solid fa-user-check"></i>
  </button>
  <button class="delete text-danger mx-1"
          (click)="deleted()"
  >
    <i class="fa-solid fa-user-slash"></i>
  </button>
  <button class="redaction text-primary mx-1"
          (click)="redaction()"
  >
    <i class="fa-solid fa-user-pen"></i>
  </button>
</div>
