import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export function filterOwnerUtil(array: string[], value: string): string[] {
    if (value !== 'withTheOwner' && value !== 'withOutOwner') {
      const index = array.indexOf(value);
      if (index === -1) {
        return [...array, value];
      } else {
        return [...array.slice(0, index), ...array.slice(index + 1)];
      }
    } else if (value === 'withTheOwner' || value === 'withOutOwner') {
      const index = array.indexOf(value);
      if (index === -1) {
        return [value];
      } else {
        return [...array.slice(0, index), ...array.slice(index + 1)];
      }
    }

}
export function filteredOwnerUtil(defaultArray, selectedArray, filtersStep, defaultValue?: boolean): AutoRegItemType[] {
  let defArr = defaultValue ? defaultArray : selectedArray;
  let newArray = [];
  defArr.forEach(item => {
    filtersStep.forEach(filter => {
      if (item.owner_id && filter !== 'withTheOwner' && filter !== 'withOutOwner' && item.owner_id.toString()=== filter) {
        newArray.push(item);
      } else if (filter === 'withTheOwner' && item.owner_id) {
        newArray.push(item)
      } else if (filter === 'withOutOwner' && !item.owner_id) {
        newArray.push(item)
      }
    })
  })
  return newArray;
}

export function filteredOwnerArrayUtil(defaultArray, selectedArray, filtersOwner, defaultValue?: boolean): AutoRegItemType[] {
  let defArr = defaultValue ? defaultArray : selectedArray;
  let newArray = [];
  defArr.forEach(item => {
    filtersOwner.forEach(filter => {
      if (item.owner_id && filter !== 'withTheOwner' && filter !== 'withOutOwner' && item.owner_id.toString() === filter) {
        newArray.push(item);
      } else if (filter === 'withTheOwner' && item.owner_id) {
        newArray.push(item)
      } else if (filter === 'withOutOwner' && !item.owner_id) {
        newArray.push(item)
      }
    })
  })
  return newArray;
}
