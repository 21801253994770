import {combineReducers} from "@ngrx/store";
import {autoRegReducer} from "@/main/autoreg/registration/store/reducer";
import {AutoRegInterface} from "@/main/autoreg/registration/store";
import {AccountsInterface} from "@/main/autoreg/accounts/store";
import {accountReducer} from "@/main/autoreg/accounts/store/reducer";
import {StatisticsState} from "@/main/autoreg/stats/store";
import {statisticsReducer} from "@/main/autoreg/stats/store/reducer";

export interface AutoRegState {
  registration: AutoRegInterface,
  accounts: AccountsInterface,
  statistics: StatisticsState
}

export const reducersAutoReg = combineReducers({
  registration: autoRegReducer,
  accounts: accountReducer,
  statistics: statisticsReducer
})
