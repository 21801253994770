<div class="row h-100 d-flex justify-content-center align-items-center">
  <div class="col-4">
    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        <a class="h1"><b>Аквариум </b>CMS</a>
      </div>
      <div class="card-body">
        <p class="login-box-msg">Войдите, чтобы начать сеанс</p>

        <form [formGroup]="loginForm" (ngSubmit)="login()" class="row">

          <div class="col-12">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Почта</mat-label>
              <input matInput placeholder="Почта"
                     type="email"
                     formControlName="email">
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Пароль</mat-label>
              <input matInput placeholder="Пароль"
                     type="password"
                     formControlName="password">
            </mat-form-field>
          </div>

            <div class="col-12">
              <button class="w-100" mat-raised-button color="primary"
                      [type]="'submit'"
                      [disabled]="(isSubmitting$ | async) || loginForm.invalid"
              >
                <span *ngIf="!(buttonLoader$ | async)">Войти</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>

        </form>
      </div>
      <!-- /.auth-card-body -->
    </div>
  </div>
</div>

