import {Component, Input, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {Observable} from "rxjs";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {UsersType} from "@/users/store/type/users.type";
import {usersSelector} from "@/users/store/selectors";
import {autoRegSetOwnerAction} from "@/main/autoreg/accounts/store/actions/autoRegSetOwner.action";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {PopUpType} from "@/shared/types/popUp.type";
import {currentUserRoleSelector} from "@/main/autoreg/accounts/store/selectors";

@Component({
  selector: 'app-set-owner',
  templateUrl: './set-owner.component.html',
  styleUrls: ['./set-owner.component.scss']
})
export class SetOwnerComponent implements OnInit {

  @Input() type: string;
  @Input() account: AutoRegItemType;

  public buttonLoader$: Observable<boolean>;
  public users$: Observable<UsersType[]>;
  public currentUserRole$: Observable<string>;

  public user: UsersType;

  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initValue();
  }

  public setOwner(): void {
    if (this.type === PopUpType.SET_OWNER_ADMIN_ITEMS) {
        this.store.dispatch(autoRegSetOwnerAction({id: this.user.id, name: this.user.username}))
    } else if (this.type === PopUpType.SET_OWNER_ADMIN_ITEM) {
        this.store.dispatch(autoRegSetOwnerAction({id: this.user.id, name: this.user.username, login: this.account.login, state: this.account.state}))
    }
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  private initValue(): void {
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
    this.users$ = this.store.pipe(select(usersSelector));
    this.currentUserRole$ = this.store.pipe(select(currentUserRoleSelector))

  }

}
