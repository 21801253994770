import { Pipe, PipeTransform } from '@angular/core';
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";


@Pipe({
  name: 'groupFilter'
})
export class GroupPipe implements PipeTransform {

  transform(value: AutoRegGroupType[], filter: 'autoreg' | 'farm'): AutoRegGroupType[] {
    return value.filter(item => item.type === filter)
  }
}
