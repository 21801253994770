<div class="container p-0 settings-container">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0 process" [formGroup]="tableAddFarm">
        <div class="card-header">
          <h3 class="card-title">Добавить фарм</h3>
        </div>
        <div class="card-body pb-0">
          <div class="row">

            <div class="col-12 mb-2" *ngIf="currentGroupInfo">
              <h6>Информация о группе:</h6>
              <div class="group-info">
                <table class="table-bordered w-100 table">
                  <tr>
                    <td>Количество авторегов</td>
                    <td>{{currentGroupInfo.autoregs.length}}</td>
                  </tr>
                  <tr>
                    <td>Текущее действие</td>
                    <td>{{currentGroupInfo.current_action}}</td>
                  </tr>
                  <tr *ngIf="currentGroupInfo.state === 'Фарм'">
                    <td>Состояние</td>
                    <td>{{currentGroupInfo.state}}</td>
                  </tr>
                  <tr *ngIf="currentGroupInfo.state === 'Фарм'">
                    <td>Этап</td>
                    <td>{{currentGroupInfo.step}}</td>
                  </tr>
                  <tr>
                    <td>Кол-во авторегов с ошибками</td>
                    <td>{{currentGroupInfo.errors}}</td>
                  </tr>

                </table>
              </div>
            </div>

            <div class="col-12">

              <mat-form-field class="example-full-width w-100">
                <mat-label>Название группы</mat-label>
                <ng-container *ngIf="autoRegGroup$ | async | getGroupToFarm as autoRegGroup">
                  <mat-select formControlName="group_id">
                    <ng-container *ngIf="(autoRegGroup | groupFilter:'farm').length !== 0">
                      <mat-optgroup [label]="'Фармы'">
                        <mat-option *ngFor="let group of autoRegGroup | groupFilter:'farm'"
                                    [value]="group.id"
                        >
                          <span>{{group.name}}</span>
                        </mat-option>
                      </mat-optgroup>
                    </ng-container>


                    <mat-optgroup [label]="'Автореги'">
                      <mat-option *ngFor="let group of autoRegGroup | groupFilter:'autoreg'"
                                  [value]="group.id">
                        <span>{{group.name}}</span>
                      </mat-option>
                    </mat-optgroup>

                  </mat-select>
                </ng-container>
              </mat-form-field>
            </div>

            <div class="col-6 my-2">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена</button>
            </div>
            <div class="col-6 my-2">
              <button class="w-100" mat-raised-button color="primary"
                      [disabled]="tableAddFarm.invalid || (buttonLoader$ | async)"
                      (click)="startNewFarm()"
              >
                <span *ngIf="!(buttonLoader$ | async)">Запустить</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
