import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {autoRegAccActions} from "@/main/autoreg/accounts/store/actions/autoRegAcc.actions";
import {
  autoRegTrChecked,
  downloadTableData,
  downloadTableDataItems
} from "@/main/autoreg/accounts/store/actions/autoRegAccTable.actions";
import {PopUpType} from "@/shared/types/popUp.type";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {autoRegFiltersAction} from "@/main/autoreg/accounts/store/actions/autoRegFilters.action";
import {
  accAddToGroupSelector,
  accCheckItemsSelector, accDeleteOwnerItemsSelector,
  accFbToolSelector,
  accIsCreateAPSelector,
  accToReLoginSelector,
  accUseOwnerSelector,
  autoRegAccItemsSelectSelector,
  autoRegGroupSelector,
  autoRegStatusSelector,
  countCheckAccInCurrentPageSelector, currentGroupFBSelector,
  currentPageDataSelector,
  currentPageSelector, currentProxyFBSelector,
  isCheckedTrSelector,
  pageNumbersSelector,
  pageSizeSelector,
  selectedTrAutoReg,
  tableSettingsSelector
} from "@/main/autoreg/accounts/store/selectors";
import {onPageChangeAction} from "@/main/autoreg/accounts/store/actions/onPageChange.action";
import {autoRegDeletedGroupAccActions} from "@/main/autoreg/accounts/store/actions/autoRegDeletedGroupAcc.actions";
import {autoRegAccDeleted} from "@/main/autoreg/accounts/store/actions/autoRegAccDeleted.actions";
import {PageEvent} from "@angular/material/paginator";
import {ContextMenuEventType} from "@/shared/types/contextMenuEvent.type";
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";
import {autoRegAddToGroupAction} from "@/main/autoreg/accounts/store/actions/autoRegAddToGroup.action";
import {AutoRegGetGroupAction} from "@/main/autoreg/accounts/store/actions/autoRegGetGroup.action";
import {AccCheckItemsType} from "@/main/autoreg/accounts/store/type/accCheckItems.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {autoRegFbGroupAction} from "@/main/autoreg/accounts/store/actions/autoRegFbGroup.action";
import {AutoRegSetOwnerSystemAction} from "@/main/autoreg/accounts/store/actions/autoRegSetOwnerSystem.action";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {autoRegGetProxyAction} from "@/main/autoreg/accounts/store/actions/autoRegGetProxy.action";
import {currentUserType} from "@/users/store/type/currentUser.type";
import {currentUserSelector, defaultApiKeyFBToolSelector, usersSelector} from "@/users/store/selectors";
import {autoRegSetOwnerAction} from "@/main/autoreg/accounts/store/actions/autoRegSetOwner.action";
import {UsersType} from "@/users/store/type/users.type";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {AutoRegTableColumnDropAction} from "@/main/autoreg/accounts/store/actions/autoRegTableColumnDrop.action";
import {integrationToScenumSelector} from "@/main/autoreg/registration/store/selectors";

@Component({
  selector: 'app-auto-reg-items',
  templateUrl: './auto-reg-items.component.html',
  styleUrls: ['./auto-reg-items.component.scss']
})

export class AutoRegItemsComponent implements OnInit, OnDestroy {

  @ViewChild('settings') settingsElement: TemplateRef<ElementRef>;
  @ViewChild('fbTools') fbToolsElement: TemplateRef<ElementRef>;
  @ViewChild('fbToolsItems') fbToolsItemsElement: TemplateRef<ElementRef>;
  @ViewChild('reLogin') reLoginElement: TemplateRef<ElementRef>;
  @ViewChild('autoRegItem') autoRegItemElement: TemplateRef<ElementRef>;
  @ViewChild('settingsCol') settingsColElement: TemplateRef<ElementRef>;
  @ViewChild('popup') popupElement: TemplateRef<ElementRef>;
  @ViewChild('createProfileAP') createProfileApElement: TemplateRef<ElementRef>;
  @ViewChild('checkAcc') checkAccElement: TemplateRef<ElementRef>;
  @ViewChild('deletedAcc') deletedElement: TemplateRef<ElementRef>;
  @ViewChild('createGroupElement') createGroupElement: TemplateRef<ElementRef>;
  @ViewChild('addToGroupElement') addToGroupElement: TemplateRef<ElementRef>;
  @ViewChild('deletedGroupElement') deletedGroupElement: TemplateRef<ElementRef>;
  @ViewChild('setOwnerElement') setOwnerElement: TemplateRef<ElementRef>;


  public columns: string[];
  public contextMenuStyle: any;
  public selectTr: boolean = false;
  public contextMenuEventType = ContextMenuEventType;
  public typePopUp: string;
  public popUpType = PopUpType;
  public autoRegAccItems: AutoRegItemType[];
  public autoRegItemData: AutoRegItemType;
  public currentAcc: AutoRegItemType;
  public pageSize: number;
  public pageIndex: number;
  public pageSizeOptions = [30, 40, 90, 150, 300, 500];
  public hidePageSize = false;
  public showPageSizeOptions = true;
  public showFirstLastButtons = true;
  public disabled = false;
  public scrollElement = true;

  public integrationToScenum$: Observable<boolean>; //Флаг для определения наличия процесса интеграции
  public columns$: Observable<string[]>; //названия столбцов таблицы
  public users$: Observable<UsersType[]>; //список пользователей
  public currentUser$: Observable<currentUserType>; //текущий пользователь
  public autoRegAccItems$: Observable<AutoRegItemType[]> //все автореги
  public accToReLogin$: Observable<AutoRegItemType[]> //все автореги
  public autoRegSelected$: Observable<AutoRegItemType[]> //отсортированные автореги
  public autoRegGroup$: Observable<AutoRegGroupType[]> //группы
  public isCheckedTr$: Observable<boolean>;
  public accUseOwner$: Observable<AutoRegItemType[]>; //автореги, доступные для назначения пользователей
  public accDeleteOwnerItems$: Observable<AutoRegItemType[]> //автореги, доступные для удаления пользователей
  public accFbTool$: Observable<AutoRegItemType[]>; //Автореги, доступные для добавления в FB-Tools
  public pageNumbers$: Observable<number[]>;
  public currentPage$: Observable<number>;
  public currentPageData$: Observable<AutoRegItemType[]>;
  public pageSize$: Observable<number>;
  public accIsCreateAP$: Observable<AutoRegItemType[]>;
  public accCheckItems$: Observable<AccCheckItemsType>;
  public countCheckAccInCurrentPage$: Observable<boolean>;
  public accAddToGroupItems$: Observable<AutoRegItemType[]>

  private defaultApiKeyFbTool$: Observable<string>;
  private defaultApiKeyFbTool: string;
  private currentUser: currentUserType;
  private popUpState$: Observable<boolean | string>
  private dialogRef: MatDialogRef<any>;
  private accUseOwner: AutoRegItemType[];
  private subscriptionList: Subscription[] = [];

  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private matDialog: MatDialog,
              private store: Store) {
  }

  public ngOnInit(): void {
    this.initValue();
    this.store.dispatch(autoRegAccActions());

    this.sub = this.columns$.subscribe(data => {
      if (data && data.length !== 0) {
        this.columns = data
      }
    });
    this.store.dispatch(autoRegFiltersAction());
    this.sub = this.currentPageData$.subscribe(data => {
      this.autoRegAccItems = data
    });
    this.sub = this.popUpState$.subscribe(data => {
      this.closePopUp(data)
    });
    this.sub = this.currentPage$.subscribe(data => this.pageIndex = data);
    this.sub = this.pageSize$.subscribe(data => this.pageSize = data);
    this.sub = this.accUseOwner$.subscribe(data => this.accUseOwner = data);
    this.sub = this.currentUser$.subscribe(data => {
      if(data !== undefined && data !== null) {
        this.currentUser = data;
      }
    });
    this.sub = this.defaultApiKeyFbTool$.subscribe(data => {
      if(data !== undefined && data !== null) {
        this.defaultApiKeyFbTool = data;
      }
    });
    this.store.dispatch(onPageChangeAction({page: this.pageIndex, pageSize: this.pageSize}));
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(item => item.unsubscribe())
  }

  //меняем местами столбцы таблицы
  public dropColumnTable(event: CdkDragDrop<string[]>): void {
    if (this.columns && this.columns.length !== 0) {
      const updatedColumns = [...this.columns];
      moveItemInArray(updatedColumns, event.previousIndex, event.currentIndex);
      this.columns = updatedColumns;
      this.store.dispatch(AutoRegTableColumnDropAction({columns: this.columns}));
    }
    this.scrollElement = true;
  }

  //переключение страниц
  public handlePageEvent($event: PageEvent): void {
    this.store.dispatch(onPageChangeAction({page: $event.pageIndex, pageSize: $event.pageSize}))
  }

  //клик правой кнопкой мыши по таблице
  public onRightClick($event: MouseEvent, item): void {
    $event.preventDefault();
    this.contextMenuStyle = {
      'left.px': $event.clientX + window.pageXOffset - 10,
      'top.px': $event.clientY + window.pageYOffset - 10,
    }
    this.currentAcc = item;
    this.selectTr = true;
  }


  //события контекстного меню
  public contextMenuEvent($event): void {
    switch ($event) {
      case false:
        this.selectTr = false;
        break;

      case this.contextMenuEventType.DELETED:
        this.deletedAutoRegItem();
        break;

      case this.contextMenuEventType.RE_LOGIN_ACC:
        this.reLoginProcess('one');
        break;

      case this.contextMenuEventType.OPEN_ACC:
        this.openAutoRegAcc();
        break;

      case this.contextMenuEventType.CREATE_ACC_AP:
        this.createProfileFromAdsPower();
        break;

      case this.contextMenuEventType.OPEN_FB_TOOLS:
        this.openFbTools();
        break;

      case this.contextMenuEventType.CHECKED:
        this.onCheckbox(this.currentAcc.id)
        break;

      case this.contextMenuEventType.CHECK_ACC:
        this.checkAccounts(this.popUpType.CHECK_ACC_ITEM);
        break;

      case this.contextMenuEventType.ADD_TO_GROUP:
        this.addToGroupAutoReg(this.popUpType.ADD_TO_GROUP);
        break;

      case this.contextMenuEventType.CHANGE_AUTO_REG_STATE_ITEM:
        this.accChangeStateToCompletedItem();
        break;

      case this.contextMenuEventType.SET_OWNER:
        this.setOwnerItem(this.currentUser.role, this.currentUser.id, this.currentUser.username, this.currentAcc.login)
        break;

      case this.contextMenuEventType.DELETE_OWNER:
        this.deletedOwner('one');
        break;
    }
  }

  //Выделяем строку при нажатии на чекбокс
  public onCheckbox(id): void {
    this.store.dispatch(autoRegTrChecked({id}))
  }

  //Загрузка всей таблицы
  public downloadTable(): void {
    this.store.dispatch(downloadTableData({}))
  }

  //Добавляем в FB-Tools один аккаунт
  public openFbTools(): void {

    this.store.dispatch(autoRegGetProxyAction({ apiKey: this.getApiKeyFB() }));
    this.store.dispatch(autoRegFbGroupAction({ apiKey: this.getApiKeyFB() }));

    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.autoRegItemData = this.currentAcc;
    this.dialogRef = this.matDialog.open(this.fbToolsElement);
  }

  //Добавляем в FB-Tools группу аккаунтов
  public openFbToolsItems(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}));

    this.store.dispatch(downloadTableDataItems());

    this.store.dispatch(autoRegGetProxyAction({ apiKey: this.getApiKeyFB() }));
    this.store.dispatch(autoRegFbGroupAction({ apiKey: this.getApiKeyFB() }));

    this.dialogRef = this.matDialog.open(this.fbToolsItemsElement);
  }

  //Проверка аккаунта
  public checkAccounts(type): void {
    this.typePopUp = type;
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.dialogRef = this.matDialog.open(this.checkAccElement);
  }

  //создание аккаунта в AP
  public createProfileFromAdsPower(): void {
    this.typePopUp = PopUpType.CREATE_ACC_AP;
    this.dialogRef = this.matDialog.open(this.createProfileApElement);
    this.store.dispatch(autoRegClosePopupAction({open: false}));
  }

  //создание группы аккаунтов в AP
  public createProfileFromAdsPowerItems(): void {
    this.typePopUp = PopUpType.CREATE_ACC_AP_GROUP;
    this.dialogRef = this.matDialog.open(this.createProfileApElement);
    this.store.dispatch(autoRegClosePopupAction({open: false}));
  }

  //запуск релогина
  public reLoginProcess(type: 'one' | 'group'): void {
    if (type === 'one') {
      this.typePopUp = PopUpType.RE_LOGIN_ITEM
    } else {
      this.typePopUp = PopUpType.RE_LOGIN_ITEMS
    }
    this.dialogRef = this.matDialog.open(this.reLoginElement);
    this.store.dispatch(autoRegClosePopupAction({open: false}))
  }


  public deletedAutoRegItem(): void {
    this.typePopUp = PopUpType.DELETED_ACC;
    this.dialogRef = this.matDialog.open(this.deletedElement);
    this.store.dispatch(autoRegClosePopupAction({open: false}))
  }

  public deletedGroupAcc(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.typePopUp = PopUpType.DELETE_ACC_GROUP;
    this.dialogRef = this.matDialog.open(this.deletedElement);
  }

  public createGroup(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.typePopUp = PopUpType.CREATE_GROUP;
    this.dialogRef = this.matDialog.open(this.createGroupElement);
  }

  public addToGroupAutoReg(type): void {
    this.store.dispatch(AutoRegGetGroupAction({update: false}))
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.typePopUp = type;
    this.dialogRef = this.matDialog.open(this.addToGroupElement);
  }

  public deletedGroup(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.typePopUp = PopUpType.DELETED_GROUP;
    this.dialogRef = this.matDialog.open(this.deletedGroupElement);
  }

  public accChangeStateToCompleted(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.typePopUp = PopUpType.CHANGE_AUTO_REG_STATE;
    this.dialogRef = this.matDialog.open(this.popupElement);
  }

  public accChangeStateToCompletedItem(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.typePopUp = PopUpType.CHANGE_AUTO_REG_STATE_ITEM;
    this.dialogRef = this.matDialog.open(this.popupElement);
  }

  public accChangeStateToGarbage(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.typePopUp = PopUpType.CHANGE_AUTO_REG_STATE_GARBAGE;
    this.dialogRef = this.matDialog.open(this.popupElement);
  }

  //события pop-up
  public popUpActions(action: any): void {
    if (action.constellation && this.typePopUp === PopUpType.DELETE_ACC_GROUP) {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(autoRegDeletedGroupAccActions({port: action.port}));
    }

    if (action.constellation && this.typePopUp === PopUpType.CHANGE_AUTO_REG_STATE_GARBAGE) {
      this.store.dispatch(AutoRegSetOwnerSystemAction({update: true, state: 'Мусор'}))
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
    }

    if (action.constellation && this.typePopUp === PopUpType.CHANGE_AUTO_REG_STATE) {
      this.store.dispatch(AutoRegSetOwnerSystemAction({update: true, state: 'Готов'}))
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
    }

    if (action.constellation && this.typePopUp === PopUpType.CHANGE_AUTO_REG_STATE_ITEM) {
      this.store.dispatch(AutoRegSetOwnerSystemAction({
        update: false,
        one: true,
        request:
          {
            accounts: [
              {
                login: this.currentAcc.login,
                state: 'Готов',
                checkpoint: this.currentAcc.checkpoint
              }
            ]
          }
      }))
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
    }

    if (action.constellation && this.typePopUp === PopUpType.DELETED_ACC) {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(autoRegAccDeleted({request: this.currentAcc, port: action.port}))
    }

    if (action.constellation && this.typePopUp === PopUpType.ADD_TO_GROUP) {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(autoRegAddToGroupAction({group: action.group, typeRequest: 'one', account: this.currentAcc}))
    }

    if (action.constellation && this.typePopUp === PopUpType.ADD_TO_GROUP_ITEMS) {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(autoRegAddToGroupAction({group: action.group, typeRequest: 'all'}))
    }

    if (action.constellation && this.typePopUp === PopUpType.DELETED_OWNER_AUTO_REG_ITEMS) {
      this.store.dispatch(autoRegSetOwnerAction({id: 0, name: null}))
    }

    if (action.constellation && this.typePopUp === PopUpType.DELETED_OWNER_AUTO_REG_ITEM) {
      this.store.dispatch(autoRegSetOwnerAction({
        id: 0,
        name: null,
        login: this.currentAcc.login,
        state: this.currentAcc.state
      }))
    }
  }


  private closePopUp(value): void {
    if (value === 'Закрыть' && this.dialogRef) {
      this.dialogRef.close()
    }
  }

  //Открываем одну авторегу для редактирования
  public openAutoRegAcc(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.dialogRef = this.matDialog.open(this.autoRegItemElement);
  }

  //Назначаем владельца авторегам
  public setOwner(role: string, id: number, name: string): void {
    if (role === 'ADMIN') {
      this.typePopUp = PopUpType.SET_OWNER_ADMIN_ITEMS;
      this.store.dispatch(autoRegClosePopupAction({open: false}));
      this.dialogRef = this.matDialog.open(this.setOwnerElement);
    } else {
      this.store.dispatch(autoRegSetOwnerAction({id: id, name: name}))
    }
  }

  //Назначаем владельца одному авторегу
  public setOwnerItem(role: string, id: number, name: string, login): void {
    if (role === 'ADMIN') {
      this.typePopUp = PopUpType.SET_OWNER_ADMIN_ITEM;
      this.store.dispatch(autoRegClosePopupAction({open: false}));
      this.dialogRef = this.matDialog.open(this.setOwnerElement);
    } else {
      this.store.dispatch(autoRegSetOwnerAction({id: id, name: name, login: login}))
    }
  }

  //Удаляем владельца у авторегов
  public deletedOwner(type: 'one' | 'group'): void {
    if (type === 'one') {
      this.typePopUp = PopUpType.DELETED_OWNER_AUTO_REG_ITEM;
    } else {
      this.typePopUp = PopUpType.DELETED_OWNER_AUTO_REG_ITEMS;
    }
    this.store.dispatch(autoRegClosePopupAction({open: false}));
    this.dialogRef = this.matDialog.open(this.popupElement)
  }

  public openSettingsCol(): void {
    this.dialogRef = this.matDialog.open(this.settingsColElement)
    this.store.dispatch(autoRegClosePopupAction({open: false}))
  }


  public update(): void {
    this.store.dispatch(autoRegAccActions())
  }

  private getApiKeyFB(): string {
    return this.currentUser.fbtool_apikey ? this.currentUser.fbtool_apikey : this.defaultApiKeyFbTool
  }

  private initValue(): void {
    this.columns$ = this.store.pipe(select(tableSettingsSelector));
    this.popUpState$ = this.store.pipe(select(autoRegStatusSelector));
    this.autoRegAccItems$ = this.store.pipe(select(autoRegAccItemsSelectSelector));
    this.isCheckedTr$ = this.store.pipe(select(isCheckedTrSelector));
    this.accToReLogin$ = this.store.pipe(select(accToReLoginSelector));
    this.pageNumbers$ = this.store.pipe(select(pageNumbersSelector));
    this.currentPageData$ = this.store.pipe(select(currentPageDataSelector));
    this.currentPage$ = this.store.pipe(select(currentPageSelector));
    this.accUseOwner$ = this.store.pipe(select(accUseOwnerSelector));
    this.accDeleteOwnerItems$ = this.store.pipe(select(accDeleteOwnerItemsSelector));
    this.accFbTool$ = this.store.pipe(select(accFbToolSelector));
    this.autoRegSelected$ = this.store.pipe(select(selectedTrAutoReg));
    this.accIsCreateAP$ = this.store.pipe(select(accIsCreateAPSelector));
    this.pageSize$ = this.store.pipe(select(pageSizeSelector));
    this.accCheckItems$ = this.store.pipe(select(accCheckItemsSelector));
    this.countCheckAccInCurrentPage$ = this.store.pipe(select(countCheckAccInCurrentPageSelector));
    this.autoRegGroup$ = this.store.pipe(select(autoRegGroupSelector));
    this.accAddToGroupItems$ = this.store.pipe(select(accAddToGroupSelector));
    this.currentUser$ = this.store.pipe(select(currentUserSelector));
    this.users$ = this.store.pipe(select(usersSelector));
    this.integrationToScenum$ = this.store.pipe(select(integrationToScenumSelector));
    this.defaultApiKeyFbTool$ = this.store.pipe(select(defaultApiKeyFBToolSelector));
  }

  public clearLocalStorage(): void {
    localStorage.clear();
    location.reload();
  }
}
