import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {UsersType} from "@/users/store/type/users.type";
import {select, Store} from "@ngrx/store";
import {usersSelector} from "@/users/store/selectors";
import {autoRegAccSortOwner} from "@/main/autoreg/accounts/store/actions/autoRegAccSort.action";
import {autoRegFilterCheckOwnerSelector, currentUserRoleSelector} from "@/main/autoreg/accounts/store/selectors";
import {toJS} from "@nrwl/devkit";
import {SortOwnerType} from "@/main/autoreg/accounts/store/type/sortOwner.type";

@Component({
  selector: 'app-filter-users',
  templateUrl: './filter-users.component.html',
  styleUrls: ['./filter-users.component.scss']
})
export class FilterUsersComponent implements OnInit {

  public users$: Observable<UsersType[]>;
  public checkUsers$: Observable<string[]>;
  public currentUserRole$: Observable<string>;

  constructor(public store: Store) {
  }

  public ngOnInit(): void {
    this.initValue();
  }

  public filterUser(user: SortOwnerType): void {
    this.store.dispatch(autoRegAccSortOwner({owner: user}))
  }

  public initValue(): void {
    this.users$ = this.store.pipe(select(usersSelector));
    this.checkUsers$ = this.store.pipe(select(autoRegFilterCheckOwnerSelector));
    this.currentUserRole$ = this.store.pipe(select(currentUserRoleSelector));
  }
}
