import {createAction, props} from "@ngrx/store";
import {ActionsType} from "@/main/cart/store/actions.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {UpdateStateRequestType} from "@/main/autoreg/accounts/store/type/requestSetOwnerSystem.type";

export const restoreAccAction = createAction(
  ActionsType.RESTORE_ACC,
  props<{request?: UpdateStateRequestType}>()
)

export const restoreAccSuccessAction = createAction(
  ActionsType.RESTORE_ACC_SUCCESS,
  props<{response: DefaultResponseType, request: UpdateStateRequestType}>()
)

export const restoreAccFailureAction = createAction(
  ActionsType.RESTORE_ACC_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const restoreAccErrorAction = createAction(
  ActionsType.RESTORE_ACC_ERROR,
  props<{error: HttpErrorResponse}>()
)
