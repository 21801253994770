import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {PopUpType} from "@/shared/types/popUp.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {Observable} from "rxjs";

export interface IPopUpData {
  constellation: boolean,
  data?: any
}

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

  @Input() type!: string;
  @Input() account: AutoRegItemType;
  @Input() nameProcess: string = '';
  @Input() user!: string;
  @Output() dataPopUp: EventEmitter<IPopUpData> = new EventEmitter<IPopUpData>();
  public typePopUp = PopUpType;
  public data: IPopUpData = {
    constellation: false,
  }

  public buttonLoader$: Observable<boolean>;
  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
  }

  public okConstellation(): void {
    this.data.constellation = true;
    this.dataPopUp.emit(this.data);
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
    this.data.constellation = false;
    this.dataPopUp.emit(this.data);
  }

  private initValue(): void {
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
  }
}
