import {AfterContentInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {NgxPermissionsService} from "ngx-permissions";
import {Observable} from "rxjs";
import {roleSelector} from "@/auth/store/selectors";
import {pageLoaderSelector} from "@/ui/store/selectors";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit {
  public pageLoader$: Observable<boolean>;
  constructor(private store: Store,
              private permissionsService: NgxPermissionsService) {
  }

  public ngOnInit(): void {

    this.store.pipe(select(roleSelector)).subscribe(role => {
      this.permissionsService.loadPermissions([role]);
    })
  }

  public ngAfterContentInit(): void {
    this.pageLoader$ = this.store.pipe(select(pageLoaderSelector));
  }
}
