import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {catchError, map, mergeMap, of, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {
  autoRegCreateProfileAPItemAction,
  autoRegCreateProfileAPItemActionError,
  autoRegCreateProfileAPItemActionFailure,
  autoRegCreateProfileAPItemActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegCreateAPItem.action";
import {ProcessService} from "@/main/autoreg/services/process.service";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";

@Injectable()
export class AutoRegCreateAPItemEffect {

  autoRegCreateAPItemEffect$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegCreateProfileAPItemAction),
    mergeMap(({data}) => {
      return this.processService.createProcessAP(data).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return autoRegCreateProfileAPItemActionSuccess({response})
          } else if (response.status === 'Failure') {
            return autoRegCreateProfileAPItemActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegCreateProfileAPItemActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegCreateProfileAPItemActionError({error}));
          } else {
            return of(autoRegCreateProfileAPItemActionError({error}));
          }
        })
      );
    })
  ))

  autoRegCreateAPItemSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegCreateProfileAPItemActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.success(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegCreateAPItemFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegCreateProfileAPItemActionFailure),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.error('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegCreateAPItemError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegCreateProfileAPItemActionError),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  constructor(
    private actions$: Actions,
    private processService: ProcessService,
    private store: Store,
    private toaStr: ToastrService
  ) {}
}
