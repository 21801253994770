import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const autoRegDeletedGroupAction = createAction(
  ActionType.AUTO_REG_DELETED_GROUP,
  props<{group_id: number}>()
)

export const autoRegDeletedGroupActionSuccess = createAction(
  ActionType.AUTO_REG_DELETED_GROUP_SUCCESS,
  props<{response: DefaultResponseType, group_id: number}>()
)

export const autoRegDeletedGroupActionFailure = createAction(
  ActionType.AUTO_REG_DELETED_GROUP_FAILURE,
  props<{error: HttpErrorResponse}>()
)
