import {IEmail} from "@/main/emails/store/types/email.interface";

export interface EmailsState {
  emails: IEmail[],
  emailsSelected: IEmail[],
  currentPageData: IEmail[],
  currentPage: number,
  pageSize: number
}

export const initialState: EmailsState = {
  emails: [],
  emailsSelected: [],
  currentPageData: [],
  currentPage: 0,
  pageSize: 30
}
