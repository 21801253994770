import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {UsersType} from "@/users/store/type/users.type";
import {ContextMenuEventType} from "@/shared/types/contextMenuEvent.type";

@Component({
  selector: 'app-context-menu-users',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  @Input() contextMenuStyle;
  @Input() user: UsersType;
  @Output() contextMenuEvent: EventEmitter<string | boolean> = new EventEmitter();

  public contextMenuTypeEvent = ContextMenuEventType;
  constructor() {
  }

  public ngOnInit(): void {

  }

  public closeContextMenu(): void {
    this.contextMenuStyle = { 'display': 'none' }
    this.contextMenuEvent.emit(false)
  }

  public activated(): void {
    this.contextMenuEvent.emit(this.contextMenuTypeEvent.ACTIVATED_USER)
  }

  public lock(): void {
    this.contextMenuEvent.emit(this.contextMenuTypeEvent.BLOCKED_USER)
  }

  public deleted(): void {
    this.contextMenuEvent.emit(this.contextMenuTypeEvent.DELETED_USER)
  }

  public redaction(): void {
    this.contextMenuEvent.emit(this.contextMenuTypeEvent.REDACTION_USER)
  }
}
