import {createSelector} from "@ngrx/store";
import {AccountsInterface} from "@/main/autoreg/accounts/store/index";
import {AppState} from "@/store/state";


export const accountFeatureSelector = (state: AppState) => state.autoReg.accounts;

export const columnsSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.columns
)

export const autoRegAccItemsSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.autoRegAccItems
)

export const autoRegAccItemsSelectSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.autoRegAccItemsSelected
)

export const accUseOwnerSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.accUseOwnerItems
)

export const accDeleteOwnerItemsSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.accDeleteOwnerItems
)

export const accToReLoginSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.accToReLogin
)

export const accFbToolSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.accFbToolsItems
)

export const autoDownloadData = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.accOfDownload
)

export const tableSettingsSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.tableSettings
)

export const autoRegStatusSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.popUpStatus
)

//для получения данных данных фильтра
export const autoRegFilterSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.filter
)

export const autoRegFilterStepSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.filter.stateAcc
)

export const autoRegFilterCheckOwnerSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.filter.owners
)

export const isCheckedTrSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.isCheckedTr
)

export const selectedTrAutoReg = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.accSelectedItems
)

export const accIsCreateAPSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.accIsCreateAP
)

export const tableDataIsDownloadSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.tableDataIsDownload
)

export const fbToolProxySelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.fbProxy
)

export const fbToolGroupSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.fbToolGroup.groups
)

export const fbToolGroupRequestLeftSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.fbToolGroup.requestsLeft
)

export const fbToolGroupDataForRequest = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.fbGroupFormatData
)

export const currentPageSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.currentPage
)

export const pageNumbersSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.pageNumbers
)

export const pageSizeSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.pageSize
)

export const currentPageDataSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.currentPageData
)

export const dataForLinearChartsAutoReg = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.dataForCharts
)

export const accAsSelfieSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.accAsSelfie
)

export const accIsCreateAPDataSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.accIsCreateAP
)


export const accCheckItemsSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.accCheckItems
)

export const countCheckAccInCurrentPageSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.countCheckAccInCurrentPage
)

export const registeredToDaySelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.dataForCharts.registeredToDay
)

export const autoRegGroupSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.autoRegGroup
)

export const accAddToGroupSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.accAddToGroup
)

export const currentGroupFBSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.currentGroupFB
)

export const currentProxyFBSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.currentProxyFB
)

export const currentProxyAndGroupFBSelector = createSelector(
  currentGroupFBSelector,
  currentProxyFBSelector,
  (currentGroupFB, currentProxyFB) => {
    // Обработайте и объедините данные по двум ключам здесь
    return {
      currentGroupFB,
      currentProxyFB,
      // ...другие вычисления или обработка данных...
    };
  }
);


export const currentUserRoleSelector = createSelector(
  accountFeatureSelector,
  (autoRegState: AccountsInterface) => autoRegState.currentUserRole
)




