import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {setChatsAction} from "@/main/auto-gpt/store/actions/setChats.action";
import {getChatsAction} from "@/main/auto-gpt/store/actions/getChats.action";

@Component({
  selector: 'app-new-chat',
  templateUrl: './new-chat.component.html',
  styleUrls: ['./new-chat.component.scss']
})
export class NewChatComponent implements OnInit {
    public formNewChat: UntypedFormGroup;
    public panelOpenState = false;
    constructor(private store: Store) {
    }

    public ngOnInit(): void {
      this.initValue();
    }

    public createChat(): void {
      this.store.dispatch(setChatsAction({request: this.formNewChat.value}));
      this.store.dispatch(getChatsAction());
    }

    private initValue(): void {
      this.formNewChat = new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        model: new UntypedFormControl('gpt-3', Validators.required),
      })
    }
}
