import { Pipe, PipeTransform } from '@angular/core';
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

@Pipe({
  name: 'getAccNoOwner',
  pure: false
})
export class GetAccNoOwnerPipe implements PipeTransform {

  transform(value: AutoRegItemType[]): AutoRegItemType[] {
    return value.filter(item => item.owner_id !== null);
  }

}
