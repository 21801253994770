<div class="search-component">
  <label class="search mb-0">
    <input type="search" #inputSearch
           class="form-control form-control-sm input-search"
           placeholder="Поиск"
           aria-controls="example1"
           (input)="search(inputSearch.value)"
    >
  </label>
</div>

