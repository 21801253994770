import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {statisticsStepSelector} from "@/main/autoreg/stats/store/selectors";
import {StepDataType} from "@/main/autoreg/stats/store/type/stepData.type";

@Component({
  selector: 'app-step-register-acc',
  templateUrl: './step-register-acc.component.html',
  styleUrls: ['./step-register-acc.component.scss']
})
export class StepRegisterAccComponent implements OnInit, OnDestroy {

  public dataCharts;
  public data$: Observable<StepDataType>;

  private subscriptionList: Subscription[] = [];
  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
    this.sub = this.data$.subscribe(data => {
      this.initCharts(data.step, data.count, data.color);
    })
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe())
  }


  private initValue(): void {
    this.data$ = this.store.pipe(select(statisticsStepSelector))
  }

  private initCharts(step, count, colors): void {
    this.dataCharts = {
      doughnutChartLabels: step,
      doughnutChartData: [
        {
          data: count,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1
        }
      ],
      doughnutChartType: 'doughnut'
    }
  }
}
