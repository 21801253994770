import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {AuthService} from "@/auth/services/auth.service";
import {Store} from "@ngrx/store";
import {StorageService} from "@/shared/services/storage.service";


@Injectable({
  providedIn: 'root'
})
export class AuthForwardGuard implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router) {}

  canActivate(): boolean {
    let isLoggedId = !!this.authService.getCookie('bonds');
    if (isLoggedId) {
      this.router.navigate(['/']);
    }
    return !isLoggedId;
  }

}

