import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[actionBtnStyle]'
})
export class ActionBtnStyleDirective implements OnInit {

  @Input() message?: string;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) {}

  public ngOnInit(): void {

  }


}
