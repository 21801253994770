export enum ActionsType {
  GET_STATISTICS = '[Statistics] Get-data',
  GET_STATISTICS_SUCCESS = '[Statistics] Get-data-success',
  GET_STATISTICS_FAILURE = '[Statistics] Get-data-failure',

  GET_STATISTICS_ALL = '[Statistics] Get-data-all',
  GET_STATISTICS_ALL_SUCCESS = '[Statistics] Get-data-all-success',
  GET_STATISTICS_ALL_FAILURE = '[Statistics] Get-data-all-failure',

  GET_STATISTICS_TODAY = '[Statistics] Get-data-today',
  GET_STATISTICS_TODAY_SUCCESS = '[Statistics] Get-data-today-success',
  GET_STATISTICS_TODAY_FAILURE = '[Statistics] Get-data-today-failure',
}
