import {AppState} from "@/store/state";
import {createSelector} from "@ngrx/store";
import {UsersState} from "@/users/store/index";

export const usersFeatureSelector = (state: AppState) => state.users
export const usersSelector = createSelector(
  usersFeatureSelector,
  (usersState: UsersState) => usersState.users
)

export const currentUserSelector = createSelector(
  usersFeatureSelector,
  (usersState: UsersState) => usersState.currentUser
)

export const popUpSelector = createSelector(
  usersFeatureSelector,
  (usersState: UsersState) => usersState.popUp
)

export const defaultPortSelector = createSelector(
  usersFeatureSelector,
  (usersState: UsersState) => {
    return usersState.users.find(item => item.id === 1).ap_port;
  }
)

export const currentUserPortSelector = createSelector(
  usersFeatureSelector,
  (usersState: UsersState) => usersState.currentUser.ap_port
)

export const defaultApiKeyFBToolSelector = createSelector(
  usersFeatureSelector,
  (usersState: UsersState) => usersState.defaultApiKeyFBTool
)
