export enum ActionStatus {
  STOP = 'Остановлен',
  UNDEFINED = 'Не определено',
  ERROR = 'Ошибка',
  COMPLETED = 'Завершен',
  COMPLETED_ERROR = 'Завершен с ошибками'
}

export enum ActionStatusColor {
  STOP = '#FF1493',
  UNDEFINED = '#B22222',
  ERROR = '#FF0000',
  COMPLETED = 'rgba(63,165,37,0.76)',
  COMPLETED_ERROR = '#FF4500'
}

