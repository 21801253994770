import {BrowserModule} from '@angular/platform-browser';
import {isDevMode, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {registerLocaleData} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {defineCustomElements} from '@profabric/web-components/loader';
import {MatDialogModule} from "@angular/material/dialog";
import {SharedModule} from "@/shared/shared.module";
import {MainModule} from "@/main/main.module";
import {NgxPermissionsModule} from "ngx-permissions";
import {AuthModule} from "@/auth/auth.module";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {EffectsModule} from "@ngrx/effects";
import {AuthInterceptor} from "@/core/interceptors/auth.interceptor";
import {reducers} from "@/store/state";
import {NgChartsModule} from "ng2-charts";
import {MatNativeDateModule} from "@angular/material/core";
import localeRu from '@angular/common/locales/ru';
import { LOCALE_ID } from '@angular/core';
import { AdminRootComponent } from '@/main/pages/admin-root/admin-root.component';
import {MatTabsModule} from "@angular/material/tabs";
import {StatsModule} from "@/main/autoreg/stats/stats.module";
import {MatPaginatorModule} from "@angular/material/paginator";
import {ContextMenuModule} from "ngx-contextmenu";
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import {UsersModule} from "@/users/users.module";


defineCustomElements();
registerLocaleData(localeRu, 'ru');

@NgModule({
    declarations: [
        AppComponent,
        AdminRootComponent
    ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgxPermissionsModule.forRoot(),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched actions, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
    }),
    StoreRouterConnectingModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    MainModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    ProfabricComponentsModule,
    MatDialogModule,
    SharedModule,
    AuthModule,
    NgChartsModule,
    MatNativeDateModule,
    MatTabsModule,
    StatsModule,
    MatPaginatorModule,
    ContextMenuModule.forRoot(),
    StoreRouterConnectingModule.forRoot(),
    UsersModule
  ],
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      },
      {
        provide: LOCALE_ID, useValue: 'ru'
      }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
