import {Action, createAction, props} from '@ngrx/store';
import {
  SET_NAVBAR_VARIANT, SET_SIDEBAR_SKIN, SET_WINDOWS_SIZE,
  TOGGLE_CONTROL_SIDEBAR,
  TOGGLE_DARK_MODE,
  TOGGLE_SIDEBAR_MENU
} from "@/store/ui/action.type";


export class ToggleSidebarMenu implements Action {
    readonly type: string = TOGGLE_SIDEBAR_MENU;
    constructor(public payload?: string) {}
}
export class ToggleControlSidebar implements Action {
    readonly type: string = TOGGLE_CONTROL_SIDEBAR;
    constructor(public payload?: string) {}
}

export class ToggleDarkMode implements Action {
    readonly type: string = TOGGLE_DARK_MODE;
    constructor(public payload?: string) {}
}

export class SetNavbarVariant implements Action {
    readonly type: string = SET_NAVBAR_VARIANT;
    constructor(public payload: string) {}
}

export class SetSidebarSkin implements Action {
    readonly type: string = SET_SIDEBAR_SKIN;
    constructor(public payload: string) {}
}
export class SetWindowSize implements Action {
    readonly type: string = SET_WINDOWS_SIZE;
    constructor(public payload: any) {}
}

export type UiAction =
    | SetNavbarVariant
    | SetSidebarSkin
    | ToggleSidebarMenu
    | ToggleControlSidebar
    | ToggleDarkMode
    | SetWindowSize;
