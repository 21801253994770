<div class="card">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center">
      <h3>Автореги пользователя {{(user$ | async)?.username}}</h3>
      <ng-container *ngIf="(user$ | async) as user">
        <button
          class="action_btn"
          [disabled]="!(autoRegs$ | async | getAccUse: user.id) || (autoRegs$ | async | getAccUse: user.id).length === 0"
          (click)="downloadToExel(user.id)"
        >
          <i class="fa-solid fa-file-arrow-down"></i>
        </button>
      </ng-container>
    </div>
  </div>
    <div class="card-body" appScroll [permissions]="scrollElement">
      <table class="mini-table table table-bordered">
        <thead>
        <tr>
          <th>№</th>
          <th>ID AP</th>
          <th>FB Tools</th>
          <th>Состояние</th>
          <th>Имя</th>
          <th>Фамилия</th>
          <th>Логин</th>
          <th>Пароль</th>
          <th>Почта</th>
          <th>Пароль от почты</th>
        </tr>
        <tbody>
      <ng-container *ngIf="(user$ | async) as user">
        <ng-container *ngIf="(autoRegs$ | async | getAccUse: user.id) && (autoRegs$ | async | getAccUse: user.id).length !== 0; else noData">
          <tr *ngFor="let autoreg of (autoRegs$ | async | getAccUse: user.id); let i = index">
            <td>{{i + 1}}</td>
            <td appClipboard>{{autoreg.ap_id}}</td>
            <td>
              <button class="fb-tools-btn" *ngIf="autoreg.state !== 'Добавлен в FB Tool'"
                      [disabled]="autoreg.state === 'Добавлен в FB Tool'"
              (click)="addToFBTools(autoreg)"
              >
                <img src="assets/img/fb-tool.png" alt="fb-tool">
              </button>
              <span class="fb-tools-true text-success" *ngIf="autoreg.state === 'Добавлен в FB Tool'">
                <i class="fa-solid fa-circle-check"></i>
              </span>
            </td>
            <td>
              <span class="state"
                    appClipboard
                    appBgColorState
                    [state]="autoreg.state">
                {{autoreg.state}}
              </span>
            </td>
            <td>
              <span class="state"
                    appClipboard>
                {{autoreg.name}}
              </span>
            </td>
            <td>
              <span class="state"
                    appClipboard>
                {{autoreg.surname}}
              </span>
            </td>
            <td>
               <span appClipboard>
                {{autoreg.login}}
              </span>
            </td>
            <td>
               <span appClipboard>
                 {{autoreg.password}}
              </span>
            </td>
            <td>
               <span appClipboard>
                {{autoreg.email}}
              </span>
            </td>
            <td>
               <span appClipboard>
                 {{autoreg.email_pass}}
              </span>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #noData>
        <tr>
          <td colspan="7">Данных нет</td>
        </tr>
      </ng-template>
        </tbody>
      </table>
  </div>
</div>

<ng-template #fbTools>
  <app-fb-tools
    [autoRegItem]="currentAcc"
    (dataPopUp)="popUpActions($event)"
  ></app-fb-tools>
</ng-template>
