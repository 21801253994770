import { Injectable } from '@angular/core';
import {select, Store} from "@ngrx/store";
import {AppState} from "@/store/state";

export interface keysLocalStorage {
  selector: any,
  key: string
}

@Injectable({
  providedIn: 'root'
})

export class LocalStorageSyncService {
  private isInit = false;
  private keysLocalStorage = null;

  constructor(private store$: Store<AppState>) {}

  init(keys: keysLocalStorage[], value, action) {
    if (this.isInit) {
      return
    }
    this.isInit = true;

    this.keysLocalStorage = keys;

    keys.forEach(item => {
      let keyValue = localStorage.getItem(item.key)
      if (keyValue) {
        value[item.key] = keyValue  ? JSON.parse(keyValue) : null;
      }
    })

    this.store$.dispatch(action({value}))

    keys.forEach(item => {
      this.store$.pipe(
        select(item.selector),
      ).subscribe(state => {
        localStorage.setItem(item.key, JSON.stringify(state))
      });
    })

  }

}
