<ng-container *ngIf="type === typePopUp.DELETED_ACC || type === typePopUp.DELETE_ACC_GROUP">
  <div class="deleted">
    <h4 *ngIf="type === typePopUp.DELETED_ACC">Вы действительно хотите удалить аккаунт {{account.login}}?</h4>
    <h4 *ngIf="type === typePopUp.DELETE_ACC_GROUP">Вы действительно хотите удалить выделенную группу аккаунтов?</h4>

    <div class="form">

      <mat-form-field class="example-full-width w-100">
        <mat-label>Порт</mat-label>
        <input type="number" matInput placeholder="Порт"
               [value]="checked ? (defaultPort$ | async).toString() : (currentUserPort$ | async).toString()"
               [readonly]="checked"
               #inputElement
        >
      </mat-form-field>

      <mat-checkbox class="example-margin" color="primary"
                    checked
                    (click)="checkedPort()"
      >
        <span>Использовать стандартный порт</span>
      </mat-checkbox>

    </div>
    <div class="actions">
      <button class="m-2 w-100" mat-raised-button color="warn" (click)="noConstellation()">Отмена</button>

      <button class="m-2 w-100" mat-raised-button color="primary"
              (click)="okConstellation(inputElement.value)"
              [disabled]="buttonLoader$ | async"
      >
        <span *ngIf="!(buttonLoader$ | async)">Удалить</span>
        <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
      </button>

    </div>
  </div>
</ng-container>
