<div class="check-filters">

  <mat-chip-row *ngFor="let filter of (filter$ | async).stateAcc">
    {{filter}}
    <button matChipRemove [attr.aria-label]="'remove'">
      <mat-icon
        (click)="filterStepRemove(filter)"
      >cancel
      </mat-icon>
    </button>
  </mat-chip-row>

  <ng-container *ngIf="(filter$ | async).owners && (filter$ | async).owners.length !== 0">

    <ng-container *ngIf="!(filter$ | async).owners.includes('withOutOwner') && !(filter$ | async).owners.includes('withTheOwner')">

        <mat-chip-row  *ngFor="let owner of (filter$ | async).owners">
            <span>
              {{+owner | setOwner:users}}
            </span>
          <button matChipRemove [attr.aria-label]="'remove'">
            <mat-icon
              (click)="checkOwnerRemove(owner)"
            >cancel
            </mat-icon>
          </button>
        </mat-chip-row>

    </ng-container>

    <ng-container *ngIf="(filter$ | async).owners.includes('withTheOwner') || (filter$ | async).owners.includes('withOutOwner')">

      <mat-chip-row *ngIf="(filter$ | async).owners.includes('withTheOwner')">
        С владельцем
        <button matChipRemove [attr.aria-label]="'remove'">
          <mat-icon
            (click)="checkOwnerRemove('withTheOwner')"
          >cancel
          </mat-icon>
        </button>
      </mat-chip-row>

      <mat-chip-row  *ngIf="(filter$ | async).owners.includes('withOutOwner')">
        Без владельца
        <button matChipRemove [attr.aria-label]="'remove'">
          <mat-icon
            (click)="checkOwnerRemove('withOutOwner')"
          >cancel
          </mat-icon>
        </button>
      </mat-chip-row>

    </ng-container>
  </ng-container>


  <mat-chip-row *ngFor="let filter of (filter$ | async).otherFilters">
    <span *ngIf="filter === filterType.TWF">C 2FA</span>
    <span *ngIf="filter === filterType.NO_TWF">Без 2FA</span>
    <span *ngIf="filter === filterType.COOKIE">С Cookie</span>
    <span *ngIf="filter === filterType.FB_TOOLS">FB Tools</span>
    <span *ngIf="filter === filterType.EXAMINATION_ACC">На проверку</span>
    <span *ngIf="filter === filterType.CREATE_PROFILE_AP">Для профиля AP</span>
    <button matChipRemove [attr.aria-label]="'remove'">
      <mat-icon
        (click)="otherFilterRemove(filter)"
      >cancel
      </mat-icon>
    </button>
  </mat-chip-row>

  <mat-chip-row *ngFor="let group of (filter$ | async).groups">
    <ng-container *ngIf="(groups$ | async) as groups">
      <span>{{group | setGroup:groups}}</span>
      <button matChipRemove [attr.aria-label]="'remove'">
        <mat-icon
                (click)="groupFilterRemove(group)"
        >cancel
        </mat-icon>
      </button>
    </ng-container>
  </mat-chip-row>

</div>

