//Фильтруем записи по временному отрезку

import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export function filterDateInterval(defaultArray, dateStart, dateEnd): AutoRegItemType[] {
  let startDate = new Date(dateStart).getTime();
  let endDate = new Date(dateEnd).getTime();
  return defaultArray.filter(item => {
    let date = new Date(item.date).getTime();
    if (date > startDate && date < endDate) {
      return item;
    }
  })
}
