import {createAction, props} from "@ngrx/store";

import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";

export const autoRegAccUpdate = createAction(
  ActionType.AUTO_REG_ITEM_UPDATE,
  props<{request:  AutoRegItemType}>()
)

export const autoRegAccUpdateSuccess = createAction(
  ActionType.AUTO_REG_ITEM_UPDATE_SUCCESS,
  props<{response:  DefaultResponseType}>()
)

export const autoRegAccUpdateFailure = createAction(
  ActionType.AUTO_REG_ITEM_UPDATE_FAILURE,
  props<{response:  DefaultResponseType}>()
)

export const autoRegAccUpdateError = createAction(
  ActionType.AUTO_REG_ITEM_UPDATE_ERROR,
  props<{error:  HttpErrorResponse}>()
)
