import { Component } from '@angular/core';

@Component({
  selector: 'app-scenum',
  templateUrl: './scenum.component.html',
  styleUrls: ['./scenum.component.scss']
})
export class ScenumComponent {

}
