import {Directive, ElementRef, Input} from '@angular/core';
import {SetBgStepUtil} from "@/main/autoreg/utils/setBgStep.util";

@Directive({
  selector: '[appBgColorStep]'
})
export class BgColorStepDirective {

  @Input() step: string;
  constructor(private elementRef: ElementRef) {}

  public ngOnInit(): void {
    this.elementRef.nativeElement.style.backgroundColor = SetBgStepUtil(this.step)
  }



}
