import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {TableDownloadType} from "@/main/autoreg/accounts/store/type/tableDownload.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";


export const downloadTableData = createAction(
  ActionType.AUTO_REG_DOWNLOAD_TABLE,
  props<{user?: number}>()
);

export const downloadTableDataItems = createAction(
  ActionType.AUTO_REG_DOWNLOAD_TABLE_ITEMS
);

export const autoRegTrChecked = createAction(
  ActionType.AUTO_REG_TR_CHECKED,
  props<{ id: number }>()
)

export const saveToExelAutoReg = createAction(
  ActionType.AUTO_REG_SAVE_TO_EXEL,
  props<{data?: AutoRegItemType[]}>()
)



