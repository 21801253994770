import { Pipe, PipeTransform } from '@angular/core';
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";

@Pipe({
  name: 'setGroup'
})
export class SetGroupPipe implements PipeTransform {

  transform(value: number, group: AutoRegGroupType[]): string {
    let newValue = ' - ';
    group.forEach(item => {
      if(item.id === value) {
        newValue = item.name;
      }
    })
    return newValue;
  }

}
