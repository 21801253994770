import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AuthService} from "@/auth/services/auth.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {select, Store} from "@ngrx/store";
import {logoutAction} from "@/auth/store/actions/logout.action";
import {PopUpType} from "@/shared/types/popUp.type";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {Observable, Subscription} from "rxjs";
import {autoRegStatusSelector} from "@/main/autoreg/accounts/store/selectors";
import {currentUser,  isSubmittedSelector} from "@/auth/store/selectors";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {

  @ViewChild('popup') popupElement: TemplateRef<ElementRef>
  public user$: Observable<string>;
  public typePopUp: string;
  private dialogRef: MatDialogRef<any>;
  private subscriptionList: Subscription[] = [];
  private statusReg$: Observable<string | boolean>;
  private isLogged$: Observable<boolean>;

  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private authService: AuthService,
              private router: Router,
              private toaStr: ToastrService,
              private store: Store,
              private matDialog: MatDialog,
              ) {
  }

  public ngOnInit(): void {
    this.initValue();

    this.sub = this.statusReg$.subscribe(data => {this.closePopUp(data)});
    this.sub = this.isLogged$.subscribe(data => {this.closePopUp(data)})
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe());
  }

  public logout(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.typePopUp = PopUpType.LOGOUT;
    this.dialogRef = this.matDialog.open(this.popupElement);
  }

  public popUpActions(action): void {
    if (action.constellation && this.typePopUp === PopUpType.LOGOUT) {
      this.store.dispatch(logoutAction());
    }
  }

  private closePopUp(value): void {
    if (value === 'Закрыть' && this.dialogRef || !value && this.dialogRef) {
      this.dialogRef.close()
    }
  }

  private initValue(): void {
    this.statusReg$ = this.store.pipe(select(autoRegStatusSelector));
    this.isLogged$ = this.store.pipe(select(isSubmittedSelector));
    this.user$ = this.store.pipe(select(currentUser))
  }

}
