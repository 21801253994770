<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Auto GPT</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Главная</a></li>
          <li class="breadcrumb-item active">Auto GPT</li>
        </ol>
      </div>
    </div>
  </div>
</div>

<section class="content">
  <div class="container-fluid mb-2">
    <div class="row mb-2">
      <div class="col-3">
        <div class="row">
          <div class="col-12 mb-2">
            <app-new-chat></app-new-chat>
          </div>
          <div class="col-12">
            <app-chat-items
            [chats]="chats$ | async"
            [currentChat]="currentChat$ | async"
            (actions)="actionsChatItems($event)"
            ></app-chat-items>
          </div>
        </div>
      </div>
      <div class="col-9 chat">
        <app-chat></app-chat>
      </div>
    </div>
  </div>
</section>
