import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/registration/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const autoRegProcessItemsActions = createAction(
  ActionType.AUTO_REG_PROCESS_ITEMS
)

export const autoRegProcessItemsSuccessActions = createAction(
  ActionType.AUTO_REG_PROCESS_ITEMS_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const autoRegProcessItemsFailureActions = createAction(
  ActionType.AUTO_REG_PROCESS_ITEMS_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegProcessItemsErrorActions = createAction(
  ActionType.AUTO_REG_PROCESS_ITEMS_ERROR,
  props<{error: HttpErrorResponse}>()
)
