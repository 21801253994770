import {UsersType} from "@/users/store/type/users.type";
import {currentUserType} from "@/users/store/type/currentUser.type";

export interface UsersState {
  users: UsersType[] | null,
  currentUser: currentUserType | null,
  popUp: boolean,
  defaultApiKeyFBTool: string
}

export const initialState: UsersState = {
  users: [],
  currentUser: null,
  popUp: false,
  defaultApiKeyFBTool: null
}
