import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {statisticsLinealSelector} from "@/main/autoreg/stats/store/selectors";
import {LinealStatType} from "@/main/autoreg/stats/store/type/linealStat.type";

@Component({
  selector: 'app-register-of-time-data',
  templateUrl: './register-of-time-data.component.html',
  styleUrls: ['./register-of-time-data.component.scss']
})
export class RegisterOfTimeDataComponent implements OnInit, OnDestroy {

  @Input() type: string;
  public dataForCharts;

  public data$: Observable<LinealStatType>;

  private subscriptionList: Subscription[] = [];
  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
    this.sub = this.data$.subscribe(data => {
      if (data !== null && data.count !== undefined && data.date !== undefined) {
        this.initCharts(data.count, data.date);
      }
    })
  }


  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe())
  }

  private initCharts(count, date): void {
    this.dataForCharts = {
      lineChartData: [
        {data: count, label: 'Динамика регистрации аккаунтов'},
      ],
      lineChartLabels: date,
      lineChartOptions: {
        responsive: true,
      },
      lineChartLegend: true,
      lineChartPlugins: [],
      lineChartType: 'line'
    }
  }

  private initValue(): void {
    this.data$ = this.store.pipe(select(statisticsLinealSelector));
  }
}
