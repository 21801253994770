import {AppState} from "@/store/state";
import {createSelector} from "@ngrx/store";
import {StatisticsState} from "@/main/autoreg/stats/store/index";

export const statisticsFeatureSelector = (state: AppState) => state.autoReg.statistics

export const statisticsTotalSelector = createSelector(
  statisticsFeatureSelector,
  (statisticsState: StatisticsState) => statisticsState.total
)

export const statisticsLinealSelector = createSelector(
  statisticsFeatureSelector,
  (statisticsState: StatisticsState) => statisticsState.lineal
)

export const statisticsStepSelector = createSelector(
  statisticsFeatureSelector,
  (statisticsState: StatisticsState) => statisticsState.step
)

export const statisticsStateSelector = createSelector(
  statisticsFeatureSelector,
  (statisticsState: StatisticsState) => statisticsState.state
)

export const intervalStatisticsSelector = createSelector(
  statisticsFeatureSelector,
  (statisticsState: StatisticsState) => statisticsState.currentInterval
)
