import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './registration.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "@/shared/shared.module";
import {
  AutoRegProcessComponent
} from "@/main/autoreg/registration/components/auto-reg-process/auto-reg-process.component";
import { NewFarmComponent } from './components/new-farm/new-farm.component';
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import { RegistrationProcessComponent } from './components/registration-process/registration-process.component';
import {MatSliderModule} from "@angular/material/slider";
import { IntegrationScenumProcessComponent } from './components/integration-scenum-process/integration-scenum-process.component';
import {NgxPermissionsModule} from "ngx-permissions";
import { SyncScenumProcessComponent } from './components/sync-scenum-process/sync-scenum-process.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {AccountsModule} from "@/main/autoreg/accounts/accounts.module";
import { GetGroupToFarmPipe } from './pipe/get-group-to-farm.pipe';
import { GetCountRegisteredTodayPipe } from './pipe/get-count-registered-today.pipe';
import { SetUserPipe } from './pipe/set-user.pipe';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { AddEmailsComponent } from './components/add-emails/add-emails.component';




@NgModule({
    declarations: [
        RegistrationComponent,
        AutoRegProcessComponent,
        NewFarmComponent,
        RegistrationProcessComponent,
        IntegrationScenumProcessComponent,
        SyncScenumProcessComponent,
        GetGroupToFarmPipe,
        GetCountRegisteredTodayPipe,
        SetUserPipe,
        AddEmailsComponent
    ],
    exports: [
        RegistrationComponent
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatSliderModule,
    NgxPermissionsModule,
    MatCheckboxModule,
    AccountsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ]
})
export class RegistrationModule { }
