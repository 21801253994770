import {createAction, props} from "@ngrx/store";
import {ActionsTypes} from "@/auth/store/action.type";
import {HttpErrorResponse} from "@angular/common/http";

export const logoutAction = createAction(
  ActionsTypes.LOGOUT
)

export const logoutSuccessAction = createAction(
  ActionsTypes.LOGOUT_SUCCESS,
  props<{status: { null }}>()
)

export const logoutFailureAction = createAction(
  ActionsTypes.LOGOUT_FAILURE,
  props<{error: HttpErrorResponse}>()
)
