import {UiState} from './ui/state';
import {AuthState} from "@/auth/store";
import {ActionReducerMap, createFeatureSelector} from "@ngrx/store";
import {uiReducer} from "@/store/ui/reducer";
import {authReducer} from "@/auth/store/reducers";
import {AutoRegState, reducersAutoReg} from "@/main/autoreg/store";
import {ChatGPT} from "@/main/auto-gpt/store";
import {chatsGptReducer} from "@/main/auto-gpt/store/reducer";
import {DEFAULT_ROUTER_FEATURENAME, getRouterSelectors, routerReducer, RouterReducerState} from "@ngrx/router-store";
import {UIState} from "@/ui/store";
import {UIReducer} from "@/ui/store/reducer";
import {UsersState} from "@/users/store";
import {usersReducer} from "@/users/store/reducer";
import {CartState} from "@/main/cart/store";
import {CartReducer} from "@/main/cart/store/reducer";
import {EmailsState} from "@/main/emails/store";
import {statisticsReducer} from "@/main/emails/store/reducer";

export interface AppState {
  [DEFAULT_ROUTER_FEATURENAME]: RouterReducerState
  ui: UiState;
  auth: AuthState,
  users: UsersState,
  autoReg: AutoRegState,
  cart: CartState,
  chatGPT: ChatGPT,
  UI: UIState,
  emails: EmailsState
}

export const reducers: ActionReducerMap<AppState> = {
  [DEFAULT_ROUTER_FEATURENAME]: routerReducer,
  ui: uiReducer,
  auth: authReducer,
  users: usersReducer,
  autoReg: reducersAutoReg,
  cart: CartReducer,
  chatGPT: chatsGptReducer,
  UI: UIReducer,
  emails: statisticsReducer
}

export const selectRouter = createFeatureSelector(DEFAULT_ROUTER_FEATURENAME);
export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectRouteDataParam, // factory function to select a route data param
  selectUrl, // select the current url
  selectTitle, // select the title if available
} = getRouterSelectors();

