import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from "@/auth/components/login/login.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ProfabricComponentsModule} from "@profabric/angular-components";
import {AuthService} from "@/auth/services/auth.service";
import {EffectsModule} from "@ngrx/effects";
import {RegisterEffect} from "@/auth/store/effects/register.effect";
import {LoginEffect} from "@/auth/store/effects/login.effect";
import {UserComponent} from "@/auth/components/user/user.component";
import {RouterLink} from "@angular/router";
import {LogoutEffect} from "@/auth/store/effects/logout.effect";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatChipsModule} from "@angular/material/chips";
import {MatButtonModule} from "@angular/material/button";
import {SharedModule} from "@/shared/shared.module";


@NgModule({
  declarations: [
    LoginComponent,
    UserComponent
  ],
  exports: [
    UserComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ProfabricComponentsModule,
    EffectsModule.forFeature([RegisterEffect, LoginEffect, LogoutEffect]),
    RouterLink,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatButtonModule,
    FormsModule,
    SharedModule
  ],
  providers: [AuthService]
})
export class AuthModule { }
