import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {setMessageChatAction} from "@/main/auto-gpt/store/actions/setMessageChat.action";
import {MessageGPType} from "@/main/auto-gpt/store/type/messageGPT.type";
import {chatCurrentSelector, messagesCurrentSelector} from "@/main/auto-gpt/store/selectors";
import {Observable, Subscription} from "rxjs";
import {ChatGptType} from "@/main/auto-gpt/store/type/chatGpt.type";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  public formMessage: UntypedFormGroup;
  public currentMessages$: Observable<MessageGPType[]>;
  public currentChat$: Observable<ChatGptType>;
  public chatName: string;

  private subscriptionList: Subscription[] = [];
  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initForm();
    this.initValue();
    this.sub = this.currentChat$.subscribe(chat => {
      this.chatName = chat.name
    })
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe())
  }

  public onMessage(): void {
    let request = {
      chat_id: 11,
      content: this.formMessage.value.message
    }
    this.store.dispatch(setMessageChatAction({request: request}))
  }

  private initForm(): void {
    this.formMessage = new UntypedFormGroup({
      message: new UntypedFormControl('', Validators.required)
    })
  }

  private initValue(): void {
    this.currentMessages$ = this.store.pipe(select(messagesCurrentSelector))
    this.currentChat$ = this.store.pipe(select(chatCurrentSelector))
  }
}
