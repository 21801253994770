import {createAction, props} from "@ngrx/store";
import {ActionsTypes} from "@/auth/store/action.type";


export const isLoggedTrueAction = createAction(
  ActionsTypes.IS_LOGGED_OK,
)

export const isLoggedFalseAction = createAction(
  ActionsTypes.IS_LOGGED_NO,
)
export const isLoggedAction = createAction(
  '[isLogged] Load state',
  props<{value: {isLogged111: boolean}}>()
)
