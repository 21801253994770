import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {FbToolsService} from "@/main/autoreg/services/fb-tools.service";
import {ToastrService} from "ngx-toastr";
import {
  autoRegFbAddAction, autoRegFbAddActionError, autoRegFbAddActionFailure,
  autoRegFbAddActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegFbAdd.action";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {Store} from "@ngrx/store";

@Injectable()
export class AutoRegFbAddEffect {
  autoRegFbAddEffect = createEffect(() => this.actions$.pipe(
    ofType(autoRegFbAddAction),
    switchMap(({request}) => {
      return this.fbToosService.setFbToolsGroup(request).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return autoRegFbAddActionSuccess({response, request})
          } else if (response.status === 'Failure') {
            return autoRegFbAddActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegFbAddActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegFbAddActionError({error}));
          } else {
            return of(autoRegFbAddActionError({error}));
          }
        })
      );
    })
  ))

  autoRegFbAddSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegFbAddActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.data.message) {
          this.toaStr.success(response.response.data.message)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegFbAddFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegFbAddActionFailure),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegFbAddError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegFbAddActionError),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private fbToosService: FbToolsService,
              private toaStr: ToastrService,
              private store: Store
  ) {
  }
}
