import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScenumRoutingModule } from './scenum-routing.module';
import { ExtensionsComponent } from './extensions/extensions.component';
import { ScriptsComponent } from './scripts/scripts.component';
import { ActionsComponent } from './actions/actions.component';
import { ScenumComponent } from './scenum.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";


@NgModule({
  declarations: [
    ExtensionsComponent,
    ScriptsComponent,
    ActionsComponent,
    ScenumComponent
  ],
  imports: [
    CommonModule,
    ScenumRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class ScenumModule { }
