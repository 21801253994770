import {Component, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {getChatsAction} from "@/main/auto-gpt/store/actions/getChats.action";
import {Observable} from "rxjs";
import {ChatGptType} from "@/main/auto-gpt/store/type/chatGpt.type";
import {chatCurrentSelector, chatsSelector} from "@/main/auto-gpt/store/selectors";
import {deleteChatsAction} from "@/main/auto-gpt/store/actions/deleteChats.action";
import {getMessageChatAction} from "@/main/auto-gpt/store/actions/getMessageChat.action";
import {currentChatAction} from "@/main/auto-gpt/store/actions/currentChat.action";

@Component({
  selector: 'app-auto-gpt',
  templateUrl: './auto-gpt.component.html',
  styleUrls: ['./auto-gpt.component.scss']
})
export class AutoGptComponent implements OnInit {

  public chats$: Observable<ChatGptType[]>;
  public currentChat$: Observable<ChatGptType>
  constructor (private store: Store) {
  }

  public ngOnInit() : void {
    this.initValue();
    this.store.dispatch(getChatsAction());
    this.store.dispatch(getMessageChatAction())
  }

  public actionsChatItems($event): void {
    if ($event.action === 'check' && $event.id) {
      this.checkedChat($event.id)
    }

    if ($event.action === 'delete' && $event.id) {
      this.deletedChat($event.id)
    }
  }

  public deletedChat(id): void {
    this.store.dispatch(deleteChatsAction({id: id}))
  }

  public checkedChat(id): void {
    this.store.dispatch(currentChatAction({id}))
  }

  private initValue(): void {
    this.chats$ = this.store.pipe(select(chatsSelector));
    this.currentChat$ = this.store.pipe(select(chatCurrentSelector))
  }

}
