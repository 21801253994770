import {Component, OnDestroy, OnInit} from '@angular/core';
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {select, Store} from "@ngrx/store";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {autoRegGroupSelector} from "@/main/autoreg/accounts/store/selectors";
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";
import {updateButtonStateAction} from "@/main/autoreg/registration/store/actions/updateButtonState.action";
import {AddFarmType} from "@/main/autoreg/registration/store/type/addFarm.type";
import {addFarmActions} from "@/main/autoreg/registration/store/actions/addFarm.actions";

@Component({
  selector: 'app-new-farm',
  templateUrl: './new-farm.component.html',
  styleUrls: ['./new-farm.component.scss']
})

export class NewFarmComponent implements OnInit, OnDestroy {

  public tableAddFarm: UntypedFormGroup;
  public currentGroupInfo: AutoRegGroupType;

  public autoRegGroup$: Observable<AutoRegGroupType[]>;
  public buttonLoader$: Observable<boolean>;

  private autoRegGroupData: AutoRegGroupType[];
  private subscriptionList: Subscription[] = [];

  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }
  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initForm();
    this.initValue();
    this.sub = this.autoRegGroup$.subscribe(data => this.autoRegGroupData = data);
    this.sub = this.tableAddFarm.valueChanges.subscribe(value => {
      this.currentGroupInfo = null;
      this.currentGroupInfo = this.autoRegGroupData.find(item => item.id === value.group_id);
    })
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe());
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}));
  }

  public startNewFarm(): void {
    if (this.tableAddFarm.value.group_id) {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      let request: AddFarmType = {
        group_id: this.tableAddFarm.value.group_id
      };
      this.store.dispatch(addFarmActions({request}))
      this.store.dispatch(updateButtonStateAction({stateButton: true}))
    }
  }

  private initForm(): void {
    this.tableAddFarm = new  UntypedFormGroup({
      group_id: new UntypedFormControl('', Validators.required)
    })
  }

  private initValue(): void {
    this.autoRegGroup$ = this.store.pipe(select(autoRegGroupSelector));
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
  }
}
