import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from "@/core/guards/auth.guard";


const routes: Routes = [
  {
    path: 'imklo',
    children: [
      {path: '', loadChildren: ()  => import('./imklo/imklo.module').then(m => m.ImkloModule), canActivate: [AuthGuard]}
    ]
  },
  {
    path: 'keitaro',
    children: [
      {path: '', loadChildren: ()  => import('./keitaro/keitaro.module').then(m => m.KeitaroModule), canActivate: [AuthGuard]}
    ]
  },
  {
    path: 'scenum',
    children: [
      {path: '', loadChildren: ()  => import('./scenum/scenum.module').then(m => m.ScenumModule), canActivate: [AuthGuard]}
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActionsRoutingModule { }
