import {STEP, COLOR} from "../../../../constants/COLORS_STEP";

export function SetBgStepUtil(step): string {
  let color = '';
  switch (step) {
    case STEP.FILLING_IN_THE_DATA:
      color = COLOR.FILLING_IN_THE_DATA;
      break;

    case STEP.ACTIVATED_2FA:
      color = COLOR.ACTIVATED_2FA;
      break;

    case STEP.ACTIVATED_2FA_OK:
      color = COLOR.ACTIVATED_2FA_OK;
      break;

    case STEP.DATA_IS_FIELD:
      color = COLOR.DATA_IS_FIELD;
      break;

    case STEP.MAIL_BINDING:
      color = COLOR.MAIL_BINDING;
      break;

    case STEP.MAIL_BINDING_OK:
      color = COLOR.MAIL_BINDING_OK;
      break;

    case STEP.SELFIE:
      color = COLOR.SELFIE;
      break;

    case STEP.CHECK_2FA:
      color = COLOR.CHECK_2FA;
      break;

    case STEP.CHECK_MAIL:
      color = COLOR.CHECK_MAIL;
      break;

    case STEP.DATA_RECORDING:
      color = COLOR.DATA_RECORDING;
      break;

    case STEP.GET_META_DATA:
      color = COLOR.GET_META_DATA;
      break;

    case STEP.LOGIN_PROFILE:
      color = COLOR.LOGIN_PROFILE;
      break;

    case STEP.LOGOUT_PROFILE:
      color = COLOR.LOGOUT_PROFILE;
      break;

    case STEP.CHECKPOINT:
      color = COLOR.CHECKPOINT;
      break;

    case STEP.COLLECTION_DATA:
      color = COLOR.COLLECTION_DATA;
      break;

    case STEP.LOGIN:
      color = COLOR.LOGIN;
      break;

    case STEP.PENDING_VERIFICATION:
      color = COLOR.PENDING_VERIFICATION;
      break;

  }

  return color;
}
