import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/registration/store/action.type";
import {SyncToScenumRequestType} from "@/main/autoreg/registration/store/type/syncToScenumRequest.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const AutoRegSyncToScenumAction = createAction(
  ActionType.AUTO_REG_SYNC_TO_SCENUM,
  props<{request: SyncToScenumRequestType}>()
)

export const AutoRegSyncToScenumActionSuccess = createAction(
  ActionType.AUTO_REG_SYNC_TO_SCENUM_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const AutoRegSyncToScenumActionFailure = createAction(
  ActionType.AUTO_REG_SYNC_TO_SCENUM_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const AutoRegSyncToScenumActionError = createAction(
  ActionType.AUTO_REG_SYNC_TO_SCENUM_ERROR,
  props<{error: HttpErrorResponse}>()
)
