import {createReducer, on} from "@ngrx/store";
import {initialState} from "@/main/autoreg/stats/store/index";
import {getStatisticsAction, getStatisticsActionSuccess} from "@/main/autoreg/stats/store/actions/getStatistics.action";
import {StatisticsType} from "@/main/autoreg/stats/store/type/statistics.type.";
import {GetDataDiagramStepUtil} from "@/main/autoreg/stats/utils/getDataDiagramStep.util";
import {GetDataDiagramStateUtil} from "@/main/autoreg/stats/utils/getDataDiagramState.util";
import {GetDataLinealChartsUtil} from "@/main/autoreg/stats/utils/getDataLinealCharts.util";
import {
  GetStatisticsAllAction,
  GetStatisticsAllActionSuccess
} from "@/main/autoreg/stats/store/actions/getStatissticsAll.action";
import {
  GetStatisticsTodayAction,
  GetStatisticsTodayActionSuccess
} from "@/main/autoreg/stats/store/actions/getStatisticsToday.action";
import {SetIntervalDateUtil} from "@/main/autoreg/stats/utils/setIntervalDate.util";

export const statisticsReducer = createReducer(
  initialState,
  on(getStatisticsAction, (state, {dateEnd, dateStart, intervalValue}) => {

    const intervalData = SetIntervalDateUtil(dateStart, dateEnd, intervalValue);

    return {
      ...state,
      currentInterval: {
        ...state.currentInterval,
        currentIntervalValue: intervalData.interval,
        end: intervalData.endDate,
        start: intervalData.startDate
      }
    }
  }),

  on(getStatisticsActionSuccess, (state, {response}) => {

    let statisticsData: StatisticsType = response.data;
    let linealData = GetDataLinealChartsUtil(statisticsData.lineal);
    let stepData = GetDataDiagramStepUtil(statisticsData.diagram.step);
    let stateData  = GetDataDiagramStateUtil(statisticsData.diagram.state);

    return {
      ...state,
      statistics: statisticsData,
      lineal: linealData,
      step: stepData,
      state: stateData
    }
  }),

  on(GetStatisticsAllAction, (state, {interval}) => {
    return {
      ...state,
      currentInterval: {
        ...state.currentInterval,
        currentIntervalValue: interval,
      }
    }
  }),

  on(GetStatisticsAllActionSuccess, (state, {response, firstRequest}) => {

    let statisticsData: StatisticsType = response.data;

    let linealData = firstRequest ? state.lineal : GetDataLinealChartsUtil(statisticsData.lineal);
    let stepData = firstRequest ? state.step : GetDataDiagramStepUtil(statisticsData.diagram.step);
    let stateData  = firstRequest ? state.state : GetDataDiagramStateUtil(statisticsData.diagram.state);

    return {
      ...state,
      statistics: statisticsData,
      total: statisticsData.total,
      lineal: linealData,
      step: stepData,
      state: stateData,
      currentInterval: {
        ...state.currentInterval,
        start: firstRequest ? state.currentInterval.start : new Date(statisticsData.lineal[0].date),
        end: firstRequest ? state.currentInterval.end : new Date(statisticsData.lineal[statisticsData.lineal.length - 1].date)}
    }
  }),

  on(GetStatisticsTodayAction, (state, {interval}) => {
    return {
      ...state,
      currentInterval: {...state.currentInterval,
        currentIntervalValue: interval
      }
    }
  }),

  on(GetStatisticsTodayActionSuccess, (state, {response}) => {

    let statisticsData: StatisticsType = response.data;

    let linealData = GetDataLinealChartsUtil(statisticsData.lineal);
    let stepData = GetDataDiagramStepUtil(statisticsData.diagram.step);
    let stateData  = GetDataDiagramStateUtil(statisticsData.diagram.state);

    return {
      ...state,
      statistics: statisticsData,
      total: statisticsData.total,
      lineal: linealData,
      step: stepData,
      state: stateData,
      currentInterval: {
        ...state.currentInterval,
        start: new Date(),
        end: new Date()
      }
    }
  }),
)
