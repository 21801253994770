import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {catchError, Observable, tap, throwError} from "rxjs";
import {AuthService} from "@/auth/services/auth.service";
import {Store} from "@ngrx/store";
import {SetRoleAction} from "@/auth/store/actions/setRole.action";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private store: Store) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (event.status !== 201 && event.body !== null && event.body.details !== null && event.body.details.author.username && event.body.details.role) {
            this.store.dispatch(SetRoleAction({role: event.body.details.role, user: event.body.details.author.username}))
          }
        }
      }, error => {
        // действия при получении ошибки
      }),
      catchError((error) => {
        if (error.status === 401) {
          this.authService.removeCookie('bonds')
          return throwError('Unauthorized');
        }
        return throwError(error);
      })
    );
  }
}
