import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ProcessService} from "@/main/autoreg/services/process.service";
import {ToastrService} from "ngx-toastr";
import {
  addFarmActions, addFarmActionsError,
  addFarmActionsFailure,
  addFarmActionsSuccess
} from "@/main/autoreg/registration/store/actions/addFarm.actions";
import {Injectable} from "@angular/core";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {Store} from "@ngrx/store";
import {autoRegProcessItemsActions} from "@/main/autoreg/registration/store/actions/AutoRegProcessItems.actions";

@Injectable()
export class AddFarmEffect {
  addFarmEffect$ = createEffect(() => this.actions$.pipe(
    ofType(addFarmActions),
    switchMap(({request}) => {
      return this.processService.addFarm(request).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {

            setTimeout(() => {
              this.store.dispatch(autoRegProcessItemsActions());
            }, 2000)


            return addFarmActionsSuccess({response})
          } else if (response.status === 'Failure') {
            return addFarmActionsFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400 || 422) {
            return of(addFarmActionsError({error}));
          } else if (error.status === 500 || 501) {
            return of(addFarmActionsError({error}));
          } else {
            return of(addFarmActionsError({error}));
          }
        })
      );
    })
  ))

  addFarmEffectSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(addFarmActionsSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.success(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  addFarmEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(addFarmActionsFailure),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegReLoginError$ = createEffect(
    () => this.actions$.pipe(
      ofType(addFarmActionsError),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private processService: ProcessService,
              private toaStr: ToastrService,
              private store: Store
  ) {
  }
}
