import {SetBgStepUtil} from "@/main/autoreg/utils/setBgStep.util";

export function GetDataDiagramStepUtil(defaultData) {
  let data = {
    step: [],
    count: [],
    color: []
  }

  let values = defaultData.map(item => {
      return item.step;
    })


  let newArr = [];
  let stepItems = Array.from(new Set(values));

  stepItems.forEach((step, index) => {
    let data = {
      step: !step || step === '-' ? 'Нет этапа' : step,
      count: 0,
      color: SetBgStepUtil(step)
    }

    defaultData.forEach(item => {
      if (step === item.step) {
        data.count += item.count;
      }
    })
    newArr.push(data);
  })

  newArr.forEach(item => {
    data.step.push(item.step)
    data.count.push(item.count)
    data.color.push(item.color)
  })

  return data;
}
