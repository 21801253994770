import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {RegerService} from "@/main/autoreg/services/reger.service";
import {ToastrService} from "ngx-toastr";
import {
  updateRegerAction, updateRegerActionError, updateRegerActionFailure,
  updateRegerActionSuccess
} from "@/main/autoreg/registration/store/actions/updateReger.action";
import {Store} from "@ngrx/store";


@Injectable()
export class UpdateRegerEffect {
  updateRegerEffect$ = createEffect(() => this.actions$.pipe(
    ofType(updateRegerAction),
    switchMap(({reger}) => {
      return this.regService.updateReger(reger).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return updateRegerActionSuccess({response, data: reger})
          } else if (response.status === 'Failure') {
            return updateRegerActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400 || 422) {
            return of(updateRegerActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(updateRegerActionError({error}));
          } else {
            return of(updateRegerActionError({error}));
          }
        })
      );
    })
  ))

  updateRegerEffectSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(updateRegerActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        if (response.response.status === 'Success') {
          if (response.response.data.pids !== 'Not set') {
            this.toaStr.success('Регистратор запущен')
          } else if (response.response.data.pids === 'Not set') {
            this.toaStr.success('Регистратор остановлен')
          }
        } else {
          this.toaStr.error('Не удалось выполнить запрос')
        }
      })
    ), {dispatch: false}
  )

  updateRegerEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(updateRegerActionFailure),
      tap((response: {response: DefaultResponseType}): void => {
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  updateRegerEffectError$ = createEffect(
    () => this.actions$.pipe(
      ofType(updateRegerActionError),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private regService: RegerService,
              private toaStr: ToastrService,
              private store: Store) {}
}
