import {createAction, props} from "@ngrx/store";
import {ActionsTypes} from "@/auth/store/action.type";
import {RequestSignupType, ResponseSignupType} from "@/auth/store/type/signup.type";
import {HttpErrorResponse} from "@angular/common/http";

export const registerAction = createAction(
  ActionsTypes.REGISTER,
  props<{request: RequestSignupType}>()
)

export const registerSuccessAction = createAction(
  ActionsTypes.REGISTER_SUCCESS,
  props<{user: ResponseSignupType}>()
)

export const registerFailureAction = createAction(
  ActionsTypes.REGISTER_FAILURE,
  props<{error: HttpErrorResponse}>()
)
