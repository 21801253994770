import {Injectable} from "@angular/core";
import {act, Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap, of, tap, withLatestFrom} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {AutoRegService} from "@/main/autoreg/services/auto-reg.service";
import {ToastrService} from "ngx-toastr";
import {
  AutoRegSetOwnerSystemAction, AutoRegSetOwnerSystemActionFailure,
  AutoRegSetOwnerSystemActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegSetOwnerSystem.action";
import {select, Store} from "@ngrx/store";
import {selectedTrAutoReg} from "@/main/autoreg/accounts/store/selectors";
import {UpdateStateRequestType} from "@/main/autoreg/accounts/store/type/requestSetOwnerSystem.type";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";

@Injectable()
export class AutoRegSetOwnerSystemEffect {
  autoRegSetOwnerSystemEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AutoRegSetOwnerSystemAction),
    withLatestFrom(this.store.pipe(select(selectedTrAutoReg))),
    mergeMap(([action, selected, ]) => {

      let request: UpdateStateRequestType = {
        accounts: []
      };

      if (!action.request) {
        request.accounts = selected.map(item => {
          return {
            login: item.login,
            state: action.state ? action.state : '',
            checkpoint: item.checkpoint
          }
        })
      } else {
        request = action.request;
      }

      return this.autoRegService.setOwnerSystem(request).pipe(
        map((response: DefaultResponseType) => {
          return AutoRegSetOwnerSystemActionSuccess({response, request, update: action.update, one: action.one, stateAutoreg: action.state, id: action.id})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(AutoRegSetOwnerSystemActionFailure({error}));
        })
      );
    })
  ))

  autoRegSetOwnerSystemSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(AutoRegSetOwnerSystemActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.status === 'Success') {
          if (response.response.details.msg) {
            this.toaStr.info(response.response.details.msg)
          } else {
            this.toaStr.warning('Нет уведомления. Сообщить Максиму')
          }
        } else if (response.response.status === 'Failure') {
          if (response.response.details.msg) {
            this.toaStr.error(response.response.details.msg)
          } else {
            this.toaStr.error('Нет уведомления. Сообщить Максиму')
          }
        }
      })
    ), {dispatch: false}
  )

  autoRegSetOwnerSystemFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(AutoRegSetOwnerSystemActionFailure),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private autoRegService: AutoRegService,
              private toaStr: ToastrService,
              private store: Store
  ) {
  }
}
