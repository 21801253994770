import {Component, Input, OnInit} from '@angular/core';
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {select, Store} from "@ngrx/store";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {selectedTrAutoReg} from "@/main/autoreg/accounts/store/selectors";
import {ProxyDeviceConstants} from "../../../../../../constants/proxy-device.constants";
import {autoRegCreateProfileAPAction} from "@/main/autoreg/accounts/store/actions/autoRegCreateProfileAP.action";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {PopUpType} from "@/shared/types/popUp.type";
import {
  autoRegCreateProfileAPItemAction
} from "@/main/autoreg/accounts/store/actions/autoRegCreateAPItem.action";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {currentUserPortSelector, defaultPortSelector} from "@/users/store/selectors";

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit {

  @Input() account!: AutoRegItemType;
  @Input() type!: string;

  public typePopUp = PopUpType;
  public devices = ProxyDeviceConstants;
  public createProfileForm: UntypedFormGroup;
  public checked = true;

  public defaultPort$: Observable<number>;
  public currentUserPort$: Observable<number>;
  public buttonLoader$: Observable<boolean>;
  public selectedTr$: Observable<AutoRegItemType[]>

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initForm();
    this.initValue();
  }

  public checkedPort(): void {
    this.checked = !this.checked;
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  public create(portValue: string): void {
    if (this.type === PopUpType.CREATE_ACC_AP_GROUP) {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(autoRegCreateProfileAPAction(
        { data:
            {
              name: this.createProfileForm.value.name,
              ap_port: +portValue,
              device_name: this.createProfileForm.value.device
            }
        }))
    }
    if (this.type === PopUpType.CREATE_ACC_AP) {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      let request = {
        name: this.createProfileForm.value.name,
        accounts: [
          {
            ap_port:  +portValue,
            login: this.account.login,
            email: this.account.email,
            password: this.account.password,
            email_pass: this.account.email_pass,
            name: this.account.name,
            surname: this.account.surname,
            bdate: this.account.str_birthday,
            twofa: this.account.twofa,
            device_name: this.createProfileForm.value.device
          }
        ]
      }
      this.store.dispatch(autoRegCreateProfileAPItemAction({data: request}))
    }
  }

  private initForm(): void {
    this.createProfileForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      device: new UntypedFormControl('', Validators.required)
    })
  }

  private initValue(): void {
    this.selectedTr$ = this.store.pipe(select(selectedTrAutoReg));
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
    this.defaultPort$ = this.store.pipe(select(defaultPortSelector));
    this.currentUserPort$ = this.store.pipe(select(currentUserPortSelector));
  }
}
