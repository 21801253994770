import {Injectable} from "@angular/core";
import {act, Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap, of, switchMap, tap, withLatestFrom} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {
  autoRegReLoginAction, autoRegReLoginActionError, autoRegReLoginActionFailure,
  autoRegReLoginActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegReLogin.action";
import {ProcessService} from "@/main/autoreg/services/process.service";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {select, Store} from "@ngrx/store";
import {accToReLoginSelector} from "@/main/autoreg/accounts/store/selectors";
import {
  AutoRegReLoginRequestGroupType,
  AutoRegReLoginRequestType
} from "@/main/autoreg/accounts/store/type/autoRegReloginRequest.type";


@Injectable()
export class AutoRegReLoginEffect {
  autoRegReLoginEffect$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegReLoginAction),
    withLatestFrom(this.store.pipe(select(accToReLoginSelector))),
    mergeMap(([action, selected]) => {
      let request: AutoRegReLoginRequestType;
      if (action.typeRequest === 'one') {
        request = action.request as AutoRegReLoginRequestType;
      } else {
        request = {
          accounts: [],
          method: (action.request as AutoRegReLoginRequestGroupType).method,
          device_name: (action.request as AutoRegReLoginRequestGroupType).device_name
        }
        request.accounts = selected.map(item => {
          return {
            ap_id: item.ap_id,
            ap_port: (action.request as AutoRegReLoginRequestGroupType).ap_port,
            login: item.login,
            password: item.password,
            twofa: item.twofa
          }
        })
      }

      return this.processService.reLogInAutoReg(request).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return autoRegReLoginActionSuccess({response, request})
          } else if (response.status === 'Failure') {
            return autoRegReLoginActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegReLoginActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegReLoginActionError({error}));
          } else {
            return of(autoRegReLoginActionError({error}));
          }
        })
      );
    })
  ))

  autoRegReLoginSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegReLoginActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.success(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegReLoginFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegReLoginActionFailure),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegReLoginError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegReLoginActionError),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private processService: ProcessService,
              private toaStr: ToastrService,
              private store: Store
  ) {
  }
}
