<mat-form-field class="datepicker-days mx-2">
  <mat-select [value]="(currentInterval$ | async).currentIntervalValue">
    <mat-option *ngFor="let option of options"
                [value]="option.value"
                (click)="selectIntervalDate(option.value)"
    >
      {{option.name}}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="fill">
<!--  <mat-label>Enter a date range</mat-label>-->
  <mat-date-range-input
    [min]="(maxMinDate$ | async).minDate"
    [max]="(maxMinDate$ | async).maxDate"
    [formGroup]="rangeForm"
    [rangePicker]="picker">
    <input  matStartDate formControlName="start" placeholder="Start date">
    <input  matEndDate formControlName="end" placeholder="End date">
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker>
    <mat-datepicker-actions>
      <button mat-button matDatepickerCancel>Отмена</button>
      <button mat-raised-button color="primary" matDatepickerApply
      (click)="updateDate()"
      >Установить</button>
    </mat-datepicker-actions>
  </mat-date-range-picker>

  <mat-error *ngIf="rangeForm.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
  <mat-error *ngIf="rangeForm.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
</mat-form-field>
