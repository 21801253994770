import {createAction, props} from "@ngrx/store";
import {ActionsType} from "@/main/auto-gpt/store/actions.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const setMessageChatAction = createAction (
  ActionsType.CHAT_GPT_MESSAGE_SET,
  props<{request: any}>()
)

export const setMessageChatActionSuccess = createAction (
  ActionsType.CHAT_GPT_MESSAGE_SET_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const setMessageChatActionFailure = createAction (
  ActionsType.CHAT_GPT_MESSAGE_SET_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const setMessageChatActionError = createAction (
  ActionsType.CHAT_GPT_MESSAGE_SET_ERROR,
  props<{error: HttpErrorResponse}>()
)
