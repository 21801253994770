import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {UsersType} from "@/users/store/type/users.type";
import {currentUserSelector, popUpSelector} from "@/users/store/selectors";
import {select, Store} from "@ngrx/store";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {autoRegAccItemsSelector} from "@/main/autoreg/accounts/store/selectors";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PopUpAction} from "@/users/store/actions/popUp.action";


@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

  @ViewChild('redactionUser') redactionUserElement: TemplateRef<ElementRef>;

  public type: string;

  public user$: Observable<UsersType>;
  public autoRegs$: Observable<AutoRegItemType[]>;
  public popUp$: Observable<boolean>;

  private dialogRef: MatDialogRef<any>;
  private subscriptionList: Subscription[] = [];
  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store,
              private matDialog: MatDialog) {
  }

  public ngOnInit(): void {
    this.initValue();
    this.sub = this.popUp$.subscribe(data => {
      if(!data) {
        this.closePopUp();
      }
    })
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe())
  }

  public redactionProfile(): void {
    this.store.dispatch(PopUpAction({statePopUp: true}))
    this.dialogRef = this.matDialog.open(this.redactionUserElement);
  }

  public popUpActions($event): void {
      if ($event && !$event.constellation) {
        this.closePopUp();
      }
  }

  private closePopUp(): void {
    if(this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private initValue(): void {
    this.user$ = this.store.pipe(select(currentUserSelector));
    this.autoRegs$ = this.store.pipe(select(autoRegAccItemsSelector));
    this.popUp$ = this.store.pipe(select(popUpSelector));
  }
}
