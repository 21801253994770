import { Pipe, PipeTransform } from '@angular/core';
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

@Pipe({
  name: 'getState'
})
export class GetStatePipe implements PipeTransform {

  transform(value: AutoRegItemType[]): string[] {
    let stateArr = [];
    if (value === null) {
      return [];
    }
    value.forEach(item => {
      if (item.state !== null) {
        stateArr.push(item.state)
      }
    })
    return [...new Set(stateArr)];
  }
}
