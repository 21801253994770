import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {SetBgStateUtil} from "@/main/autoreg/utils/setBgState.util";
import {STATE} from "../../../constants/COLORS_STATE";

@Directive({
  selector: '[appBgColorState]'
})
export class BgColorStateDirective implements OnInit {

  @Input() state: STATE;

  constructor(private elementRef: ElementRef) {}

  public ngOnInit(): void {
    this.elementRef.nativeElement.style.backgroundColor = SetBgStateUtil(this.state)
  }
}
