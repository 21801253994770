import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {ToastrService} from "ngx-toastr";

@Directive({
  selector: '[appClipboard]'
})

export class ClipboardDirective implements OnInit {

  @Input() fb: boolean | null = null;
  @Input() name: string | null = null;
  @Input() surname: string | null = null;
  @Input() userAgent: string | null = null;
  @Input() cookie: string | null = null;
  @Input() password: string | null = null;

  constructor(private toaStr: ToastrService,
              private elementRef: ElementRef) {
  }

  public ngOnInit() {
    this.elementRef.nativeElement.style.cursor = 'pointer';
  }

  @HostListener('dblclick', ['$event.target'])
  onClick(element: HTMLElement): void {
    if(!element.classList.contains('noClipBoard')) {
      let string = '';
      if (this.fb) {
        string = `${this.name} ${this.surname}|${this.userAgent}|${this.cookie}|${this.password}|`
      } else {
        string = element.innerText;
      }
      const value = string;
      const input = document.createElement('input');
      input.style.position = 'fixed';
      input.style.opacity = '0';
      input.value = value;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      if (this.fb) {
        this.toaStr.success('Данные для FB-Tools скопированы в буфер обмена')
      } else {
        this.toaStr.success('Содержимое ячейки скопировано в буфер обмена')
      }
      document.body.removeChild(input);
    }
  }
}
