import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {RequestCreateAPType} from "@/main/autoreg/accounts/store/type/requestCreateAP.type";

export const autoRegCreateProfileAPItemAction = createAction(
  ActionType.AUTO_REG_CREATE_PROFILE_AD_ITEM,
  props<{data: RequestCreateAPType}>()
)

export const autoRegCreateProfileAPItemActionSuccess = createAction(
  ActionType.AUTO_REG_CREATE_PROFILE_AD_ITEM_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const autoRegCreateProfileAPItemActionFailure = createAction(
  ActionType.AUTO_REG_CREATE_PROFILE_AD_ITEM_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegCreateProfileAPItemActionError = createAction(
  ActionType.AUTO_REG_CREATE_PROFILE_AD_ITEM_ERROR,
  props<{error: HttpErrorResponse}>()
)
