import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AddCardComponent} from "@/main/tools/add-card/add-card.component";
import {GenDocsComponent} from "@/main/tools/gen-docs/gen-docs.component";
import {GenWhiteComponent} from "@/main/tools/gen-white/gen-white.component";

const routes: Routes = [

  {
    path: 'add-card',
    component: AddCardComponent
  },
  {
    path: 'docs-gen',
    component: GenDocsComponent
  },
  {
    path: 'white-gen',
    component: GenWhiteComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ToolsRoutingModule { }
