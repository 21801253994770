import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/registration/store/action.type";
import {HttpErrorResponse} from "@angular/common/http";

export const downloadEmailsAction = createAction(
  ActionType.DOWNLOAD_EMAILS,
  props<{file: File}>()
)

export const downloadEmailsSuccessAction = createAction(
  ActionType.DOWNLOAD_EMAILS_SUCCESS
)

export const downloadEmailsFailureAction = createAction(
  ActionType.DOWNLOAD_EMAILS_FAILURE
)


export const downloadEmailsErrorAction = createAction(
  ActionType.DOWNLOAD_EMAILS_ERROR,
  props<{error: HttpErrorResponse}>()
)
