export enum ActionType {
  GET_USERS = '[User] Get users',
  GET_USERS_SUCCESS = '[User] Get users success',
  GET_USERS_FAILURE = '[User] Get users failure',

  GET_USER = '[User] Get user',
  GET_USER_SUCCESS = '[User] Get user success',
  GET_USER_FAILURE = '[User] Get user failure',

  GET_ME = '[User] Get me',
  GET_ME_SUCCESS = '[User] Get me success',
  GET_ME_FAILURE = '[User] Get me failure',

  DELETE_USER = '[User] Delete user',
  DELETE_USER_SUCCESS = '[User] Delete user success',
  DELETE_USER_FAILURE = '[User] Delete user failure',

  UPDATE_USER = '[User] Update user',
  UPDATE_USER_SUCCESS = '[User] Update user success',
  UPDATE_USER_FAILURE = '[User] Update user failure',

  ACTIVATE_USER = '[User] Activate user',
  ACTIVATE_USER_SUCCESS = '[User] Activate user success',
  ACTIVATE_USER_FAILURE = '[User] Activate user failure',

  POP_UP_USER = '[Users] Pop-up'

}
