import {AppState} from "@/store/state";
import {createSelector} from "@ngrx/store";

export const UIFeatureSelector = (state: AppState) => state.UI;
export const buttonLoaderSelector = createSelector(
  UIFeatureSelector,
  (UIState) => UIState.buttonLoader
)

export const pageLoaderSelector = createSelector(
  UIFeatureSelector,
  (UIState) => UIState.pageLoader
)
