<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark d-flex align-items-bottom">
          <a [routerLink]="'accounts'"
             [routerLinkActive]="'active'"
          ><span>Аккаунты</span></a>
          <a [routerLink]="'registration'"
             [routerLinkActive]="'active'"
          ><span>Процессы</span></a>
          <a [routerLink]="'stats'"
             [routerLinkActive]="'active'"
          ><span>Статистика</span></a>
        </h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item">Регистрация аккаунтов</li>
          <li class="breadcrumb-item active">{{(currentRoute$ | async).routeConfig.title}}</li>
        </ol>
      </div>
    </div>
  </div>
</div>


<router-outlet></router-outlet>
