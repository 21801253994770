import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ScenumComponent} from "@/main/actions/scenum/scenum.component";
import {ActionsComponent} from "@/main/actions/scenum/actions/actions.component";
import {ExtensionsComponent} from "@/main/actions/scenum/extensions/extensions.component";
import {ScriptsComponent} from "@/main/actions/scenum/scripts/scripts.component";
import {AutoregComponent} from "@/main/autoreg/autoreg.component";

const routes: Routes = [
  {
    path: 'scenum',
    component: ScenumComponent
  },
  {
    path: 'scenum/actions',
    component: ActionsComponent
  },
  {
    path: 'scenum/extensions',
    component: ExtensionsComponent
  },
  {
    path: 'scenum/scripts',
    component: ScriptsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScenumRoutingModule { }
