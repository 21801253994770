<div class="deleted-group">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0 process">
        <div class="card-header">
          <h3 class="card-title">Назначить владельца</h3>
        </div>
        <div class="card-body pb-0">
          <div class="row">

            <div class="col-12 my-2">
              <mat-form-field class="w-100">
                <mat-label>Пользователи</mat-label>
                <mat-select [(ngModel)]="user">
                  <mat-optgroup *ngFor="let role of users$ | async | getRole" [label]="role | translationRole">
                    <mat-option *ngFor="let user of users$ | async | getUserRole:role" [value]="user">
                      {{user.username}}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-6">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена</button>
            </div>

            <div class="col-6">
              <button class="w-100" mat-raised-button color="primary"
                      (click)="setOwner()"
                      [disabled]="(buttonLoader$ | async) || !user"
              >
                <span *ngIf="!(buttonLoader$ | async)">Назначить</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
