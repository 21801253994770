<div class="deleted-group">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0 process">
        <div class="card-header">
          <h3 class="card-title">Удалить группу</h3>
        </div>
        <div class="card-body pb-0">
          <div class="row">

            <div class="col-12 my-2">
              <mat-form-field class="w-100">
                <mat-label>Группы</mat-label>
                <mat-select [(ngModel)]="currentGroupID">
                  <mat-optgroup [label]="'Фармы'">
                    <mat-option *ngFor="let group of group$ | async | groupFilter:'farm'"
                                [value]="group.id"
                    >
                      {{group.name}}
                    </mat-option>
                  </mat-optgroup>

                  <mat-optgroup [label]="'Автореги'">
                    <mat-option *ngFor="let group of group$ | async | groupFilter:'autoreg'"
                                [value]="group.id">
                      {{group.name}}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-6">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена</button>
            </div>

            <div class="col-6">
              <button class="w-100" mat-raised-button color="primary"
                      (click)="deleted()"
                      [disabled]="(buttonLoader$ | async)"
              >
                <span *ngIf="!(buttonLoader$ | async)">Удалить</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
