import {createAction, props} from "@ngrx/store";
import {ActionsType} from "@/main/autoreg/stats/store/actions.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const GetStatisticsTodayAction = createAction(
  ActionsType.GET_STATISTICS_TODAY,
  props<{interval: number}>()
)

export const GetStatisticsTodayActionSuccess = createAction(
  ActionsType.GET_STATISTICS_TODAY_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const GetStatisticsTodayActionFailure = createAction(
  ActionsType.GET_STATISTICS_TODAY_FAILURE,
  props<{error: HttpErrorResponse}>()
)
