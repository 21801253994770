<button class="sort-button" mat-icon-button [matMenuTriggerFor]="menu" >
  <i class="fa-solid fa-list-check"></i>
</button>

<mat-menu #menu="matMenu" xPosition="before" class="menu">
  <button mat-menu-item class="menu-sort-item button"
          *ngFor="let item of autoRegAccItems$ | async | getState"
          (click)="filterState(item)"
          [class.active]="(filterValue$ |  async).stateAcc.includes(item)"
  >
    <span class="icon"><i class="fa-solid fa-check"></i></span>
    <span>{{item}}</span>
  </button>

</mat-menu>
