import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {environment} from "../../../../environments/environment";
import {
  UpdateStateRequestType
} from "@/main/autoreg/accounts/store/type/requestSetOwnerSystem.type";

@Injectable()
export class CartService {

  constructor(private http: HttpClient) { }

  public getCartData(): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + 'autoreg/trash', {withCredentials: true})
  }

  public restoreAcc(request: UpdateStateRequestType): Observable<DefaultResponseType> {
    return this.http.patch<DefaultResponseType>(environment.api + 'autoreg/states', request,
      {
        withCredentials: true,
        headers: {
          authorization: 'vBNk7u5ih5ZegpFmYSGkQlDL'
        }
      })
  }
}
