import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/registration/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const autoRegTerminateActions = createAction(
  ActionType.AUTO_REG_TERMINATE,
  props<{request: { pid: number }}>()
)

export const autoRegDeletedActions = createAction(
  ActionType.AUTO_REG_DELETED,
  props<{index: number}>()
)

export const autoRegTerminateSuccessActions = createAction(
  ActionType.AUTO_REG_TERMINATE_SUCCESS,
  props<{response: DefaultResponseType, pid: number}>()
)

export const autoRegTerminateFailureActions = createAction(
  ActionType.AUTO_REG_TERMINATE_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegTerminateErrorActions = createAction(
  ActionType.AUTO_REG_TERMINATE_ERROR,
  props<{error: HttpErrorResponse}>()
)
