import {TotalStatType} from "@/main/autoreg/stats/store/type/totalStat.type";
import {LinealStatType} from "@/main/autoreg/stats/store/type/linealStat.type";
import {StatisticsType} from "@/main/autoreg/stats/store/type/statistics.type.";
import {StepDataType} from "@/main/autoreg/stats/store/type/stepData.type";
import {StateDataType} from "@/main/autoreg/stats/store/type/stateData.type";
import {CurrentIntervalType} from "@/main/autoreg/stats/store/type/currentInterval.type";

export interface StatisticsState {
  statistics: StatisticsType,
  total: TotalStatType,
  lineal: LinealStatType,
  step: StepDataType,
  state: StateDataType,
  currentInterval: CurrentIntervalType
}

export const initialState: StatisticsState = {
  statistics: null,
  total: {
    count: 0,
    checkpoint: 0,
    ready: 0,
    blocked: 0,
    ownerless: 0,
    with_ap: 0,
    not_cookie: 0,
    not_token: 0,
    not_2fa: 0,
    need_check: 0
  },
  lineal: {
    date: [],
    count: []
  },
  step: {
    step: [],
    count: [],
    color: []
  },
  state: {
    state: [],
    count: [],
    color: []
  },
  currentInterval: {
    start: new Date(),
    end: new Date(),
    currentIntervalValue: 7
  }
}
