import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {select, Store} from "@ngrx/store";
import {autoRegAccItemsSelector, autoRegStatusSelector} from "@/main/autoreg/accounts/store/selectors";
import {currentUserSelector, defaultApiKeyFBToolSelector} from "@/users/store/selectors";
import {UsersType} from "@/users/store/type/users.type";
import {downloadTableData} from "@/main/autoreg/accounts/store/actions/autoRegAccTable.actions";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {autoRegFbGroupAction} from "@/main/autoreg/accounts/store/actions/autoRegFbGroup.action";
import {autoRegGetProxyAction} from "@/main/autoreg/accounts/store/actions/autoRegGetProxy.action";
import {currentUserType} from "@/users/store/type/currentUser.type";

@Component({
  selector: 'app-use-autoregs',
  templateUrl: './use-autoregs.component.html',
  styleUrls: ['./use-autoregs.component.scss']
})
export class UseAutoregsComponent implements OnInit, OnDestroy {

  @ViewChild('fbTools') fbToolsElement: TemplateRef<ElementRef>;

  public autoRegs$: Observable<AutoRegItemType[]>;
  public user$: Observable<UsersType>;
  private popUpState$: Observable<boolean | string>
  public scrollElement = true;
  public currentAcc: AutoRegItemType;
  private defaultApiKeyFbTool$: Observable<string>;
  private defaultApiKeyFbTool: string;
  private currentUser: currentUserType;

  private dialogRef: MatDialogRef<any>;
  private subscriptionList: Subscription[] = [];

  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store,
              private matDialog: MatDialog) {
  }

  public ngOnInit(): void {
    this.initValue();
    this.sub = this.popUpState$.subscribe(data => {
      this.closePopUp(data)
    });

    this.sub = this.user$.subscribe(data => {
      if(data !== undefined && data !== null) {
        this.currentUser = data;
      }
    });
    this.sub = this.defaultApiKeyFbTool$.subscribe(data => {
      if(data !== undefined && data !== null) {
        this.defaultApiKeyFbTool = data;
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe());
  }

  addToFBTools(autoreg: AutoRegItemType): void {
    this.store.dispatch(autoRegFbGroupAction({ apiKey: this.getApiKeyFB() }));
    this.store.dispatch(autoRegGetProxyAction({ apiKey: this.getApiKeyFB() }));
    this.currentAcc = autoreg;
    this.store.dispatch(autoRegClosePopupAction({open: false}));
    this.dialogRef = this.matDialog.open(this.fbToolsElement)
  }

  public downloadToExel(user: number): void {
    this.store.dispatch(downloadTableData({user: user}))
  }

  public popUpActions($event): void {

  }

  private getApiKeyFB(): string {
    return this.currentUser.fbtool_apikey ? this.currentUser.fbtool_apikey : this.defaultApiKeyFbTool
  }

  private closePopUp(value): void {
    if (value === 'Закрыть' && this.dialogRef) {
      this.dialogRef.close()
    }
  }

  private initValue(): void {
    this.user$ = this.store.pipe(select(currentUserSelector));
    this.autoRegs$ = this.store.pipe(select(autoRegAccItemsSelector));
    this.popUpState$ = this.store.pipe(select(autoRegStatusSelector));
    this.defaultApiKeyFbTool$ = this.store.pipe(select(defaultApiKeyFBToolSelector));
  }
}
