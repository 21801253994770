import { Component } from '@angular/core';

@Component({
  selector: 'app-gen-white',
  templateUrl: './gen-white.component.html',
  styleUrls: ['./gen-white.component.scss']
})
export class GenWhiteComponent {

}
