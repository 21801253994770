//Сортируем записи по дате - убывание/возрастание
export function sortDate(defaultArray, selectedArray, value, sortDefault: boolean) {
  return Array.isArray(selectedArray)
    ? [...defaultArray].sort((a, b) => {
      if (sortDefault) {
        if (a.group_id === null && b.group_id === null) {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();
          if (value === 'dateOld') {
            return dateA - dateB;
          } else if (value === 'dateNew') {
            return dateB - dateA;
          }
        }
      } else {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        if (value === 'dateOld') {
          return dateA - dateB;
        } else if (value === 'dateNew') {
          return dateB - dateA;
        }
      }
    })
    : selectedArray;
}
