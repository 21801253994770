import {createAction, props} from "@ngrx/store";
import {ActionsType} from "@/main/auto-gpt/store/actions.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const getChatsAction = createAction (
  ActionsType.CHAT_GPT_GET
)

export const getChatsActionSuccess = createAction (
  ActionsType.CHAT_GPT_GET_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const getChatsActionFailure = createAction (
  ActionsType.CHAT_GPT_GET_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const getChatsActionError = createAction (
  ActionsType.CHAT_GPT_GET_ERROR,
  props<{error: HttpErrorResponse}>()
)
