import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const autoRegChangedAccAction = createAction(
  ActionType.AUTO_REG_CHANGED_ACC,
  props<{device: string, port: number, typeRequest: string, payload?: any}>()
)

export const autoRegChangedAccActionSuccess = createAction(
  ActionType.AUTO_REG_CHANGED_ACC_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const autoRegChangedAccActionFailure = createAction(
  ActionType.AUTO_REG_CHANGED_ACC_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegChangedAccActionError = createAction(
  ActionType.AUTO_REG_CHANGED_ACC_ERROR,
  props<{error: HttpErrorResponse}>()
)
