<pf-dropdown hide-arrow class="user-menu">
    <div slot="button">
        <a class="nav-link">
            <pf-image
                fallback-src="assets/img/default-profile.png"
                class="user-image-small"
                width="25"
                height="25"
                alt="User Image"
                rounded
            ></pf-image>
        </a>
    </div>
    <div slot="menu">
        <li class="user-header bg-primary">
            <pf-image
                fallback-src="assets/img/default-profile.png"
                class="user-image-big"
                alt="User Image"
                width="90"
                height="90"
                rounded
            ></pf-image>

            <p>
                <span>{{user$ | async}}</span>

            </p>
        </li>
        <!-- Menu Body -->

        <!-- Menu Footer-->
        <li class="user-footer d-flex justify-content-between">
          <button [routerLink]="'/profile'" mat-stroked-button color="primary">Профиль</button>
          <button (click)="logout()" mat-stroked-button color="warn">Выйти</button>
        </li>
    </div>
</pf-dropdown>

<ng-template #popup>
  <app-popup
    (dataPopUp)="popUpActions($event)"
    [type]="typePopUp"
  ></app-popup>
</ng-template>
