export function SetIntervalDateUtil(dateStart, dateEnd, intervalValue) {
  let data = {
    startDate: dateStart ? dateStart : new Date(),
    endDate: dateEnd,
    interval: intervalValue ? intervalValue : 0,
  }

  if (intervalValue) {
    data.startDate.setDate(data.endDate.getDate() - intervalValue);
  } else if (dateStart) {
    data.startDate = dateStart;
    data.endDate = dateEnd;
  }

  return data;

}
