import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {AccountsComponent} from './accounts.component';
import {AutoRegItemsComponent} from "@/main/autoreg/accounts/components/auto-reg-items/auto-reg-items.component";
import {FbToolsItemsComponent} from "@/main/autoreg/accounts/components/fb-tools-items/fb-tools-items.component";
import {FilterComponent} from "@/main/autoreg/accounts/components/filter/filter.component";
import {ReloginComponent} from "@/main/autoreg/accounts/components/relogin/relogin.component";
import {SettingsColComponent} from "@/main/autoreg/accounts/components/settings-col/settings-col.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "@/shared/shared.module";
import {CreateProfileComponent} from './components/create-profile/create-profile.component';
import {CheckAccountsComponent} from './components/check-accounts/check-accounts.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatSelectModule} from "@angular/material/select";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {StatsModule} from "@/main/autoreg/stats/stats.module";
import {DatePickerComponent} from './components/filter/date-picker/date-picker.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatPaginatorIntl, MatPaginatorModule} from "@angular/material/paginator";
import {MatMenuModule} from "@angular/material/menu";
import {ContextMenuModule} from "ngx-contextmenu";
import {DeleteAccComponent} from './components/delete-acc/delete-acc.component';
import {CustomPaginatorIntl} from "@/main/autoreg/accounts/components/auto-reg-items/customPaginator";
import {AddToGroupComponent} from "@/main/autoreg/accounts/components/add-to-group/add-to-group.component";
import {DeletedGroupComponent} from "@/main/autoreg/accounts/components/deleted-group/deleted-group.component";
import {CreateGroupComponent} from "@/main/autoreg/accounts/components/create-group/create-group.component";
import {GroupPipe} from "@/main/autoreg/accounts/pipe/group.pipe";
import { ReplaceNullDirective } from './directive/replace-null.directive';
import { GetStatePipe } from './pipe/get-state.pipe';
import {NgxPermissionsModule} from "ngx-permissions";
import {ClipboardCookieDirective} from "@/main/autoreg/accounts/directive/clipboard-cookie.directive";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { SortComponent } from './components/sort/sort.component';
import { FilterStateComponent } from './components/filter-state/filter-state.component';
import { CheckFiltersComponent } from './components/check-filters/check-filters.component';
import { AdditionalFiltersComponent } from './components/additional-filters/additional-filters.component';
import { SetOwnerComponent } from './components/set-owner/set-owner.component';
import { GetRolePipe } from './pipe/get-role.pipe';
import { GetUserRolePipe } from './pipe/get-user-role.pipe';
import { TranslationRolePipe } from './pipe/translation-role.pipe';
import { SetOwnerPipe } from './pipe/set-owner.pipe';
import { SetGroupPipe } from './pipe/set-group.pipe';
import { SetTypePipe } from './pipe/set-type.pipe';
import {CdkDrag, CdkDropList} from "@angular/cdk/drag-drop";
import { SetColumnNamePipe } from './pipe/set-column-name.pipe';
import { FilterUsersComponent } from './components/filter-users/filter-users.component';
import {MatRadioModule} from "@angular/material/radio";
import { GetAccNoOwnerPipe } from './pipe/get-acc-no-owner.pipe';
import { FilterGroupComponent } from './components/filter-group/filter-group.component';



@NgModule({
  declarations: [
    AccountsComponent,
    AutoRegItemsComponent,
    FbToolsItemsComponent,
    FilterComponent,
    ReloginComponent,
    SettingsColComponent,
    CreateProfileComponent,
    CheckAccountsComponent,
    DatePickerComponent,
    DeleteAccComponent,
    CreateGroupComponent,
    AddToGroupComponent,
    DeletedGroupComponent,
    GroupPipe,
    ReplaceNullDirective,
    GetStatePipe,
    ClipboardCookieDirective,
    SortComponent,
    FilterStateComponent,
    CheckFiltersComponent,
    AdditionalFiltersComponent,
    SetOwnerComponent,
    GetRolePipe,
    GetUserRolePipe,
    TranslationRolePipe,
    SetOwnerPipe,
    SetGroupPipe,
    SetTypePipe,
    SetColumnNamePipe,
    FilterUsersComponent,
    GetAccNoOwnerPipe,
    FilterGroupComponent
  ],
    exports: [
        CheckAccountsComponent,
        AutoRegItemsComponent,
        GroupPipe
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        FormsModule,
        MatChipsModule,
        MatSelectModule,
        MatTooltipModule,
        MatButtonModule,
        StatsModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatMenuModule,
        ContextMenuModule,
        NgxPermissionsModule,
        MatCheckboxModule,
        NgOptimizedImage,
        CdkDropList,
        CdkDrag,
        MatRadioModule
    ],
  providers: [
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }
  ]
})
export class AccountsModule {
}
