import {createAction, props} from "@ngrx/store";
import {ActionsTypes} from "@/auth/store/action.type";
import {HttpErrorResponse} from "@angular/common/http";
import {LoginRequestType} from "@/auth/store/type/loginRequestType";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";

export const loginAction = createAction(
    ActionsTypes.LOGIN,
  props<{request: LoginRequestType}>()
)

export const loginSuccessAction = createAction(
  ActionsTypes.LOGIN_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const loginFailureAction = createAction(
  ActionsTypes.LOGIN_FAILURE,
  props<{error: HttpErrorResponse}>()
)
