import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/users/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const activateUserAction = createAction(
  ActionType.ACTIVATE_USER,
  props<{id: number}>()
)

export const activateUserActionSuccess = createAction(
  ActionType.ACTIVATE_USER_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const activateUserActionFailure = createAction(
  ActionType.ACTIVATE_USER_FAILURE,
  props<{error: HttpErrorResponse}>()
)
