import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AutoRegFilterType} from "@/main/autoreg/accounts/store/type/AutoRegFilter.type";
import {PopUpType} from "@/shared/types/popUp.type";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {UsersType} from "@/users/store/type/users.type";
import {select, Store} from "@ngrx/store";
import {UpdateUserAction} from "@/users/store/actions/updateUser.action";
import {Observable} from "rxjs";
import {buttonLoaderSelector} from "@/ui/store/selectors";

@Component({
  selector: 'app-redaction-auth',
  templateUrl: './redaction-user.component.html',
  styleUrls: ['./redaction-user.component.scss']
})
export class RedactionUserComponent implements OnInit {

  @Input() user!: UsersType;
  @Output() dataPopUp: EventEmitter<{ constellation: boolean }> = new EventEmitter();
  public typePopUp = PopUpType;
  public data: { constellation: boolean } = { constellation: false }
  public redactionForm: UntypedFormGroup;

  public buttonLoader$: Observable<boolean>;

  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initValue();
    this.initForm(this.user.username, this.user.email, this.user.ap_port, this.user.fbtool_apikey);
  }

  public okConstellation(): void {
    if (this.redactionForm.value.name && this.redactionForm.value.email) {
      this.store.dispatch(UpdateUserAction({request:
          {
            id: this.user.id,
            username: this.redactionForm.value.name,
            email: this.redactionForm.value.email,
            ap_port: this.redactionForm.value.ap_port,
            fbtool_apikey: this.redactionForm.value.fbtool_apikey,
          }
      }))
    }
  }

  public noConstellation(): void {
    this.data.constellation = false;
    this.dataPopUp.emit(this.data);
  }

  private initForm(name, email, port, fb_tool): void {
    this.redactionForm = new UntypedFormGroup({
      name: new UntypedFormControl(name, Validators.required),
      email: new UntypedFormControl(email, Validators.required),
      ap_port: new UntypedFormControl(port, Validators.required),
      fbtool_apikey: new UntypedFormControl(fb_tool, Validators.required),
    })
  }

  private initValue(): void {
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
  }
}
