import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {AutoRegService} from "@/main/autoreg/services/auto-reg.service";
import {ToastrService} from "ngx-toastr";
import {
  autoRegAccUpdate, autoRegAccUpdateError, autoRegAccUpdateFailure,
  autoRegAccUpdateSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegAccUpdate.actions";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {Store} from "@ngrx/store";

@Injectable()
export class AutoRegAccUpdateEffect {
  autoRegUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegAccUpdate),

    switchMap(({request}) => {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      return this.autoRegService.updateAutoReg(request).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return autoRegAccUpdateSuccess({response})
          } else if (response.status === 'Failure') {
            return autoRegAccUpdateFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegAccUpdateError({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegAccUpdateError({error}));
          } else {
            return of(autoRegAccUpdateError({error}));
          }
        })
      );
    })
  ))

  autoDeleteSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegAccUpdateSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.success(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoDeleteFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegAccUpdateFailure),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoDeleteError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegAccUpdateError),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private autoRegService: AutoRegService,
              private toaStr: ToastrService,
              private store: Store
  ) {
  }
}
