import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {AutoRegService} from "@/main/autoreg/services/auto-reg.service";
import {ToastrService} from "ngx-toastr";
import {
  autoRegCreateGroupAction, autoRegCreateGroupActionFailure,
  autoRegCreateGroupActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRregCreateGroup.action";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {Store} from "@ngrx/store";

@Injectable()
export class AutoRegCreateGroupEffect {
  autoRegCreateGroupEffect$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegCreateGroupAction),
    switchMap(({request}) => {
      return this.autoRegService.createGroup(request).pipe(
        map((response: DefaultResponseType) => {
          return autoRegCreateGroupActionSuccess({response})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(autoRegCreateGroupActionFailure({error}));
        })
      );
    })
  ))

  AutoRegCreateGroupSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegCreateGroupActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.status === 'Success') {
          if (response.response.details.msg) {
            this.toaStr.info(response.response.details.msg)
          } else {
            this.toaStr.warning('Нет уведомления. Сообщить Максиму')
          }
        } else if (response.response.status === 'Failure') {
          if (response.response.details.msg) {
            this.toaStr.error(response.response.details.msg)
          } else {
            this.toaStr.error('Нет уведомления. Сообщить Максиму')
          }
        }
      })
    ), {dispatch: false}
  )

  AutoRegCreateGroupFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegCreateGroupActionFailure),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private autoRegService: AutoRegService,
              private toaStr: ToastrService,
              private store: Store
  ) {
  }
}
