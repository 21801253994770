import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export function filteredStateUtil(defaultArray, selectedArray, filtersStep, defaultValue?: boolean): AutoRegItemType[] {
  let defArr = defaultValue ? defaultArray : selectedArray;
  let newArray = [];
  defArr.forEach(item => {
    filtersStep.forEach(filter => {
      if (item.state === filter) {
        newArray.push(item);
      }
    })
  })
  return newArray;
}
