import {FiltersAutoRegType} from "@/main/autoreg/accounts/store/type/filtersAutoReg.type";
import {getAccFbToolsUtil} from "@/main/autoreg/utils/getAccFbTools.util";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export function otherFiltersCheck(selectedArray: AutoRegItemType[], defaultArray, filters, role: string, defaultValue?: boolean): AutoRegItemType[] {
  let defaultArr = defaultValue ? defaultArray : selectedArray;
  let newArray = defaultArray;

  if (filters.includes(FiltersAutoRegType.COOKIE)) {
    newArray = defaultArr.filter(item => item.cookie !== null);
  }

  if (filters.includes(FiltersAutoRegType.TWF)) {
    newArray = defaultArr.filter(item => item.twofa !== null && item.twofa !== 'Not set');
  }

  if (filters.includes(FiltersAutoRegType.NO_TWF)) {
    newArray = defaultArr.filter(item => item.twofa === null || item.twofa === 'Not set');
  }

  if (filters.includes(FiltersAutoRegType.CREATE_PROFILE_AP)) {
    newArray = defaultArr.filter(item => item.ap_id === null);
  }

  if (filters.includes(FiltersAutoRegType.FB_TOOLS)) {
    newArray = getAccFbToolsUtil(defaultArr, false, role);
  }

  if (!filters.includes(FiltersAutoRegType.COOKIE)
    && !filters.includes(FiltersAutoRegType.TWF)
    && !filters.includes(FiltersAutoRegType.NO_TWF)
    && !filters.includes(FiltersAutoRegType.CREATE_PROFILE_AP)
    && !filters.includes(FiltersAutoRegType.EXAMINATION_ACC)
    && !filters.includes(FiltersAutoRegType.FB_TOOLS)) {
    newArray = defaultArr;
  }
  return newArray;
}
