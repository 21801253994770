import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {environment} from "../../../../environments/environment";

@Injectable()
export class AutoGptService {

  constructor (private http: HttpClient) { }

  public getChat(): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + 'chat/', {withCredentials: true})
  }

  public setChat(request): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>(environment.api + 'chat/', request, {withCredentials: true})
  }

  public deleteChat(id): Observable<DefaultResponseType> {
    let request = {id: id};
    return this.http.delete<DefaultResponseType>(environment.api + 'chat/', {withCredentials: true, body: request})
  }

  public updateChat(request): Observable<DefaultResponseType> {
    return this.http.patch<DefaultResponseType>(environment.api + 'chat/', request, {withCredentials: true})
  }

  public getMessage(): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + 'message/', {withCredentials: true})
  }

  public setMessage(request): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>(environment.api + 'message/', request, {withCredentials: true})
  }
}
