export const ProxyDeviceConstants: {value: string, device: string}[] = [
  {value: 's7', device: 'S7'},
  {value: 's8', device: 'S8'},
  {value: 's9', device: 'S9'},
  {value: 'a331', device: 'A331'},
  {value: 'a332', device: 'A332'},
  {value: 'a333', device: 'A333'},
  {value: 'a334', device: 'A334'},
  {value: 'a335', device: 'A335'},
  {value: 'a336', device: 'A336'},
]

