import {createSelector} from "@ngrx/store";

import {AppState} from "@/store/state";
import {AutoRegInterface} from "@/main/autoreg/registration/store/index";



export const autoRegFeatureSelector = (state: AppState) => state.autoReg.registration

export const autoRegProcessSelector = createSelector(
  autoRegFeatureSelector,
  (autoRegState: AutoRegInterface) => autoRegState.autoRegProcess
)

export const autoRegStatusSelector = createSelector(
  autoRegFeatureSelector,
  (autoRegState: AutoRegInterface) => autoRegState.autoRegStatus
)

export const statDataProcessed = createSelector(
  autoRegFeatureSelector,
  (autoRegState: AutoRegInterface) => autoRegState.dataForCharts
)

export const regerSelector = createSelector(
  autoRegFeatureSelector,
  (autoRegState: AutoRegInterface) => autoRegState.reger
)

export const regConsumablesSelector = createSelector(
  autoRegFeatureSelector,
  (autoRegState: AutoRegInterface) => autoRegState.regConsumables
)

export const addToProcessSelector = createSelector(
  autoRegFeatureSelector,
  (autoRegState: AutoRegInterface) => autoRegState.addToProcess
)

export const useDevicesSelector = createSelector(
  autoRegFeatureSelector,
  (autoRegState: AutoRegInterface) => {
    let processes = autoRegState.autoRegProcess;
    let devices = [];
    processes.forEach(item => {
      if (item.name === 'Relogin') {
        devices.push(item.device_name)
      }
    })
    return devices;
  }
)

export const integrationToScenumSelector = createSelector(
  autoRegFeatureSelector,
  (autoRegState: AutoRegInterface) => autoRegState.integrationToScenum
)

export const updateProcessSelector = createSelector(
  autoRegFeatureSelector,
  (autoRegState: AutoRegInterface) => autoRegState.updateProcess
)











