import {ActionReducer, createReducer, on} from "@ngrx/store";
import {CartState, initialState} from "@/main/cart/store/index";
import {cartItemSelectedAction} from "@/main/cart/store/actions/cartItemSelected.action";
import {cartOnPageAction} from "@/main/cart/store/actions/cartOnPage.action";
import {getPostsForPage} from "@/main/autoreg/utils/pagination-utils";
import {getCartDataSuccessAction} from "@/main/cart/store/actions/getCartData.action";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {TrCheckedUtil} from "@/main/autoreg/utils/trChecked.util";
import {restoreAccSuccessAction} from "@/main/cart/store/actions/restoreAcc.action";
import {UpdateStateRequestType} from "@/main/autoreg/accounts/store/type/requestSetOwnerSystem.type";

export const CartReducer: ActionReducer<CartState> = createReducer(
  initialState,
  on(cartItemSelectedAction, (state, {id}) => {

    //отмечаем аккаунты с помощью чекбоксов
    let page: AutoRegItemType[] = TrCheckedUtil(state.currentPageData, id);

    //Проверяем, все ли аккаунты чекнуты на текущей странице
    let countCheckAccInCurrentPage = false;
    if (page.length === page.filter(item => item.checked).length) {
      countCheckAccInCurrentPage = true;
    }

    let selectedTr = page.filter(item => item.checked);

    return {
      ...state,
      currentPageData: page,
      selectedTr: selectedTr,
      countCheckAccInCurrentPage: countCheckAccInCurrentPage
    }
  }),

  on(cartOnPageAction, (state, {page, pageSize}) => {

    let pageData: AutoRegItemType[] = getPostsForPage(page, pageSize, state.cartData);

    return {
      ...state,
      currentPageData: pageData
    }
  }),

  on(getCartDataSuccessAction, (state, {response}) => {

    let data: AutoRegItemType[] = (response.data as AutoRegItemType[]).map((item, index) => {
      return {
        ...item,
        id: index + 1
      }
    });
    let pageData: AutoRegItemType[] = getPostsForPage(state.currentPage, state.pageSize, data);

    return {
      ...state,
      cartData: data,
      currentPageData: pageData,
      selectedTr: []
    }
  }),

  on(restoreAccSuccessAction, (state, {response, request}) => {

    let newCartData = filterData(state.cartData, request);

    function filterData(defArr: AutoRegItemType[], reqArr: UpdateStateRequestType): AutoRegItemType[] {
      return defArr.filter(defItem => {
        return !reqArr.accounts.some(reqItem => reqItem.login === defItem.login);
      });
    }

    let page = getPostsForPage(state.currentPage, state.pageSize, newCartData);

    if (state.currentPageData.length === 0) {
      state.currentPageData = getPostsForPage(state.currentPage - 1, state.pageSize, state.cartData);
    }

    let countCheckAccInCurrentPage = false;
    if (state.currentPageData.length === state.currentPageData.filter(item => item.checked).length && state.currentPageData.length !== 0) {
      countCheckAccInCurrentPage = true;
    }

    if (newCartData.length === 0) {
      countCheckAccInCurrentPage = false;
    }

    return {
      ...state,
      cartData: newCartData,
      currentPageData: page,
      countCheckAccInCurrentPage: countCheckAccInCurrentPage,
      selectedTr: []
    }
  })

)
