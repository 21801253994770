<div class="setting">
  <div class="row">
    <div class="col-12">
      <div class="card card-secondary mb-0 process ">
        <div class="card-header">
          <h3 class="card-title">Настройка столбцов таблицы</h3>
        </div>
        <div class="card-body pb-0">
          <div class="row">
            <div class="col-md-12 settings-list">

              <ng-container *ngFor="let column of columns$ | async">
                <mat-checkbox *ngIf="column !== 'extension_id'
                              && column !== 'mismatchCookieAndId'
                              && column !== 'process_id'
                              && column !== 'group_id'
                              && column !== 'id'
                              && column !== 'checked'" class="example-margin" color="primary"
                              [checked]="(tableState$ | async).includes(column)"
                              (click)="updateSettings(column)"
                >{{column | setColumnName}}</mat-checkbox>
              </ng-container>

            </div>
            <div class="col-6 mt-3 mb-1">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена</button>
            </div>

            <div class="col-6 mb-1">
              <button class="w-100 mt-3" mat-raised-button
                      (click)="setDefaultSettings('default')"
              >По умолчанию</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
