import {createSelector} from "@ngrx/store";
import {AppState} from "@/store/state";
import {AuthState} from "@/auth/store/index";

export const authFeatureSelector = (state: AppState) => state.auth;

export const isSubmittedSelector = createSelector(
  authFeatureSelector,
  (authState: AuthState) => authState.isSubmitting)

export const registerErrors = createSelector(
  authFeatureSelector,
  (authState: AuthState) => authState.isRegisterUser)

export const currentUser = createSelector(
  authFeatureSelector,
  (authState: AuthState) => authState.currentUser)

export const isLogged = createSelector(
  authFeatureSelector,
  (authState: AuthState) => authState.isLogged
)

export const roleSelector = createSelector(
  authFeatureSelector,
  (authState: AuthState) => authState.role
)
