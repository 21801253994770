import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setType'
})
export class SetTypePipe implements PipeTransform {

  transform(value: string): string {
    let result = ' - ';
    switch (value) {
      case 'autoreg':
        result = 'Авторег';
        break;

      case 'farm':
        result = 'Фарм';
        break;

      default:
        result = ' - ';
        break;
    }

    return result;
  }

}
