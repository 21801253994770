import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CartComponent} from "@/main/cart/cart.component";
import { CartTableComponent } from '@/main/cart/components/cart-table/cart-table.component';
import {CartService} from "@/main/cart/services/cart.service";
import {SharedModule} from "@/shared/shared.module";
import {MatPaginatorModule} from "@angular/material/paginator";
import {EffectsModule} from "@ngrx/effects";
import {GetCartDataEffect} from "@/main/cart/store/effects/getCartData.effect";
import {RestoreAccEffect} from "@/main/cart/store/effects/restoreAcc.effect";



@NgModule({
  declarations: [
    CartComponent,
    CartTableComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatPaginatorModule,
    EffectsModule
      .forFeature(
        [
          GetCartDataEffect,
          RestoreAccEffect
        ])
  ],
  providers: [
    CartService
  ]
})
export class CartModule { }
