import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AutoRegStartType} from "@/main/autoreg/registration/store/type/autoRegStart.type";
import {Observable} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {environment} from "../../../../environments/environment";
import {AddFarmType} from "@/main/autoreg/registration/store/type/addFarm.type";
import {IntegrationToScenumType} from "@/main/autoreg/registration/store/type/integrationToScenum.type";
import {RequestCreateAPType} from "@/main/autoreg/accounts/store/type/requestCreateAP.type";
import {AutoRegCheckAccItemsRequestType} from "@/main/autoreg/accounts/store/type/autoRegCheckAccItemsRequest.type";
import {SyncToScenumRequestType} from "@/main/autoreg/registration/store/type/syncToScenumRequest.type";
import {AutoRegReLoginRequestType} from "@/main/autoreg/accounts/store/type/autoRegReloginRequest.type";

@Injectable()

export class ProcessService {

  constructor(private http: HttpClient) { }

  public getAutoRegProcesses(): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + 'process', {withCredentials: true})
  }

  public startProcess(data: AutoRegStartType): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>(environment.api + 'process/autoreg', data,{ withCredentials: true });
  }

  public terminateProcess(pid): Observable<DefaultResponseType> {
    return this.http.delete<DefaultResponseType>(environment.api + 'process/terminate', { withCredentials: true, body: pid });
  }

  public pauseProcess(pid): Observable<DefaultResponseType> {
    let body = {pid: pid}
    return this.http.patch<DefaultResponseType>(environment.api + 'process/pause', body, { withCredentials: true});
  }

  public playProcess(pid): Observable<DefaultResponseType> {
    let body = {pid: pid}
    return this.http.patch<DefaultResponseType>(environment.api + 'process/continue', body, { withCredentials: true});
  }

  public addFarm(request: AddFarmType): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>(environment.api + 'process/farm', request, { withCredentials: true})
  }

  public integrationToScenum(request: IntegrationToScenumType): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>(environment.api + 'process/integration', request, { withCredentials: true})
  }

  public syncToScenum(request: SyncToScenumRequestType): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>(environment.api + 'process/sync', request, { withCredentials: true})
  }

  public reLogInAutoReg(request: AutoRegReLoginRequestType): Observable<DefaultResponseType> {
    if(request) {
      return this.http.post<DefaultResponseType>(environment.api + 'process/autoreg/relogin', request, { withCredentials: true });
    }
  }

  public createProcessAP(request: RequestCreateAPType): Observable<DefaultResponseType> {
    if(request) {
      return this.http.post<DefaultResponseType>(environment.api + 'process/createAP', request, { withCredentials: true });
    }
  }

  public checkAccItems(request: AutoRegCheckAccItemsRequestType): Observable<DefaultResponseType> {
    if(request) {
      return this.http.post<DefaultResponseType>(environment.api + 'process/check', request, { withCredentials: true });
    }
  }
}
