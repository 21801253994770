import {createAction, props} from "@ngrx/store";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export const autoRegAccDeleted = createAction(
  ActionType.AUTO_REG_ITEM_DELETED,
  props<{request:  AutoRegItemType, port: number}>()
)

export const autoRegAccDeletedSuccess = createAction(
  ActionType.AUTO_REG_ITEM_DELETED_SUCCESS,
  props<{response: DefaultResponseType, account: any}>()
)

export const autoRegAccDeletedFailure = createAction(
  ActionType.AUTO_REG_ITEM_DELETED_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegAccDeletedError = createAction(
  ActionType.AUTO_REG_ITEM_DELETED_ERROR,
  props<{error: HttpErrorResponse}>()
)
