import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {StatisticsService} from "@/main/autoreg/services/statistics.service";
import {
  GetStatisticsTodayAction, GetStatisticsTodayActionFailure,
  GetStatisticsTodayActionSuccess
} from "@/main/autoreg/stats/store/actions/getStatisticsToday.action";
import {ToastrService} from "ngx-toastr";

@Injectable()
export class GetStatisticsTodayEffect {
  getStatisticsTodayEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetStatisticsTodayAction),
    switchMap(() => {
      return this.statisticsService.getStatisticsToday().pipe(
        map((response: DefaultResponseType) => {
          return GetStatisticsTodayActionSuccess({response})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(GetStatisticsTodayActionFailure({error}));
        })
      );
    })
  ))

  getStatisticsTodaySuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(GetStatisticsTodayActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        if (response.response.status === 'Failure') {
          if (response.response.details.msg) {
            this.toaStr.error(response.response.details.msg)
          } else {
            this.toaStr.error('Данные статистики не загружены. Нет уведомления. Сообщить Максиму')
          }
        }
      })
    ), {dispatch: false}
  )

  getStatisticsTodayFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(GetStatisticsTodayActionFailure),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          this.toaStr.error('Данные статистики не загружены')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Данные статистики не загружены. Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private statisticsService: StatisticsService,
              private toaStr: ToastrService
  ) {
  }
}
