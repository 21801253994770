export type RegerType = {
  pids?: number,
  windows_profile: string,
  state: RegerState,
  processes: number
}

export type RegerStartType = Omit<RegerType, 'pid'>;

export enum RegerState {
  START = 'Start',
  STOP = 'Stop',
  PAUSE = 'Pause',
}
