import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private http: HttpClient) { }

  public getStatistics(start_date: string, end_date: string): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + `stats/interval?start_date=${start_date}&end_date=${end_date}`, {withCredentials: true})
  }

  public getStatisticsToday(): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + `stats/today`, {withCredentials: true})
  }

  public getStatisticsAll(): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + `stats/all`, {withCredentials: true})
  }
}
