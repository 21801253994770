<div class="relogin">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0 process">
        <div class="card-header">
          <h3 class="card-title" *ngIf="type === popUpType.RE_LOGIN_ITEM">Релогин аккаунта {{account.login}}</h3>
          <h3 class="card-title" *ngIf="type !== popUpType.RE_LOGIN_ITEM">Релогин аккаунтов</h3>
        </div>
        <div class="card-body pb-0" [formGroup]="reLoginForm">
          <div class="row">

            <div class="col-12 mt-2">
              <mat-form-field class="example-full-width w-100" appearance="fill">
                <mat-label>Устройство</mat-label>
                <mat-select formControlName="device">
                  <ng-container *ngFor="let device of devices">
                    <mat-option *ngIf="!(useDevices$ | async).includes(device.value)" [value]="device.value">{{device.device}}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-12 my-2">
              <mat-radio-group aria-label="Select an option" formControlName="method">
                <mat-radio-button value="Mbasic">Mbasic</mat-radio-button>
                <mat-radio-button class="mx-4" value="Origin">Origin</mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="col-12">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Порт</mat-label>
                <input type="number" matInput placeholder="Порт"
                       [value]="checked ? (defaultPort$ | async).toString() : (currentUserPort$ | async).toString()"
                       [readonly]="checked"
                       #inputElement
                >
              </mat-form-field>
            </div>

            <div class="col-12 mb-2">
              <mat-checkbox class="example-margin w-100" color="primary"
                            (click)="checkedPort()"
                            checked
              >
                <span>Использовать стандартный порт</span>
              </mat-checkbox>
            </div>

            <div class="col-6 my-2">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена</button>
            </div>

            <div class="col-6 my-2">
              <button class="w-100" mat-raised-button color="primary"
                      [disabled]="reLoginForm.invalid || (buttonLoader$ | async)"
                      (click)="okConstellation(inputElement.value)"
              >
                <span *ngIf="!(buttonLoader$ | async)">Релогин</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
