import {createReducer, on} from "@ngrx/store";
import {initialState} from "@/users/store/index";
import {GetUsersActionFailure, GetUsersActionSuccess} from "@/users/store/actions/getUsers.action";
import {getMeActionFailure, getMeActionSuccess} from "@/users/store/actions/getMe.action";
import {PopUpAction} from "@/users/store/actions/popUp.action";
import {UpdateUserActionSuccess} from "@/users/store/actions/updateUser.action";
import {deleteUsersActionSuccess} from "@/users/store/actions/deleteUsers.action";
import {filter} from "rxjs/operators";
import {activateUserActionSuccess} from "@/users/store/actions/activateUser.action";
import {UsersType} from "@/users/store/type/users.type";
import {currentUserType} from "@/users/store/type/currentUser.type";

export const usersReducer = createReducer(
  initialState,
  on(GetUsersActionSuccess, (state, {response}) => {

    let defaultApiKeyFBTool = (response.data as UsersType[])
      .find(item => item.email === 'kudrin.alexander.p@gmail.com').fbtool_apikey;

    return {
      ...state,
      users: response.data,
      defaultApiKeyFBTool: defaultApiKeyFBTool
    }
  }),


  on(getMeActionSuccess, (state, {response}) => {
    return {
      ...state,
      currentUser: response.data
    }
  }),

  on(PopUpAction, (state, {statePopUp}) => {
    return {
      ...state,
      popUp: statePopUp
    }
  }),

  on(UpdateUserActionSuccess, (state, {response}) => {
    let responseData: UsersType = response.data;
    let newUsersArray = state.users;

    if(responseData) {
      newUsersArray = state.users.map(item => {
        if(item.id === response.data.id) {
          return {
            ...item,
            username: response.data.username,
            email: response.data.email,
            ap_port: response.data.ap_port,
            fbtool_apikey: response.data.fbtool_apikey
          }
        }
        return item;
      })
    }

    let newCurrentUser: currentUserType = state.currentUser;
    if (responseData.id === newCurrentUser.id) {
      let keys = Object.keys(state.currentUser);
      keys.forEach(key => {
        if (responseData[key] && responseData[key] !== newCurrentUser[key]) {
          newCurrentUser = {...newCurrentUser}
          newCurrentUser[key] = responseData[key];
        }
      })
    }

    return {
      ...state,
      popUp: false,
      users: newUsersArray,
      currentUser: newCurrentUser
    }
  }),

  on(deleteUsersActionSuccess, (state, {response, id}) => {
    let newUsers = state.users.filter(item => item.id !== id);
    return {
      ...state,
      popUp: false,
      users: newUsers
    }
  }),

  on(activateUserActionSuccess, (state, {response}) => {

    return {
      ...state,
      popUp: false,
    }
  })
)
