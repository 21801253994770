import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/registration/store/action.type";
import {IntegrationToScenumType} from "@/main/autoreg/registration/store/type/integrationToScenum.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const integrationToScenumAction = createAction(
  ActionType.AUTO_REG_INTEGRATION_TO_SCENUM,
  props<{request: IntegrationToScenumType}>()
)

export const integrationToScenumActionSuccess = createAction(
  ActionType.AUTO_REG_INTEGRATION_TO_SCENUM_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const integrationToScenumActionFailure = createAction(
  ActionType.AUTO_REG_INTEGRATION_TO_SCENUM_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const integrationToScenumActionError = createAction(
  ActionType.AUTO_REG_INTEGRATION_TO_SCENUM_ERROR,
  props<{error: HttpErrorResponse}>()
)
