import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {UpdateStateRequestType} from "@/main/autoreg/accounts/store/type/requestSetOwnerSystem.type";

export const AutoRegSetOwnerSystemAction = createAction(
  ActionType.AUTO_REG_SET_OWNER_SYSTEM,
  props<{id?: number, update?: boolean, request?: UpdateStateRequestType, one?: boolean, state?: string}>()
)

export const AutoRegSetOwnerSystemActionSuccess = createAction(
  ActionType.AUTO_REG_SET_OWNER_SUCCESS_SYSTEM_SUCCESS,
  props<{response: DefaultResponseType, request: UpdateStateRequestType, update: boolean, one: boolean, stateAutoreg: string, id?: number}>()
)

export const AutoRegSetOwnerSystemActionFailure = createAction(
  ActionType.AUTO_REG_SET_OWNER_FAILURE_SYSTEM_FAILURE,
  props<{error: HttpErrorResponse}>()
)
