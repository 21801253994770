import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {Observable, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {
  cartDataSelector,
  countCheckAccInCurrentPageSelector,
  currentPageSelector,
  pageSelector,
  pageSizeSelector, selectedTrSelector
} from "@/main/cart/store/selectors";
import {PageEvent} from "@angular/material/paginator";
import {cartOnPageAction} from "@/main/cart/store/actions/cartOnPage.action";
import {getCartDataAction} from "@/main/cart/store/actions/getCartData.action";
import {cartItemSelectedAction} from "@/main/cart/store/actions/cartItemSelected.action";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ContextMenuEventType} from "@/shared/types/contextMenuEvent.type";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {autoRegStatusSelector} from "@/main/autoreg/accounts/store/selectors";
import {PopUpType} from "@/shared/types/popUp.type";
import {IPopUpData} from "@/shared/components/popup/popup.component";
import _default from "chart.js/dist/core/core.layouts";
import update = _default.update;
import {UpdateStateRequestType} from "@/main/autoreg/accounts/store/type/requestSetOwnerSystem.type";
import {restoreAccAction} from "@/main/cart/store/actions/restoreAcc.action";

@Component({
  selector: 'app-cart-table',
  templateUrl: './cart-table.component.html',
  styleUrls: ['./cart-table.component.scss']
})
export class CartTableComponent implements OnInit, OnDestroy {

  @ViewChild('autoRegItem') autoRegItemElement: TemplateRef<ElementRef>;
  @ViewChild('popup') popupElement: TemplateRef<ElementRef>;

  public cartData$: Observable<AutoRegItemType[]>;
  public currentPageData$: Observable<AutoRegItemType[]>;
  public selectedAcc$: Observable<AutoRegItemType[]>;

  public pageSize$: Observable<number>;
  public currentPage$: Observable<number>;
  public countCheckAccInCurrentPage$: Observable<boolean>;
  private popUpState$: Observable<boolean | string>

  public typePopUp: PopUpType;
  public contextMenuStyle: any;
  public currentAcc: AutoRegItemType;
  public disabled = false;
  public pageSize: number;
  public pageSizeOptions = [30, 90, 150, 300, 500];
  public hidePageSize = false;
  public showPageSizeOptions = true;
  public showFirstLastButtons = true;

  private dialogRef: MatDialogRef<any>;
  private subscriptionList: Subscription[] = [];

  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store,
              private matDialog: MatDialog) {
  }

  public ngOnInit(): void {
    this.store.dispatch(getCartDataAction());
    this.initValue();
    this.sub = this.popUpState$.subscribe(data => {
      this.closePopUp(data)
    });
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe());
  }

  public updateCart(): void {
    this.store.dispatch(getCartDataAction());
  }

  //клик правой кнопкой мыши по таблице
  public onRightClick($event: MouseEvent, item): void {
    $event.preventDefault();
    this.contextMenuStyle = {
      'left.px': $event.clientX + window.pageXOffset - 100,
      'top.px': $event.clientY + window.pageYOffset - 135,
    }
    this.currentAcc = item;
  }

  public checkedTr(id: number): void {
    this.store.dispatch(cartItemSelectedAction({id}))
  }

  //переключение страниц
  public handlePageEvent($event: PageEvent): void {
    this.store.dispatch(cartOnPageAction({page: $event.pageIndex, pageSize: $event.pageSize}))
  }

  public contextMenuEvent($event: ContextMenuEventType | boolean): void {
    switch ($event) {
      case ContextMenuEventType.OPEN_CART_ACC:
        this.openAutoRegAcc();
        break;
      case ContextMenuEventType.RESTORE_ACC:
        this.restoreAcc('one')
    }
  }

  public popUpActions($event: IPopUpData): void {
    if ($event.constellation && this.typePopUp === PopUpType.RESTORE_AUTO_REG) {
      this.restoreAccOne();
    }
    if ($event.constellation && this.typePopUp === PopUpType.RESTORE_AUTO_REG_ITEMS) {
      this.restoreAccGroup();
    }
  }

  public restoreAcc(type: 'group' | 'one'): void {
    if (type === 'one') {
      this.typePopUp = PopUpType.RESTORE_AUTO_REG
    } else {
      this.typePopUp = PopUpType.RESTORE_AUTO_REG_ITEMS
    }
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.dialogRef = this.matDialog.open(this.popupElement);
  }

  private openAutoRegAcc(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.dialogRef = this.matDialog.open(this.autoRegItemElement);
  }

  private restoreAccOne(): void {
    let request: UpdateStateRequestType = {
      accounts: [
        {
          login: this.currentAcc.login,
          state: 'Востановлен',
          checkpoint: this.currentAcc.checkpoint ? this.currentAcc.checkpoint : 'Yes'
        }
      ]
    }
    this.store.dispatch(restoreAccAction({request}))
  }

  private restoreAccGroup(): void {
    this.store.dispatch(restoreAccAction({}))
  }

  private closePopUp(value): void {
    if(value === 'Закрыть' && this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private initValue(): void {
    this.cartData$ = this.store.pipe(select(cartDataSelector));
    this.currentPageData$ = this.store.pipe(select(pageSelector));
    this.pageSize$ = this.store.pipe(select(pageSizeSelector));
    this.currentPage$ = this.store.pipe(select(currentPageSelector));
    this.countCheckAccInCurrentPage$ = this.store.pipe(select(countCheckAccInCurrentPageSelector));
    this.popUpState$ = this.store.pipe(select(autoRegStatusSelector));
    this.selectedAcc$ = this.store.pipe(select(selectedTrSelector));
  }
}



