
<mat-card class="example-card h-100 d-flex flex-column justify-content-between">
  <mat-card-header>
    <mat-card-title class="title">{{chatName}}</mat-card-title>
  </mat-card-header>
  <div class="chat-body p-3">

    <mat-card class="message-item my-2 p-2"
              *ngFor="let message of currentMessages$ | async"
              [class.user]="message.role === 'user'"
    >
      <mat-card-header class="message-header">
        <mat-card-title class="chat-item-title">{{message.role}}</mat-card-title>
      </mat-card-header>
      <div appClipboard class="text-message">
        <span>{{message.content}}</span>
      </div>
    </mat-card>

  </div>
  <div class="d-flex w-100 justify-content-between footer">
    <mat-form-field class="example-full-width w-100">
      <mat-label>Текст сообщения</mat-label>
      <textarea matInput placeholder="Введите текст сообщения"></textarea>
    </mat-form-field>
    <div class="d-flex align-items-center justify-content-center">
      <button class="mx-4" mat-raised-button color="primary">Отправить</button>
    </div>
  </div>

</mat-card>
