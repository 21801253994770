import {Component} from '@angular/core';
import {TypeBoxEnum} from "@/main/autoreg/stats/store/type/typeBox.enum";

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent {

  public boxType = TypeBoxEnum;

  constructor() {}
}
