import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {catchError, map, mergeMap, of, tap, withLatestFrom} from "rxjs";
import {accCheckItemsSelector} from "@/main/autoreg/accounts/store/selectors";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {
  autoRegChangedAccAction, autoRegChangedAccActionError, autoRegChangedAccActionFailure,
  autoRegChangedAccActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegChangedAcc.action";
import {AutoRegCheckAccItemsRequestType} from "@/main/autoreg/accounts/store/type/autoRegCheckAccItemsRequest.type";
import {ProcessService} from "@/main/autoreg/services/process.service";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";

@Injectable()
export class AutoRegChangeAccEffect {

  autoRegChangeAccEffect$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegChangedAccAction),
    withLatestFrom(this.store.pipe(select(accCheckItemsSelector))),
    mergeMap(([action, accCheckItems]) => {
      let dataRequest = [];
      if (action.typeRequest === 'group') {
        dataRequest = accCheckItems.accounts.map(item => {
          return {
            ap_id: item.ap_id ? item.ap_id : 'Not set',
            ap_port: action.port === 0 ? item.ap_port : action.port,
            login: item.login,
            email: item.email,
            email_pass: item.email_pass,
            group_name: item.group_name === null ? 'Not set' : item.group_name,
            password: item.password,
            first_name: item.name,
            last_name: item.surname,
            bdate: item.str_birthday,
            twofa: item.twofa,
            device_name: action.device
          }
        });
      } else if (action.typeRequest === 'one' && action.payload) {
        let data = {
          ap_id: action.payload.ap_id,
          ap_port: action.port,
          login: action.payload.login,
          email: action.payload.email,
          email_pass: action.payload.email_pass,
          group_name: action.payload.group_name === null ? 'Not set' : action.payload.group_name,
          password: action.payload.password,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          bdate: action.payload.bdate,
          twofa: action.payload.twofa,
          device_name: action.device
        }
        dataRequest.push(data);
      }

      let request: AutoRegCheckAccItemsRequestType = {
        accounts: dataRequest,
        check_checkpoint: accCheckItems.filters.includes('checkpoint'),
        check_twofa: accCheckItems.filters.includes('2FA'),
        check_email: accCheckItems.filters.includes('post')
      }

      return this.processService.checkAccItems(request).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return autoRegChangedAccActionSuccess({response})
          } else if (response.status === 'Failure') {
            return autoRegChangedAccActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegChangedAccActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegChangedAccActionError({error}));
          } else {
            return of(autoRegChangedAccActionError({error}));
          }
        })
      );
    })
  ))

  autoRegChangeAccSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegChangedAccActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.success(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegChangeAccFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegChangedAccActionFailure),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.error('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegChangeAccError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegChangedAccActionError),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  constructor(
    private actions$: Actions,
    private processService: ProcessService,
    private store: Store,
    private toaStr: ToastrService
  ) {}
}
