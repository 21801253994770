<div class="user">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0 process">
        <div class="card-header">
          <h3 class="card-title">Редактировать профиль {{user.username}}</h3>
        </div>
        <div class="card-body pb-0" [formGroup]="redactionForm">
          <div class="row">

            <div class="col-12">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Электронная почта</mat-label>
                <input matInput placeholder="Электронная почта" formControlName="email">
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Имя</mat-label>
                <input matInput placeholder="Имя" formControlName="name">
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Порт</mat-label>
                <input type="number" matInput placeholder="Порт" formControlName="ap_port">
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Ключ FB-Tool</mat-label>
                <input  matInput placeholder="Ключ FB-Tool" formControlName="fbtool_apikey">
              </mat-form-field>
            </div>

            <div class="col-6 my-2">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена</button>
            </div>


            <div class="col-6 my-2">
              <button class="w-100" mat-raised-button color="primary"
                      [disabled]="redactionForm.invalid || (buttonLoader$ | async)"
                      (click)="okConstellation()"
              >
                <span *ngIf="!(buttonLoader$ | async)">Сохранить</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
