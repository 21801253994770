import {Component, Input, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {statisticsTotalSelector} from "@/main/autoreg/stats/store/selectors";
import {TotalStatType} from "@/main/autoreg/stats/store/type/totalStat.type";
import {TypeBoxEnum} from "@/main/autoreg/stats/store/type/typeBox.enum";
import {registeredToDaySelector} from "@/main/autoreg/accounts/store/selectors";

@Component({
  selector: 'app-box-stat',
  templateUrl: './box-stat.component.html',
  styleUrls: ['./box-stat.component.scss']
})
export class BoxStatComponent implements OnInit {

  @Input() typeBox: string;
  public box = TypeBoxEnum;
  public statisticsTotal$: Observable<TotalStatType>;
  public registeredToday$: Observable<number>;

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
  }

  private initValue(): void {
    this.statisticsTotal$ = this.store.pipe(select(statisticsTotalSelector));
    this.registeredToday$ = this.store.pipe(select(registeredToDaySelector));
  }
}
