import {Directive, ElementRef, HostListener, Input} from '@angular/core';


@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {

  @Input() permissions: boolean;
  private isDragging: boolean = false;
  private mouseDownX: number = 0;
  private startScrollLeft: number = 0;

  constructor(private elementRef: ElementRef) { }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (this.permissions) {
      event.preventDefault();
      this.isDragging = true;
      this.mouseDownX = event.clientX;
      this.startScrollLeft = this.elementRef.nativeElement.scrollLeft;
    }
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.isDragging = false;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      const delta = (event as MouseEvent)?.clientX - this.mouseDownX;
      const isLeft = (event as MouseEvent)?.clientX < this.elementRef.nativeElement.offsetLeft;

      if (isLeft) {
        this.elementRef.nativeElement.scrollLeft = this.startScrollLeft + delta;
      } else {
        this.elementRef.nativeElement.scrollLeft = this.startScrollLeft - delta;
      }
    }
  }
}
