import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {AutoRegUpdateSelfieType} from "@/main/autoreg/accounts/store/type/autoRegUpdateSelfie.type";

export const autoRegUpdateSelfieAction = createAction(
  ActionType.AUTO_REG_UPDATE_SELFIE
)

export const autoRegUpdateSelfieActionSuccess = createAction(
  ActionType.AUTO_REG_UPDATE_SELFIE_SUCCESS,
  props<{response: DefaultResponseType, account: AutoRegUpdateSelfieType}>()
)

export const autoRegUpdateSelfieActionFailure = createAction(
  ActionType.AUTO_REG_UPDATE_SELFIE_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegUpdateSelfieActionError = createAction(
  ActionType.AUTO_REG_UPDATE_SELFIE_ERROR,
  props<{error: HttpErrorResponse}>()
)
