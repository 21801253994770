import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/registration/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {RegerType} from "@/main/autoreg/registration/type/reger.type";


export const updateRegerAction = createAction(
  ActionType.UPDATE_REGER,
  props<{reger: RegerType}>()
)

export const updateRegerActionSuccess = createAction(
  ActionType.UPDATE_REGER_SUCCESS,
  props<{response: DefaultResponseType, data: RegerType}>()
)

export const updateRegerActionFailure = createAction(
  ActionType.UPDATE_REGER_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const updateRegerActionError = createAction(
  ActionType.UPDATE_REGER_ERROR,
  props<{error: HttpErrorResponse}>()
)
