<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <div class="d-flex">

<!--          <action-button-->
<!--          [icon]="'fa-solid fa-house'"-->
<!--          ></action-button>-->

          <button class="farm-button button"
                  *ngxPermissionsOnly="['ADMIN']"
                  (click)="openNewFarm()"
          >
            <i class="fa-solid fa-user-plus"></i>
          </button>
          <button class="scenum-button button"
                  [disabled]="integrationToScenum$ | async"
                  [class.disabled]="integrationToScenum$ | async"
                  *ngxPermissionsOnly="['ADMIN']"
                  (click)="integrationProcess()"
          >
            <i class="fa-solid fa-link"></i>
          </button>
          <button class="sync-button button"
                  *ngxPermissionsOnly="['ADMIN']"
                  (click)="syncProcess()"
          >
            <i class="fa-solid fa-group-arrows-rotate"></i>
          </button>
        </div>
        <button class="update-button button"
                (click)="update()"
                [disabled]="addToProcess$ | async"
        >
          <span class="icon download-data"
          [class.download-data]="addToProcess$ | async"
          >
            <i class="fa-solid fa-arrows-rotate"></i>
          </span>
        </button>
      </div>
      <div class="card-body p-3">
        <table class="table table-sm table-one">
          <thead>
          <tr>
            <th style="width: 10px">#</th>
            <th>Название</th>
            <th class="state">Состояние</th>
            <th>Пользователь</th>
            <th>Порт</th>
            <th>Статус</th>
            <th>Дата начала</th>
            <th>Дата окончания</th>
            <th>Кол-во итераций</th>
            <th>Текущая итерация</th>
            <th>Устройство</th>
            <th>Действия</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngIf="(autoRegProcessItems$ | async)">
            <tr *ngFor="let item of autoRegProcessItems$ | async; let i = index">
              <td>{{i + 1}}</td>
              <td>{{item.name}}</td>
              <td class="state"
                  [class.blink]="item.state === 'Приостановлен'"
                  [class.warning]="item.state === 'Остановлен системой'"
              >{{item.state}}</td>
              <td>
                <span *ngIf="users$ | async as users">
                  {{item.owner | setUser:users}}
                </span>
              </td>
              <td>{{item.ap_port}}</td>
              <td class="status">
                  <span
                    [class.red]="item.status === 'Регистрация'
                  || item.status === 'Заполнение профиля'
                  || item.status === 'Фарм (1)'
                  || item.status === 'Фарм (2)'
                  || item.status === 'Фарм (3)'"
                    [class.yellow]="item.status === 'Фарм (4)'
                  || item.status === 'Фарм (5)'"
                    [class.green]="item.status === 'Фарм (6)'"
                    [class.blue]="item.status === 'Обновление Cookie'"
                  >{{item.status}}</span>
              </td>
              <td>{{item.date_start | date: 'dd.MM.yyyy'}}</td>
              <td>{{item.date_end | date: 'dd.MM.yyyy'}}</td>
              <td>{{item.iterations}}</td>
              <td>{{item.current_iter ? item.current_iter : '-'}}</td>
              <td>{{item.device_name ? item.device_name : '-'}}</td>
              <td class="process-actions">
                  <span class="terminate-process">
                    <i class="fa-solid fa-xmark text-danger mx-2"
                       (click)="terminateProcess(item.pid, item.name)"></i>
                  </span>
                <span class="play-process" *ngIf="item.state === 'Приостановлен'">
                    <i class="fa-solid fa-play mx-2 text-success"
                       (click)="playProcess(item.pid)"></i>
                  </span>
                <span class="stop-process" *ngIf="item.state === 'Остановлен системой'">
                    <i class="fa-solid fa-stop mx-2 text-warning"></i>
                  </span>
                <span class="pause-process" *ngIf="item.state === 'Запущен'">
                    <i class="fa-solid fa-pause mx-2"
                       (click)="pauseProcess(item.pid)"></i>
                  </span>
              </td>
            </tr>
          </ng-container>

          <tr *ngIf="(autoRegProcessItems$ | async) !== null && (autoRegProcessItems$ | async).length === 0">
            <td colspan="12" class="no-processed">Нет активных процессов</td>
          </tr>

          <tr *ngIf="(autoRegProcessItems$ | async) === null">
            <td colspan="12" class="no-processed">Ошибка при получении списка процессов</td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #newFarm>
  <app-new-farm></app-new-farm>
</ng-template>

<ng-template #integrationToScenum>
  <app-integration-scenum-process></app-integration-scenum-process>
</ng-template>

<ng-template #syncToScenum>
  <app-sync-scenum-process></app-sync-scenum-process>
</ng-template>

<ng-template #popup>
  <app-popup
    [type]="typePopUp"
    [nameProcess]="nameProcess"
    (dataPopUp)="popUpActions($event)">
  </app-popup>
</ng-template>
