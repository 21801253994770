import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {dataForLinearChartsAutoReg} from "@/main/autoreg/accounts/store/selectors";
import {getStatisticsAction} from "@/main/autoreg/stats/store/actions/getStatistics.action";
import {intervalStatisticsSelector} from "@/main/autoreg/stats/store/selectors";
import {CurrentIntervalType} from "@/main/autoreg/stats/store/type/currentInterval.type";
import {OptionsInterval} from "../../../../../../constants/options-interval";
import {GetStatisticsAllAction} from "@/main/autoreg/stats/store/actions/getStatissticsAll.action";
import {GetStatisticsTodayAction} from "@/main/autoreg/stats/store/actions/getStatisticsToday.action";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnDestroy {

  @Input() typeCharts: string;
  public options = OptionsInterval;
  public rangeForm: FormGroup;

  public data$: Observable<CurrentIntervalType>;
  public maxMinDate$: Observable<any>;
  public currentInterval$: Observable<CurrentIntervalType>

  private subscriptionList: Subscription[] = [];
  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }
  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
    this.sub = this.data$.subscribe(data => {
        this.initForm(data.start, data.end);
    })

  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe())
  }

  public selectIntervalDate(interval: number): void {

    if (interval === 0) {
      this.store.dispatch(GetStatisticsAllAction({interval: interval, firstRequest: false}))
    } else if (interval === 1) {
      this.store.dispatch(GetStatisticsTodayAction({interval: interval}))
    } else if (interval !== 1 && interval !== 0) {
      this.store.dispatch(getStatisticsAction({dateEnd: new Date(), intervalValue: interval}))
    }
  }

  public updateDate(): void {
    this.store.dispatch(getStatisticsAction({dateEnd: this.rangeForm.value.end, dateStart: this.rangeForm.value.start}))
  }

  private initValue(): void {
    this.data$ = this.store.pipe(select(intervalStatisticsSelector));
    this.maxMinDate$ = this.store.pipe(select(dataForLinearChartsAutoReg));
    this.currentInterval$ = this.store.pipe(select(intervalStatisticsSelector))
  }

  private initForm(start, end): void {
  this.rangeForm = new FormGroup({
      start: new FormControl<Date | null>(start),
      end: new FormControl<Date | null>(end),
    });
  }
}
