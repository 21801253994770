import {createAction, props} from "@ngrx/store";
import {AutoRegAddFbToolsType} from "@/main/autoreg/accounts/store/type/autoRegAddFbTools.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";

export const autoRegFbAddGroupAction = createAction(
  ActionType.AUTO_REG_FB_ADD_GROUP,
  props<{request: AutoRegAddFbToolsType[]}>()
)

export const autoRegFbAddGroupActionSuccess = createAction(
  ActionType.AUTO_REG_FB_ADD_GROUP_SUCCESS,
  props<{response: DefaultResponseType, data: AutoRegAddFbToolsType[]}>()
)

export const autoRegFbAddGroupActionFailure = createAction(
  ActionType.AUTO_REG_FB_ADD_GROUP_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegFbAddGroupAddActionError = createAction(
  ActionType.AUTO_REG_FB_ADD_GROUP_ERROR,
  props<{error: HttpErrorResponse}>()
)
