import {createAction, props} from "@ngrx/store";
import {ActionsType} from "@/main/autoreg/stats/store/actions.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const getStatisticsAction = createAction(
  ActionsType.GET_STATISTICS,
  props<{dateEnd: Date, dateStart?: Date, intervalValue?: number}>()
)

export const getStatisticsActionSuccess = createAction(
  ActionsType.GET_STATISTICS_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const getStatisticsActionFailure = createAction(
  ActionsType.GET_STATISTICS_FAILURE,
  props<{error: HttpErrorResponse}>()
)
