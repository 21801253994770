import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {
  registerAction,
  registerFailureAction,
  registerSuccessAction
} from "@/auth/store/actions/register.action";
import {catchError, map, switchMap, of, tap} from "rxjs";
import {AuthService} from "@/auth/services/auth.service";
import {ResponseSignupType} from "@/auth/store/type/signup.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {Store} from "@ngrx/store";
import {GetUsersAction} from "@/users/store/actions/getUsers.action";
import {PopUpAction} from "@/users/store/actions/popUp.action";

@Injectable()
export class RegisterEffect {
  registerEffect$ = createEffect(() => this.actions$.pipe(
    ofType(registerAction),
    switchMap(({request}) => {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      return this.authService.register(request).pipe(
        map((user: ResponseSignupType) => {
          this.store.dispatch(ButtonLoaderAction({load: false}))
          this.store.dispatch(PopUpAction({statePopUp: false}))
          this.store.dispatch(GetUsersAction())
          return registerSuccessAction({user})
        }),
        catchError((error: HttpErrorResponse) => {
          this.store.dispatch(ButtonLoaderAction({load: false}))
          return of(registerFailureAction({ error }));
      })
      );
    })
  ))

  registerSuccessEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(registerSuccessAction),
      tap(({user}) => {
        this.toaStr.success('Пользователь ' + user.username + 'зарегистрирован')
      })
    ), {dispatch: false}
  )

  registerFailureEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(registerFailureAction),
      tap(({error}) => {
        this.toaStr.error('Не удалось добавить нового пользователя')
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private authService: AuthService,
              private toaStr: ToastrService,
              private store: Store) {
  }
}
