import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";
import {select, Store} from "@ngrx/store";
import {autoRegFilterSelector, autoRegGroupSelector} from "@/main/autoreg/accounts/store/selectors";
import {autoregFilterGroup} from "@/main/autoreg/accounts/store/actions/autoRegAccSort.action";
import {AutoRegFilterType} from "@/main/autoreg/accounts/store/type/AutoRegFilter.type";

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent implements OnInit {

  public filterValue$: Observable<AutoRegFilterType>;
  public autoregGroup$: Observable<AutoRegGroupType[]>;

  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initValue();
  }

  public filterGroup(item: AutoRegGroupType) {
    this.store.dispatch(autoregFilterGroup({group: item.id}))
  }

  private initValue(): void {
    this.filterValue$ = this.store.pipe(select(autoRegFilterSelector));
    this.autoregGroup$ = this.store.pipe(select(autoRegGroupSelector));
  }
}
