import {createAction, props} from "@ngrx/store";
import {ActionsType} from "@/main/cart/store/actions.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const getCartDataAction = createAction(
  ActionsType.GET_CART_DATA
)

export const getCartDataSuccessAction = createAction(
  ActionsType.GET_CART_DATA_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const getCartDataFailureAction = createAction(
  ActionsType.GET_CART_DATA_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const getCartDataErrorAction = createAction(
  ActionsType.GET_CART_DATA_ERROR,
  props<{error: HttpErrorResponse}>()
)
