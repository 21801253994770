import {sortDate} from "@/main/autoreg/utils/sortDate.util";
import {DateFormatToViewUtil} from "@/main/autoreg/stats/utils/dateFormatToView.util";

export function GetDataLinealChartsUtil(defaultData) {
  let data = sortDate(defaultData, defaultData, 'dateOld', false);
  data = data.map(item => {
    return {...item, date: DateFormatToViewUtil(item.date)}
  })

  let linealData = {
    date: [],
    count: []
  };

  data.forEach(item => {
    linealData.date.push(item.date)
    linealData.count.push(item.count)
  })

  return linealData;
}
