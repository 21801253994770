import {Directive, ElementRef, HostListener, OnInit} from "@angular/core";
import {ToastrService} from "ngx-toastr";

@Directive({
  selector: '[appClipboardInput]'
})

export class ClipboardInputDirective implements OnInit {
  constructor(private toaStr: ToastrService,
              private el: ElementRef) {
  }

  public ngOnInit(): void {
    this.el.nativeElement.style.cursor = 'pointer'
  }

  @HostListener('dblclick', ['$event.target'])
  onClick(element: HTMLInputElement): void {
    element.select();
    document.execCommand('copy');
    this.toaStr.success('Содержимое поля скопировано в буфер обмена')
  }
}
