import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {AutoRegUpdateSelfieType} from "@/main/autoreg/accounts/store/type/autoRegUpdateSelfie.type";
import {AutoRegDeletedRequestType} from "@/main/autoreg/accounts/store/type/autoRegDeletedRequest.type";
import {CreateGroupRequestType} from "@/main/autoreg/accounts/store/type/createGroupRequest.type";
import {AddToGroupRequestType} from "@/main/autoreg/accounts/store/type/addToGroupRequest.type";
import {
  UpdateStateRequestType
} from "@/main/autoreg/accounts/store/type/requestSetOwnerSystem.type";

@Injectable()
export class AutoRegService {
  constructor(private http: HttpClient) {
  }

  public getAutoRegData(): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + 'autoreg', {withCredentials: true})
  }

  public accDeleted(request: AutoRegDeletedRequestType): Observable<DefaultResponseType> {
    return this.http.delete<DefaultResponseType>(environment.api + 'autoreg', {withCredentials: true, body: request});
  }

  public updateAutoReg(autoRegItem: AutoRegItemType): Observable<DefaultResponseType> {
    if (autoRegItem) {
      return this.http.patch<DefaultResponseType>(environment.api + 'autoreg', autoRegItem, {withCredentials: true});
    }
  }

  public updateSelfie(request: AutoRegUpdateSelfieType): Observable<DefaultResponseType> {
    if (request) {
      return this.http.patch<DefaultResponseType>(environment.api + 'system/autoreg/state', request,
        {
          withCredentials: true,
          headers: {
            authorization: 'vBNk7u5ih5ZegpFmYSGkQlDL'
          }
        });
    }
  }

  public getGroup(): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + 'autoreg/group', {withCredentials: true})
  }

  public deletedGroup(group_id: number): Observable<DefaultResponseType> {
    return this.http.delete<DefaultResponseType>(environment.api + 'autoreg/group', {
      withCredentials: true,
      body: { id: group_id }
    })
  }

  public createGroup(request: CreateGroupRequestType): Observable<DefaultResponseType> {
    return this.http.post<DefaultResponseType>(environment.api + 'autoreg/group', request, {withCredentials: true})
  }

  public addToGroup(request: AddToGroupRequestType): Observable<DefaultResponseType> {
    return this.http.put<DefaultResponseType>(environment.api + 'autoreg/group', request, {withCredentials: true})
  }

  public setOwner(request: {user_id: number, logins: string[]}): Observable<DefaultResponseType> {
    return this.http.put<DefaultResponseType>(environment.api + 'autoreg/owner', request, {withCredentials: true})
  }

  public setOwnerSystem(request: UpdateStateRequestType): Observable<DefaultResponseType> {
    return this.http.patch<DefaultResponseType>(environment.api + 'autoreg/states', request,
      {
        withCredentials: true,
        headers: {
          authorization: 'vBNk7u5ih5ZegpFmYSGkQlDL'
        }
      })
  }
}
