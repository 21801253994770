import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {CreateGroupRequestType} from "@/main/autoreg/accounts/store/type/createGroupRequest.type";

export const autoRegCreateGroupAction = createAction(
  ActionType.AUTO_REG_CREATE_GROUP,
  props<{request: CreateGroupRequestType}>()
)

export const autoRegCreateGroupActionSuccess = createAction(
  ActionType.AUTO_REG_CREATE_GROUP_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const autoRegCreateGroupActionFailure = createAction(
  ActionType.AUTO_REG_CREATE_GROUP_FAILURE,
  props<{error: HttpErrorResponse}>()
)
