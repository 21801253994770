export enum ContextMenuEventType {
  OPEN_FB_TOOLS = 'Open-fb-tools',
  DELETED = 'Deleted',
  OPEN_ACC = 'Open-acc',
  OPEN_CART_ACC = 'OPEN_CART_ACC',
  RESTORE_ACC = 'RESTORE_ACC',
  CREATE_ACC_AP = 'Create-acc',
  RE_LOGIN_ACC = 'Re-login',
  CHECKED = 'Checked',
  CHECK_ACC = 'Check_acc',
  ADD_TO_GROUP = 'ADD_TO_GROUP',
  CHANGE_AUTO_REG_STATE_ITEM = 'CHANGE_AUTO_REG_STATE_ITEM',
  SET_OWNER = 'SET_OWNER',
  DELETE_OWNER = 'DELETE_OWNER',

  ACTIVATED_USER = 'ACTIVATED_USER',
  BLOCKED_USER = 'BLOCKED_USER',
  REDACTION_USER = 'REDACTION_USER',
  DELETED_USER = 'DELETED_USER',

  UPDATE_STATUS_EMAIL = 'UPDATE_STATUS_EMAIL'
}
