import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";


export const autoRegFiltersAction = createAction(
  ActionType.AUTO_REG_FILTERS
)

export const autoRegFiltersDateInterval = createAction(
  ActionType.AUTO_REG_FILTERS_DATE_INTERVAL,
  props<{start: string, end: string}>()
)
