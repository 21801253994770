import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  public getUsers(): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + 'users', {withCredentials: true})
  }

  public getUser(id): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + `users/special?user_id=${id}`, {withCredentials: true})
  }

  public deleteUsers(id): Observable<DefaultResponseType> {
    return this.http.delete<DefaultResponseType>(environment.api + 'users',
      {withCredentials: true, body: { id: id } });
  }

  public updateUser(request): Observable<DefaultResponseType> {
    return this.http.patch<DefaultResponseType>(environment.api + 'users', request, {withCredentials: true})
  }

  public activateUser(id): Observable<DefaultResponseType> {
    let body = {id: id};
    return this.http.patch<DefaultResponseType>(environment.api + `users/activate`, body, {withCredentials: true})
  }

  public getMe(): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + 'users/me', {withCredentials: true})
  }
}
