import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {LinealStatType} from "@/main/autoreg/stats/store/type/linealStat.type";
import {statisticsLinealSelector} from "@/main/autoreg/stats/store/selectors";

@Component({
  selector: 'app-register-of-time-count',
  templateUrl: './register-of-time-count.component.html',
  styleUrls: ['./register-of-time-count.component.scss']
})
export class RegisterOfTimeCountComponent implements OnInit, OnDestroy {

  @Input() type: string;

  public dataForCharts;
  public data$: Observable<LinealStatType>;

  private subscriptionList: Subscription[] = [];
  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
    this.sub = this.data$.subscribe(data => {
      if (data !== null && data.count !== undefined && data.date !== undefined) {
        this.initCharts(data.count, data.date);
      }
    })
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe())
  }

  private initCharts(count, date): void {
    this.dataForCharts = {
      barChartOptions: {
        responsive: true
      },
      barChartLabels: date,
      barChartType: 'bar',
      barChartLegend: true,
      barChartPlugins: [],
      barChartData: [
        {data: count, label: 'Количество зарегистрированных аккаунтов'}
      ]
    }
  }

  private initValue(): void {
    this.data$ = this.store.pipe(select(statisticsLinealSelector));
  }
}
