export type MenuSidebarType = {
  name: string,
  iconClasses: string,
  path?: string[],
  children?: MenuSidebarType
}[]

export const MENU: MenuSidebarType = [
  {
    name: 'Главная',
    iconClasses: 'nav-icon fas fa-house',
    path: ['/']
  },
  {
    name: 'Регистрация аккаунтов',
    iconClasses: 'fas fa-folder',
    children: [
      {
        name: 'Аккаунты',
        iconClasses: 'fas fa-arrow-right',
        path: ['autoreg/accounts']
      },
      {
        name: 'Процессы',
        iconClasses: 'fas fa-arrow-right',
        path: ['autoreg/registration']
      },
      {
        name: 'Статистика',
        iconClasses: 'fas fa-arrow-right',
        path: ['autoreg/stats']
      }
    ]
  },
  {
    name: 'Почты',
    iconClasses: 'fa-solid fa-envelope',
    path: ['/emails']
  },

];

