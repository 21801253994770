import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {AuthService} from "@/auth/services/auth.service";
import {logoutAction, logoutFailureAction, logoutSuccessAction} from "@/auth/store/actions/logout.action";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {Store} from "@ngrx/store";


@Injectable()
export class LogoutEffect {
  logoutEffect$ = createEffect(() => this.actions$.pipe(
    ofType(logoutAction),

    switchMap(() => {
      return this.authService.logout().pipe(
        map((status: null) => {
          this.store.dispatch(ButtonLoaderAction({load: true}))
          this.authService.removeCookie('bonds')
          return logoutSuccessAction(status)
        }),
        catchError((error: HttpErrorResponse) => {
          return of(logoutFailureAction({ error }));
        })
      );
    })
  ))

  redirectAfterSubmitEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(logoutSuccessAction),
      tap(() => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        this.authService.removeCookie('bonds')
        this.router.navigateByUrl('/login');
        this.toaStr.success('Вы вышли из системы')

      }),
    ), {dispatch: false}
  )

  logOutFailureEffect$ = createEffect(
    () => this.actions$.pipe(
      ofType(logoutFailureAction),
      tap(() => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        this.authService.removeCookie('bonds')
        this.router.navigateByUrl('/login');
        this.toaStr.success('Вы вышли из системы')
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private authService: AuthService,
              private router: Router,
              private toaStr: ToastrService,
              private store: Store) {
  }
}
