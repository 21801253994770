import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {catchError, map, mergeMap, of, tap, withLatestFrom} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {
  autoRegCreateProfileAPAction, autoRegCreateProfileAPActionError, autoRegCreateProfileAPActionFailure,
  autoRegCreateProfileAPActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegCreateProfileAP.action";
import {HttpErrorResponse} from "@angular/common/http";
import {accIsCreateAPDataSelector} from "@/main/autoreg/accounts/store/selectors";
import {createAppType, RequestCreateAPType} from "@/main/autoreg/accounts/store/type/requestCreateAP.type";
import {ProcessService} from "@/main/autoreg/services/process.service";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";

@Injectable()
export class AutoRegCreateAPEffect {

  autoRegCreateAPEffect$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegCreateProfileAPAction),
    withLatestFrom(this.store.pipe(select(accIsCreateAPDataSelector))),
    mergeMap(([action, select]) => {
      let dataOfRequest: RequestCreateAPType = {
        name: action.data.name,
        accounts: [],
      }
      select.forEach(item => {
        let acc:  createAppType = {
          name: item.name,
          surname: item.surname,
          bdate: item.str_birthday,
          ap_port: action.data.ap_port === 0 ? item.ap_port : action.data.ap_port,
          email_pass: item.email_pass,
          login: item.login,
          email: item.email,
          password: item.password,
          twofa: item.twofa,
          device_name: action.data.device_name.toString()
        }
        dataOfRequest.accounts.push(acc)
      });

      return this.processService.createProcessAP(dataOfRequest).pipe(
        map((response: DefaultResponseType) => {
          let data = dataOfRequest;
          if (response.status === 'Success') {
            return autoRegCreateProfileAPActionSuccess({response, data})
          } else if (response.status === 'Failure') {
            return autoRegCreateProfileAPActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegCreateProfileAPActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegCreateProfileAPActionError({error}));
          } else {
            return of(autoRegCreateProfileAPActionError({error}));
          }
        })
      );
    })
  ))

  autoRegCreateAPEffectSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegCreateProfileAPActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.success(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegCreateAPEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegCreateProfileAPActionFailure),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.error('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegCreateAPEffectError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegCreateProfileAPActionError),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  constructor(
    private actions$: Actions,
    private processService: ProcessService,
    private store: Store,
    private toaStr: ToastrService
  ) {}
}
