<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Главная</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Главная</a></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
    </div>
  </div>
</div>


<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 col-6">
        <app-register-of-time-count [type]="'lite'"></app-register-of-time-count>
      </div>
      <div class="col-lg-6 col-6">
        <div class="row">
          <div class="col-6">
            <app-box-stat [typeBox]="boxType.COUNT"></app-box-stat>
          </div>
          <div class="col-6">
            <app-box-stat [typeBox]="boxType.READY"></app-box-stat>
          </div>
          <div class="col-6">
            <app-box-stat [typeBox]="boxType.CHECK"></app-box-stat>
          </div>
          <div class="col-6">
            <app-box-stat [typeBox]="boxType.REGISTERED_TODAY"></app-box-stat>
          </div>
          <div class="col-6">
            <app-box-stat [typeBox]="boxType.BLOCKED"></app-box-stat>
          </div>
        </div>
      </div>
    </div>
    <div class="row">

    </div>
  </div>
</section>

