import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {RegerService} from "@/main/autoreg/services/reger.service";
import {
  getConsumablesAction, getConsumablesActionError, getConsumablesActionFailure,
  getConsumablesActionSuccess
} from "@/main/autoreg/registration/store/actions/getConsumables.action";

@Injectable()
export class GetConsumablesEffect {
  getConsumablesEffect$ = createEffect(() => this.actions$.pipe(
    ofType(getConsumablesAction),
    switchMap(() => {
      return this.regService.getConsumables().pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return getConsumablesActionSuccess({response})
          } else if (response.status === 'Failure') {
            return getConsumablesActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400 || 422) {
            return of(getConsumablesActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(getConsumablesActionError({error}));
          } else {
            return of(getConsumablesActionError({error}));
          }
        })
      );
    })
  ))


  getConsumablesEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(getConsumablesActionFailure),
      tap((response: {response: DefaultResponseType}): void => {
        console.error(`Данные не получены. Ошибка: ${response.response.details.msg}`)
      })
    ), {dispatch: false}
  )

  getConsumablesEffectError$ = createEffect(
    () => this.actions$.pipe(
      ofType(getConsumablesActionError),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          console.error(`Данные не получены. Ошибка`)
        } else if (error.status === 500 || 501) {
          console.error(`Данные не получены. Ошибка сервера`)
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private regService: RegerService
  ) {
  }
}
