import { Component } from '@angular/core';

@Component({
  selector: 'app-trafic',
  templateUrl: './trafic.component.html',
  styleUrls: ['./trafic.component.scss']
})
export class TraficComponent {

}
