<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12" *ngxPermissionsOnly="['ADMIN', 'WEBMASTER']">
        <app-registration-process></app-registration-process>
      </div>
      <div class="col-12">
        <app-auto-reg-process></app-auto-reg-process>
      </div>
    </div>
  </div>
</section>



