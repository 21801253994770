import {createAction, props} from "@ngrx/store";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {
  AutoRegReLoginRequestGroupType,
  AutoRegReLoginRequestType
} from "@/main/autoreg/accounts/store/type/autoRegReloginRequest.type";

export const autoRegReLoginAction = createAction(
  ActionType.AUTO_REG_ITEM_RE_LOGIN,
  props<{request: AutoRegReLoginRequestType | AutoRegReLoginRequestGroupType, typeRequest: 'one' | 'group'}>()
)

export const autoRegReLoginActionSuccess = createAction(
  ActionType.AUTO_REG_ITEM_RE_LOGIN_SUCCESS,
  props<{response: DefaultResponseType, request: AutoRegReLoginRequestType}>()
)

export const autoRegReLoginActionFailure = createAction(
  ActionType.AUTO_REG_ITEM_RE_LOGIN_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegReLoginActionError = createAction(
  ActionType.AUTO_REG_ITEM_RE_LOGIN_ERROR,
  props<{error: HttpErrorResponse}>()
)
