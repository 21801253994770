<div class="row d-flex justify-content-between py-3 w-100">
  <button class="btn-action new-user"
          (click)="registration()"
  >
    <i class="fa-solid fa-id-badge"></i>
  </button>

  <button class="btn-action update-button"
          (click)="updateList()"
  >
    <i class="fa-solid fa-repeat"></i>
  </button>
</div>

<table class="table table-hover text-nowrap">
  <thead>
  <tr>
    <th class="id">ID</th>
    <th>Пользователь</th>
    <th>Почта</th>
    <th>Роль</th>
    <th>Порт</th>
    <th>API ключ</th>
    <th>Активный</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let user of users$ | async"
      (contextmenu)="onRightClick($event, user)"
  >
    <td class="id">{{user.id}}</td>
    <td><span appClipboard>{{user.username}}</span></td>
    <td><span appClipboard>{{user.email}}</span></td>
    <td><span appClipboard>{{user.role | setRole}}</span></td>
    <td><span appClipboard>{{user.ap_port}}</span></td>
    <td><span appClipboard>{{user.fbtool_apikey}}</span></td>
    <td><span appClipboard>{{user.is_active | setStatus}}</span></td>
  </tr>
  </tbody>
</table>

<ng-template #popup>
  <app-popup
    [type]="type"
    [user]="nameUser"
    (dataPopUp)="popUpActions($event)"
  ></app-popup>
</ng-template>

<ng-template #redactionUser>
  <app-redaction-auth
    [user]="user"
    (dataPopUp)="popUpActions($event)"
  ></app-redaction-auth>
</ng-template>

<ng-template #registrationComponent>
  <app-registration>
  </app-registration>
</ng-template>

<app-context-menu-users
  [contextMenuStyle]="contextMenuStyle"
  [user]="user"
  (contextMenuEvent)="contextMenuEvent($event)"
>
</app-context-menu-users>
