<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <mat-tab-group animationDuration="1500ms">
              <mat-tab label="Общие данные">
                <div class="row d-flex mt-3">
                  <div class="col-md-3 col-sm-6 col-3">
                    <app-box-stat [typeBox]="boxType.COUNT"></app-box-stat>
                  </div>
                  <div class="col-md-3 col-sm-6 col-3">
                    <app-box-stat [typeBox]="boxType.CHECKPOINT"></app-box-stat>
                  </div>
                  <div class="col-md-3 col-sm-6 col-3">
                    <app-box-stat [typeBox]="boxType.CHECK"></app-box-stat>
                  </div>
                  <div class="col-md-3 col-sm-6 col-3">
                    <app-box-stat [typeBox]="boxType.REGISTERED_TODAY"></app-box-stat>
                  </div>
                  <div class="col-md-3 col-sm-6 col-3">
                    <app-box-stat [typeBox]="boxType.BLOCKED"></app-box-stat>
                  </div>
                  <div class="col-md-3 col-sm-6 col-3">
                    <app-box-stat [typeBox]="boxType.WITH_AP"></app-box-stat>
                  </div>
                  <div class="col-md-3 col-sm-6 col-3">
                    <app-box-stat [typeBox]="boxType.NO_TWOFA"></app-box-stat>
                  </div>
                  <div class="col-md-3 col-sm-6 col-3">
                    <app-box-stat [typeBox]="boxType.COOKIE_TOKEN"></app-box-stat>
                  </div>
                  <div class="col-md-3 col-sm-6 col-3">
                    <app-box-stat [typeBox]="boxType.NO_OWNER"></app-box-stat>
                  </div>
                  <div class="col-md-3 col-sm-6 col-3">
                    <app-box-stat [typeBox]="boxType.READY"></app-box-stat>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Динамика">
                <div class="row d-flex justify-content-center mt-3">
                  <div class="col-11">
                    <app-register-of-time-data></app-register-of-time-data>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Количество">
                <div class="row d-flex justify-content-center mt-3">
                  <div class="col-11">
                    <app-register-of-time-count></app-register-of-time-count>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Этап/Состояние">
                <div class="row d-flex justify-content-center mt-3">
                  <div class="col-6">
                    <app-step-register-acc></app-step-register-acc>
                  </div>
                  <div class="col-6">
                    <app-state-register-acc></app-state-register-acc>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>




