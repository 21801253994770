<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Профиль</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Главная</a></li>
          <li class="breadcrumb-item active">Профиль пользователя</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <div class="card card-primary card-outline">
          <div class="card-body box-profile">

            <h3 class="profile-username text-center">
              {{(user$ | async)?.username}}
            </h3>

            <p class="text-muted text-center">{{(user$ | async)?.role | setRole}}</p>

            <ul class="list-group list-group-unbordered mb-3">
              <li class="list-group-item">
                <b>Авторегов используется</b>
                <ng-container *ngIf="user$ | async as user">
                  <a class="float-right">{{(autoRegs$ | async | getAccUse:user.id)?.length}}</a>
                </ng-container>
              </li>
              <li class="list-group-item">
                <b>Порт AP</b> <a class="float-right">{{(user$ | async)?.ap_port}}</a>
              </li>
              <li class="list-group-item">
                <b>АPI ключ</b> <a class="float-right">{{(user$ | async)?.fbtool_apikey}}</a>
              </li>
            </ul>

            <button mat-raised-button color="primary" class="w-100"
                    (click)="redactionProfile()"
            >Редактировать
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <app-use-autoregs></app-use-autoregs>
      </div>
    </div>
  </div>
</section>

<ng-template #redactionUser>
  <ng-container *ngIf="user$ | async as user">
    <app-redaction-auth
      [user]="user"
      (dataPopUp)="popUpActions($event)"
    ></app-redaction-auth>
  </ng-container>
</ng-template>
