export enum ActionType {

  //для данных первой таблицы
  AUTO_REG_PROCESS_ITEMS = '[AutoReg] Auto-reg-process-items',
  AUTO_REG_PROCESS_ITEMS_SUCCESS = '[AutoReg] Auto-reg-process-items-success',
  AUTO_REG_PROCESS_ITEMS_FAILURE = '[AutoReg] Auto-reg-process-items-failure',
  AUTO_REG_PROCESS_ITEMS_ERROR = '[AutoReg] Auto-reg-process-items-error',

  //для удаления процесса
  AUTO_REG_TERMINATE = '[AutoReg] Terminate',
  AUTO_REG_DELETED = '[AutoReg] Deleted',
  AUTO_REG_TERMINATE_SUCCESS = '[AutoReg] Terminate-success',
  AUTO_REG_TERMINATE_FAILURE = '[AutoReg] Terminate-failure',
  AUTO_REG_TERMINATE_ERROR = '[AutoReg] Terminate-error',

  //остановка процесса на паузу
  AUTO_REG_PROCESS_PAUSE = '[AutoReg] Pause',
  AUTO_REG_PROCESS_PAUSE_SUCCESS = '[AutoReg] Pause-success',
  AUTO_REG_PROCESS_PAUSE_FAILURE  = '[AutoReg] Pause-failure',
  AUTO_REG_PROCESS_PAUSE_ERROR  = '[AutoReg] Pause-error',

  //продолжение процесса
  AUTO_REG_PROCESS_PLAY = '[AutoReg] Play',
  AUTO_REG_PROCESS_PLAY_SUCCESS = '[AutoReg] Play-success',
  AUTO_REG_PROCESS_PLAY_FAILURE  = '[AutoReg] Play-failure',
  AUTO_REG_PROCESS_PLAY_ERROR  = '[AutoReg] Play-error',

  AUTO_REG_ADD_FARM = '[AutoReg] Add-farm',
  AUTO_REG_ADD_FARM_SUCCESS = '[AutoReg] Add-farm-success',
  AUTO_REG_ADD_FARM_FAILURE  = '[AutoReg] Add-farm-failure',
  AUTO_REG_ADD_FARM_ERROR  = '[AutoReg] Add-farm-error',

  AUTO_REG_INTEGRATION_TO_SCENUM = '[AutoReg] Intergration-to-scenum',
  AUTO_REG_INTEGRATION_TO_SCENUM_SUCCESS = '[AutoReg] Intergration-to-scenum-success',
  AUTO_REG_INTEGRATION_TO_SCENUM_FAILURE  = '[AutoReg] Intergration-to-scenum-failure',
  AUTO_REG_INTEGRATION_TO_SCENUM_ERROR  = '[AutoReg] Intergration-to-scenum-error',

  AUTO_REG_SYNC_TO_SCENUM = '[AutoReg] Sync-to-scenum',
  AUTO_REG_SYNC_TO_SCENUM_SUCCESS = '[AutoReg] Sync-to-scenum-success',
  AUTO_REG_SYNC_TO_SCENUM_FAILURE  = '[AutoReg] Sync-to-scenum-failure',
  AUTO_REG_SYNC_TO_SCENUM_ERROR  = '[AutoReg] Sync-to-scenum-error',

  //закрытие модального окна
  CLOSE_POP_UP = '[AutoReg] Close pop-up',

  GET_REGER = '[AutoReg] Get-reger',
  GET_REGER_SUCCESS = '[AutoReg] Get-reger-success',
  GET_REGER_FAILURE = '[AutoReg] Get-reger-failure',
  GET_REGER_ERROR = '[AutoReg] Get-reger-error',

  UPDATE_REGER = '[AutoReg] Update-reger',
  UPDATE_REGER_SUCCESS = '[AutoReg] Update-reger-success',
  UPDATE_REGER_FAILURE = '[AutoReg] Update-reger-failure',
  UPDATE_REGER_ERROR = '[AutoReg] Update-reger-error',

  GET_CONSUMABLES = '[AutoReg] Get-consumables',
  GET_CONSUMABLES_SUCCESS = '[AutoReg] Get-consumables-success',
  GET_CONSUMABLES_FAILURE = '[AutoReg] Get-consumables-failure',
  GET_CONSUMABLES_ERROR = '[AutoReg] Get-consumables-error',

  UPDATE_BUTTON_PROCESS = '[AutoReg] Update-button-process',

  GET_AUTOREGS = '[AutoReg] Get-autoreg-to-process',

  GET_GROUP_INFO = '[AutoReg]',

  DOWNLOAD_EMAILS = '[AutoReg] Download-emails',
  DOWNLOAD_EMAILS_SUCCESS = '[AutoReg] Download-emails-success',
  DOWNLOAD_EMAILS_FAILURE = '[AutoReg] Download-emails-failure',
  DOWNLOAD_EMAILS_ERROR = '[AutoReg] Download-emails-error'
}
