import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {select, Store} from "@ngrx/store";
import {integrationToScenumAction} from "@/main/autoreg/registration/store/actions/integrationToScenum.action";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {Observable} from "rxjs";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";
import {autoRegGroupSelector} from "@/main/autoreg/accounts/store/selectors";
import {updateButtonStateAction} from "@/main/autoreg/registration/store/actions/updateButtonState.action";
import {currentUserPortSelector, defaultPortSelector} from "@/users/store/selectors";

@Component({
  selector: 'app-integration-scenum-process',
  templateUrl: './integration-scenum-process.component.html',
  styleUrls: ['./integration-scenum-process.component.scss']
})
export class IntegrationScenumProcessComponent implements OnInit {

  public integrationForm: UntypedFormGroup;
  public checked: boolean = true;

  public defaultPort$: Observable<number>;
  public currentUserPort$: Observable<number>;
  public autoRegGroup$: Observable<AutoRegGroupType[]>;
  public buttonLoader$: Observable<boolean>;

  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initForm();
    this.initValue();
  }

  public checkedPort(): void {
    this.checked = !this.checked;
  }

  public integrationProcess(portValue: string): void {
    if (this.integrationForm.value.name) {
      let request = {
        names: this.integrationForm.value.name.split(', '),
        ap_port: +portValue
      }
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(updateButtonStateAction({stateButton: true}))
      this.store.dispatch(integrationToScenumAction({request}))
    }
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  private initForm(): void {
    this.integrationForm = new  UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required)
    })
  }

  private initValue(): void {
    this.autoRegGroup$ = this.store.pipe(select(autoRegGroupSelector));
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
    this.defaultPort$ = this.store.pipe(select(defaultPortSelector));
    this.currentUserPort$ = this.store.pipe(select(currentUserPortSelector));
  }
}
