import {createReducer, on} from "@ngrx/store";
import {
  autoRegTerminateSuccessActions
} from "@/main/autoreg/registration/store/actions/autoRegTerminate.action";
import {
  autoRegProcessItemsErrorActions,
  autoRegProcessItemsFailureActions,
  autoRegProcessItemsSuccessActions
} from "@/main/autoreg/registration/store/actions/AutoRegProcessItems.actions";
import {
  autoRegProcessPauseSuccessActions
} from "@/main/autoreg/registration/store/actions/autoRegProcessPause.actions";
import {
  autoRegProcessPlaySuccessActions
} from "@/main/autoreg/registration/store/actions/autoRegProcessPlay.actions";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {AutoRegInterface, initialState} from "@/main/autoreg/registration/store/index";
import {
  addFarmActions, addFarmActionsError,
  addFarmActionsFailure,
  addFarmActionsSuccess
} from "@/main/autoreg/registration/store/actions/addFarm.actions";
import {
  integrationToScenumActionSuccess
} from "@/main/autoreg/registration/store/actions/integrationToScenum.action";
import {getRegActionSuccess} from "@/main/autoreg/registration/store/actions/getReg.action";
import {getConsumablesActionSuccess} from "@/main/autoreg/registration/store/actions/getConsumables.action";
import {
  updateRegerAction, updateRegerActionFailure,
  updateRegerActionSuccess
} from "@/main/autoreg/registration/store/actions/updateReger.action";
import {AutoRegSyncToScenumActionSuccess} from "@/main/autoreg/registration/store/actions/autoRegSyncToScenum.action";
import {updateButtonStateAction} from "@/main/autoreg/registration/store/actions/updateButtonState.action";
import {AutoRegProcessItemsType} from "@/main/autoreg/registration/store/type/autoRegProcessItems.type";
import {getAutoRegsAction} from "@/main/autoreg/registration/store/actions/getAutoRegs.action";
import {downloadEmailsSuccessAction} from "@/main/autoreg/registration/store/actions/downloadEmails.action";


export const autoRegReducer = createReducer(
  initialState,
  on(
    autoRegClosePopupAction, (state, {open}): AutoRegInterface => ({
      ...state,
      autoRegStatus: open
    })
  ),

  on(
    autoRegTerminateSuccessActions, (state, {pid}): AutoRegInterface => ({
      ...state,
      autoRegStatus: 'Закрыть',
      autoRegProcess: state.autoRegProcess.filter((item) => item.pid !== pid)
    })
  ),
  on(
    autoRegProcessItemsSuccessActions, (state, {response}): AutoRegInterface => {

      let integrationToScenum = !!(response.data as AutoRegProcessItemsType).find(item => item.name === 'Интеграция со Scenum');

      return {
        ...state,
        autoRegProcess: response.data,
        integrationToScenum: integrationToScenum
      }
    }
  ),
  on(
    autoRegProcessItemsFailureActions, (state, {response}): AutoRegInterface => {

      return {
        ...state,
        autoRegProcess: []
      }
    }
  ),
  on(
    autoRegProcessItemsErrorActions, (state, {error}): AutoRegInterface => {
      return {
        ...state,
        autoRegProcess: []
      }
    }
  ),
  on(
    autoRegProcessItemsFailureActions, (state, {response}): AutoRegInterface => ({
      ...state,
      autoRegProcess: null
    })
  ),
  on(
    autoRegProcessItemsErrorActions, (state, {error}): AutoRegInterface => ({
      ...state,
      autoRegProcess: null
    })
  ),
  on(
    autoRegProcessPauseSuccessActions, (state, {response, pid}): AutoRegInterface => ({
      ...state,
      autoRegProcess: state.autoRegProcess.map((item) =>
        item.pid === pid ? {...item, state: "Приостановлен"} : item
      ),
    })
  ),
  on(
    autoRegProcessPlaySuccessActions, (state, {response, pid}): AutoRegInterface => ({
      ...state,
      autoRegProcess: state.autoRegProcess.map((item) =>
        item.pid === pid ? {...item, state: "Запущен"} : item
      ),
    })
  ),
  on(
    addFarmActions, (state, {request}): AutoRegInterface => ({
      ...state,
      autoRegStatus: false,
      autoRegNewFarm: null
    })
  ),
  on(
    addFarmActionsSuccess, (state, {response}): AutoRegInterface => ({
      ...state,
      autoRegStatus: 'Закрыть',
      autoRegNewFarm: response.data
    })
  ),
  on(
    addFarmActionsFailure, (state, {response}): AutoRegInterface => ({
      ...state,
      autoRegStatus: false,
      autoRegNewFarm: null
    })
  ),
  on(
    addFarmActionsError, (state, {error}): AutoRegInterface => ({
      ...state,
      autoRegStatus: false,
      autoRegNewFarm: null
    })
  ),

  on(
    integrationToScenumActionSuccess, (state, {response}): AutoRegInterface => ({
      ...state,
      autoRegStatus: 'Закрыть',
      autoRegNewFarm: null
    })
  ),

  on(
    AutoRegSyncToScenumActionSuccess, (state, {response}): AutoRegInterface => ({
      ...state,
      autoRegStatus: 'Закрыть',
      autoRegNewFarm: null
    })
  ),

  on(
    getRegActionSuccess, (state, {response}): AutoRegInterface => {

      return {
        ...state,
        reger: response.data
      }
    }
  ),

  on(
    getConsumablesActionSuccess, (state, {response}): AutoRegInterface => {

      return {
        ...state,
        regConsumables: response.data
      }
    }
  ),

  on(updateRegerAction, (state): AutoRegInterface => {
    return {
      ...state,
      updateProcess: true
    }
  }),

  on(updateRegerActionSuccess, (state, {response, data}): AutoRegInterface => {

      return {
        ...state,
        reger: response.data,
        updateProcess: false
      }
    }
  ),

  on(updateRegerActionFailure, (state): AutoRegInterface => {
    return {
      ...state,
      updateProcess: false
    }
  }),

  on(updateButtonStateAction, (state, {stateButton}) => {
    return {
      ...state,
      addToProcess: stateButton
    }
  }),

  on(getAutoRegsAction, (state, {autoregs}) => {
    return {
      ...state,
      autoRegs: autoregs
    }
  })
)









