import { Pipe, PipeTransform } from '@angular/core';
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

@Pipe({
  name: 'getAccUse'
})
export class GetAccUsePipe implements PipeTransform {

  transform(value: AutoRegItemType[], user: number): AutoRegItemType[] {
    return value.filter(item => (item.state === 'Используется' || item.state === 'Добавлен в FB Tool') && item.owner_id === user)
  }
}
