export enum PopUpType {
  DELETED_ACC = 'DELETED_ACC',
  DELETE_ACC_GROUP = 'DELETED_GROUP_ACC',
  DEL_PROCESS = 'DELETED_PROCESS',

  REGISTER_NEW_USER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  ACTIVATED_ACC = 'ACTIVATED',
  DELETED_ACC_USER = 'DELETED_ACC_USER',
  BLOCKED_ACC_USER = 'lOCK_ACC_USER',

  CREATE_ACC_AP = 'CREATE_ACC_AP',
  CREATE_ACC_AP_GROUP = 'CREATE_ACC_APP_GROUP',

  CHECK_ACC_ITEM = 'CHECK_ACC_ITEM',
  CHECK_ACC_ITEMS = 'CHECK_ACC_ITEMS',

  CREATE_GROUP = 'CREATE_GROUP',
  ADD_TO_GROUP = 'ADD_TO_GROUP',
  ADD_TO_GROUP_ITEMS = 'ADD_TO_GROUP_ITEMS',
  DELETED_GROUP = 'DELETED_GROUP',

  ADD_TO_FB_TOOLS = 'ADD_TO_FB_TOOLS',
  ADD_TO_FB_TOOLS_ITEMS = 'ADD_TO_FB_TOOLS_ITEMS',

  CHANGE_AUTO_REG_STATE = 'CHANGE_AUTO_REG_STATE',
  CHANGE_AUTO_REG_STATE_ITEM = 'CHANGE_AUTO_REG_STATE_ITEM',

  CHANGE_AUTO_REG_STATE_GARBAGE = 'CHANGE_AUTO_REG_STATE_GARBAGE',

  SET_OWNER_ADMIN_ITEMS = 'SET_OWNER_ADMIN_ITEMS',
  SET_OWNER_ADMIN_ITEM = 'SET_OWNER_ADMIN_ITEM',

  RE_LOGIN_ITEM = 'RE_LOGIN_ITEM',
  RE_LOGIN_ITEMS = 'RE_LOGIN_ITEMS',

  DELETED_OWNER_AUTO_REG_ITEM = 'DELETED_OWNER_AUTO_REG_ITEM',
  DELETED_OWNER_AUTO_REG_ITEMS = 'DELETED_OWNER_AUTO_REG_ITEMS',

  RESTORE_AUTO_REG = 'RESTORE_AUTO_REG',
  RESTORE_AUTO_REG_ITEMS = 'RESTORE_AUTO_REG_ITEMS'
}
