import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {autoRegAccUpdate} from "@/main/autoreg/accounts/store/actions/autoRegAccUpdate.actions";
import {select, Store} from "@ngrx/store";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {Observable} from "rxjs";
import {UpdateStateRequestType} from "@/main/autoreg/accounts/store/type/requestSetOwnerSystem.type";
import {restoreAccAction} from "@/main/cart/store/actions/restoreAcc.action";

@Component({
  selector: 'app-auto-reg-item',
  templateUrl: './auto-reg-item.component.html',
  styleUrls: ['./auto-reg-item.component.scss']
})
export class AutoRegItemComponent implements OnInit {

  @Input() autoRegItem!: AutoRegItemType;
  @Input() type?: string;

  public formControlSelect: string;

  public autoRegItemForm: FormGroup;
  public redaction: boolean = true;
  public changeForm: boolean = false;

  public buttonLoader$: Observable<boolean>;

  constructor(private store: Store) {}
  public ngOnInit(): void {
    this.initValue();
    if(this.autoRegItem) {
      this.formInit();
    }

    this.updateChangeForm();
    this.formControlSelect = this.autoRegItem.state;
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  public updateAutoReg(): void {
    this.store.dispatch(autoRegAccUpdate({request: this.autoRegItemForm.value}))
  }

  public restore(): void {
    let request: UpdateStateRequestType = {
      accounts: [
        {
          login: this.autoRegItem.login,
          state: 'Востановлен',
          checkpoint: this.autoRegItem.checkpoint
        }
      ]
    }
    this.store.dispatch(restoreAccAction({request}))
  }

  private updateChangeForm(): void {
      const oldValue = JSON.stringify(this.autoRegItemForm.value);
      this.autoRegItemForm.valueChanges.subscribe(value => {
        const newValue = JSON.stringify(value);
        this.changeForm = newValue !== oldValue;
      })
  }

  private formInit(): void {
      this.autoRegItemForm = new FormGroup({
        ap_id: new FormControl(this.autoRegItem.ap_id),
        ap_port: new FormControl(this.autoRegItem.ap_port),
        group_name: new FormControl(this.autoRegItem.group_name),
        step: new FormControl(this.autoRegItem.step),
        state: new FormControl(this.autoRegItem.state),
        owner: new FormControl(this.autoRegItem.owner_id),
        extension_id: new FormControl(this.autoRegItem.extension_id),
        login: new FormControl(this.autoRegItem.login),
        password: new FormControl(this.autoRegItem.password),
        twofa: new FormControl(this.autoRegItem.twofa),
        email: new FormControl(this.autoRegItem.email),
        email_pass: new FormControl(this.autoRegItem.email_pass),
        name: new FormControl(this.autoRegItem.name),
        surname: new FormControl(this.autoRegItem.surname),
        str_birthday: new FormControl(this.autoRegItem.str_birthday),
        token: new FormControl(this.autoRegItem.token),
        ua: new FormControl(this.autoRegItem.ua),
        cookie: new FormControl(this.autoRegItem.cookie),
        date: new FormControl(this.autoRegItem.date),
      })
    }

    private initValue(): void {
      this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
    }
}
