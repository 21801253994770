<div class="card">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center">
      <button class="action_btn"
      (click)="restoreAcc('group')"
              [disabled]="(selectedAcc$ | async).length === 0"
      >
        <i class="fa-solid fa-trash-can-arrow-up"></i>
        <span class="count" *ngIf="(selectedAcc$ | async).length > 0">{{(selectedAcc$ | async).length}}</span>
      </button>

      <button class="update-button action_btn"
              (click)="updateCart()"
      >
        <i class="fa-solid fa-arrows-rotate"></i>
      </button>
    </div>
  </div>
  <div class="card-body">
    <table class="mini-table table table-bordered">
      <thead>
      <tr>
        <th>
          <input type="checkbox" (change)="checkedTr(0)" [checked]="countCheckAccInCurrentPage$ | async">
        </th>
        <th>Логин</th>
        <th>Пароль</th>
        <th>2FA</th>
        <th>Почта</th>
        <th>Пароль от почты</th>
        <th>Имя</th>
        <th>Фамилия</th>
        <th>Дата рождения</th>
      </tr>
      <tbody>
      <tr *ngFor="let autoreg of currentPageData$ | async"
          [class.active]="autoreg.checked"
          (contextmenu)="onRightClick($event, autoreg)"
      >
        <td>
          <input type="checkbox" (change)="checkedTr(autoreg.id)" [checked]="autoreg.checked">
        </td>
        <td><span appClipboard>{{autoreg.login}}</span></td>
        <td><span appClipboard>{{autoreg.password}}</span></td>
        <td><span appClipboard>{{autoreg.twofa}}</span></td>
        <td><span appClipboard>{{autoreg.email}}</span></td>
        <td><span appClipboard>{{autoreg.email_pass}}</span></td>
        <td><span appClipboard>{{autoreg.name}}</span></td>
        <td><span appClipboard>{{autoreg.surname}}</span></td>
        <td><span appClipboard>{{autoreg.str_birthday}}</span></td>
      </tr>
      <ng-container *ngIf="(cartData$ | async).length === 0">
        <tr>
          <td colspan="9">В корзине нет авторегов</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <div class="card-footer">
    <mat-paginator *ngIf="(cartData$ | async)?.length > (pageSize$ | async)"
                   class="demo-paginator"
                   (page)="handlePageEvent($event)"
                   [length]="(cartData$ | async).length"
                   [pageSize]="pageSize$ | async"
                   [disabled]="disabled"
                   [showFirstLastButtons]="showFirstLastButtons"
                   [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
                   [hidePageSize]="hidePageSize"
                   [pageIndex]="currentPage$ | async"
                   aria-label="Выбрать страницу">
    </mat-paginator>
  </div>
</div>

<app-context-menu
  [contextMenuStyle]="contextMenuStyle"
  [currentAcc]="currentAcc"
  [type]="'cart'"
  (contextMenuEvent)="contextMenuEvent($event)"
></app-context-menu>

<ng-template #autoRegItem>
  <app-auto-reg-item
    [type]="'cart'"
    [autoRegItem]="currentAcc"
  ></app-auto-reg-item>
</ng-template>

<ng-template #popup>
  <app-popup
    [type]="typePopUp"
    [account]="currentAcc"
    (dataPopUp)="popUpActions($event)"
  ></app-popup>
</ng-template>
