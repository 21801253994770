import { Component } from '@angular/core';

@Component({
  selector: 'app-gen-docs',
  templateUrl: './gen-docs.component.html',
  styleUrls: ['./gen-docs.component.scss']
})
export class GenDocsComponent {

}
