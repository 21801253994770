export enum STATE {
  REGISTERED = 'Регистрация',
  REGISTERED_OK = 'Зарегистрирован',
  ERROR = 'Ошибка',
  NEEDS_VERIFICATION = 'Требует проверки',
  VERIFICATION = 'Проверка аккаунта',
  VERIFICATION_OK = 'Проверка закончена',
  CREATE_PROFILE = 'Создание профиля AP',
  CREATE_PROFILE_OK = 'Профиль AP создан',
  RE_LOGIN = 'Relogin',
  RE_LOGIN_OK = 'Relogin выполнен',
  SYNC_TO_SCENUM = 'Синхронизация со Scenum',
  SYNC_TO_SCENUM_OK = 'Синхронизация закончена',
  DATA_RECONCILIATION = 'Сверка данных',
  DATA_IS_CHECKED = 'Данные проверены',
  FARM = 'Фарм',
  COMPLETED = 'Готов',
  CHECKPOINT = 'Checkpoint',
  USED = 'Используется',
  BLOCKED = 'Заблокирован',
  SYNC = 'Синхронизирован',
  ADD_TO_FB_TOOL = 'Добавлен в FB Tool',
  RESTORE = 'Востановлен',
  SELFIE = 'Селфи',
  GARBAGE = 'Мусор'
}

export enum COLOR {
  NO_COLOR = '',
  REGISTERED = 'rgba(255,81,0,0.45)',
  REGISTERED_OK = 'rgba(255,69,0,0.74)',
  ERROR = 'rgba(139,0,0,0.53)',
  NEEDS_VERIFICATION = 'rgba(178,34,34,0.44)',
  VERIFICATION = 'rgba(255,102,0,0.49)',
  VERIFICATION_OK = '#FFA500',
  CREATE_PROFILE = '#FFDAB9',
  CREATE_PROFILE_OK = '#FFFF00',
  RE_LOGIN = '#4169E1',
  RE_LOGIN_OK = '#6495ED',
  SYNC_TO_SCENUM = '#8d64ed',
  SYNC_TO_SCENUM_OK = 'rgba(141,100,237,0.51)',
  DATA_RECONCILIATION = 'rgba(109,100,237,0.51)',
  DATA_IS_CHECKED = '#98FB98',
  FARM = '#ebfc00',
  COMPLETED = '#7CFC00',
  CHECKPOINT = 'rgba(157,100,237,0.58)',
  USED = '#a8b498',
  BLOCKED = 'rgba(255,0,0,0.51)',
  SYNC = 'rgba(137,94,158,0.58)',
  ADD_TO_FB_TOOL = '#6ecef6',
  RESTORE = 'rgba(255,81,0,0.51)',
  SELFIE = 'rgba(255,191,0,0.29)',
  GARBAGE = 'rgb(255,0,0)'
}
