import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AutoRegService} from "@/main/autoreg/services/auto-reg.service";
import {select, Store} from "@ngrx/store";
import {ToastrService} from "ngx-toastr";
import {catchError, from, map, mergeMap, of, tap, withLatestFrom} from "rxjs";
import {accAsSelfieSelector} from "@/main/autoreg/accounts/store/selectors";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {
  autoRegUpdateSelfieAction, autoRegUpdateSelfieActionError,
  autoRegUpdateSelfieActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegUpdateSelfie.action";

@Injectable()
export class AutoRegUpdateSelfieEffect {
  constructor(
    private actions$: Actions,
    private autoRegService: AutoRegService,
    private store: Store,
    private toaStr: ToastrService
  ) {}

  autoRegUpdateSelfieEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(autoRegUpdateSelfieAction),
      withLatestFrom(this.store.pipe(select(accAsSelfieSelector))),
      mergeMap(([action, accAsSelfie]) =>
        from(accAsSelfie).pipe(
          mergeMap(item =>
            of({
              login: item.login,
              state: 'Требует проверки',
              checkpoint: 'Yes'
            }).pipe(
              mergeMap(modifiedItem =>
                this.autoRegService.updateSelfie(modifiedItem).pipe(
                  map(response => {
                    let account = modifiedItem;
                    return autoRegUpdateSelfieActionSuccess({ response, account })
                  }),
                  catchError(error => of(autoRegUpdateSelfieActionError({ error })))
                )
              )
            )
          )
        )
      )
    )
  );


  autoRegUpdateSelfieSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegUpdateSelfieActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        if (response.response.status === 'Success') {
          if (response.response.details.msg) {
            this.toaStr.info(response.response.details.msg)
          } else {
            this.toaStr.warning('Нет уведомления. Сообщить Максиму')
          }
        } else if (response.response.status === 'Failure') {
          if (response.response.details.msg) {
            this.toaStr.error(response.response.details.msg)
          } else {
            this.toaStr.error('Нет уведомления. Сообщить Максиму')
          }
        }
      })
    ), {dispatch: false}
  )

  autoRegUpdateSelfieFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegUpdateSelfieActionError),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

}
