<div class="fb-tools-items">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0 process">
        <div class="card-header">
          <h3 class="card-title">FB-Tool</h3>
        </div>
        <div class="card-body pb-0" [formGroup]="fbToolFormItems">
          <div class="row">
            <div class="col-12 my-2">
              <h4>Доступно запросов: <span class="text-bold">{{requestCount$ | async}}</span></h4>
              <h4 [class.text-danger]="(requestCount$ | async) < (dataForQuery$ | async).length"
                  [class.text-success]="(requestCount$ | async) > (dataForQuery$ | async).length"
              >Выбрано аккаунтов: {{(dataForQuery$ | async).length}}</h4>
            </div>
          </div>
          <div class="row acc-items">
            <div class="col-12 my-2" *ngFor="let item of dataForQuery; let i = index">
              <mat-form-field class="example-full-width w-100">
                <mat-label>{{i + 1}}. Аккаунт {{item.email}}</mat-label>
                <textarea matInput placeholder="Данные в формате FB-Tool..."
                          appClipboardInput
                          readonly
                          rows="1"
                          [value]="item.dataForFbTools.toString()">
                </textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-2">
              <mat-form-field class="example-full-width w-100" appearance="fill">
                <mat-label>Добавить автоматически</mat-label>
                <mat-select formControlName="group">
                  <mat-option *ngFor="let item of fbGroup$ | async" [value]="item.id">{{item.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 my-2">
              <mat-form-field class="example-full-width w-100" appearance="fill">
                <mat-label>Прокси</mat-label>
                <mat-select formControlName="proxy">
                  <mat-option *ngFor="let item of fbToolProxy$ | async" [value]="item.id">{{item.proxy}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-4 my-2">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена
              </button>
            </div>

            <div class="col-4 my-2">
              <button class="w-100" mat-raised-button color="accent"
                      (click)="saveToExel()"
              >Сохранить в Excel
              </button>
            </div>

            <div class="col-4 my-2">
              <button class="w-100" mat-raised-button color="primary"
                      [disabled]="(requestCount$ | async) <  (dataForQuery$ | async)?.length || fbToolFormItems?.invalid || (buttonLoader$ | async)"
                      (click)="okConstellation()"
              >
                <span *ngIf="!(buttonLoader$ | async)">Подтвердить</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>



          </div>
        </div>
      </div>
    </div>
  </div>
</div>

