import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export function getAccToStepSelfieUtil(array): AutoRegItemType[] {
  let currentDate = new Date();
  return array.filter(item => item.step === 'Селфи' && item.state === 'Checkpoint' && differenceTime(currentDate, new Date(item.date)) > 26)
}

export function differenceTime(endDate, startDate): number {
  return Math.floor((endDate - startDate) / (1000 * 60 * 60));
}
