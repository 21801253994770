import {ChatGptType} from "@/main/auto-gpt/store/type/chatGpt.type";
import {MessageGPType} from "@/main/auto-gpt/store/type/messageGPT.type";

export interface ChatGPT {
  chats: ChatGptType[],
  messages: MessageGPType[],
  currentChat: ChatGptType,
  currentMessages: MessageGPType[]
}

export const initialState: ChatGPT = {
  chats: [],
  messages: [],
  currentChat: {
    id: 0,
    name: '',
    model: '',
    owner: 0,
    date_at: '',
    checked: false
  },
  currentMessages: []
}
