import {Pipe, PipeTransform} from '@angular/core';
import {UsersType} from "@/users/store/type/users.type";

@Pipe({
  name: 'getRole'
})
export class GetRolePipe implements PipeTransform {

  transform(arr: UsersType[]): string[] {
    let roles = arr.map(item => {
      return item.role;
    })
    return [...new Set(roles)];
  }
}
