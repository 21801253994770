import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {AutoGptService} from "@/main/auto-gpt/services/auto-gpt.service";
import {ToastrService} from "ngx-toastr";
import {
  deleteChatsAction, deleteChatsActionError,
  deleteChatsActionFailure,
  deleteChatsActionSuccess
} from "@/main/auto-gpt/store/actions/deleteChats.action";

@Injectable()
export class DeleteChatsEffect {
  deleteChatsEffect = createEffect(() => this.actions$.pipe(
    ofType(deleteChatsAction),
    switchMap(({id}) => {
      return this.chatService.deleteChat(id).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return deleteChatsActionSuccess({response})
          } else if (response.status === 'Failure') {
            return deleteChatsActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400 || 422) {
            return of(deleteChatsActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(deleteChatsActionError({error}));
          } else {
            return of(deleteChatsActionError({error}));
          }
        })
      );
    })
  ))

  constructor(private actions$: Actions,
              private chatService: AutoGptService,
              private toaStr: ToastrService
  ) {
  }
}
