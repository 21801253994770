import { Component } from '@angular/core';

@Component({
  selector: 'app-imklo',
  templateUrl: './imklo.component.html',
  styleUrls: ['./imklo.component.scss']
})
export class ImkloComponent {

}
