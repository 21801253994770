import {restrictionsForFBTools} from "../../../../constants/restrictionsForFBTools";

export function getAccFbToolsUtil(array, checked, role) {

  if (checked) {
    let arr = getMapArr(array);
    return arr.filter(item => {
      if (item !== null) {
        if (item.checked && restrictionsForFBTools(item, role)) {
          return item;
        }
      }
    })
  } else {
    let arr = getMapArr(array);
    return arr.filter(item => {
      if (item !== null) {
        if (restrictionsForFBTools(item, role)) {
          return item;
        }
      }
    })
  }
}

function getMapArr(arr) {
  return arr.map(item => {
    return {
      id: item.id,
      ap_id: item.ap_id,
      ap_port: item.ap_port,
      state: item.state,
      group_name: item.group_name,
      group_id: item.group_id,
      owner_id: item.owner_id,
      action_status: item.action_status,
      checkpoint: item.checkpoint,
      login: item.login,
      password: item.password,
      email: item.email,
      email_pass: item.email_pass,
      name: item.name,
      surname: item.surname,
      str_birthday: item.str_birthday,
      token: item.token,
      ua: item.ua,
      cookie: item.cookie,
      date: item.date,
      checked: item.checked,
      process_id: item.process_id,
      type: item.type
    }
  })
}
