import { Pipe, PipeTransform } from '@angular/core';
import {ROLES} from "../../../../../constants/ROLES";

@Pipe({
  name: 'translationRole'
})
export class TranslationRolePipe implements PipeTransform {

  transform(role): string {
    let translateRole = '';
    switch (role) {
      case ROLES.GUEST:
        translateRole = 'Гость'
        break;
      case ROLES.BAYER:
        translateRole = 'Баеры';
        break;
      case ROLES.CONTENT_MANAGER:
        translateRole = 'Контент-менеджеры';
        break;
      case ROLES.WEBMASTER:
        translateRole = 'Веб-мастеры';
        break;
      case ROLES.ADMIN:
        translateRole = 'Администраторы';
        break;
      default:
        translateRole = 'Гость'

    }
    return translateRole;
  }
}
