import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PopUpType} from "@/shared/types/popUp.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {select, Store} from "@ngrx/store";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {Observable} from "rxjs";
import {currentUserPortSelector, defaultPortSelector} from "@/users/store/selectors";

@Component({
  selector: 'app-delete-acc',
  templateUrl: './delete-acc.component.html',
  styleUrls: ['./delete-acc.component.scss']
})
export class DeleteAccComponent implements OnInit {

  @Input() type!: string;
  @Input() account: AutoRegItemType;
  @Output() deletePopUp: EventEmitter<{constellation: boolean, port: number}> = new EventEmitter();

  public defaultPort$: Observable<number>;
  public currentUserPort$: Observable<number>;

  public typePopUp = PopUpType;
  public checked = true;
  public dataPopUp = {
    constellation: false,
    port: null
  }

  public buttonLoader$: Observable<boolean>;

  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initValue();
  }

  public checkedPort(): void {
    this.checked = !this.checked;
  }

  public okConstellation(portValue: string): void {
    this.dataPopUp.constellation = true;
    this.dataPopUp.port = Number(portValue);
    this.deletePopUp.emit(this.dataPopUp);
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
    this.dataPopUp.constellation = false;
    this.deletePopUp.emit(this.dataPopUp);
  }

  private initValue(): void {
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
    this.defaultPort$ = this.store.pipe(select(defaultPortSelector));
    this.currentUserPort$ = this.store.pipe(select(currentUserPortSelector));
  }
}
