import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@/main/main.component';
import {LoginComponent} from '@/auth/components/login/login.component';
import {AuthGuard} from "@/core/guards/auth.guard";
import {AuthForwardGuard} from "@/core/guards/auth-forward.guard";

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', loadChildren: ()  => import('@/main/main.module').then(m => m.MainModule)}
    ]
  },
  {
    path: 'login',
    canActivate: [AuthForwardGuard],
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
