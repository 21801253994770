import { Component } from '@angular/core';
import {Observable} from "rxjs";
import {AutoRegFilterType} from "@/main/autoreg/accounts/store/type/AutoRegFilter.type";
import {select, Store} from "@ngrx/store";
import {autoRegFilterSelector} from "@/main/autoreg/accounts/store/selectors";
import {autoRegAccSort} from "@/main/autoreg/accounts/store/actions/autoRegAccSort.action";

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent {

  public filterValue$: Observable<AutoRegFilterType>;
  constructor(private store: Store) {
    this.initValue();
  }

  public sort(value: string): void {
    this.store.dispatch(autoRegAccSort({value}))
  }

  private initValue(): void {
    this.filterValue$ = this.store.pipe(select(autoRegFilterSelector));
  }

}
