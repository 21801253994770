import { Pipe, PipeTransform } from '@angular/core';
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

@Pipe({
  name: 'getCountRegisteredToday'
})
export class GetCountRegisteredTodayPipe implements PipeTransform {

  transform(autoregs: AutoRegItemType[]): AutoRegItemType[] {
    return autoregs.filter(item => {
      let today = new Date();
      let todayString = `${today.getDay()}.${today.getMonth()}.${today.getFullYear()}`
      let dateAutoregRegister = new Date(item.date);
      let dateAutoregRegisterString = `${dateAutoregRegister.getDay()}.${dateAutoregRegister.getMonth()}.${dateAutoregRegister.getFullYear()}`
      if(todayString === dateAutoregRegisterString && item.state === 'Зарегистрирован') {
        return item;
      }
    })
  }

}
