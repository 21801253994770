import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {UsersService} from "@/users/services/users.service";
import {ToastrService} from "ngx-toastr";
import {
  UpdateUserAction,
  UpdateUserActionFailure,
  UpdateUserActionSuccess
} from "@/users/store/actions/updateUser.action";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";

@Injectable()
export class UpdateUserEffect {
  updateUserEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateUserAction),
    switchMap(({request}) => {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      return this.usersService.updateUser(request).pipe(
        map((response: DefaultResponseType) => {
          this.store.dispatch(ButtonLoaderAction({load: false}))
          return UpdateUserActionSuccess({response})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(UpdateUserActionFailure({ error }));
        })
      );
    })
  ))

  updateUserEffectSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(UpdateUserActionSuccess),
      tap(({response}) => {
        if(response.status === 'Success') {
          this.toaStr.success(response.details.msg)
        } else if (response.status === 'Failure') {
          this.toaStr.error(response.details.msg)
        }
      })
    ), {dispatch: false}
  )

  updateUserEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(UpdateUserActionFailure),
      tap((error) => {
        this.toaStr.error('Не удалось обновить данные пользователя. Попробуйте позже')
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private usersService: UsersService,
              private toaStr: ToastrService,
              private store: Store) {
  }
}
