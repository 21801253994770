import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {PopupComponent} from '@/shared/components/popup/popup.component';
import {SearchFilterPipe} from './pipe/search-filter.pipe';
import {ClipboardDirective} from './directives/clipboard.directive';
import {ClipboardModule} from "ngx-clipboard";
import {ScrollDirective} from './directives/scroll.directive';
import {MatButtonModule} from "@angular/material/button";
import {ContextMenuComponent} from "@/shared/components/context-menu/context-menu.component";
import {ClipboardInputDirective} from "@/shared/directives/clipboardInput.directive";
import {TextColorDirective} from './directives/text-color.directive';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {NgxPermissionsModule} from "ngx-permissions";
import {ButtonLoaderComponent} from './components/button-loader/button-loader.component';
import {PageLoaderComponent} from './components/page-loader/page-loader.component';
import {GetAccUsePipe} from './pipe/get-acc-use.pipe';
import {SetRolePipe} from "@/shared/pipe/set-role.pipe";
import {RedactionUserComponent} from "@/shared/components/redaction-user/redaction-user.component";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import { UseAutoregsComponent } from './components/use-autoregs/use-autoregs.component';
import {CdkDropList} from "@angular/cdk/drag-drop";
import {BgColorStateDirective} from "@/shared/directives/bg-color-state.directive";
import {BgColorStepDirective} from "@/shared/directives/bg-color-step.directive";
import {FBToolsComponent} from "@/shared/components/fb-tools/fb-tools.component";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {AutoRegItemComponent} from "@/shared/components/auto-reg-item/auto-reg-item.component";
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { ActionBtnStyleDirective } from './directives/action-btn-style.directive';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {SearchComponent} from "@/shared/components/search/search.component";


@NgModule({
  declarations: [
    SearchFilterPipe,
    ClipboardDirective,
    ScrollDirective,
    PopupComponent,
    ContextMenuComponent,
    ClipboardInputDirective,
    TextColorDirective,
    ButtonLoaderComponent,
    PageLoaderComponent,
    GetAccUsePipe,
    SetRolePipe,
    RedactionUserComponent,
    UseAutoregsComponent,
    BgColorStateDirective,
    BgColorStepDirective,
    FBToolsComponent,
    AutoRegItemComponent,
    ActionButtonComponent,
    ActionBtnStyleDirective,
    SearchComponent
  ],
    exports: [
        SearchFilterPipe,
        ClipboardDirective,
        ScrollDirective,
        PopupComponent,
        ContextMenuComponent,
        ClipboardInputDirective,
        TextColorDirective,
        ButtonLoaderComponent,
        PageLoaderComponent,
        GetAccUsePipe,
        SetRolePipe,
        RedactionUserComponent,
        UseAutoregsComponent,
        BgColorStateDirective,
        BgColorStepDirective,
        FBToolsComponent,
        AutoRegItemComponent,
        ActionButtonComponent,
        SearchComponent
    ],
  imports: [
    CommonModule,
    ClipboardModule,
    MatButtonModule,
    MatCheckboxModule,
    NgxPermissionsModule.forChild(),
    MatInputModule,
    ReactiveFormsModule,
    CdkDropList,
    MatOptionModule,
    MatSelectModule,
    NgOptimizedImage,
    MatProgressSpinnerModule
  ]
})
export class SharedModule {
}
