import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/registration/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const autoRegProcessPlayActions = createAction(
  ActionType.AUTO_REG_PROCESS_PLAY,
  props<{request: { pid: number }}>()
)

export const autoRegProcessPlaySuccessActions = createAction(
  ActionType.AUTO_REG_PROCESS_PLAY_SUCCESS,
  props<{response: DefaultResponseType, pid}>()
)

export const autoRegProcessPlayFailureActions = createAction(
  ActionType.AUTO_REG_PROCESS_PLAY_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const autoRegProcessPlayErrorActions = createAction(
  ActionType.AUTO_REG_PROCESS_PLAY_ERROR,
  props<{error: HttpErrorResponse}>()
)
