import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ListUsersComponent} from "@/users/components/list-users/list-users.component";
import {SharedModule} from "@/shared/shared.module";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {ReactiveFormsModule} from "@angular/forms";
import {EffectsModule} from "@ngrx/effects";
import {GetUsersEffect} from "@/users/store/effects/getUsers.effect";
import {UpdateUserEffect} from "@/users/store/effects/updateUser.effect";
import {DeleteUserEffect} from "@/users/store/effects/deleteUsers.effect";
import {ActivateUserEffect} from "@/users/store/effects/activateUser.effect";
import {GetMeEffect} from "@/users/store/effects/getMe.effect";
import {GetUserEffect} from "@/users/store/effects/getUser.effect";
import { SetStatusPipe } from './pipe/set-status.pipe';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import {RegistrationComponent} from "@/users/components/registration/registration.component";
import {MatChipsModule} from "@angular/material/chips";



@NgModule({
  declarations: [
    ListUsersComponent,
    SetStatusPipe,
    ContextMenuComponent,
    RegistrationComponent
  ],
  exports: [
    ListUsersComponent,
    RegistrationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
    EffectsModule
      .forFeature([
        GetUsersEffect,
        UpdateUserEffect,
        DeleteUserEffect,
        ActivateUserEffect,
        GetMeEffect,
        GetUserEffect
      ]),
    MatChipsModule
  ]
})
export class UsersModule { }
