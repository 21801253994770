import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {UsersService} from "@/users/services/users.service";
import {getMeAction, getMeActionFailure, getMeActionSuccess} from "@/users/store/actions/getMe.action";
import {Injectable} from "@angular/core";
import {SetRoleUtil} from "@/auth/utils/setRole.util";
@Injectable()
export class GetMeEffect {
  getMeEffect$ = createEffect(() => this.actions$.pipe(
    ofType(getMeAction),
    switchMap(() => {
      return this.usersService.getMe().pipe(
        map((response: DefaultResponseType) => {
          response.data.role = SetRoleUtil(response.data.role_id)
          return getMeActionSuccess({response})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(getMeActionFailure({ error }));
        })
      );
    })
  ))

  getMeEffectSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(getMeActionSuccess),
      tap(({response}) => {
        if(response.status === 'Failure') {
          console.error('Не удалось получить данные о текущем пользователе')
        }
      })
    ), {dispatch: false}
  )

  getMeEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(getMeActionFailure),
      tap((error) => {
        console.error('Не удалось получить данные о текущем пользователе')
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private usersService: UsersService) {
  }
}
