export enum ActionType {
  GET_EMAILS = '[Emails] Get',
  GET_EMAILS_SUCCESS = '[Emails] Get-success',
  GET_EMAILS_ERROR = '[Emails] Get-error',

  CHANGE_STATUS = '[Emails] Change status',
  CHANGE_STATUS_SUCCESS = '[Emails] Change status success',
  CHANGE_STATUS_ERROR = '[Emails] Change status error',

  CHANGE_PAGE = '[Emails] Change-page',

  SEARCH_EMAIL = '[Emails] Search'
}
