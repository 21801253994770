<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-6">
        <h3 class="card-title">Регистрация аккаунтов</h3>
      </div>
      <div class="col-6 d-flex justify-content-end align-items-center" *ngIf="type !== 'lite'">
        <app-date-picker class="datepicker-days"
                         [typeCharts]="'data'"
        ></app-date-picker>
      </div>
    </div>
  </div>
  <div class="card-body">
    <canvas baseChart
            [datasets]="dataForCharts.lineChartData"
            [labels]="dataForCharts.lineChartLabels"
            [options]="dataForCharts.lineChartOptions"
            [legend]="dataForCharts.lineChartLegend"
            [type]="dataForCharts.lineChartType"
            [plugins]="dataForCharts.lineChartPlugins">
    </canvas>
  </div>
</div>



