import {Component, EventEmitter, Output} from '@angular/core';
import {Observable} from "rxjs";
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {select, Store} from "@ngrx/store";
import {autoRegGroupSelector} from "@/main/autoreg/accounts/store/selectors";
import {autoRegDeletedGroupAction} from "@/main/autoreg/accounts/store/actions/autoRegDeletedGroup.action";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {buttonLoaderSelector} from "@/ui/store/selectors";

@Component({
  selector: 'app-deleted-group',
  templateUrl: './deleted-group.component.html',
  styleUrls: ['./deleted-group.component.scss']
})
export class DeletedGroupComponent {

  @Output() deletedGroupEvent: EventEmitter<any> = new EventEmitter;
  public group$: Observable<AutoRegGroupType[]>;
  public currentGroupID: number;
  public data = {
    constellation: false
  }
  public buttonLoader$: Observable<boolean>;

  constructor(private store: Store) {
    this.initValue();
  }
  public deleted(): void {
    this.store.dispatch(ButtonLoaderAction({load: true}))
    this.store.dispatch(autoRegDeletedGroupAction({group_id: this.currentGroupID}))
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  private initValue(): void {
    this.group$ = this.store.pipe(select(autoRegGroupSelector));
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
  }
}
