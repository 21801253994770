import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setColumnName'
})
export class SetColumnNamePipe implements PipeTransform {

  transform(value: string): string {
    let result = value;
    switch (value) {
      case 'ap_id':
        result = 'AP ID'
        break;
      case 'id':
        result = 'ID'
        break;
      case 'ap_port':
        result = 'Порт'
        break;
      case 'state':
        result = 'Состояние'
        break;
      case 'group_name':
        result = 'Название группы'
        break;
      case 'group_id':
        result = 'ID группы'
        break;
      case 'owner_id':
        result = 'Владелец'
        break;
      case 'step':
        result = 'Этап'
        break;
      case 'extension_id':
        result = 'ID расширения'
        break;
      case 'profile_id':
        result = 'ID профиля'
        break;
      case 'action_status':
        result = 'Статус сценария'
        break;
      case 'checkpoint':
        result = 'Checkpoint'
        break;
      case 'login':
        result = 'Логин'
        break;
      case 'password':
        result = 'Пароль'
        break;
      case 'twofa':
        result = 'Двухфакторная аутентификация'
        break;
      case 'email':
        result = 'Почта'
        break;
      case 'email_pass':
        result = 'Пароль от почты'
        break;
      case 'name':
        result = 'Имя'
        break;
      case 'surname':
        result = 'Фамилия'
        break;
      case 'str_birthday':
        result = 'Дата рождения'
        break;
      case 'token':
        result = 'Токен'
        break;
      case 'ua':
        result = 'UA'
        break;
      case 'cookie':
        result = 'Cookie'
        break;
      case 'date':
        result = 'Дата регистрации'
        break;
      case 'process_id':
        result = 'ID процесса'
        break;
      case 'type':
        result = 'Тип'
        break;
      case 'mismatchCookieAndId':
        result = 'Ошибка метаданных'
        break;
      default:
        result = value;
    }
    return result;
  }

}
