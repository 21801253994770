import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {UsersService} from "@/users/services/users.service";
import {getUserAction, getUserActionFailure, getUserActionSuccess} from "@/users/store/actions/getUser.action";
import {Injectable} from "@angular/core";
@Injectable()
export class GetUserEffect {
  getUserEffect$ = createEffect(() => this.actions$.pipe(
    ofType(getUserAction),
    switchMap(({id}) => {
      return this.usersService.getUser(id).pipe(
        map((response: DefaultResponseType) => {
          return getUserActionSuccess({response})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(getUserActionFailure({ error }));
        })
      );
    })
  ))

  deleteUserEffectSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(getUserActionSuccess),
      tap(({response}) => {
        if(response.status === 'Failure') {
          console.error('Не удалось получить данные о пользователе')
        }
      })
    ), {dispatch: false}
  )

  deleteUserEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(getUserActionFailure),
      tap((error) => {
        console.error('Не удалось получить данные о пользователе')
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private usersService: UsersService) {
  }
}
