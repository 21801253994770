import {createReducer, on} from "@ngrx/store";
import {initialState} from "@/main/emails/store/index";
import {getEmailAction, getEmailErrorAction, getEmailSuccessAction} from "@/main/emails/store/actions/getEmail.action";
import {getPostsForPage} from "@/main/autoreg/utils/pagination-utils";
import {IEmail} from "@/main/emails/store/types/email.interface";
import {changePageAction} from "@/main/emails/store/actions/changePage.action";
import {changeStatusSuccessAction} from "@/main/emails/store/actions/changeStatus.action";
import {searchEmailAction} from "@/main/emails/store/actions/searchEmail.action";

export const statisticsReducer = createReducer(
  initialState,
  on(getEmailAction, (state) => {
    return {
      ...state,
      emails: [],
      emailsSelected: []
    }
  }),
  on(getEmailSuccessAction, (state, {response}) => {
    let emailsSelected = response;
    let pageData = getPostsForPage(state.currentPage, state.pageSize, emailsSelected);
    return {
      ...state,
      emails: response,
      emailsSelected: emailsSelected,
      currentPageData: pageData
    }
  }),
  on(getEmailErrorAction, (state) => {
    return {
      ...state,
      emails: [],
      emailsSelected: [],
      currentPageData: []
    }
  }),
  on(changePageAction, (state, {page, pageSize}) => {

    let pageData: IEmail[] = getPostsForPage(page, pageSize, state.emailsSelected);

    return {
      ...state,
      currentPageData: pageData
    }
  }),
  on(changeStatusSuccessAction, (state, {response, email, status}) => {
    let mapArray = state.emails.map(item => {
      if (item.login === email) {
        return {...item, status: status}
      } else {
        return item;
      }
    });

    let pageData: IEmail[] = getPostsForPage(state.currentPage, state.pageSize, mapArray);

    return {
      ...state,
      emails: mapArray,
      emailsSelected: mapArray,
      currentPageData: pageData
    }
  }),
  on (searchEmailAction, (state, {searchText}) => {

    const searchEmails = state.emails.filter(item => {
      if (item.login.includes(searchText) || item.password.includes(searchText)) {
        return item;
      }
    })

    let pageData: IEmail[] = getPostsForPage(state.currentPage, state.pageSize, searchEmails);

    return {
      ...state,
      emailsSelected: searchEmails,
      currentPageData: pageData
    }
  })
)
