import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImkloRoutingModule } from './imklo-routing.module';
import { DomainsComponent } from './domains/domains.component';
import { GeoComponent } from './geo/geo.component';
import { ImkloComponent } from './imklo.component';


@NgModule({
  declarations: [
    DomainsComponent,
    GeoComponent,
    ImkloComponent
  ],
  imports: [
    CommonModule,
    ImkloRoutingModule
  ]
})
export class ImkloModule { }
