export function AutoRegAddGroupUtil(accounts, groups) {
  let newData = [];

  accounts.forEach(account => {
    let accountItem = { ...account };

    groups.forEach(group => {
      if (account.group_id && account.group_id === group.id) {
        accountItem.group_name = group.name;
        accountItem.type = group.type;
      }
    });

    newData.push(accountItem);
  });

  return newData;
}
