import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {
  autoRegTerminateActions, autoRegTerminateErrorActions, autoRegTerminateFailureActions,
  autoRegTerminateSuccessActions
} from "@/main/autoreg/registration/store/actions/autoRegTerminate.action";
import {ProcessService} from "@/main/autoreg/services/process.service";

@Injectable()
export class AutoRegTerminateEffect {
  autoRegTerminateEffect$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegTerminateActions),
    switchMap(({request}) => {
      return this.processService.terminateProcess(request).pipe(
        map((response: DefaultResponseType) => {
          const pid = request.pid;
          if (response.status === 'Success') {
            return autoRegTerminateSuccessActions({response, pid})
          } else if (response.status === 'Failure') {
            return autoRegTerminateFailureActions({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegTerminateErrorActions({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegTerminateErrorActions({error}));
          } else {
            return of(autoRegTerminateErrorActions({error}));
          }
        })
      );
    })
  ))

  autoRegTerminateEffectSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegTerminateSuccessActions),
      tap((response: {response: DefaultResponseType}): void => {
        if (response.response.details.msg) {
          this.toaStr.success(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegTerminateEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegTerminateFailureActions),
      tap((response: {response: DefaultResponseType}): void => {
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoRegTerminateEffectError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegTerminateErrorActions),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private processService: ProcessService,
              private toaStr: ToastrService
  ) {
  }
}
