import { Pipe, PipeTransform } from '@angular/core';
import {UsersType} from "@/users/store/type/users.type";

@Pipe({
  name: 'setOwner'
})
export class SetOwnerPipe implements PipeTransform {

  transform(id: number, users: UsersType[]): string {
    let nameUser = '-';
    users.forEach(item => {
      if (id !== null && item.id === id) {
        nameUser = item.username
      }
    })
    return nameUser;
  }
}
