import {Component, OnInit} from '@angular/core';
import {ProxyDeviceConstants} from "../../../../../../constants/proxy-device.constants";
import {PopUpType} from "@/shared/types/popUp.type";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {select, Store} from "@ngrx/store";
import {autoRegCreateGroupAction} from "@/main/autoreg/accounts/store/actions/autoRregCreateGroup.action";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {Observable} from "rxjs";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {

  public createGroup: UntypedFormGroup;
  public name: string;
  public date: Date = new Date();

  public readonly devices = ProxyDeviceConstants;
  public readonly PopUpType = PopUpType;

  public buttonLoader$: Observable<boolean>;

  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initForm();
    this.initValue();
    this.createGroup.valueChanges.subscribe(value => {
      this.name = this.setName(value.type, value.count, value.device, value.date);
    })
  }

  public create(): void {
    if (this.createGroup.valid
      && this.createGroup.value.name
      && this.createGroup.value.scenum_id
      && this.createGroup.value.type) {
        let request = {
          name: this.createGroup.value.name,
          scenum_id: this.createGroup.value.scenum_id,
          type: this.createGroup.value.type
        }
        this.store.dispatch(ButtonLoaderAction({load: true}))
        this.store.dispatch(autoRegCreateGroupAction({request}))
    }
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  private initForm(): void {
    this.name = this.setName();
    this.createGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.name),
      scenum_id: new UntypedFormControl('Not set', Validators.required),
      device: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl('farm', Validators.required),
      count: new UntypedFormControl(1, Validators.required),
      date: new UntypedFormControl(this.date, Validators.required)
    })
  }

  private initValue(): void {
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
  }

  private setName(type?: string, box?: string, proxy?: string, date?: Date): string {
    let typeValue = type === 'autoreg' ? `AR_` : 'Farm_';
    let dateValue = date ? this.getDate(date) : this.getDate(new Date);
    let boxValue = box ? `p${box}_` : 'p1_';
    let proxyValue = proxy ? `${proxy}` : '';
    return `${typeValue}${dateValue}_${boxValue}${proxyValue}`
  }

  private getDate(date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${day}${month}`
  }
}
