import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {autoRegReLoginAction} from "@/main/autoreg/accounts/store/actions/autoRegReLogin.action";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {ProxyDeviceConstants} from "../../../../../../constants/proxy-device.constants";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {environment} from "../../../../../../environments/environment";
import {Observable} from "rxjs";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {accToReLoginSelector} from "@/main/autoreg/accounts/store/selectors";
import {PopUpType} from "@/shared/types/popUp.type";
import {currentUserPortSelector, defaultPortSelector} from "@/users/store/selectors";
import {useDevicesSelector} from "@/main/autoreg/registration/store/selectors";

@Component({
  selector: 'app-relogin',
  templateUrl: './relogin.component.html',
  styleUrls: ['./relogin.component.scss']
})
export class ReloginComponent implements OnInit {

  @Output() dataPopUp: EventEmitter<any> = new EventEmitter();
  @Input() account: AutoRegItemType;
  @Input() type: PopUpType;

  public popUpType = PopUpType;
  public reLoginForm: UntypedFormGroup;
  public devices = ProxyDeviceConstants;
  public checked = true;
  public port: number = environment.ap_port;
  public method: boolean = false;

  public useDevices$: Observable<string[]>;
  public defaultPort$: Observable<number>;
  public currentUserPort$: Observable<number>;
  public accToReLogin$: Observable<AutoRegItemType[]>;
  public buttonLoader$: Observable<boolean>;
  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initForm();
    this.initValue();
  }

  public checkedPort(): void {
    this.checked = !this.checked;
  }

  public okConstellation(portValue: string): void {
    if (this.type === PopUpType.RE_LOGIN_ITEM) {
      if (portValue && this.reLoginForm.value.device
        && this.reLoginForm.value.method) {
        let request = {
          accounts: [
            {
              ap_id: this.account.ap_id,
              ap_port: +portValue,
              login:  this.account.login,
              password: this.account.password,
              twofa: this.account.twofa
            }
          ],
          method: this.reLoginForm.value.method === 'Origin',
          device_name: this.reLoginForm.value.device
        }
        this.store.dispatch(ButtonLoaderAction({load: true}))
        this.store.dispatch(autoRegReLoginAction({request, typeRequest: 'one'}))
      }
    } else {
      let request = {
        ap_port: +portValue,
        method: this.reLoginForm.value.method === 'Origin',
        device_name: this.reLoginForm.value.device
      }
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(autoRegReLoginAction({request, typeRequest: 'group'}))
    }
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  public initForm(): void {
    this.reLoginForm = new UntypedFormGroup({
      device: new UntypedFormControl('', Validators.required),
      method: new UntypedFormControl('Origin', Validators.required)
    })
  }

  public initValue(): void {
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
    this.accToReLogin$ = this.store.pipe(select(accToReLoginSelector));
    this.defaultPort$ = this.store.pipe(select(defaultPortSelector));
    this.currentUserPort$ = this.store.pipe(select(currentUserPortSelector));
    this.useDevices$ = this.store.pipe(select(useDevicesSelector));
  }
}
