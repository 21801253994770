import {createAction, props} from "@ngrx/store";
import {ActionsType} from "@/main/autoreg/stats/store/actions.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const GetStatisticsAllAction = createAction(
  ActionsType.GET_STATISTICS_ALL,
  props<{interval?: number, firstRequest: boolean}>()
)

export const GetStatisticsAllActionSuccess = createAction(
  ActionsType.GET_STATISTICS_ALL_SUCCESS,
  props<{response: DefaultResponseType, firstRequest: boolean}>()
)

export const GetStatisticsAllActionFailure = createAction(
  ActionsType.GET_STATISTICS_ALL_FAILURE,
  props<{error: HttpErrorResponse}>()
)
