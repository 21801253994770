export enum  ActionsTypes {
  REGISTER = '[User] Register',
  REGISTER_SUCCESS = '[User] Register success',
  REGISTER_FAILURE = '[User] Register failure',

  LOGIN = '[User] Login',
  LOGIN_SUCCESS = '[User] Login success',
  LOGIN_FAILURE = '[User] Login failure',

  LOGOUT = '[User] Logout',
  LOGOUT_SUCCESS = '[User] Logout success',
  LOGOUT_FAILURE = '[User] Logout failure',

  IS_LOGGED_OK = '[User] Is logged TRUE',
  IS_LOGGED_NO = '[User] Is logged FALSE',

  SET_ROLE = '[User] Ste role',
}
