import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {UsersService} from "@/users/services/users.service";
import {ToastrService} from "ngx-toastr";
import {
  activateUserAction,
  activateUserActionFailure,
  activateUserActionSuccess
} from "@/users/store/actions/activateUser.action";
import {Injectable} from "@angular/core";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {Store} from "@ngrx/store";
@Injectable()
export class ActivateUserEffect {
  activateUserEffect$ = createEffect(() => this.actions$.pipe(
    ofType(activateUserAction),
    switchMap(({id}) => {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      return this.usersService.activateUser(id).pipe(
        map((response: DefaultResponseType) => {
          this.store.dispatch(ButtonLoaderAction({load: false}))
          return activateUserActionSuccess({response})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(activateUserActionFailure({ error }));
        })
      );
    })
  ))

  activateUserEffectSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(activateUserActionSuccess),
      tap(({response}) => {
        if(response.status === 'Success') {
          this.toaStr.success(response.details.msg)
        } else if (response.status === 'Failure') {
          this.toaStr.error(response.details.msg)
        }
      })
    ), {dispatch: false}
  )

  activateUserEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(activateUserActionFailure),
      tap((error) => {
        this.toaStr.error('Не удалось активировать пользователя. Попробуйте позже')
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private usersService: UsersService,
              private toaStr: ToastrService,
              private store: Store) {
  }
}
