import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setRole'
})
export class SetRolePipe implements PipeTransform {

  transform(value): string {
    let role = value;
    switch (value) {
      case 'ADMIN':
        role = 'Администратор'
        break;

      case 'BAYER':
        role = 'Баер'
        break;

      case 'GUEST':
        role = 'Гость'
        break;

      case 'CONTENT_MANAGER':
        role = 'Контент-менеджер'
        break;

      case 'WEBMASTER':
        role = 'Вебмастер'
        break;
    }

    return role;

  }

}
