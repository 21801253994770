import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/autoreg/registration/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {AddFarmType} from "@/main/autoreg/registration/store/type/addFarm.type";

export const addFarmActions = createAction(
  ActionType.AUTO_REG_ADD_FARM,
  props<{request: AddFarmType}>()
)

export const addFarmActionsSuccess = createAction(
  ActionType.AUTO_REG_ADD_FARM_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const addFarmActionsFailure = createAction(
  ActionType.AUTO_REG_ADD_FARM_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const addFarmActionsError = createAction(
  ActionType.AUTO_REG_ADD_FARM_ERROR,
  props<{error: HttpErrorResponse}>()
)
