import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {environment} from "../../../../../../environments/environment";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {ProxyDeviceConstants} from "../../../../../../constants/proxy-device.constants";
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";
import {autoRegGroupSelector} from "@/main/autoreg/accounts/store/selectors";
import {AutoRegSyncToScenumAction} from "@/main/autoreg/registration/store/actions/autoRegSyncToScenum.action";
import {updateButtonStateAction} from "@/main/autoreg/registration/store/actions/updateButtonState.action";
import {currentUserPortSelector, defaultPortSelector} from "@/users/store/selectors";

@Component({
  selector: 'app-sync-scenum-process',
  templateUrl: './sync-scenum-process.component.html',
  styleUrls: ['./sync-scenum-process.component.scss']
})
export class SyncScenumProcessComponent {

  public syncForm: UntypedFormGroup;
  public device = ProxyDeviceConstants;

  public checked: boolean = true;

  public defaultPort$: Observable<number>;
  public currentUserPort$: Observable<number>;
  public autoRegGroup$: Observable<AutoRegGroupType[]>;
  public buttonLoader$: Observable<boolean>;

  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initForm();
    this.initValue();
  }

  public checkedPort(): void {
    this.checked = !this.checked;
  }

  public syncProcess(portValue: string): void {
    this.store.dispatch(updateButtonStateAction({stateButton: true}))
    this.store.dispatch(AutoRegSyncToScenumAction({request: {
        name: this.syncForm.value.name,
        ap_port: +portValue,
        device_name: this.syncForm.value.device_name,
        iters: this.syncForm.value.iters
      }}))
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  private initForm(): void {
    this.syncForm = new  UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      iters: new UntypedFormControl(1, Validators.required),
      device_name: new UntypedFormControl('', Validators.required),
    })
  }

  private initValue(): void {
    this.autoRegGroup$ = this.store.pipe(select(autoRegGroupSelector));
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
    this.defaultPort$ = this.store.pipe(select(defaultPortSelector));
    this.currentUserPort$ = this.store.pipe(select(currentUserPortSelector));
  }
}
