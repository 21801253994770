import {createReducer, on} from "@ngrx/store";
import {ChatGPT, initialState} from "@/main/auto-gpt/store/index";
import {getChatsActionSuccess} from "@/main/auto-gpt/store/actions/getChats.action";
import {setChatsActionSuccess} from "@/main/auto-gpt/store/actions/setChats.action";
import {deleteChatsActionSuccess} from "@/main/auto-gpt/store/actions/deleteChats.action";
import {getMessageChatActionSuccess} from "@/main/auto-gpt/store/actions/getMessageChat.action";
import {currentChatAction} from "@/main/auto-gpt/store/actions/currentChat.action";

export const chatsGptReducer = createReducer(
  initialState,
  on(getChatsActionSuccess, (state, {response}): ChatGPT => {
    let chats = response.data.map(item => {
      return {
        ...item,
        checked: false
      }
    })

    let currentChat = chats.find((item, index) => index + 1 === chats.length);

    return {
      ...state,
      chats: chats,
      currentChat: currentChat
    }
  }),
  on(setChatsActionSuccess, (state, {response}): ChatGPT => {
    return {
      ...state,
    }
  }),
  on(deleteChatsActionSuccess, (state, {response}): ChatGPT => {
    let chats = state.chats.filter(item => item.id !== response.data.id);

    return {
      ...state,
      chats: chats
    }
  }),
  on(getMessageChatActionSuccess, (state, {response}): ChatGPT => {
    let currentMessages = response.data.filter(item => item.chat_id === state.currentChat.id);
    return {
      ...state,
      messages: response.data,
      currentMessages: currentMessages
    }
  }),
  on(currentChatAction, (state, {id}): ChatGPT => {
    let currentChat = state.chats.find(item => item.id === id);
    let currentMessages = state.messages.filter(item => item.chat_id === id);

    return {
      ...state,
      currentChat: currentChat,
      currentMessages: currentMessages
    }
  }),
)
