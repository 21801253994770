import {AppState} from '@/store/state';
import {ToggleSidebarMenu} from '@/store/ui/actions';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding,  OnInit, Renderer2} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {autoRegAccActions} from "@/main/autoreg/accounts/store/actions/autoRegAcc.actions";
import {getStatisticsAction} from "@/main/autoreg/stats/store/actions/getStatistics.action";
import {GetStatisticsAllAction} from "@/main/autoreg/stats/store/actions/getStatissticsAll.action";
import {GetUsersAction} from "@/users/store/actions/getUsers.action";
import {getMeAction} from "@/users/store/actions/getMe.action";
import {AutoRegGetGroupAction} from "@/main/autoreg/accounts/store/actions/autoRegGetGroup.action";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  @HostBinding('class') class = 'wrapper';

  public ui: Observable<UiState>;

  constructor(private renderer: Renderer2,
              private store: Store<AppState>) {
  }

  public ngOnInit(): void {
    //получаем данные о пользователях
    this.store.dispatch(GetUsersAction());
    //получаем данные о текущем пользователе
    this.store.dispatch(getMeAction());
    //получаем данные о группах
    this.store.dispatch(AutoRegGetGroupAction({update: false}))

    //получаем автореги
    this.store.dispatch(autoRegAccActions());

    this.uiThemesInit();
    this.store.dispatch(GetStatisticsAllAction({interval: 0, firstRequest: true}))
    this.store.dispatch(getStatisticsAction({dateEnd: new Date(), intervalValue: 7}));
  }

  public onToggleMenuSidebar(): void {
    this.store.dispatch(new ToggleSidebarMenu());
  }

  private uiThemesInit(): void {
    this.ui = this.store.select('ui');
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'auth-page'
    );
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'register-page'
    );
    this.renderer.addClass(
      document.querySelector('app-root'),
      'layout-fixed'
    );

    this.ui.subscribe(
      ({menuSidebarCollapsed, controlSidebarCollapsed, darkMode}) => {
        if (menuSidebarCollapsed) {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'sidebar-open'
          );
          this.renderer.addClass(
            document.querySelector('app-root'),
            'sidebar-collapse'
          );
        } else {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'sidebar-collapse'
          );
          this.renderer.addClass(
            document.querySelector('app-root'),
            'sidebar-open'
          );
        }

        if (controlSidebarCollapsed) {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'control-sidebar-slide-open'
          );
        } else {
          this.renderer.addClass(
            document.querySelector('app-root'),
            'control-sidebar-slide-open'
          );
        }

        if (darkMode) {
          this.renderer.addClass(
            document.querySelector('app-root'),
            'dark-mode'
          );
        } else {
          this.renderer.removeClass(
            document.querySelector('app-root'),
            'dark-mode'
          );
        }
      }
    );
  }
}
