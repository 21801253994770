import {createAction, props} from "@ngrx/store"
import {HttpErrorResponse} from "@angular/common/http";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";

export const autoRegAccActions = createAction(
  ActionType.AUTO_REG_ITEMS
)

export const autoRegAccActionsSuccess = createAction(
  ActionType.AUTO_REG_ITEMS_SUCCESS,
  props<{response: DefaultResponseType, responseData: AutoRegItemType[], currentUserID: number}>()
)

export const autoRegAccActionsFailure = createAction(
  ActionType.AUTO_REG_ITEMS_FAILURE,
  props<{error: HttpErrorResponse}>()
)



