<mat-accordion>
  <mat-expansion-panel
    [expanded]="panelOpenState"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="title">Чаты</span>
      </mat-panel-title>
      <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>

    <mat-card *ngFor="let chat of chats" class="my-3"
    [class.active]="chat.id === currentChat.id"
    >
      <mat-card-content class="chat-item"
      (click)="chatAction($event, 'check', chat.id)"
      >
        <span>{{chat.name}}</span>
        <span
        (click)="chatAction($event,'delete', chat.id)"
        ><i class="fa-solid fa-trash"></i></span>
      </mat-card-content>
    </mat-card>

  </mat-expansion-panel>
</mat-accordion>
