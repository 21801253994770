import {Component, OnInit, OnDestroy, Renderer2, HostBinding} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {Router} from "@angular/router";
import {AuthService} from "@/auth/services/auth.service";
import {Observable} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {select, Store} from "@ngrx/store";
import {isSubmittedSelector, registerErrors} from "@/auth/store/selectors";
import {loginAction} from "@/auth/store/actions/login.action";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";

@Component({
  selector: 'app-auth',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'auth-box';
  public loginForm: UntypedFormGroup;
  public isSubmitting$: Observable<boolean>;
  private isStatusLogin$: Observable<boolean | null>;
  public buttonLoader$: Observable<boolean>;

  constructor(
    private renderer: Renderer2,
    private authService: AuthService,
    private router: Router,
    private toaStr: ToastrService,
    private store: Store
  ) {
  }

  public ngOnInit(): void {
    this.renderer.addClass(
      document.querySelector('app-root'),
      'auth-page'
    );
    this.loginFormInit();
    this.initializeValues();
      }

  public login(): void {
      if (this.loginForm.valid && this.loginForm.value.email && this.loginForm.value.password) {
        const request = {
          email: this.loginForm.controls['email'].value,
          password: this.loginForm.controls['password'].value
        };
        this.store.dispatch(ButtonLoaderAction({load: true}))
        this.store.dispatch(loginAction({ request }));
      }
  }
  public ngOnDestroy(): void {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'auth-page'
    );
  }



  private loginFormInit(): void {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', Validators.required),
    });
  }

  public initializeValues(): void {
    this.isSubmitting$ = this.store.pipe(select(isSubmittedSelector));
    this.isStatusLogin$ = this.store.pipe(select(registerErrors));
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
  }
}
