import { Pipe, PipeTransform } from '@angular/core';
import {UsersType} from "@/users/store/type/users.type";

@Pipe({
  name: 'setUser'
})
export class SetUserPipe implements PipeTransform {

  transform(value: number, users: UsersType[]): unknown {
    const user = users.find(item => item.id === +value);
    return user.username;
  }
}
