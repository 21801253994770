import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ChatGptType} from "@/main/auto-gpt/store/type/chatGpt.type";

@Component({
  selector: 'app-chat-items',
  templateUrl: './chat-items.component.html',
  styleUrls: ['./chat-items.component.scss']
})
export class ChatItemsComponent {

  @Input() chats: ChatGptType[];
  @Input() currentChat: ChatGptType;
  @Output() actions: EventEmitter<any> = new EventEmitter();

  public panelOpenState = true;

  constructor() {}

  public chatAction($event, action, id): void {
    $event.stopPropagation();
    this.actions.emit({action: action, id: id})
  }
}
