import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoGptComponent } from './auto-gpt.component';
import { ChatComponent } from './components/chat/chat.component';
import {AutoGptService} from "@/main/auto-gpt/services/auto-gpt.service";
import { NewChatComponent } from './components/new-chat/new-chat.component';
import {ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "@/shared/shared.module";
import {EffectsModule} from "@ngrx/effects";
import {SetChatsEffect} from "@/main/auto-gpt/store/effects/setChats.effect";
import {GetChatsEffect} from "@/main/auto-gpt/store/effects/getChats.effect";
import {DeleteChatsEffect} from "@/main/auto-gpt/store/effects/deleteChats.effect";
import {GetMessageEffect} from "@/main/auto-gpt/store/effects/getMessage.effect";
import {SetMessageEffect} from "@/main/auto-gpt/store/effects/setMessage.effect";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import { ChatItemsComponent } from './components/chat-items/chat-items.component';
import {MatCardModule} from "@angular/material/card";

@NgModule({
  declarations: [
    AutoGptComponent,
    ChatComponent,
    NewChatComponent,
    ChatItemsComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        EffectsModule
            .forFeature(
                [
                    SetChatsEffect,
                    GetChatsEffect,
                    DeleteChatsEffect,
                    GetMessageEffect,
                    SetMessageEffect
                ]),
        MatExpansionModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule
    ],
  exports: [
    NewChatComponent
  ],
  providers: [AutoGptService]
})
export class AutoGptModule { }
