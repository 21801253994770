import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {
  autoRegAccDeleted, autoRegAccDeletedError, autoRegAccDeletedFailure,
  autoRegAccDeletedSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegAccDeleted.actions";
import {AutoRegService} from "@/main/autoreg/services/auto-reg.service";
import {Store} from "@ngrx/store";
import {AutoRegDeletedRequestType} from "@/main/autoreg/accounts/store/type/autoRegDeletedRequest.type";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";

@Injectable()
export class AutoRegAccDeletedEffect {
  autoRegDeleted$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegAccDeleted),
    switchMap(({request, port}) => {
      let requestData: AutoRegDeletedRequestType = {
        accounts: []
      }
      let account = {
        login: request.login,
        ap_id: request.ap_id,
        group_name: request.group_name === null ? 'lorem' :  request.group_name,
        extension_id: request.extension_id,
        ap_port: port
      }
      requestData.accounts.push(account);

      return this.autoRegService.accDeleted(requestData).pipe(
        map((response: DefaultResponseType) => {
         let account = request;
          if (response.status === 'Success') {
            return autoRegAccDeletedSuccess({response, account})
          } else if (response.status === 'Failure') {
            return autoRegAccDeletedFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegAccDeletedError({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegAccDeletedError({error}));
          } else {
            return of(autoRegAccDeletedError({error}));
          }
        })
      );
    })
  ))

  autoDeleteSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegAccDeletedSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.success(response.response.details.msg)
        } else {
          this.toaStr.warning('Нет уведомления. Сообщить Максиму')
        }

      })
    ), {dispatch: false}
  )

  autoDeleteFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegAccDeletedFailure),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.details.msg) {
          this.toaStr.error(response.response.details.msg)
        } else {
          this.toaStr.warning('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  autoDeleteError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegAccDeletedError),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )



  constructor(private actions$: Actions,
              private autoRegService: AutoRegService,
              private toaStr: ToastrService,
              private store: Store
  ) {
  }
}
