import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap, of, tap, withLatestFrom} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {AutoRegService} from "@/main/autoreg/services/auto-reg.service";
import {ToastrService} from "ngx-toastr";
import {
  autoRegAddToGroupAction, autoRegAddToGroupActionFailure,
  autoRegAddToGroupActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegAddToGroup.action";
import {select, Store} from "@ngrx/store";
import {
  selectedTrAutoReg
} from "@/main/autoreg/accounts/store/selectors";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";

@Injectable()
export class AutoRegAddToGroupEffect {
  autoRegAddToGroupEffect$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegAddToGroupAction),
    withLatestFrom(this.store.pipe(select(selectedTrAutoReg))),
    mergeMap(([action, autoregItems]) => {
      let request = {
        logins: [],
        group_name: action.group
      }
      if (action.typeRequest === 'one' && action.account) {
        request.logins[0] = action.account.login
      } else if (action.typeRequest === 'all' && !action.account) {
        autoregItems.forEach(item => {
          request.logins.push(item.login)
        })
      }
      return this.autoRegService.addToGroup(request).pipe(
        map((response: DefaultResponseType) => {
          return autoRegAddToGroupActionSuccess({response, logins: request.logins, groupName: request.group_name})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(autoRegAddToGroupActionFailure({error}));
        })
      );
    })
  ))

  autoRegAddToGroupSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegAddToGroupActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (response.response.status === 'Success') {
          if (response.response.details.msg) {
            this.toaStr.info(response.response.details.msg)
          } else {
            this.toaStr.warning('Нет уведомления. Сообщить Максиму')
          }
        } else if (response.response.status === 'Failure') {
          if (response.response.details.msg) {
            this.toaStr.error(response.response.details.msg)
          } else {
            this.toaStr.error('Нет уведомления. Сообщить Максиму')
          }
        }
      })
    ), {dispatch: false}
  )

  autoRegAddToGroupFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegAddToGroupActionFailure),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Запрос не выполнен')
        } else if (error.status === 500 || 501) {
          this.toaStr.error('Неизвестная ошибка. Сообщить Максиму')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private autoRegService: AutoRegService,
              private toaStr: ToastrService,
              private store: Store) {
  }
}
