export enum STEP {
  FILLING_IN_THE_DATA = 'Заполнение профиля',
  ACTIVATED_2FA = 'Активация 2ФА',
  ACTIVATED_2FA_OK = '2ФА активирован',
  DATA_IS_FIELD = 'Данные заполнены',
  MAIL_BINDING = 'Привязка почты',
  MAIL_BINDING_OK = 'Почта привязана',
  SELFIE = 'Селфи',
  CHECK_2FA = 'Проверка 2ФА',
  CHECK_MAIL = 'Проверка почты',
  DATA_RECORDING = 'Запись данных',
  GET_META_DATA = 'Получение метаданных',
  LOGIN_PROFILE = 'Вход в профиль',
  LOGOUT_PROFILE = 'Выход из профиля',
  CHECKPOINT = 'Прохожу чекпоинт',
  COLLECTION_DATA = 'Сбор данных',
  LOGIN = 'Логин',
  PENDING_VERIFICATION = 'Ожидает проверки'
}

export enum COLOR {
  FILLING_IN_THE_DATA = 'rgba(255,81,0,0.45)',
  ACTIVATED_2FA = 'rgba(255,9,0,0.33)',
  ACTIVATED_2FA_OK = 'rgba(0,115,255,0.33)',
  DATA_IS_FIELD = 'rgba(106,0,255,0.33)',
  MAIL_BINDING = 'rgba(0,85,255,0.33)',
  MAIL_BINDING_OK = 'rgba(0,64,255,0.33)',
  SELFIE = 'rgba(255,251,0,0.45)',
  CHECK_2FA = 'rgba(128,255,0,0.45)',
  CHECK_MAIL = 'rgba(136,255,0,0.45)',
  DATA_RECORDING = 'rgba(25,0,255,0.45)',
  GET_META_DATA = 'rgba(196,186,176,0.45)',
  LOGIN_PROFILE = 'rgba(255,81,0,0.45)',
  LOGOUT_PROFILE = 'rgba(191,121,85,0.45)',
  CHECKPOINT = 'rgba(76,34,18,0.45)',
  COLLECTION_DATA = 'rgba(113,112,187,0.45)',
  LOGIN = 'rgba(128,255,0,0.24)',
  PENDING_VERIFICATION = 'rgba(255,183,0,0.45)'
}
