import {Action, createReducer, on} from "@ngrx/store";
import {
  registerAction,
  registerFailureAction,
  registerSuccessAction
} from "@/auth/store/actions/register.action";
import {loginAction, loginFailureAction, loginSuccessAction} from "@/auth/store/actions/login.action";
import {logoutAction, logoutFailureAction, logoutSuccessAction} from "@/auth/store/actions/logout.action";
import {isLoggedFalseAction, isLoggedTrueAction} from "@/auth/store/actions/isLogged.action";
import {AuthState} from "@/auth/store/index";
import {SetRoleAction} from "@/auth/store/actions/setRole.action";
import {SetRoleUtil} from "@/auth/utils/setRole.util";

const initialState: AuthState = {
  isSubmitting: false,
  validationErrors: null,
  currentUser: null,
  isRegisterUser: null,
  isLogged: false,
  role: 'GUEST'
}

export const authReducer = createReducer(
  initialState,
  on(
    registerAction, (state): AuthState => ({
      ...state,
      isSubmitting: true,
      validationErrors: null,
      isRegisterUser: null
    })
  ),
  on(
    registerSuccessAction, (state, {user}): AuthState => ({
      ...state,
      isSubmitting: false,
      validationErrors: null,
      isRegisterUser: true
    })
  ),
  on(
    registerFailureAction, (state, action): AuthState => ({
      ...state,
      isSubmitting: false,
      validationErrors: action.error,
      isRegisterUser: false
    })
  ),
  on(
    loginAction, (state, action): AuthState => ({
      ...state,
      isSubmitting: true,
      isRegisterUser: false
    })
  ),
  on(
    loginSuccessAction, (state, {response}): AuthState => {

      return {
        ...state,
        isSubmitting: false,
        validationErrors: null,
        // currentUser: response.details.author,
        isRegisterUser: true,
      }
    }
  ),
  on(
    loginFailureAction, (state, action): AuthState => ({
      ...state,
      isSubmitting: false,
      validationErrors: action.error,
      isRegisterUser: false
    })
  ),
  on(
    logoutAction, (state, action): AuthState => ({
      ...state,
      isSubmitting: true,
      isRegisterUser: true
    })
  ),
  on(
    logoutSuccessAction, (state, action): AuthState => ({
      ...state,
      isSubmitting: false,
      validationErrors: null,
      currentUser: null,
      isRegisterUser: false
    })
  ),
  on(
    logoutFailureAction, (state, action): AuthState => ({
      ...state,
      isSubmitting: false,
      validationErrors: null,
      currentUser: null,
      isRegisterUser: false
    })
  ),
  on(
    isLoggedTrueAction, (state): AuthState => ({
      ...state,
      isLogged: true
    })
  ),
  on(
    isLoggedFalseAction, (state): AuthState => ({
      ...state,
      isLogged: false
    })
  ),

  on(SetRoleAction, (state, {role, user}) => {

    let roleName = SetRoleUtil(role);

    return {
      ...state,
      currentUser: user,
      role: roleName
    }
  })
)

export function reducer(state: AuthState, action: Action) {
  return authReducer(state, action);
}
