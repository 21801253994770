import {createAction, props} from "@ngrx/store";
import {ActionsType} from "@/main/auto-gpt/store/actions.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const setChatsAction = createAction (
  ActionsType.CHAT_GPT_SET,
  props<{request: any}>()
)

export const setChatsActionSuccess = createAction (
  ActionsType.CHAT_GPT_SET_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const setChatsActionFailure = createAction (
  ActionsType.CHAT_GPT_SET_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const setChatsActionError = createAction (
  ActionsType.CHAT_GPT_SET_ERROR,
  props<{error: HttpErrorResponse}>()
)
