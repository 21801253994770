import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {environment} from "../../../../environments/environment";
import {RegerType} from "@/main/autoreg/registration/type/reger.type";


@Injectable()
export class RegerService {

  constructor(private http: HttpClient) { }

  public getReger(windowsProfile: string): Observable<DefaultResponseType> {
    let params = new HttpParams();
    params = params.set('windows_profile', windowsProfile);
    return this.http.get<DefaultResponseType>(environment.api + 'reger', { withCredentials: true, params});
  }

  public updateReger(request: RegerType): Observable<DefaultResponseType> {
    return this.http.patch<DefaultResponseType>(environment.api + 'reger', request, { withCredentials: true});
  }

  public getConsumables(): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + 'reger/consumables', { withCredentials: true});
  }

  public downloadEmails(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(environment.api + 'email', formData, {withCredentials: true});
  }
}
