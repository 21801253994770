import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {Observable, Subscription} from "rxjs";
import {FbGroupType} from "@/main/autoreg/accounts/store/type/autoRegFbToolGroup.type";
import {AutoRegAddFbToolsType} from "@/main/autoreg/accounts/store/type/autoRegAddFbTools.type";
import {autoRegFbAddAction} from "@/main/autoreg/accounts/store/actions/autoRegFbAdd.action";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {
  currentGroupFBSelector, currentProxyAndGroupFBSelector, currentProxyFBSelector,
  fbToolGroupRequestLeftSelector,
  fbToolGroupSelector,
  fbToolProxySelector
} from "@/main/autoreg/accounts/store/selectors";
import {ProxyDeviceConstants} from "../../../../constants/proxy-device.constants";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {FbToolProxyType} from "@/main/autoreg/accounts/store/type/fbToolProxy.type";
import {
  updateToLocalStorageProxyAndGroupFBAction
} from "@/main/autoreg/accounts/store/actions/updateToLocalStorageProxyAndGroupFB.action";
import {saveToExelAutoReg} from "@/main/autoreg/accounts/store/actions/autoRegAccTable.actions";
import {currentUserSelector, defaultApiKeyFBToolSelector} from "@/users/store/selectors";
import {UsersType} from "@/users/store/type/users.type";

@Component({
  selector: 'app-fb-tools',
  templateUrl: './fb-tools.component.html',
  styleUrls: ['./fb-tools.component.scss']
})

export class FBToolsComponent implements OnInit, OnDestroy {

  @Output() dataPopUp: EventEmitter<any> = new EventEmitter();
  @Input() autoRegItem: AutoRegItemType;

  public devices = ProxyDeviceConstants;
  public dataForFbTool: string;
  public fbToolForm: UntypedFormGroup;

  public fbToolProxy$: Observable<FbToolProxyType>;
  public fbGroup$: Observable<FbGroupType>;
  public requestCount$: Observable<number>;
  public buttonLoader$: Observable<boolean>;

  private currentUser$: Observable<UsersType>;
  private currentProxy$: Observable<number>;
  private currentGroup$: Observable<number>;
  private defaultApiKeyFbTool$: Observable<string>;
  private defaultApiKeyFbTool: string;
  private currentUser: UsersType;
  public currentGroup: number;
  public currentProxy: number;
  private subscriptionList: Subscription[] = [];

  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }


  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initValue();
    this.sub = this.currentUser$.subscribe(data => data ? this.currentUser = data : null);
    this.sub = this.defaultApiKeyFbTool$.subscribe(data => data ? this.defaultApiKeyFbTool = data : null);

    this.sub = this.currentGroup$.subscribe(data => {
      if (data) {
        this.currentGroup = data;
      }
    });
    this.sub = this.currentProxy$.subscribe(data => {
      if (data) {
        this.currentProxy = data;
      }
    });

    this.initForm(this.currentGroup, this.currentProxy)
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe());
  }

  public okConstellation(): void {
    let request: AutoRegAddFbToolsType = {
      api_key: this.currentUser.fbtool_apikey ? this.currentUser.fbtool_apikey : this.defaultApiKeyFbTool,
      token: this.autoRegItem.token,
      name: this.autoRegItem.name + ' ' + this.autoRegItem.surname,
      cookie: this.autoRegItem.cookie,
      proxy: this.fbToolForm.value.proxy.toString(),
      ua: this.autoRegItem.ua,
      group: this.fbToolForm.value.group
    }
    this.store.dispatch(ButtonLoaderAction({load: true}))
    this.store.dispatch(autoRegFbAddAction({request}))

    this.store.dispatch(updateToLocalStorageProxyAndGroupFBAction({group: this.fbToolForm.value.group}))
    this.store.dispatch(updateToLocalStorageProxyAndGroupFBAction({proxy: this.fbToolForm.value.proxy}))
  }

  public saveToExel(): void {
    this.store.dispatch(saveToExelAutoReg({data: [this.autoRegItem]}))
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  private dateFormatFbTool(item): string {
    return `${item.name} ${item.surname}|${item.ua}|${item.cookie}|${item.password}|`
  }

  private initForm(group, proxy): void {
    this.dataForFbTool = this.dateFormatFbTool(this.autoRegItem);
    this.fbToolForm = new UntypedFormGroup({
      fbToolData: new UntypedFormControl(this.dataForFbTool),
      group: new UntypedFormControl(group ? group.toString() : '', Validators.required),
      proxy: new UntypedFormControl(proxy ? proxy.toString() : '', Validators.required)
    })
  }

  private initValue(): void {
    this.fbGroup$ = this.store.pipe(select(fbToolGroupSelector));
    this.requestCount$ = this.store.pipe(select(fbToolGroupRequestLeftSelector));
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
    this.fbToolProxy$ = this.store.pipe(select(fbToolProxySelector));
    this.currentUser$ = this.store.pipe(select(currentUserSelector));
    this.defaultApiKeyFbTool$ = this.store.pipe(select(defaultApiKeyFBToolSelector));

    this.currentProxy$ = this.store.pipe(select(currentProxyFBSelector));
    this.currentGroup$ = this.store.pipe(select(currentGroupFBSelector));
  }
}
