<div class="container p-0 integration">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0 process" [formGroup]="integrationForm">
        <div class="card-header">
          <h3 class="card-title">Интеграция со Scenum</h3>
        </div>
        <div class="card-body pb-0">
          <div class="row">
            <div class="col-12">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Название группы</mat-label>
                <mat-select formControlName="name">
                  <mat-option *ngFor="let group of autoRegGroup$ | async | getGroupToFarm"
                              [value]="group.name"
                              [disabled]="group.scenum_id !== 'Not set' && group.scenum_id !== null"
                  >
                    {{group.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 my-2">
              <mat-form-field class="example-full-width w-100">
                <mat-label>Порт</mat-label>
                <input type="number" matInput placeholder="Порт"
                       [value]="checked ? (defaultPort$ | async).toString() : (currentUserPort$ | async).toString()"
                       [readonly]="checked"
                       #inputElement
                >
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-checkbox class="example-margin" color="primary"
                            checked
                            (click)="checkedPort()"
              >
                <span>Использовать стандартный порт</span>
              </mat-checkbox>
            </div>
            <div class="col-6 my-2">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена</button>
            </div>
            <div class="col-6 my-2">
              <button class="w-100" mat-raised-button color="primary"
                      [disabled]="integrationForm.invalid || (buttonLoader$ | async)"
                      (click)="integrationProcess(inputElement.value)"
              >
                <span *ngIf="!(buttonLoader$ | async)">Запустить</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
