import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/main/emails/store/action.type";
import {HttpErrorResponse} from "@angular/common/http";
import {IEmail} from "@/main/emails/store/types/email.interface";


export const getEmailAction = createAction(
  ActionType.GET_EMAILS
)

export const getEmailSuccessAction = createAction(
  ActionType.GET_EMAILS_SUCCESS,
  props<{response: IEmail[]}>()
)

export const getEmailErrorAction = createAction(
  ActionType.GET_EMAILS_ERROR,
  props<{error: HttpErrorResponse}>()
)

