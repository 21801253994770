import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {PopUpType} from "@/shared/types/popUp.type";
import {Observable, Subscription} from "rxjs";
import {AutoRegProcessItemsType} from "@/main/autoreg/registration/store/type/autoRegProcessItems.type";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {autoRegProcessItemsActions} from "@/main/autoreg/registration/store/actions/AutoRegProcessItems.actions";
import {autoRegProcessPauseActions} from "@/main/autoreg/registration/store/actions/autoRegProcessPause.actions";
import {autoRegProcessPlayActions} from "@/main/autoreg/registration/store/actions/autoRegProcessPlay.actions";
import {select, Store} from "@ngrx/store";
import {
  addToProcessSelector,
  autoRegProcessSelector,
  autoRegStatusSelector, integrationToScenumSelector
} from "@/main/autoreg/registration/store/selectors";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {autoRegTerminateActions} from "@/main/autoreg/registration/store/actions/autoRegTerminate.action";
import {UsersType} from "@/users/store/type/users.type";
import {usersSelector} from "@/users/store/selectors";

@Component({
  selector: 'app-auto-reg-process',
  templateUrl: './auto-reg-process.component.html',
  styleUrls: ['./auto-reg-process.component.scss']
})
export class AutoRegProcessComponent implements OnInit, OnDestroy {

  @ViewChild('popup') popupElement: TemplateRef<ElementRef>;
  @ViewChild('newProcess') newProcessElement: TemplateRef<ElementRef>;
  @ViewChild('newFarm') newFarmElement: TemplateRef<ElementRef>;
  @ViewChild('integrationToScenum') integrationToScenumElement: TemplateRef<ElementRef>;
  @ViewChild('syncToScenum') syncToScenumElement: TemplateRef<ElementRef>;

  public typePopUp: string = PopUpType.DEL_PROCESS;
  public pid: number = 0;
  public nameProcess: string = '';
  public autoRegProcessItems$: Observable<AutoRegProcessItemsType>;

  private statusReg$: Observable<string | boolean>;
  public addToProcess$: Observable<boolean>;
  public integrationToScenum$: Observable<boolean>;
  public users$: Observable<UsersType[]>;

  private dialogRef: MatDialogRef<any> | null = null;
  private subscriptionList: Subscription[] = [];

  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store,
              private matDialog: MatDialog) {
  }

  public ngOnInit(): void {
    this.update()
    this.initValue();
    this.sub = this.statusReg$.subscribe(data => this.closePopUp(data))
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(item => item.unsubscribe())
  }

  public terminateProcess(pid: number, name: string): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.pid = pid;
    this.nameProcess = name;
    this.typePopUp = PopUpType.DEL_PROCESS;
    this.dialogRef = this.matDialog.open(this.popupElement);
  }

  public pauseProcess(pid): void {
    this.store.dispatch(autoRegProcessPauseActions({request: pid}))
  }

  public playProcess(pid): void {
    this.store.dispatch(autoRegProcessPlayActions({request: pid}))
  }

  public openNewFarm(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.dialogRef = this.matDialog.open(this.newFarmElement)
  }

  public integrationProcess(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.dialogRef = this.matDialog.open(this.integrationToScenumElement)
  }

  public syncProcess(): void {
    this.store.dispatch(autoRegClosePopupAction({open: false}))
    this.dialogRef = this.matDialog.open(this.syncToScenumElement)
  }

  public popUpActions(action): void {
    if (action.constellation && this.typePopUp === PopUpType.DEL_PROCESS && this.pid) {
      this.store.dispatch(autoRegTerminateActions({request: {pid: this.pid}}));
    }
  }

  public update(): void {
    this.store.dispatch(autoRegProcessItemsActions())
  }

  private closePopUp(value: string | boolean): void {
    if (value === 'Закрыть' && this.dialogRef) {
      this.dialogRef.close()
    }
  }

  private initValue(): void {
    this.statusReg$ = this.store.pipe(select(autoRegStatusSelector));
    this.autoRegProcessItems$ = this.store.pipe(select(autoRegProcessSelector));
    this.addToProcess$ = this.store.pipe(select(addToProcessSelector));
    this.integrationToScenum$ = this.store.pipe(select(integrationToScenumSelector));
    this.users$ = this.store.pipe(select(usersSelector));
  }
}
