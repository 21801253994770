<button class="sort-button" mat-icon-button [matMenuTriggerFor]="menu" >
  <i class="fa-solid fa-users-gear"></i>
</button>

<mat-menu #menu="matMenu" xPosition="before" class="menu">

  <ng-container *ngIf="(currentUserRole$ | async) === 'ADMIN'">
    <button mat-menu-item class="menu-sort-item button"
            *ngFor="let user of users$ | async"
            (click)="filterUser(user.id.toString())"
            [class.active]="(checkUsers$ | async).includes(user.id.toString())"
    >
      <span class="icon"><i class="fa-solid fa-check"></i></span>
      <span>{{user.username}}</span>
    </button>
  </ng-container>

  <ng-container *ngIf="(currentUserRole$ | async) !== 'ADMIN'">

    <button mat-menu-item class="menu-sort-item button"
            (click)="filterUser('withTheOwner')"
            [class.active]="(checkUsers$ | async).includes('withTheOwner')"
    >
      <span class="icon"><i class="fa-solid fa-check"></i></span>
      <span>С владельцем</span>
    </button>

    <button mat-menu-item class="menu-sort-item button"
            (click)="filterUser('withOutOwner')"
            [class.active]="(checkUsers$ | async).includes('withOutOwner')"
    >
      <span class="icon"><i class="fa-solid fa-check"></i></span>
      <span>Без владельца</span>
    </button>
  </ng-container>

</mat-menu>
