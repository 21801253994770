import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {select, Store} from "@ngrx/store";
import {autoRegAccItemsSelector, autoRegFilterSelector} from "@/main/autoreg/accounts/store/selectors";
import {AutoRegFilterType} from "@/main/autoreg/accounts/store/type/AutoRegFilter.type";
import {FiltersAutoRegType} from "@/main/autoreg/accounts/store/type/filtersAutoReg.type";
import {autoRegAccSortState} from "@/main/autoreg/accounts/store/actions/autoRegAccSort.action";

@Component({
  selector: 'app-filter-state',
  templateUrl: './filter-state.component.html',
  styleUrls: ['./filter-state.component.scss']
})
export class FilterStateComponent implements OnInit {

  public filter = FiltersAutoRegType;

  public autoRegAccItems$: Observable<AutoRegItemType[]>;
  public filterValue$: Observable<AutoRegFilterType>;

  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initValue();
  }

  public filterState(stateValue: string): void {
    this.store.dispatch(autoRegAccSortState({stateValue}))
  }

  private initValue(): void {
    this.filterValue$ = this.store.pipe(select(autoRegFilterSelector));
    this.autoRegAccItems$ = this.store.pipe(select(autoRegAccItemsSelector))
  }
}
