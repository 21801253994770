import {AppState} from "@/store/state";
import {createSelector} from "@ngrx/store";
import {CartState} from "@/main/cart/store/index";


export const cartFeatureSelector = (state: AppState) => state.cart;

export const cartDataSelector = createSelector(
  cartFeatureSelector,
  (cartState: CartState) => cartState.cartData
)

export const selectedTrSelector = createSelector(
  cartFeatureSelector,
  (cartState: CartState) => cartState.selectedTr
)

export const pageSelector = createSelector(
  cartFeatureSelector,
  (cartState: CartState) => cartState.currentPageData
)

export const pageSizeSelector = createSelector(
  cartFeatureSelector,
  (cartState: CartState) => cartState.pageSize
)

export const currentPageSelector = createSelector(
  cartFeatureSelector,
  (cartState: CartState) => cartState.currentPage
)

export const countCheckAccInCurrentPageSelector = createSelector(
  cartFeatureSelector,
  (cartState: CartState) => cartState.countCheckAccInCurrentPage
)
