export enum ActionsType {
  SELECTED_TR = '[Cart] Selected-item',
  CHANGE_PAGE = '[Cart] Change-page',

  GET_CART_DATA = '[Cart] Get-data',
  GET_CART_DATA_SUCCESS = '[Cart] Get-data-success',
  GET_CART_DATA_FAILURE = '[Cart] Get-data-failure',
  GET_CART_DATA_ERROR = '[Cart] Get-data-error',

  RESTORE_ACC = '[Cart] Restore-acc',
  RESTORE_ACC_SUCCESS = '[Cart] Restore-acc-success',
  RESTORE_ACC_FAILURE = '[Cart] Restore-acc-failure',
  RESTORE_ACC_ERROR = '[Cart] Restore-acc-error',
}
