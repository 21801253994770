import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AccountsComponent} from "@/main/autoreg/accounts/accounts.component";
import {RegistrationComponent} from "@/main/autoreg/registration/registration.component";
import {StatsComponent} from "@/main/autoreg/stats/stats.component";



const routes: Routes = [
  {
    path: 'registration',
    component: RegistrationComponent,
    title: 'Процессы'
  },
  {
    path: 'accounts',
    component: AccountsComponent,
    title: 'Аккаунты'
  },
  {
    path: 'stats',
    component: StatsComponent,
    title: 'Статистика'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AutoregRoutingModule {
}
