import {createAction, props} from "@ngrx/store";
import {ActionType} from "@/users/store/action.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const getUserAction = createAction(
  ActionType.GET_USER,
  props<{id: number}>()
)

export const getUserActionSuccess = createAction(
  ActionType.GET_USER_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const getUserActionFailure = createAction(
  ActionType.GET_USER_FAILURE,
  props<{error: HttpErrorResponse}>()
)
