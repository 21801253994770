import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {AutoRegService} from "@/main/autoreg/services/auto-reg.service";
import {
  AutoRegGetGroupAction, AutoRegGetGroupActionFailure,
  AutoRegGetGroupActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegGetGroup.action";
import {
  autoRegCreateGroupActionFailure,
  autoRegCreateGroupActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRregCreateGroup.action";
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";

@Injectable()
export class AutoRegGetGroupEffect {
  autoRegGetGroupEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AutoRegGetGroupAction),
    switchMap(({update}) => {
      return this.autoRegService.getGroup().pipe(
        map((response: DefaultResponseType) => {
          let responseModify = {...response};
          responseModify.data = (responseModify.data as AutoRegGroupType[])
            .map(item => {
            return {
              ...item,
              state: null,
              autoregs: [],
              errors: null,
              step: null
            }
          })
          return AutoRegGetGroupActionSuccess({response: responseModify, update})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(AutoRegGetGroupActionFailure({error}));
        })
      );
    })
  ))

  autoRegGetGroupSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegCreateGroupActionSuccess),
      tap((response: {response: DefaultResponseType}): void => {
         if (response.response.status === 'Failure') {
          console.error('Данные для групп не получены')
        }
      })
    ), {dispatch: false}
  )

  autoRegGetGroupFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegCreateGroupActionFailure),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          console.error('Данные для групп не получены')
        } else if (error.status === 500 || 501) {
          console.error('Данные для групп не получены.Ошибка сервера')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private autoRegService: AutoRegService,
  ) {
  }
}
