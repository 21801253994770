<div class="filter">
  <div class="row">
    <div class="col-12">
      <div class="card card-secondary mb-0 process ">
        <div class="card-header">
          <h3 class="card-title">Фильтры</h3>
        </div>
        <div class="card-body pb-0">
          <div class="row">
            <div class="dateReg col-12">
              <div class="row">
                <div class="col-12">

                </div>
              </div>
              <div class="row">
<!--                <div class="col-6">-->
<!--                  <h6 class="text-center">Владелец</h6>-->
<!--                  <mat-form-field appearance="fill" class="w-100">-->
<!--                    <mat-label>Владелец</mat-label>-->
<!--                    <mat-select #inputSelect (change)="ownerFilter(inputSelect.value)">-->
<!--                      <mat-option [value]="'Владелец 1'">Владелец 1</mat-option>-->
<!--                      <mat-option [value]="'Владелец 2'">Владелец 1</mat-option>-->
<!--                    </mat-select>-->
<!--                  </mat-form-field>-->
<!--                </div>-->
<!--                <div class=" col-6">-->
<!--                  <h6 class="text-center">Состояние</h6>-->
<!--                  <mat-form-field class="w-100">-->
<!--                    <mat-label>Состояние</mat-label>-->
<!--                    <mat-select multiple [ngModel]="(filterValue$ | async).stateAcc">-->
<!--                      <mat-option-->
<!--                        *ngFor="let item of autoRegAccItems$ | async | getState"-->
<!--                        [value]="item"-->
<!--                      >{{item}}</mat-option>-->
<!--                    </mat-select>-->
<!--                  </mat-form-field>-->
<!--                </div>-->
              </div>
              <div class="row mb-2">
                <div class="col-6">
                  <mat-checkbox class="example-margin" color="primary"
                                [checked]="(filterValue$ | async)?.otherFilters?.includes(filter.COOKIE)"
                                (click)="otherFilter(filter.COOKIE)"
                  >Только с Cookie</mat-checkbox>
                </div>
                <div class="col-6">
                  <mat-checkbox class="example-margin" color="primary"
                                [checked]="(filterValue$ | async)?.otherFilters?.includes(filter.TWF)"
                                (click)="otherFilter(filter.TWF)"
                  >Только с 2FA</mat-checkbox>
                </div>
                <div class="col-6">
                  <mat-checkbox class="example-margin" color="primary"
                                [checked]="(filterValue$ | async)?.otherFilters?.includes(filter.NO_TWF)"
                                (click)="otherFilter(filter.NO_TWF)"
                  >Без 2FA</mat-checkbox>
                </div>
                <div class="col-6">
                  <mat-checkbox class="example-margin" color="primary"
                                [checked]="(filterValue$ | async)?.otherFilters?.includes(filter.FB_TOOLS)"
                                (click)="otherFilter(filter.FB_TOOLS)"
                  >Для FB-Tools</mat-checkbox>

                </div>
                <div class="col-6">
                  <mat-checkbox class="example-margin" color="primary"
                                [checked]="(filterValue$ | async)?.otherFilters?.includes(filter.CREATE_PROFILE_AP)"
                                (click)="otherFilter(filter.CREATE_PROFILE_AP)"
                  >Для профиля в AP</mat-checkbox>
                </div>
                <div class="col-6">
                  <mat-checkbox class="example-margin" color="primary"
                                [checked]="(filterValue$ | async)?.otherFilters?.includes(filter.EXAMINATION_ACC)"
                                (click)="otherFilter(filter.EXAMINATION_ACC)"
                  >На проверку</mat-checkbox>

                </div>

              </div>
            </div>

          </div>

          <div class="row mb-1">
            <div class="col-6">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена</button>
            </div>

            <div class="col-6">
              <button class="w-100" mat-raised-button
                      (click)="deletedFilters()"
              >Сбросить</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
