<div class="container p-0 settings-container">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0 process">
        <div class="card-header">
          <h3 class="card-title">Добавить почты</h3>
        </div>
        <div class="card-body pb-0">
          <div class="row">

            <div class="col-12 p-4">
              <input type="file" (change)="eventFile($event)">
            </div>

            <div class="col-6 my-2">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена</button>
            </div>
            <div class="col-6 my-2">
              <button class="w-100" mat-raised-button color="primary"
                      [disabled]="!file"
                      (click)="downloadEmails()"
              >
                <span *ngIf="!(buttonLoader$ | async)">Загрузить</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


