import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {FbToolsService} from "@/main/autoreg/services/fb-tools.service";
import {
  autoRegGetProxyAction, autoRegGetProxyActionError, autoRegGetProxyActionFailure,
  autoRegGetProxyActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegGetProxy.action";

@Injectable()
export class AutoRegGetProxyEffect {
  autoRegGetProxyEffect$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegGetProxyAction),
    switchMap(({apiKey}) => {
      return this.fbToosService.getProxy(apiKey).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return autoRegGetProxyActionSuccess({response})
          } else if (response.status === 'Failure') {
            return autoRegGetProxyActionFailure({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(autoRegGetProxyActionError({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegGetProxyActionError({error}));
          } else {
            return of(autoRegGetProxyActionError({error}));
          }
        })
      );
    })
  ))

  autoRegFbGroupFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegGetProxyActionFailure),
      tap((response: {response: DefaultResponseType}): void => {
        console.error('Прокси для FB-Tool не получены')
      })
    ), {dispatch: false}
  )

  autoRegFbGroupError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegGetProxyActionError),
      tap(({error}): void => {
        if (error.status === 400 || 422) {
          console.error('Прокси для FB-Tool не получены')
        } else if (error.status === 500 || 501) {
          console.error('Прокси для FB-Tool не получены. Ошибка сервера')
        }
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private fbToosService: FbToolsService
  ) {
  }
}
