import { Pipe, PipeTransform } from '@angular/core';
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";

@Pipe({
  name: 'getGroupToFarm'
})
export class GetGroupToFarmPipe implements PipeTransform {

  transform(group: AutoRegGroupType[]): AutoRegGroupType[] {
    return group.filter(item => item.autoregs && item.autoregs.length !== 0);
  }

}
