import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {environment} from "../../../../environments/environment";
import {AutoRegAddFbToolsType} from "@/main/autoreg/accounts/store/type/autoRegAddFbTools.type";

@Injectable()
export class FbToolsService {

  constructor(private http: HttpClient) { }

  public getFbToolsGroup(api_key: string): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + `fb_tool/groups`,
      {
        withCredentials: true,
        params: {
          api_key: api_key
        }
      });
  }

  public getProxy(api_key: string): Observable<DefaultResponseType> {
    return this.http.get<DefaultResponseType>(environment.api + `fb_tool/proxies`,
      {
        withCredentials: true,
        params: {
          api_key: api_key
        }
      });
  }

  public setFbToolsGroup(body): Observable<DefaultResponseType> {
    let request: AutoRegAddFbToolsType = {
      api_key: body.api_key,
      token: body.token,
      name: body.name,
      cookie: body.cookie,
      proxy: body.proxy,
      ua: body.ua,
      group: body.group
    }
    return this.http.post<DefaultResponseType>(environment.api + 'fb_tool', request, {
      withCredentials: true,
      params: {
        api_key: body.api_key
      }
    });
  }

}
