import {Component, OnDestroy, OnInit} from '@angular/core';
import {keysLocalStorage, LocalStorageSyncService} from "@/core/services/local-storage-sync.service";
import {select, Store} from "@ngrx/store";
import {
  accAsSelfieSelector,
  autoRegFilterSelector, columnsSelector, currentGroupFBSelector,
  currentPageSelector, currentProxyFBSelector, pageSizeSelector,
  tableSettingsSelector
} from "@/main/autoreg/accounts/store/selectors";
import {LocalStorageSyncAutoRegType} from "@/shared/types/LocalStorageSync.type";
import {
  autoRegLoadFromLocalStorage
} from "@/main/autoreg/accounts/store/actions/autoRegStateFromToStorage.sction";
import {Observable, Subscription} from "rxjs";
import {autoRegUpdateSelfieAction} from "@/main/autoreg/accounts/store/actions/autoRegUpdateSelfie.action";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, OnDestroy {

  public accAsSelfieItems$: Observable<AutoRegItemType[]>;

  private keysToLocalStorage: keysLocalStorage[] = [
      { selector: tableSettingsSelector, key: 'tableSettings' },
      { selector: autoRegFilterSelector, key: 'filter' },
      { selector: currentPageSelector, key: 'currentPage' },
      { selector: pageSizeSelector, key: 'pageSize' },
      { selector: columnsSelector, key: 'columns' },
      { selector: currentGroupFBSelector, key: 'currentGroupFB' },
      { selector: currentProxyFBSelector, key: 'currentProxyFB' },
    ]

  private valueLocalStorage: LocalStorageSyncAutoRegType = {
    columns: [],
    tableSettings: [],
    filter: {
      sortValue: 'dateOld',
      stateAcc: ['Используется', 'Готов', 'Relogin закончен', 'Проверка закончена'],
      owners: [],
      currentDateStart: '',
      currentDateEnd: '',
      otherFilters: [],
      groups: []
    },
    currentPage: 0,
    pageSize: 30,
    currentGroupFB: 0,
    currentProxyFB: 0
  }

  private subscriptionList: Subscription[] = [];
  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store,
              private localStorageSyncService: LocalStorageSyncService) {}

  public ngOnInit(): void {
    this.initValue();
    this.localStorageSyncService.init(this.keysToLocalStorage, this.valueLocalStorage, autoRegLoadFromLocalStorage)
    this.sub = this.accAsSelfieItems$.subscribe(data => {
      if (data.length !== 0) {
        this.store.dispatch(autoRegUpdateSelfieAction());
      }
    })
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe())
  }

  private initValue(): void {
    this.accAsSelfieItems$ = this.store.pipe(select(accAsSelfieSelector))
  }
}
