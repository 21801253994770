<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-2">
        <h3 class="card-title">Состояния</h3>
      </div>
      <div class="col-10 d-flex justify-content-end align-items-center">
        <app-date-picker class="datepicker-days"
                         [typeCharts]="'data'"
        ></app-date-picker>
      </div>
    </div>
  </div>
  <div class="card-body">
    <canvas baseChart
            [datasets]="dataCharts.doughnutChartData"
            [labels]="dataCharts.doughnutChartLabels"
            [type]="dataCharts.doughnutChartType">
    </canvas>
  </div>
</div>
