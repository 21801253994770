import {SetBgStateUtil} from "@/main/autoreg/utils/setBgState.util";

export function GetDataDiagramStateUtil(defaultData) {
  let data = {
    state: [],
    count: [],
    color: []
  }


  let values = defaultData.map(item => {
    return item.state;
  })

  let newArr = [];
  let stateItems = Array.from(new Set(values));

  stateItems.forEach((state, index) => {
    let data = {
      state: state ? state : 'Нет текущего состояния',
      count: 0,
      color: SetBgStateUtil(state)
    }

    defaultData.forEach(item => {
      if (state === item.state) {
        data.count += item.count;
      }
    })
    newArr.push(data);
  })

  newArr.forEach(item => {
    data.state.push(item.state)
    data.count.push(item.count)
    data.color.push(item.color)
  })

  return data;
}
