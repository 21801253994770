import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export function SortGroupUtil(array): AutoRegItemType[] {
  let result = [];
  if (array.length <= 1) {
    return array;
  }
  let pivotIndex = Math.floor(array.length / 2);
  let pivot = array[pivotIndex];
  let itemToGroup = [];
  let itemWithoutAGroup = [];

  for (let i = 0; i < array.length; i++) {
    if (array[i] === pivot) {
      continue;
    }

    if (array[i].group_id !== null) {
      itemToGroup.push(array[i])
    } else {
      itemWithoutAGroup.push(array[i]);
    }
  }
  result = [...SortGroupUtil(itemToGroup), pivot, ...SortGroupUtil(itemWithoutAGroup)];
  return SortGroup(result);
}

function SortGroup(array): AutoRegItemType[] {
  return array.sort((a, b) => {
    if (a.group_id !== null && b.group_id !== null) {
      return a.group_id - b.group_id
    }
  })
}

