import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {DataForChartsType} from "@/main/autoreg/stats/store/type/DataForCharts.type";
import {select, Store} from "@ngrx/store";
import {autoRegFilterSelector, dataForLinearChartsAutoReg} from "@/main/autoreg/accounts/store/selectors";
import {autoRegFiltersDateInterval} from "@/main/autoreg/accounts/store/actions/autoRegFilters.action";
import {AutoRegFilterType} from "@/main/autoreg/accounts/store/type/AutoRegFilter.type";

@Component({
  selector: 'app-date-picker-filter',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})

export class DatePickerComponent implements OnInit, OnDestroy {

  public rangeForm: FormGroup;

  public data$: Observable<DataForChartsType>;
  public currentData$: Observable<AutoRegFilterType>;

  private subscriptionList: Subscription[] = [];
  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }
  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
    this.sub = this.currentData$.subscribe(data => {
      this.initForm(data.currentDateStart, data.currentDateEnd)
    })
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe())
  }

  public updateDate(): void {
    this.store.dispatch(autoRegFiltersDateInterval(
      {
        start: this.rangeForm.value.start,
        end: this.rangeForm.value.end
      }))
  }

  private initValue(): void {
    this.data$ = this.store.pipe(select(dataForLinearChartsAutoReg));
    this.currentData$ = this.store.pipe(select(autoRegFilterSelector))
  }

  private initForm(start, end): void {
    this.rangeForm = new FormGroup({
      start: new FormControl<Date | null>(start),
      end: new FormControl<Date | null>(end),
    });
  }
}
