import {createReducer, on} from "@ngrx/store";
import {AccountsInterface, initialState} from "@/main/autoreg/accounts/store/index";
import {autoRegFiltersDateInterval} from "@/main/autoreg/accounts/store/actions/autoRegFilters.action";
import {
  autoRegLoadFromLocalStorage
} from "@/main/autoreg/accounts/store/actions/autoRegStateFromToStorage.sction";
import {autoRegChangeSettingsActions} from "@/main/autoreg/accounts/store/actions/autoRegChangeSettings.action";
import {
  autoRegAccActionsFailure,
  autoRegAccActionsSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegAcc.actions";
import {autoRegAccDeletedSuccess} from "@/main/autoreg/accounts/store/actions/autoRegAccDeleted.actions";
import {autoRegAccUpdateSuccess} from "@/main/autoreg/accounts/store/actions/autoRegAccUpdate.actions";
import {
  autoRegAccSort,
  autoRegAccSortOther,
  autoRegAccSortOwner,
  autoRegAccSortState,
  autoRegDeletedFilter, autoregFilterGroup
} from "@/main/autoreg/accounts/store/actions/autoRegAccSort.action";
import {AutoRegFilterType} from "@/main/autoreg/accounts/store/type/AutoRegFilter.type";
import {
  autoRegTrChecked,
  downloadTableDataItems
} from "@/main/autoreg/accounts/store/actions/autoRegAccTable.actions";
import {
  autoRegOwnerUpdateAction,
  autoRegOwnerUpdateSuccessAction
} from "@/main/autoreg/accounts/store/actions/autoRegOwnerUpdate.action";
import {
  autoRegDeletedGroupAccActions,
  autoRegDeletedGroupAccActionsSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegDeletedGroupAcc.actions";
import {
  autoRegFbGroupActionError,
  autoRegFbGroupActionFailure,
  autoRegFbGroupActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegFbGroup.action";
import {autoRegReLoginActionSuccess} from "@/main/autoreg/accounts/store/actions/autoRegReLogin.action";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {onPageChangeAction} from "@/main/autoreg/accounts/store/actions/onPageChange.action";
import {getPostsForPage} from "@/main/autoreg/utils/pagination-utils";
import {pageCountUtil} from "@/main/autoreg/utils/page-count.util";
import {
  autoRegCreateProfileAPAction,
  autoRegCreateProfileAPActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegCreateProfileAP.action";
import {getAccToStepSelfieUtil} from "@/main/autoreg/utils/getAccToStepSelfie.util";
import {
  autoRegUpdateSelfieActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegUpdateSelfie.action";
import {autoRegSelectedAPAction} from "@/main/autoreg/accounts/store/actions/autoRegSelectedAP.action";
import {
  autoRegSetCheckAccParamsAction
} from "@/main/autoreg/accounts/store/actions/autoRegSetCheckAccParams.action";
import {
  autoRegCreateProfileAPItemActionSuccess
} from "@/main/autoreg/accounts/store/actions/autoRegCreateAPItem.action";
import {autoRegSearchAction} from "@/main/autoreg/accounts/store/actions/autoRegSearch.action";
import {autoRegChangedAccActionSuccess} from "@/main/autoreg/accounts/store/actions/autoRegChangedAcc.action";
import {searchUtil} from "@/main/autoreg/utils/search.util";
import {getAccFbToolsUtil} from "@/main/autoreg/utils/getAccFbTools.util";
import {UpdateTableSettingsUtil} from "@/main/autoreg/utils/updateTableSettings.util";
import {UpdateAutoRegItemUtil} from "@/main/autoreg/utils/updateAutoRegItem.util";
import {SetCheckParamsUtil} from "@/main/autoreg/utils/setCheckParams.util";
import {TrCheckedUtil} from "@/main/autoreg/utils/trChecked.util";
import {sortDate} from "@/main/autoreg/utils/sortDate.util";
import {otherFiltersCheck} from "@/main/autoreg/utils/otherFiltersCheck.util";
import {filterDateInterval} from "@/main/autoreg/utils/filterDateInterval.util";
import {filterUtil} from "@/main/autoreg/utils/filter.util";
import {filteredStateUtil} from "@/main/autoreg/utils/filterStep.util";
import {otherFilterUtil} from "@/main/autoreg/utils/otherFilters.util";
import {autoRegFbAddGroupActionSuccess} from "@/main/autoreg/accounts/store/actions/autoRegFbAddGroup.action";
import {AutoRegGetGroupActionSuccess} from "@/main/autoreg/accounts/store/actions/autoRegGetGroup.action";
import {AutoRegAddGroupUtil} from "@/main/autoreg/utils/autoRegAddGroup.util";
import {autoRegCreateGroupActionSuccess} from "@/main/autoreg/accounts/store/actions/autoRregCreateGroup.action";
import {autoRegAddToGroupActionSuccess} from "@/main/autoreg/accounts/store/actions/autoRegAddToGroup.action";
import {autoRegDeletedGroupActionSuccess} from "@/main/autoreg/accounts/store/actions/autoRegDeletedGroup.action";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {AutoRegSetOwnerUtil} from "@/main/autoreg/utils/autoRegSetOwner.util";
import {SortGroupUtil} from "@/main/autoreg/utils/sortGroup.util";
import {autoRegFbAddAction, autoRegFbAddActionSuccess} from "@/main/autoreg/accounts/store/actions/autoRegFbAdd.action";
import {AutoRegSetOwnerSystemActionSuccess} from "@/main/autoreg/accounts/store/actions/autoRegSetOwnerSystem.action";
import {autoRegGetProxyActionSuccess} from "@/main/autoreg/accounts/store/actions/autoRegGetProxy.action";
import {SetRoleUtil} from "@/auth/utils/setRole.util";
import {GetColumnsNameTableUtil} from "@/main/autoreg/utils/getColumnsNameTable.util";
import {AutoRegTableColumnDropAction} from "@/main/autoreg/accounts/store/actions/autoRegTableColumnDrop.action";
import {filteredOwnerArrayUtil, filteredOwnerUtil, filterOwnerUtil} from "@/main/autoreg/utils/filteredOwner.util";
import {
  updateToLocalStorageProxyAndGroupFBAction
} from "@/main/autoreg/accounts/store/actions/updateToLocalStorageProxyAndGroupFB.action";
import {AutoRegAddFbToolsType} from "@/main/autoreg/accounts/store/type/autoRegAddFbTools.type";
import {setProcessesAction} from "@/main/autoreg/accounts/store/actions/setProcesses.action";
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";
import {filteredGroupUtil} from "@/main/autoreg/utils/filterGroup.util";
import {filter} from "rxjs/operators";


export const accountReducer = createReducer(
  initialState,

  //Настройка расположения столбцов
  on(AutoRegTableColumnDropAction, (state, {columns}) => {
    return {
      ...state,
      tableSettings: columns
    }
  }),

  //Получение списка авторегов
  on(autoRegAccActionsSuccess, (state, {responseData, response}): AccountsInterface => {

      //получаем названия столбцов таблицы
      let columnName = GetColumnsNameTableUtil(responseData);

      //Устанавливаем роль для модуля авторегов
      let role = null;
      let userID = null;

      if (response.details.role) {
        role = SetRoleUtil(response.details.role)
      }

      if (response.details.author.id) {
        userID = (response.details.author.id);
      }

      let data: AutoRegItemType[] = responseData;
      let autoregGroup = state.autoRegGroup;

      if (autoregGroup) {
        data = AutoRegAddGroupUtil(data, autoregGroup);

        autoregGroup = autoregGroup.map(group => {
          const groupModify = { ...group };
          const matchingAutoregs = data.filter(autoreg => autoreg.group_id === groupModify.id);
          groupModify.autoregs = [...groupModify.autoregs, ...matchingAutoregs];
          return groupModify;
        })
          .map(group => {
            let groupModify = { ...group };
            let state = null;
            let step = null;
            let errors = null;
            if (group.autoregs && group.autoregs.length !== 0) {
              state = groupModify.autoregs.find(item => item.state).state;
              step = groupModify.autoregs.find(item => item.step).step;
              errors = groupModify.autoregs.filter(item => item.action_status === 'Ошибка').length;
            }
            groupModify = { ...groupModify, state: state, step: step, errors: errors};
            return groupModify;
          })
      }

      if (state.filter.sortValue === 'group') {
        data = SortGroupUtil(data);
      }

      let defaultData = sortDate(data, state.autoRegAccItemsSelected, state.filter.sortValue, state.filter.sortValue === 'group');//сортируем по дате
      let selectedAccItems = sortDate(data, state.autoRegAccItemsSelected, state.filter.sortValue, state.filter.sortValue === 'group');//сортируем по дате

      //Выбираем первую и споследнюю дату для фильтра
      let dates = sortDate(responseData, state.autoRegAccItemsSelected, 'dateNew', false);
      let endDate = dates[0].date;
      let startDate = dates[dates.length - 1].date;

      //Сортируем полученные данные в соответствии с установленными фильтрами
      let filters = state.filter;

      if (filters.groups && filters.groups.length !== 0) {
          selectedAccItems = filteredGroupUtil(state.autoRegAccItems, selectedAccItems, filters.groups, false);
      }

      if (filters.stateAcc && filters.stateAcc.length !== 0) {
        selectedAccItems = filteredStateUtil(selectedAccItems, state.autoRegAccItemsSelected, filters.stateAcc, true);
      }

      if (filters.owners && filters.owners.length !== 0) {
        selectedAccItems = filteredOwnerArrayUtil(state.autoRegAccItems, selectedAccItems, filters.owners, !!state.searchText);
      }

      if (filters.otherFilters && filters.otherFilters.length !== 0) {
        selectedAccItems = otherFiltersCheck(selectedAccItems, state.autoRegAccItems, filters.otherFilters, state.currentUserRole, true);
      }

      //Получаем количество страниц и записи для первой страницы
      let pagesLength = pageCountUtil(selectedAccItems, state.pageSize)//получаем количество страниц
      let currentPage = getPostsForPage(state.currentPage, state.pageSize, selectedAccItems);//записи для страницы


      //Собираем аккаунты с селфи, и проверяет дату регистрации;
      let accToStepSelfie = getAccToStepSelfieUtil(data);

      return {
        ...state,
        columns: columnName,
        autoRegAccItems: defaultData,
        autoRegAccItemsSelected: selectedAccItems,
        autoRegGroup: autoregGroup,
        accOfDownload: selectedAccItems,
        accUseOwnerItems: [],
        accCheckItems: {...state.accCheckItems, accounts: []},
        pageNumbers: pagesLength,
        accSelectedItems: [],
        accAddToGroup: [],
        accIsCreateAP: [],
        accToReLogin: [],
        accFbToolsItems: [],
        accDeleteOwnerItems: [],
        countCheckAccInCurrentPage: false,
        currentPageData: currentPage,
        accAsSelfie: accToStepSelfie,
        dataForCharts: {
          ...state.dataForCharts,
          maxDate: endDate,
          minDate: startDate,
        },
        filter: {
          ...state.filter,
          currentDateStart: startDate,
          currentDateEnd: endDate
        },
        currentUserRole: role,
        currentUserID: userID
      }
    }
  ),

  //Реализация поиска
  on(autoRegSearchAction, (state, {textSearch}): AccountsInterface => {

    let selectedAccItems = searchUtil(state.autoRegAccItems, state.autoRegAccItemsSelected, textSearch, true);

    //Сортируем полученные данные в соответствии с установленными фильтрами
    if (state.filter.stateAcc && state.filter.stateAcc.length !== 0) {
      selectedAccItems = filteredStateUtil(state.autoRegAccItems, selectedAccItems, state.filter.stateAcc, false);
    }

    if (state.filter.otherFilters && state.filter.otherFilters.length !== 0) {
      selectedAccItems = otherFiltersCheck(selectedAccItems, state.autoRegAccItems, state.filter.otherFilters, state.currentUserRole, true);
    }

    if (state.filter.owners && state.filter.owners.length !== 0) {
      selectedAccItems = filteredOwnerArrayUtil(state.autoRegAccItems, selectedAccItems, state.filter.owners, !!state.searchText);
    }

    //Получаем количество страниц и записи для первой страницы
    let pageCount = pageCountUtil(selectedAccItems, state.pageSize);
    let page = getPostsForPage(0, state.pageSize, selectedAccItems);

    return {
      ...state,
      autoRegAccItemsSelected: selectedAccItems,
      currentPage: 0,
      searchText: textSearch,
      accToReLogin: [],
      accDeleteOwnerItems: [],
      accAddToGroup: [],
      countCheckAccInCurrentPage: false,
      accSelectedItems: [],
      accCheckItems: {...state.accCheckItems, accounts: []},
      accIsCreateAP: [],
      accUseOwnerItems: [],
      currentPageData: page,
      pageNumbers: pageCount,
    }
  }),

  //Переключение страниц
  on(onPageChangeAction, (state, {page, pageSize}): AccountsInterface => {

      let newData = getPostsForPage(page, pageSize, state.autoRegAccItemsSelected);

      return {
        ...state,
        currentPage: page,
        currentPageData: newData,
        pageSize: pageSize,
        accUseOwnerItems: [],
        accIsCreateAP: [],
        accSelectedItems: [],
        accCheckItems: {...state.accCheckItems, accounts: []},
        countCheckAccInCurrentPage: false,
        accAddToGroup: []
      }
    }
  ),

  //Управление модальными окнами
  on(autoRegClosePopupAction, (state, {open}): AccountsInterface => ({
      ...state,
      popUpStatus: open
    })
  ),

  //Загрузка данных из localStorage
  on(autoRegLoadFromLocalStorage, (state, {value}): AccountsInterface => {
      return {
        ...state,
        tableSettings: value.tableSettings.length !== 0 ? value.tableSettings : state.tableSettingsDefault,
        filter: value.filter ? value.filter : state.filter,
        currentPage: value.currentPage ? value.currentPage : state.currentPage,
        pageSize: value.pageSize ? value.pageSize : state.pageSize,
        currentGroupFB: value.currentGroupFB ? +value.currentGroupFB : state.currentGroupFB,
        currentProxyFB: value.currentProxyFB ? +value.currentProxyFB : state.currentProxyFB
      }
    }
  ),

  //Настройка столбцов
  on(autoRegChangeSettingsActions, (state, {value}): AccountsInterface => {

      let tableSettings = UpdateTableSettingsUtil(value, state.tableSettings, state.tableSettingsDefault);

      return {
        ...state,
        tableSettings: tableSettings
      }
    }
  ),

  //Запрос групп
  on(AutoRegGetGroupActionSuccess, (state, {response, update}): AccountsInterface => {

    let groups = response.data as AutoRegGroupType[];

    let autoregAccItems = state.autoRegAccItems;
    let selectedAutoReg = state.autoRegAccItemsSelected;
    let currentPage = state.currentPageData;

    if (update) {
      autoregAccItems = AutoRegAddGroupUtil(state.autoRegAccItems, groups);
      selectedAutoReg = AutoRegAddGroupUtil(state.autoRegAccItemsSelected, groups);
      currentPage = getPostsForPage(state.currentPage, state.pageSize, selectedAutoReg);//записи для страницы
    }

    return {
      ...state,
      autoRegAccItems: autoregAccItems,
      autoRegAccItemsSelected: selectedAutoReg,
      autoRegGroup: groups,
      currentPageData: currentPage
    }
  }),

  on(autoRegAccActionsFailure, (state): AccountsInterface => ({
      ...state,
      autoRegAccItems: []
    })
  ),

  //Удаление авторег
  on(autoRegAccDeletedSuccess, (state, {response, account}): AccountsInterface => {

      let page = state.currentPageData.filter(item => item.login !== account.login);
      let countCheckAccInCurrentPage = false;
      if (page.length === page.filter(item => item.checked).length) {
        countCheckAccInCurrentPage = true;
      }
      let filter = {...state.filter};
      if (page.length === 0) {
        page = getPostsForPage(0, state.pageSize, state.autoRegAccItems);
        filter.stateAcc = [];
        countCheckAccInCurrentPage = false;
      }
      let accSelectedItems = page.filter(item => item.checked);
      let accCreateIsAP = accSelectedItems.filter(item => item.checked && item.ap_id === null);
      let accFBTool = getAccFbToolsUtil(accSelectedItems, true, state.currentUserRole);

      return {
        ...state,
        currentPageData: page,
        accSelectedItems: accSelectedItems,
        countCheckAccInCurrentPage: countCheckAccInCurrentPage,
        popUpStatus: 'Закрыть',
        filter: filter,
        accUseOwnerItems: accFBTool,
        accIsCreateAP: accCreateIsAP,
        accToReLogin: [],
        accAddToGroup: [],
        accCheckItems: {...state.accCheckItems, accounts: accCreateIsAP}
      }
    }
  ),
  //Обновление авторегов
  on(autoRegAccUpdateSuccess, (state, {response}): AccountsInterface => {
      //Обновление аккаунта
      let autoRegAccItems = UpdateAutoRegItemUtil(state.autoRegAccItems, response.data);
      let newAutoRegSelected = UpdateAutoRegItemUtil(state.autoRegAccItemsSelected, response.data);
      let page = getPostsForPage(state.currentPage, state.pageSize, newAutoRegSelected);

      return {
        ...state,
        popUpStatus: 'Закрыть',
        currentPageData: page,
        autoRegAccItems: autoRegAccItems,
        autoRegAccItemsSelected: newAutoRegSelected,
        accFbToolsItems: [],
        accAddToGroup: [],
        accSelectedItems: [],
        accCheckItems: {...state.accCheckItems, accounts: [], filters: []},
        accUseOwnerItems: [],
        accToReLogin: [],
        countCheckAccInCurrentPage: false
      }
    },
  ),

  //Сортировка авторегов
  on(autoRegAccSort, (state, {value}): AccountsInterface => {

      let selectedAccItems = state.autoRegAccItemsSelected;
      const filters: AutoRegFilterType = {...state.filter, sortValue: value};

      if (value === 'dateNew' || value === 'dateOld') {
        selectedAccItems = sortDate(state.autoRegAccItemsSelected, state.autoRegAccItems, value, false);
      } else if (value === 'group') {
        selectedAccItems = SortGroupUtil(selectedAccItems);
        selectedAccItems = sortDate(selectedAccItems, state.autoRegAccItems, value, true);
      }

      let page = getPostsForPage(state.currentPage, state.pageSize, selectedAccItems);

      let countCheckAccInCurrentPage = false;
      if (page.length === page.filter(item => item.checked === true).length && page.length !== 0) {
        countCheckAccInCurrentPage = true;
      }

      if (countCheckAccInCurrentPage) {
        page.map(item => {
          return {...item, checked: true}
        });
      }

      return {
        ...state,
        filter: filters,
        autoRegAccItemsSelected: selectedAccItems,
        accOfDownload: selectedAccItems,
        currentPageData: page,
        autoRegAccItems: selectedAccItems,
        countCheckAccInCurrentPage: countCheckAccInCurrentPage,
        accUseOwnerItems: [],
        accDeleteOwnerItems: [],
        accAddToGroup: [],
        accToReLogin: [],
        accFbToolsItems: [],
        accIsCreateAP: [],
        accSelectedItems: [],
        accCheckItems: {...state.accCheckItems, accounts: []}
      };
    }
  ),

  //Фильтр по дате
  on(autoRegFiltersDateInterval, (state, {start, end}) => {

    //Фильтруем записи по временному отрезку
    let sortArray = filterDateInterval(state.autoRegAccItemsSelected, start, end);


    if (state.filter.stateAcc && state.filter.stateAcc.length !== 0) {
      sortArray = filteredStateUtil(state.autoRegAccItems, sortArray, state.filter.stateAcc, false);
    }

    if (state.filter.otherFilters && state.filter.otherFilters.length !== 0) {
      sortArray = otherFiltersCheck(state.autoRegAccItems, sortArray, state.filter.otherFilters, state.currentUserRole, false);
    }

    if (state.filter.owners && state.filter.owners.length !== 0) {
      sortArray = filteredOwnerArrayUtil(state.autoRegAccItems, sortArray, state.filter.owners, !!state.searchText);
    }

    let page = getPostsForPage(state.currentPage, state.pageSize, sortArray);
    let pagesLength = pageCountUtil(sortArray, state.pageSize);

    let countCheckAccInCurrentPage = false;
    if (page.length === page.filter(item => item.checked === true).length && page.length !== 0) {
      countCheckAccInCurrentPage = true;
    }

    if (countCheckAccInCurrentPage) {
      page.map(item => {
        return {...item, checked: true}
      });
    }

    return {
      ...state,
      autoRegAccItemsSelected: sortArray,
      accOfDownload: sortArray,
      filter: {...state.filter, currentDateStart: start, currentDateEnd: end},
      currentPageData: page,
      pageNumbers: pagesLength,
      countCheckAccInCurrentPage: countCheckAccInCurrentPage,
      accUseOwnerItems: [],
      accIsCreateAP: [],
      accSelectedItems: [],
      accDeleteOwnerItems: [],
      accAddToGroup: [],
      accToReLogin: [],
      accFbToolsItems: [],
      accCheckItems: {...state.accCheckItems, accounts: []},
      currentPage: 0
    }
  }),

  //Фильтр по группе
  on(autoregFilterGroup, (state, {group}): AccountsInterface => {
    let filters: AutoRegFilterType = {...state.filter};

    filters.groups = filterUtil(filters.groups, group);

    let filtered = filteredGroupUtil(state.autoRegAccItems, state.autoRegAccItemsSelected, filters.groups, true);

    if (filters && filters.groups && filters.groups.length === 0) {
          filtered = state.autoRegAccItems;
      }

      if (state.searchText) {
          filtered = searchUtil(state.autoRegAccItems, filtered, state.searchText);
      }

      if (filters.owners && filters.owners.length !== 0) {
          filtered = filteredOwnerArrayUtil(state.autoRegAccItems, filtered, filters.owners, !!state.searchText);
      }

      if (filters.otherFilters && filters.otherFilters.length !== 0) {
          filtered = otherFiltersCheck(filtered, state.autoRegAccItems, filters.otherFilters, state.currentUserRole, true);
      }

      if (filters.currentDateStart && filters.currentDateEnd) {
          filtered = filterDateInterval(filtered, filters.currentDateStart, filters.currentDateEnd);
      }

      let pageCount = pageCountUtil(filtered, state.pageSize);
      let page = getPostsForPage(0, state.pageSize, filtered);


      let countCheckAccInCurrentPage = false;
      if (page.length === page.filter(item => item.checked === true).length
          && page.length !== 0) {
          countCheckAccInCurrentPage = true;
      }

      if (countCheckAccInCurrentPage) {
          page.map(item => {
              return {...item, checked: true}
          });
      }

      return {
          ...state,
          autoRegAccItemsSelected: filtered,
          accOfDownload: filtered,
          filter: filters,
          currentPage: 0,
          currentPageData: page,
          pageNumbers: pageCount,
          countCheckAccInCurrentPage: countCheckAccInCurrentPage,
          accUseOwnerItems: [],
          accIsCreateAP: [],
          accFbToolsItems: [],
          accSelectedItems: [],
          accToReLogin: [],
          accAddToGroup: [],
          accCheckItems: {...state.accCheckItems, accounts: []}
      }
  }),

  //Фильтр по состоянию
  on(autoRegAccSortState, (state, {stateValue}): AccountsInterface => {
    let filters: AutoRegFilterType = {...state.filter};

    filters.stateAcc = filterUtil(filters.stateAcc, stateValue);

    let filteredState = filteredStateUtil(state.autoRegAccItems, state.autoRegAccItemsSelected, filters.stateAcc, true);

    if (filters && filters.stateAcc && filters.stateAcc.length === 0) {
      filteredState = state.autoRegAccItems;
    }

    if (state.searchText) {
      filteredState = searchUtil(state.autoRegAccItems, filteredState, state.searchText);
    }

    if (filters.owners && filters.owners.length !== 0) {
      filteredState = filteredOwnerArrayUtil(state.autoRegAccItems, filteredState, filters.owners, !!state.searchText);
    }

    if (filters.otherFilters && filters.otherFilters.length !== 0) {
      filteredState = otherFiltersCheck(filteredState, state.autoRegAccItems, filters.otherFilters, state.currentUserRole, true);
    }

    if (filters.currentDateStart && filters.currentDateEnd) {
      filteredState = filterDateInterval(filteredState, filters.currentDateStart, filters.currentDateEnd);
    }

    let pageCount = pageCountUtil(filteredState, state.pageSize);
    let page = getPostsForPage(0, state.pageSize, filteredState);


    let countCheckAccInCurrentPage = false;
    if (page.length === page.filter(item => item.checked === true).length
      && page.length !== 0) {
      countCheckAccInCurrentPage = true;
    }

    if (countCheckAccInCurrentPage) {
      page.map(item => {
        return {...item, checked: true}
      });
    }

    return {
      ...state,
      autoRegAccItemsSelected: filteredState,
      accOfDownload: filteredState,
      filter: filters,
      currentPage: 0,
      currentPageData: page,
      pageNumbers: pageCount,
      countCheckAccInCurrentPage: countCheckAccInCurrentPage,
      accUseOwnerItems: [],
      accIsCreateAP: [],
      accFbToolsItems: [],
      accSelectedItems: [],
      accToReLogin: [],
      accAddToGroup: [],
      accCheckItems: {...state.accCheckItems, accounts: []}
    }
  }),

  on(autoRegAccSortOther, (state, {checkbox}): AccountsInterface => {
      let filtered: AutoRegItemType[];
      let filters: AutoRegFilterType = {...state.filter};

      filters.otherFilters = otherFilterUtil(filters.otherFilters, checkbox);

      filtered = otherFiltersCheck(state.autoRegAccItemsSelected, state.autoRegAccItems, filters.otherFilters, state.currentUserRole, true);

      if (state.searchText) {
        filtered = searchUtil(state.autoRegAccItems, filtered, state.searchText);
      }

      if (filters.stateAcc && filters.stateAcc.length !== 0) {
        filtered = filteredStateUtil(state.autoRegAccItems, filtered, filters.stateAcc, !!state.searchText);
      }

      if (filters.owners && filters.owners.length !== 0) {
        filtered = filteredOwnerArrayUtil(state.autoRegAccItems, filtered, filters.owners, !!state.searchText);
      }

      let pageCount = pageCountUtil(filtered, 20);
      let page = getPostsForPage(0, state.pageSize, filtered);

      let countCheckAccInCurrentPage = false;
      if (page.length === page.filter(item => item.checked === true).length && page.length !== 0) {
        countCheckAccInCurrentPage = true;
      }

      if (countCheckAccInCurrentPage) {
        page.map(item => {
          return {...item, checked: true}
        });
      }

      return {
        ...state,
        autoRegAccItemsSelected: filtered,
        accOfDownload: filtered,
        filter: filters,
        currentPage: 0,
        currentPageData: page,
        pageNumbers: pageCount,
        countCheckAccInCurrentPage: countCheckAccInCurrentPage,
        accUseOwnerItems: [],
        accIsCreateAP: [],
        accFbToolsItems: [],
        accSelectedItems: [],
        accToReLogin: [],
        accAddToGroup: [],
        accCheckItems: {...state.accCheckItems, accounts: []}
      };
    }
  ),

  //Фильтр по пользователю
  on(autoRegAccSortOwner, (state, {owner}): AccountsInterface => {
      let filters: AutoRegFilterType = {...state.filter};

      filters.owners = filterOwnerUtil(filters.owners, owner.toString());

      let filteredOwner = filteredOwnerUtil(state.autoRegAccItems, state.autoRegAccItemsSelected, filters.owners, true);

      if (filters && filters.owners && filters.owners.length === 0) {
        filteredOwner = state.autoRegAccItems;
      }

      if (state.searchText) {
        filteredOwner = searchUtil(state.autoRegAccItems, filteredOwner, state.searchText);
      }

      if (filters.stateAcc && filters.stateAcc.length !== 0) {
        filteredOwner = filteredStateUtil(state.autoRegAccItems, filteredOwner, filters.stateAcc, !!state.searchText);
      }

      if (filters.otherFilters && filters.otherFilters.length !== 0) {
        filteredOwner = otherFiltersCheck(filteredOwner, state.autoRegAccItems, filters.otherFilters, state.currentUserRole, true);
      }

      if (filters.currentDateStart && filters.currentDateEnd) {
        filteredOwner = filterDateInterval(filteredOwner, filters.currentDateStart, filters.currentDateEnd);
      }

      let pageCount = pageCountUtil(filteredOwner, state.pageSize);
      let page = getPostsForPage(0, state.pageSize, filteredOwner);

      let countCheckAccInCurrentPage = false;
      if (page.length === page.filter(item => item.checked === true).length
        && page.length !== 0) {
        countCheckAccInCurrentPage = true;
      }

      if (countCheckAccInCurrentPage) {
        page.map(item => {
          return {...item, checked: true}
        });
      }

      return {
        ...state,
        autoRegAccItemsSelected: filteredOwner,
        accOfDownload: filteredOwner,
        filter: filters,
        currentPage: 0,
        currentPageData: page,
        pageNumbers: pageCount,
        countCheckAccInCurrentPage: countCheckAccInCurrentPage,
        accUseOwnerItems: [],
        accIsCreateAP: [],
        accFbToolsItems: [],
        accSelectedItems: [],
        accToReLogin: [],
        accAddToGroup: [],
        accCheckItems: {...state.accCheckItems, accounts: []}
      };
    }
  ),

  on(autoRegDeletedFilter, (state): AccountsInterface => {
      const filter: AutoRegFilterType = {
        sortValue: '',
        stateAcc: [],
        owners: [],
        currentDateStart: state.dataForCharts.minDate,
        currentDateEnd: state.dataForCharts.maxDate,
        otherFilters: [],
        groups: []
      }

      let pageCount = pageCountUtil(state.autoRegAccItems, state.pageSize);
      let page = getPostsForPage(state.currentPage, state.pageSize, state.autoRegAccItems);

      return {
        ...state,
        autoRegAccItemsSelected: state.autoRegAccItems,
        accOfDownload: state.autoRegAccItems,
        filter: filter,
        pageNumbers: pageCount,
        currentPageData: page,
      }
    }
  ),

  on(autoRegTrChecked, (state, {id}) => {

    //отмечаем аккаунты с помощью чекбоксов
    let page: AutoRegItemType[] = TrCheckedUtil(state.currentPageData, id);

    //Проверяем, все ли аккаунты чекнуты на текущей странице
    let countCheckAccInCurrentPage = false;
    if (page.length === page.filter(item => item.checked).length) {
      countCheckAccInCurrentPage = true;
    }
    //Все выделенные аккаунты
    let isCheckedTr: AutoRegItemType[] = page.filter(item => item.checked);

    //Аккаунты, доступные для создания профиля в AP
    let accCreateIsAP: AutoRegItemType[] = page.filter(item => item.checked && item.ap_id === null);

    //Аккаунты, доступные для релогина
    let accToReLogin: AutoRegItemType[] = page.filter(item =>
      item.checked && item.ap_id !== null && item.state !== 'Фарм');

    //Аккаунты, доступные для проверки
    let accCheck = page.filter(item => item.checked && item.state !== 'Фарм');

    //аккаунты, доступные для присвоения владельца
    let accUseOwner = getAccFbToolsUtil(page, true, state.currentUserRole);
    let accDeleteOwnerItems = accUseOwner.filter(item => item.owner_id !== null);

    //аккаунты для добавления в FB-Tools
    let accFbTool = page.filter(item =>
      item.checked && item.state === 'Используется' && item.owner_id === state.currentUserID)

    return {
      ...state,
      currentPageData: page,
      accSelectedItems: isCheckedTr,
      accAddToGroup: isCheckedTr,
      accToReLogin: accToReLogin,
      countCheckAccInCurrentPage: countCheckAccInCurrentPage,
      accUseOwnerItems: accUseOwner,
      accDeleteOwnerItems: accDeleteOwnerItems,
      accFbToolsItems: accFbTool,
      accIsCreateAP: accCreateIsAP,
      accOfDownload: isCheckedTr.length !== 0 ? isCheckedTr : state.autoRegAccItemsSelected,
      accCheckItems: {...state.accCheckItems, accounts: accCheck}
    }
  }),

  on(autoRegSelectedAPAction, (state, {id}) => {

    return {
      ...state,
      accIsCreateAP: state.currentPageData.filter(item => item.id === id)
    }
  }),


  on(downloadTableDataItems, (state, payload) => {

    let table = state.accFbToolsItems.map((item, index) => ({
      'Номер': index + 1,
      'Имя': item.name,
      'Фамилия': item.surname,
      'UA': item.ua,
      'cookie': item.cookie,
      'Пароль': item.password,
      'FBTools': `${item.name} ${item.surname}|${item.ua}|${item.cookie}|${item.password}|`
    }));

    let dataForFbToolsQuery = state.accFbToolsItems.map(item => {
      return {
        token: item.token,
        name: item.name + ' ' + item.surname,
        cookie: item.cookie,
        proxy: '',
        ua: item.ua,
        group: 0,
        dataForFbTools: `${item.name} ${item.surname}|${item.ua}|${item.cookie}|${item.password}|`,
        email: item.email
      }
    })

    return {
      ...state,
      tableData: null,
      tableDataIsDownload: table,
      fbGroupFormatData: dataForFbToolsQuery
    }
  }),

  on(autoRegFbAddAction, (state, {request}) => {
    return {
      ...state,
      currentGroupFB: request.group
    }
  }),

  on(autoRegFbAddActionSuccess, (state, {response, request}) => {


    let newAutoregAccItemsSelected = updateState(state.autoRegAccItemsSelected, request);
    let autoregAccItems = updateState(state.autoRegAccItems, request);

    let newPage = getPostsForPage(state.currentPage, state.pageSize, newAutoregAccItemsSelected);

    function updateState(defaultArr: AutoRegItemType[], response: AutoRegAddFbToolsType): AutoRegItemType[] {
      return defaultArr.map(item => {
        if (item.token === response.token) {
          return {...item, state: 'Добавлен в FB Tool'}
        }
        return item;
      })
    }

    return {
      ...state,
      popUpStatus: 'Закрыть',
      autoRegAccItems: autoregAccItems,
      autoRegAccItemsSelected: newAutoregAccItemsSelected,
      currentPageData: newPage
    }
  }),

  on(autoRegOwnerUpdateAction, (state) => ({
    ...state
  })),

  on(autoRegOwnerUpdateSuccessAction, (state) => ({
    ...state,
  })),

  on(autoRegDeletedGroupAccActions, (state) => {
    return {
      ...state
    }
  }),

  on(autoRegDeletedGroupAccActionsSuccess, (state, {response}) => {

    let filter = {...state.filter};
    let currentDataPage = state.currentPageData.filter(item => !item.checked);
    if (currentDataPage.length === 0) {
      currentDataPage = getPostsForPage(0, state.pageSize, state.autoRegAccItems);
      filter.stateAcc = [];
    }

    let countCheckAccInCurrentPage = false;
    if (currentDataPage.length === currentDataPage.filter(item => item.checked).length) {
      countCheckAccInCurrentPage = true;
    }

    let accCreateIsAP = currentDataPage.filter(item => item.checked && item.ap_id === null);
    let accCheck = currentDataPage.filter(item => item.checked && item.ap_id !== null);
    let accUseOwner = getAccFbToolsUtil(currentDataPage, true, state.currentUserRole);

    return {
      ...state,
      currentPageData: currentDataPage,
      countCheckAccInCurrentPage: countCheckAccInCurrentPage,
      accSelectedItems: [],
      accUseOwnerItems: [],
      accToReLogin: [],
      filter: filter,
      accAddToGroup: [],
      popUpStatus: 'Закрыть',
      accUseOwner: accUseOwner,
      accIsCreateAP: accCreateIsAP,
      accCheckItems: {...state.accCheckItems, accounts: accCheck}
    }
  }),

  on(autoRegFbGroupActionSuccess, (state, {response}) => {
    return {
      ...state,
      fbToolGroup: response.data
    }
  }),

  on(autoRegGetProxyActionSuccess, (state, {response}) => {
    let fbProxy = [];

    if (response.data) {
      fbProxy = response.data.proxies;
    }
    return {
      ...state,
      fbProxy: fbProxy,
      fbToolGroup: {...state.fbToolGroup, requestsLeft: response.data.requestsLeft}
    }
  }),

  on(autoRegFbGroupActionFailure, (state, {response}) => ({
    ...state,
    fbToolGroup: null
  })),

  on(autoRegFbGroupActionError, (state, {error}) => ({
    ...state,
    fbToolGroup: null
  })),

  on(autoRegReLoginActionSuccess, (state, {response, request}) => {

    let accToReLogin = state.accToReLogin.filter(itemDefault => {
      return request.accounts.some(item => itemDefault.login !== item.login);
    });


    return {
      ...state,
      popUpStatus: 'Закрыть',
      accToReLogin: accToReLogin
    }
  }),


  on(autoRegCreateProfileAPAction, (state, {data}) => {
    let dataOfRequest = {
      name: data.name,
      accounts: []
    }

    dataOfRequest.accounts = state.accIsCreateAP.map(item => {
      return {
        ap_port: data.ap_port,
        login: item.login,
        email: item.email,
        password: item.password,
        twofa: item.twofa,
        device_name: data.device_name
      }
    });

    return {
      ...state
    }
  }),

  on(autoRegCreateProfileAPActionSuccess, (state, {response, data}) => {

    let newArray = state.currentPageData.map(itemMap => {
      data.accounts.forEach(item => {
        if (itemMap.email === item.email) {
          return {...itemMap, step: 'Создаю профиль AP'}
        }
      })
      return itemMap;
    })

    let accIsCreateAP = state.accIsCreateAP.filter(itemDefault => {
      data.accounts.forEach(item => {
        if (itemDefault.email !== item.email) {
          return item;
        }
      })
    })

    return {
      ...state,
      popUpStatus: 'Закрыть',
      currentPageData: newArray,
      accIsCreateAP: accIsCreateAP
    }
  }),

  on(autoRegCreateProfileAPItemActionSuccess, (state, {response}) => {

    return {
      ...state,
      popUpStatus: 'Закрыть'
    }
  }),

  on(autoRegUpdateSelfieActionSuccess, (state, {response, account}) => {
    let newArray = state.autoRegAccItemsSelected.map(item => {
      if (account.login === item.login) {
        return {...item, state: 'Требует проверки', step: 'Селфи', checkpoint: 'Yes'}
      }
      return item;
    })

    let page = getPostsForPage(state.currentPage, state.pageSize, newArray);

    return {
      ...state,
      autoRegAccItemsSelected: newArray,
      currentPageData: page
    }
  }),

  on(autoRegSetCheckAccParamsAction, (state, {params}) => {

    //устнавливаем параметры для проверки аккаунта
    let param = SetCheckParamsUtil(params, state.accCheckItems.filters);

    return {
      ...state,
      accCheckItems: {...state.accCheckItems, filters: param}
    }
  }),

  on(autoRegChangedAccActionSuccess, (state, {response}) => {

    return {
      ...state,
      popUpStatus: 'Закрыть',
      accCheckItems: {...state.accCheckItems, accounts: [], filters: []},
    }
  }),

  on(autoRegFbAddGroupActionSuccess, (state, {response, data}) => {

    let accUseOwnerItems = state.accUseOwnerItems.filter(itemFb => {
      data.forEach(item => {
        if (item.email === itemFb.email) {
          return itemFb;
        }
      })
    })

    let autoRegAccItems = updateState(state.autoRegAccItems, data);
    let newData = updateState(state.autoRegAccItemsSelected, data);
    let page = getPostsForPage(state.currentPage, state.pageSize, newData);

    function updateState(defaultArr: AutoRegItemType[], responseData: AutoRegAddFbToolsType[]): AutoRegItemType[] {
      let newData: AutoRegItemType[] = [];
      defaultArr.forEach(account => {
        let accountItem = {...account};

        responseData.forEach(item => {
          if (account.token === item.token) {
            accountItem.state = 'Добавлен в FB Tool'
          }
        });
        newData.push(accountItem);
      });

      return newData;
    }


    return {
      ...state,
      popUpStatus: 'Закрыть',
      autoRegAccItems: autoRegAccItems,
      accUseOwnerItems: accUseOwnerItems,
      autoRegAccItemsSelected: newData,
      currentPageData: page,
      accAddToGroup: [],
      accSelectedItems: [],
      accCheckItems: {...state.accCheckItems, accounts: [], filters: []}
    }
  }),

  on(autoRegCreateGroupActionSuccess, (state, {response}) => {

    return {
      ...state,
      popUpStatus: 'Закрыть',
    }
  }),

  on(autoRegAddToGroupActionSuccess, (state, {response, logins, groupName}) => {

    let newData = state.autoRegAccItems.map(account => {
      let accountItem = {...account};
      state.autoRegGroup.forEach(group => {
        logins.forEach(login => {
          if (group.name === groupName && login === account.login) {
            accountItem = {...account, group_name: groupName, type: group.type, group_id: group.id}
          }
        })
      })
      return accountItem;
    })

    let page = getPostsForPage(state.currentPage, state.pageSize, newData);

    return {
      ...state,
      autoRegAccItems: newData,
      currentPageData: page,
      popUpStatus: 'Закрыть',
      accUseOwnerItems: [],
      accIsCreateAP: [],
      accAddToGroup: [],
      accSelectedItems: [],
      accCheckItems: {...state.accCheckItems, accounts: [], filters: []}
    }
  }),

  on(autoRegDeletedGroupActionSuccess, (state, {response, group_id}) => {

    let newArrGroup = state.autoRegGroup.filter(item => item.id !== group_id);
    let selectedArr = state.autoRegAccItemsSelected.filter(item => item.group_id !== group_id);
    let defaultArr = state.autoRegAccItems.filter(item => item.group_id !== group_id);
    let page = getPostsForPage(state.currentPage, state.pageSize, selectedArr);

    return {
      ...state,
      autoRegAccItems: defaultArr,
      autoRegAccItemsSelected: selectedArr,
      currentPageData: page,
      autoRegGroup: newArrGroup,
      popUpStatus: 'Закрыть',
    }
  }),

  on(AutoRegSetOwnerSystemActionSuccess, (state, {response, request, update, stateAutoreg, id}) => {
    let userId = id;
    if (!id && id !== 0) {
      userId = state.currentUserID
    }

    let autoRegAccItems = AutoRegSetOwnerUtil(state.autoRegAccItems, request.accounts, userId, stateAutoreg);
    let autoRegAccItemsSelected = AutoRegSetOwnerUtil(state.autoRegAccItemsSelected, request.accounts, userId, stateAutoreg);
    let page = AutoRegSetOwnerUtil(state.currentPageData, request.accounts, userId, stateAutoreg);
    let accDeleteOwnerItems = state.accDeleteOwnerItems;
    let accToFbTools = state.accFbToolsItems;

    if (id !== 0) {
      accToFbTools = state.accUseOwnerItems.filter(itemDefault => {
        return request.accounts.some(item => {
          return itemDefault.login === item.login && itemDefault.checked;
        });
      });
      accDeleteOwnerItems = accToFbTools;
    }

    if (id === 0) {
      accToFbTools = state.accUseOwnerItems.filter(itemDefault => {
        return request.accounts.some(item => {
          return itemDefault.login !== item.login && itemDefault.checked;
        });
      });
      accDeleteOwnerItems = accToFbTools;
    }


    return {
      ...state,
      autoRegAccItems: autoRegAccItems,
      accOfDownload: autoRegAccItemsSelected,
      autoRegAccItemsSelected: autoRegAccItemsSelected,
      currentPageData: page,
      accDeleteOwnerItems: accDeleteOwnerItems,
      popUpStatus: 'Закрыть',
      accFbToolsItems: accToFbTools,
      // accAddToGroup: [],
      // accSelectedItems: [],
      // accCheckItems: {...state.accCheckItems, accounts: [], filters: []},
    }
  }),

  on(updateToLocalStorageProxyAndGroupFBAction, (state, {proxy, group}) => {
    return {
      ...state,
      currentGroupFB: group ? +group : state.currentGroupFB,
      currentProxyFB: proxy ? +proxy : state.currentProxyFB
    }
  }),

  on(setProcessesAction, (state, {payload}) => {

    return {
      ...state,
      processes: payload
    }
  })
)
