<button class="sort-button" mat-icon-button [matMenuTriggerFor]="menu" >
  <i class="fa-solid fa-people-group"></i>
</button>

<mat-menu #menu="matMenu" xPosition="before" class="menu">
  <button mat-menu-item class="menu-sort-item button"
          *ngFor="let item of autoregGroup$ | async"
          (click)="filterGroup(item)"
          [class.active]="(filterValue$ |  async).groups.includes(item.id)"

  >
    <span class="icon"><i class="fa-solid fa-check"></i></span>
    <span>{{item.name}}</span>
  </button>
</mat-menu>

