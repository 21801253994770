<ng-container *ngIf="typeBox === box.COUNT">
  <div class="info-box">
    <span class="info-box-icon bg-info"><i class="fa-solid fa-user"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">Аккаунтов всего</span>
      <span class="info-box-number">{{(statisticsTotal$ | async)?.count}}</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="typeBox === box.CHECKPOINT">
  <div class="info-box">
    <span class="info-box-icon bg-success"><i class="fa-solid fa-circle-check"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">Аккаунтов c чекпоинтом</span>
      <span class="info-box-number">{{(statisticsTotal$ | async)?.checkpoint}}</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="typeBox === box.READY">
  <div class="info-box">
    <span class="info-box-icon bg-warning"><i class="fa-solid fa-thumbs-up"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">Готово к использованию</span>
      <span class="info-box-number">{{(statisticsTotal$ | async)?.ready}}</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="typeBox === box.NO_OWNER">
  <div class="info-box">
    <span class="info-box-icon bg-danger"><i class="fa-solid fa-user-slash"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">Не имеет владельца</span>
      <span class="info-box-number">{{(statisticsTotal$ | async)?.ownerless}}</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="typeBox === box.COOKIE_TOKEN">
  <div class="info-box">
    <span class="info-box-icon bg-danger"><i class="fa-solid fa-cookie-bite"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">Нет cookie/token</span>
      <span class="info-box-number">
        <span>Cookie:</span> {{(statisticsTotal$ | async)?.not_cookie}}
        <span>Token:</span> {{(statisticsTotal$ | async)?.not_token}}
      </span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="typeBox === box.NO_TWOFA">
  <div class="info-box">
    <span class="info-box-icon bg-danger"><i class="fa-solid fa-circle-exclamation"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">Нет 2FA</span>
      <span class="info-box-number">{{(statisticsTotal$ | async)?.not_2fa}}</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="typeBox === box.CHECK">
  <div class="info-box">
    <span class="info-box-icon bg-danger"><i class="fa-solid fa-magnifying-glass"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">Аккаунты требуют проверки</span>
      <span class="info-box-number">{{(statisticsTotal$ | async)?.need_check}}</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="typeBox === box.WITH_AP">
  <div class="info-box">
    <span class="info-box-icon bg-primary"><i class="fa-solid fa-check-double"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">С AP</span>
      <span class="info-box-number">{{(statisticsTotal$ | async)?.with_ap}}</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="typeBox === box.BLOCKED">
  <div class="info-box">
    <span class="info-box-icon bg-danger"><i class="fa-solid fa-lock"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">Аккаунты заблокированы</span>
      <span class="info-box-number">{{(statisticsTotal$ | async)?.blocked}}</span>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="typeBox === box.REGISTERED_TODAY">
  <div class="info-box">
    <span class="info-box-icon bg-primary"><i class="fa-regular fa-calendar-check"></i></span>
    <div class="info-box-content">
      <span class="info-box-text">Зарегистрировано сегодня</span>
      <span class="info-box-number">{{(registeredToday$ | async)}}</span>
    </div>
  </div>
</ng-container>
