import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {
  downloadEmailsAction, downloadEmailsErrorAction, downloadEmailsFailureAction,
  downloadEmailsSuccessAction
} from "@/main/autoreg/registration/store/actions/downloadEmails.action";
import {RegerService} from "@/main/autoreg/services/reger.service";
import {Store} from "@ngrx/store";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {getConsumablesAction} from "@/main/autoreg/registration/store/actions/getConsumables.action";

@Injectable()
export class DownloadEmailsEffect {
  downloadEmailsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(downloadEmailsAction),
    switchMap(({file}) => {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      return this.regerService.downloadEmails(file).pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            return downloadEmailsSuccessAction()
          } else if (response.status === 'Failure') {
            return downloadEmailsFailureAction()
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if(error.status === 400 || 422) {
            return of(downloadEmailsErrorAction({error}));
          } else if (error.status === 500 || 501) {
            return of(downloadEmailsErrorAction({error}));
          } else {
            return of(downloadEmailsErrorAction({error}));
          }
        })
      );
    })
  ))

  downloadEmailsEffectSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(downloadEmailsSuccessAction),
      tap((): void => {
        this.store.dispatch(getConsumablesAction())
        this.store.dispatch(ButtonLoaderAction({load: false}))
        this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}));
        this.toaStr.info('Файл с почтами загружен')
      })
    ), {dispatch: false}
  )

  downloadEmailsEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(downloadEmailsFailureAction),
      tap((): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        this.toaStr.error('Ошибка загрузки')
      })
    ), {dispatch: false}
  )

  downloadEmailsEffectError$ = createEffect(
    () => this.actions$.pipe(
      ofType(downloadEmailsErrorAction),
      tap(({error}): void => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        this.toaStr.error('Ошибка загрузки ' + error.message)
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private regerService: RegerService,
              private toaStr: ToastrService,
              private store: Store
  ) {
  }
}
