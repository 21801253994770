import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";

export function searchUtil(defaultArr:AutoRegItemType[], value: AutoRegItemType[], search: string, defaultValue?: boolean): AutoRegItemType[] {

  let data = defaultValue ? defaultArr : value;

  if (!search.trim()) {
    return data;
  }

  return searchAcc(data, search);
}

export function searchAcc(arr, value) {
  let result = [];
  arr.forEach(item => {
    if (!result.includes(item)) {
      let res = searchValue(item, value);
      if (res) {
        result.push(res);
      }
    }
  })
  return result;
}

function searchValue(item: AutoRegItemType, value: string) {
  let result;
  Object.keys(item).forEach(accountKey => {
    if (item[accountKey] && item[accountKey].toString().toLowerCase().includes(value.toString().toLowerCase())) {
     result = item;
    }
  })
  return result;
}



