import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {AuthService} from "@/auth/services/auth.service";
import {loginAction, loginFailureAction, loginSuccessAction} from "@/auth/store/actions/login.action";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {StorageService} from "@/shared/services/storage.service";
import {Store} from "@ngrx/store";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";

@Injectable()
export class LoginEffect {
  login$ = createEffect(() => this.actions$.pipe(
    ofType(loginAction),
    switchMap(({request}) => {
      return this.authService.login(request).pipe(

        map((response: DefaultResponseType) => {
          this.storageService.set('isLoggedId', true);
          return loginSuccessAction({response})
        }),
        catchError((error: HttpErrorResponse) => {
          return of(loginFailureAction({error}));
        })
      );
    })
  ))

  redirectAfterSubmit$ = createEffect(
    () => this.actions$.pipe(
      ofType(loginSuccessAction),
      tap(() => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        this.toaStr.success('Вы успешно авторизовались')
        this.router.navigateByUrl('/');
      })
    ), {dispatch: false}
  )

  logInFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(loginFailureAction),
      tap(({error}) => {
        this.store.dispatch(ButtonLoaderAction({load: false}))
        if (error.error.detail === 'LOGIN_BAD_CREDENTIALS') {
          this.toaStr.error('Такого пользователя не существует. Проверьте введенные данные')
        } else {
          this.toaStr.error('Не удалось войти в систему')
        }
      })
    ), {dispatch: false}
  )

  constructor(private actions$: Actions,
              private authService: AuthService,
              private router: Router,
              private toaStr: ToastrService,
              private storageService: StorageService,
              private store: Store) {
  }
}
