import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {select, Store} from "@ngrx/store";
import {
  autoRegSetCheckAccParamsAction
} from "@/main/autoreg/accounts/store/actions/autoRegSetCheckAccParams.action";
import {accCheckItemsSelector, selectedTrAutoReg} from "@/main/autoreg/accounts/store/selectors";
import {Observable, Subscription} from "rxjs";
import {AccCheckItemsType} from "@/main/autoreg/accounts/store/type/accCheckItems.type";
import {autoRegChangedAccAction} from "@/main/autoreg/accounts/store/actions/autoRegChangedAcc.action";
import {ProxyDeviceConstants} from "../../../../../../constants/proxy-device.constants";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../../../environments/environment";
import {PopUpType} from "@/shared/types/popUp.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {currentUserPortSelector, defaultPortSelector} from "@/users/store/selectors";

@Component({
  selector: 'app-check-accounts',
  templateUrl: './check-accounts.component.html',
  styleUrls: ['./check-accounts.component.scss']
})
export class CheckAccountsComponent implements OnInit, OnDestroy {

  @Input() type: string;
  @Input() account: AutoRegItemType;

  public popUpType = PopUpType;
  public checkAccForm: UntypedFormGroup;
  public devices = ProxyDeviceConstants;
  public checked = true;
  public port: number = environment.ap_port;
  public disabledCheckbox: boolean = true; //если хотя бы один аккаунт не имее ap_id, то доступна только проверка чекпоинта

  public defaultPort$: Observable<number>;
  public currentUserPort$: Observable<number>;
  public selectedAcc$: Observable<AutoRegItemType[]>;
  public buttonLoader$: Observable<boolean>;
  public params$: Observable<AccCheckItemsType>;

  private subscriptionList: Subscription[] = [];
  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
    this.initForm();
    this.selectedAcc$.subscribe(data => {
      if (data) {
        let result = data.find(item => item.ap_id === null);
        this.disabledCheckbox = !!result;
      }
    })
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(s => s.unsubscribe());
  }

  public checkedPort(): void {
    this.checked = !this.checked;
  }

  public onChange(value): void {
    this.store.dispatch(autoRegSetCheckAccParamsAction({params: value}))
  }

  public ok(portValue: string): void {
    if (this.type === PopUpType.CHECK_ACC_ITEMS) {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(autoRegChangedAccAction(
        {
          device: this.checkAccForm.value.device,
          port: +portValue,
          typeRequest: 'group'
        }));
    } else {
      let dataRequest = {
        ap_id: this.account.ap_id ? this.account.ap_id : 'Not set',
        login: this.account.login,
        email: this.account.email,
        password: this.account.password,
        email_pass: this.account.email_pass,
        group_name: this.account.group_name,
        first_name: this.account.name,
        last_name: this.account.surname,
        bdate: this.account.str_birthday,
        twofa: this.account.twofa
      }
      this.store.dispatch(ButtonLoaderAction({load: true}))
      this.store.dispatch(autoRegChangedAccAction(
        {
          device: this.checkAccForm.value.device,
          port: +portValue,
          typeRequest: 'one',
          payload: dataRequest
        }));
    }
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  private initValue(): void {
    this.params$ = this.store.pipe(select(accCheckItemsSelector));
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
    this.selectedAcc$ = this.store.pipe(select(selectedTrAutoReg));
    this.defaultPort$ = this.store.pipe(select(defaultPortSelector));
    this.currentUserPort$ = this.store.pipe(select(currentUserPortSelector));
  }

  private initForm(): void {
    this.checkAccForm = new UntypedFormGroup({
      device: new UntypedFormControl('', Validators.required)
    })
  }
}
