import {createAction, props} from "@ngrx/store";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {ActionType} from "@/main/autoreg/accounts/store/action.type";

export const autoRegOwnerUpdateAction = createAction(
  ActionType.AUTO_REG_UPDATE
);


export const autoRegOwnerUpdateSuccessAction = createAction(
  ActionType.AUTO_REG_OWNER_UPDATE_SUCCESS,
  props<{ response: DefaultResponseType }>()

);

export const autoRegOwnerUpdateFailureAction = createAction(
  ActionType.AUTO_REG_OWNER_UPDATE_FAILURE,
  props<{ response: DefaultResponseType }>()
);

export const autoRegOwnerUpdateErrorAction = createAction(
  ActionType.AUTO_REG_OWNER_UPDATE_ERROR,
  props<{ error: HttpErrorResponse }>()
);
