import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatsComponent } from './stats.component';
import { RegisterOfTimeDataComponent } from './components/register-of-time-data/register-of-time-data.component';
import {NgChartsModule} from "ng2-charts";
import { BoxStatComponent } from '@/main/autoreg/stats/components/box-stat/box-stat.component';
import { StepRegisterAccComponent } from '@/main/autoreg/stats/components/step-register-acc/step-register-acc.component';
import {MatTabsModule} from "@angular/material/tabs";
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import { RegisterOfTimeCountComponent } from './components/register-of-time-count/register-of-time-count.component';
import { StateRegisterAccComponent } from '@/main/autoreg/stats/components/state-register-acc/state-register-acc.component';
import {MatChipsModule} from "@angular/material/chips";
import {MatSelectModule} from "@angular/material/select";



@NgModule({
  declarations: [
    StatsComponent,
    RegisterOfTimeDataComponent,
    BoxStatComponent,
    StepRegisterAccComponent,
    DatePickerComponent,
    RegisterOfTimeCountComponent,
    StateRegisterAccComponent
  ],
  exports: [
    RegisterOfTimeDataComponent,
    RegisterOfTimeCountComponent,
    BoxStatComponent,
    DatePickerComponent
  ],
  imports: [
    CommonModule,
    NgChartsModule,
    MatTabsModule,
    MatInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatChipsModule,
    MatSelectModule,
  ]
})
export class StatsModule { }
