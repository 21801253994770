import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {FbGroupType} from "@/main/autoreg/accounts/store/type/autoRegFbToolGroup.type";
import {select, Store} from "@ngrx/store";
import {AutoRegAddFbToolsType} from "@/main/autoreg/accounts/store/type/autoRegAddFbTools.type";
import {saveToExelAutoReg} from "@/main/autoreg/accounts/store/actions/autoRegAccTable.actions";
import {autoRegFbAddGroupAction} from "@/main/autoreg/accounts/store/actions/autoRegFbAddGroup.action";
import {
  accUseOwnerSelector, currentGroupFBSelector, currentProxyFBSelector,
  fbToolGroupDataForRequest,
  fbToolGroupRequestLeftSelector,
  fbToolGroupSelector, fbToolProxySelector
} from "@/main/autoreg/accounts/store/selectors";
import {autoRegClosePopupAction} from "@/main/autoreg/registration/store/actions/autoRegClosePopup.action";
import {ProxyDeviceConstants} from "../../../../../../constants/proxy-device.constants";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {buttonLoaderSelector} from "@/ui/store/selectors";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {FbToolProxyType} from "@/main/autoreg/accounts/store/type/fbToolProxy.type";
import {UsersType} from "@/users/store/type/users.type";
import {currentUserSelector, defaultApiKeyFBToolSelector} from "@/users/store/selectors";
import {
  updateToLocalStorageProxyAndGroupFBAction
} from "@/main/autoreg/accounts/store/actions/updateToLocalStorageProxyAndGroupFB.action";

@Component({
  selector: 'app-fb-tools-items',
  templateUrl: './fb-tools-items.component.html',
  styleUrls: ['./fb-tools-items.component.scss']
})

export class FbToolsItemsComponent implements OnInit, OnDestroy {

  @Output() dataPopUp: EventEmitter<any> = new EventEmitter();

  public devices = ProxyDeviceConstants;
  public fbToolFormItems: UntypedFormGroup;
  public dataForQuery: AutoRegAddFbToolsType[];

  public autoRegToFBTools$: Observable<AutoRegItemType[]>;
  public fbGroup$: Observable<FbGroupType>;
  public requestCount$: Observable<number>;
  public dataForQuery$: Observable<AutoRegAddFbToolsType[]>;
  public buttonLoader$: Observable<boolean>;
  public fbToolProxy$: Observable<FbToolProxyType>;
  public currentGroup: number;
  public currentProxy: number;

  private currentProxy$: Observable<number>;
  private currentGroup$: Observable<number>;
  private currentUser$: Observable<UsersType>;
  private defaultApiKeyFbTool$: Observable<string>;
  private defaultApiKeyFbTool: string;
  private currentUser: UsersType;
  private subscriptionList: Subscription[] = [];

  set sub(sub: Subscription) {
    this.subscriptionList.push(sub);
  }

  constructor(private store: Store) {
  }

  public ngOnInit(): void {
    this.initValue();
    this.sub = this.dataForQuery$.subscribe(data => {
      this.dataForQuery = data
    });

    this.sub = this.currentUser$.subscribe(data => data ? this.currentUser = data : null);
    this.sub = this.defaultApiKeyFbTool$.subscribe(data => data ? this.defaultApiKeyFbTool = data : null);

    this.sub = this.currentGroup$.subscribe(data => {
      if (data) {
        this.currentGroup = data;
      }
    });
    this.sub = this.currentProxy$.subscribe(data => {
      if (data) {
        this.currentProxy = data;
      }
    });

    this.initForm(this.currentGroup, this.currentProxy)
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach(item => item.unsubscribe())
  }

  public okConstellation(): void {
    let request = this.dataFormatFbTool();

    this.store.dispatch(ButtonLoaderAction({load: true}))
    this.store.dispatch(autoRegFbAddGroupAction({request}))

    this.store.dispatch(updateToLocalStorageProxyAndGroupFBAction({group: this.fbToolFormItems.value.group}))
    this.store.dispatch(updateToLocalStorageProxyAndGroupFBAction({proxy: this.fbToolFormItems.value.proxy}))
  }

  public noConstellation(): void {
    this.store.dispatch(autoRegClosePopupAction({open: 'Закрыть'}))
  }

  public saveToExel(): void {
    this.store.dispatch(saveToExelAutoReg({}))
  }

  private dataFormatFbTool(): AutoRegAddFbToolsType[] {
    let request = [];
    if (this.dataForQuery) {
      request = this.dataForQuery.map(item => {
        return {
          api_key: this.currentUser.fbtool_apikey ? this.currentUser.fbtool_apikey : this.defaultApiKeyFbTool,
          token: item.token,
          name: item.name,
          cookie: item.cookie,
          proxy: this.fbToolFormItems.value.proxy.toString(),
          ua: item.ua,
          group: this.fbToolFormItems.value.group
        }
      })
    }
    return request;
  }

  private initForm(group, proxy): void {
    this.fbToolFormItems = new UntypedFormGroup({
      group: new UntypedFormControl(group ? group.toString() : '', Validators.required),
      proxy: new UntypedFormControl(proxy ? proxy.toString() : '', Validators.required)
    })
  }

  private initValue(): void {
    this.fbGroup$ = this.store.pipe(select(fbToolGroupSelector));
    this.requestCount$ = this.store.pipe(select(fbToolGroupRequestLeftSelector));
    this.dataForQuery$ = this.store.pipe(select(fbToolGroupDataForRequest));
    this.autoRegToFBTools$ = this.store.pipe(select(accUseOwnerSelector));
    this.buttonLoader$ = this.store.pipe(select(buttonLoaderSelector));
    this.fbToolProxy$ = this.store.pipe(select(fbToolProxySelector));
    this.currentUser$ = this.store.pipe(select(currentUserSelector));
    this.defaultApiKeyFbTool$ = this.store.pipe(select(defaultApiKeyFBToolSelector));

    this.currentProxy$ = this.store.pipe(select(currentProxyFBSelector));
    this.currentGroup$ = this.store.pipe(select(currentGroupFBSelector));
  }
}


