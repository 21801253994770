import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {UsersService} from "@/users/services/users.service";
import {
  deleteUsersAction,
  deleteUsersActionFailure,
  deleteUsersActionSuccess
} from "@/users/store/actions/deleteUsers.action";
import {ToastrService} from "ngx-toastr";
import {ButtonLoaderAction} from "@/ui/store/actions/buttonLoader.action";
import {Store} from "@ngrx/store";

@Injectable()
export class DeleteUserEffect {
  deleteUserEffect$ = createEffect(() => this.actions$.pipe(
    ofType(deleteUsersAction),
    switchMap(({id}) => {
      this.store.dispatch(ButtonLoaderAction({load: true}))
      return this.usersService.deleteUsers(id).pipe(
        map((response: DefaultResponseType) => {
          this.store.dispatch(ButtonLoaderAction({load: false}))
          return deleteUsersActionSuccess({response, id: id})
        }),
        catchError((error: HttpErrorResponse) => {
          this.store.dispatch(ButtonLoaderAction({load: false}))
          return of(deleteUsersActionFailure({ error }));
        })
      );
    })
  ))

  deleteUserEffectSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(deleteUsersActionSuccess),
      tap(({response}) => {
        if(response.status === 'Success') {
          this.toaStr.success(response.details.msg)
        } else if (response.status === 'Failure') {
          this.toaStr.error(response.details.msg)
        }
      })
    ), {dispatch: false}
  )

  deleteUserEffectFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(deleteUsersActionFailure),
      tap((error) => {
        this.toaStr.error('Не удалось удалить пользователя. Попробуйте позже')
      })
    ), {dispatch: false}
  )


  constructor(private actions$: Actions,
              private usersService: UsersService,
              private toaStr: ToastrService,
              private store: Store) {
  }
}
