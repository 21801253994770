<div class="check-acc h-100">
  <div class="row">
    <div class="col-12">
      <div class="card card-danger mb-0" style="border: 0 solid rgba(0,0,0,.125);">
        <div class="card-header">
          <h3 *ngIf="type === popUpType.CHECK_ACC_ITEM" class="card-title">Проверка аккаунта {{account.login}}</h3>
          <h3 *ngIf="type === popUpType.CHECK_ACC_ITEMS" class="card-title">Проверка аккаунтов</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 pl-3 pr-3" [formGroup]="checkAccForm">

              <mat-form-field class="example-full-width w-100" appearance="fill">
                <mat-label>Устройство</mat-label>
                <mat-select formControlName="device">
                  <mat-option *ngFor="let item of devices" [value]="item.value">{{item.device}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="example-full-width w-100">
                <mat-label>Порт</mat-label>
                <input type="number" matInput placeholder="Порт"
                       [value]="checked ? (defaultPort$ | async).toString() : (currentUserPort$ | async).toString()"
                       [readonly]="checked"
                       #inputElement
                >
              </mat-form-field>

              <mat-checkbox class="example-margin w-100" color="primary"
                            (click)="checkedPort()"
                            checked
              >
                <span>Использовать стандартный порт</span>
              </mat-checkbox>

              <mat-checkbox class="example-margin w-100" color="accent"
                              *ngxPermissionsOnly="['ADMIN', 'WEBMASTER']"
                              [checked]="(params$ | async).filters.includes('checkpoint') && !(params$ | async).filters.includes('update')"
                              (click)="onChange('checkpoint')">Checkpoint
              </mat-checkbox>

              <mat-checkbox class="example-margin w-100 check-acc-checkbox" color="accent"
                            *ngxPermissionsOnly="['ADMIN', 'WEBMASTER']"
                            [checked]="(params$ | async).filters.includes('2FA') && !(params$ | async).filters.includes('update')"
                            [disabled]="type === popUpType.CHECK_ACC_ITEM && account.ap_id === null || disabledCheckbox"
                            (click)="onChange('2FA')">Двухфакторная аутентификация
              </mat-checkbox>

              <mat-checkbox class="example-margin w-100 check-acc-checkbox" color="accent"
                            *ngxPermissionsOnly="['ADMIN', 'WEBMASTER']"
                            [checked]="(params$ | async).filters.includes('post') && !(params$ | async).filters.includes('update')"
                            [disabled]="type === popUpType.CHECK_ACC_ITEM && account.ap_id === null || disabledCheckbox"
                            (click)="onChange('post')">Проверка почты
              </mat-checkbox>

              <mat-checkbox class="example-margin w-100 check-acc-checkbox" color="warn"
                            [checked]="(params$ | async).filters.includes('update')"
                            [disabled]="type === popUpType.CHECK_ACC_ITEM && account.ap_id === null || disabledCheckbox"
                            (click)="onChange('update')">Обновить метаданные (cookie, token, ua)
              </mat-checkbox>


            </div>

            <div class="col-6 mt-2">
              <button class="w-100" mat-raised-button color="warn"
                      (click)="noConstellation()"
              >Отмена
              </button>
            </div>

            <div class="col-6 mt-2">
              <button class="w-100" mat-raised-button color="primary"
                      [disabled]="(params$ | async).filters.length === 0 || checkAccForm.invalid || (buttonLoader$ | async)"
                      (click)="ok(inputElement.value)"
              >
                <span *ngIf="!(buttonLoader$ | async)">Начать</span>
                <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
