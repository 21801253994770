
import {TableDownloadType} from "@/main/autoreg/accounts/store/type/tableDownload.type";
import {AutoRegFilterType} from "@/main/autoreg/accounts/store/type/AutoRegFilter.type";
import {AutoRegFbToolGroupType} from "@/main/autoreg/accounts/store/type/autoRegFbToolGroup.type";
import {AutoRegAddFbToolsType} from "@/main/autoreg/accounts/store/type/autoRegAddFbTools.type";
import {DataForChartsType} from "@/main/autoreg/stats/store/type/DataForCharts.type";
import {AccCheckItemsType} from "@/main/autoreg/accounts/store/type/accCheckItems.type";
import {AutoRegGroupType} from "@/main/autoreg/accounts/store/type/autoRegGroup.type";
import {AutoRegItemType} from "@/main/autoreg/accounts/store/type/autoRegItem.type";
import {FbToolProxyType} from "@/main/autoreg/accounts/store/type/fbToolProxy.type";
import {AutoRegProcessItemsType} from "@/main/autoreg/registration/store/type/autoRegProcessItems.type";

export interface AccountsInterface {
  columns: string[], //названия столбцов таблицы
  autoRegAccItems: AutoRegItemType[], //дефолтные данные
  autoRegGroup: AutoRegGroupType[],
  autoRegAccItemsSelected: AutoRegItemType[], //отфильтрованные данные
  accToReLogin: AutoRegItemType[], //автореги, доступные для релогина
  accUseOwnerItems: AutoRegItemType[], //автореги, которым можно назначить владельца
  accDeleteOwnerItems: AutoRegItemType[], //автореги, у которых можно удалить владеьльца
  accFbToolsItems: AutoRegItemType[], //автореги, которые можно добавить в FB-Tool
  accCheckItems: AccCheckItemsType, //данные, подготовленные для проверки аккаунтов
  accAsSelfie: AutoRegItemType[], //данные аккаунтов со статусом 'Селфи', которые нужно отправить на провреку
  accOfDownload: AutoRegItemType[] //данные для загрузки в Exel-таблицу
  searchText: string, // Текст поиска
  isCheckedTr: boolean, //флаг для определения, есть ли чекнутые аккаунты. Используется для згарузки данных в EXCEL
  accSelectedItems: AutoRegItemType[], //Выделенные строки
  accAddToGroup: AutoRegItemType[],
  accIsCreateAP: AutoRegItemType[], //аккаунты, доступные для создания профиля в AP
  tableDataIsDownload: TableDownloadType[], //данные для загрузки в EXCEL c вкладки FB-Tools
  tableSettingsDefault: string[], //настройка столбцов таблицы по умолчания
  tableSettings: string[], //настройки таблицы
  popUpStatus: string | boolean, //флаг состояния поп-апа
  filter: AutoRegFilterType, //фильтры
  fbToolGroup: AutoRegFbToolGroupType, //группы для fb-tool и количество доступных запросов
  fbProxy: FbToolProxyType,
  fbGroupFormatData: AutoRegAddFbToolsType[], //данные для отравки на fb-tool
  currentPage: number, //Текущая страница
  currentPageData: AutoRegItemType[], //Записи для текущей страницы
  countCheckAccInCurrentPage: boolean, //Флаг, который определяет, все ли записи на странице выделены
  pageNumbers: number[], //Данные о количестве страниц. Используется тоьлько для отображения пагинатора
  pageSize: number, //Количество записей на странице
  dataForCharts: DataForChartsType //Данные для статистики

  currentUserID: number //Текущий пользователь
  currentUserRole: string //Роль текущего пользователя

  currentGroupFB: number //Текущая группа для подставления в FB-Tools
  currentProxyFB: number //Текущий прокси для подставления в FB-Tools

  processes: AutoRegProcessItemsType
}

export const initialState: AccountsInterface = {
  columns: [],
  autoRegAccItems: [],
  autoRegGroup: [],
  autoRegAccItemsSelected: [],
  accToReLogin: [],
  accUseOwnerItems: [],
  accDeleteOwnerItems: [],
  accFbToolsItems: [],
  accCheckItems: {
    accounts: [],
    filters: []
  },
  accOfDownload: [],
  searchText: '',
  isCheckedTr: true,
  accIsCreateAP: [],
  accSelectedItems: [],
  accAddToGroup: [],
  tableDataIsDownload: [],
  tableSettingsDefault: ['ap_id', 'state', 'group_name', 'owner_id', 'checkpoint', 'login', 'password', 'twofa', 'email', 'email_pass', 'name', 'surname', 'cookie', 'type', 'date'],
  tableSettings: [],
  popUpStatus: true,
  filter: {
    sortValue: 'dateOld',
    stateAcc: ['Используется', 'Готов', 'Relogin закончен', 'Проверка закончена'],
    owners: [],
    currentDateStart: null,
    currentDateEnd: null,
    otherFilters: [],
    groups: []
  },
  fbToolGroup: {
    groups: [],
    requestsLeft: 0
  },
  fbProxy: [],
  fbGroupFormatData: [],
  currentPage: 0,
  currentPageData: [],
  countCheckAccInCurrentPage: false,
  pageNumbers: [],
  pageSize: 30,
  dataForCharts: {
    maxDate: null,
    minDate: null,
    registeredToDay:  0
  },
  accAsSelfie: [],

  currentUserID: null,
  currentUserRole: null,

  currentGroupFB: null,
  currentProxyFB: null,

  processes: []
}
