import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, from, withLatestFrom, mergeMap, tap} from "rxjs";
import {AutoRegService} from "@/main/autoreg/services/auto-reg.service";
import {
  autoRegOwnerUpdateAction, autoRegOwnerUpdateErrorAction,
  autoRegOwnerUpdateSuccessAction
} from "@/main/autoreg/accounts/store/actions/autoRegOwnerUpdate.action";
import {select, Store} from "@ngrx/store";
import {Injectable} from "@angular/core";
import {selectedTrAutoReg} from "@/main/autoreg/accounts/store/selectors";


@Injectable()
export class AutoRegOwnerUpdateEffect {
  constructor(
    private actions$: Actions,
    private autoRegService: AutoRegService,
    private store: Store
  ) {}

  autoRegOwnerUpdateEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(autoRegOwnerUpdateAction),
      withLatestFrom(this.store.pipe(select(selectedTrAutoReg))),
      mergeMap(([action, selectedTr]) =>
        from(selectedTr).pipe(
          mergeMap(item =>
            this.autoRegService.updateAutoReg(item).pipe(
              map(response => autoRegOwnerUpdateSuccessAction({ response })),
              catchError(error => of(autoRegOwnerUpdateErrorAction({ error })))
            )
          )
        )
      )
    )
  );
}
