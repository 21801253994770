import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutoregComponent} from "@/main/autoreg/autoreg.component";
import {AutoRegService} from "@/main/autoreg/services/auto-reg.service";
import {EffectsModule} from "@ngrx/effects";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "@/shared/shared.module";
import {AutoRegAccEffect} from "@/main/autoreg/accounts/store/effects/autoRegAcc.effect";
import {AutoRegTerminateEffect} from "@/main/autoreg/registration/store/effects/autoRegTerminate.effect";
import {AutoRegProcessItemsEffect} from "@/main/autoreg/registration/store/effects/AutoRegProcessItems.effect";
import {AutoRegProcessPauseEffect} from "@/main/autoreg/registration/store/effects/autoRegProcessPause.effect";
import {AutoRegProcessPlayEffect} from "@/main/autoreg/registration/store/effects/autoRegProcessPlay.effect";
import {AutoRegAccDeletedEffect} from "@/main/autoreg/accounts/store/effects/autoRegAccDeleted.effect";
import {AutoRegAccUpdateEffect} from "@/main/autoreg/accounts/store/effects/autoRegAccUpdate.effect";
import {TableEffects} from "@/main/autoreg/accounts/store/effects/autoRegAccTable.effect";
import {AutoRegOwnerUpdateEffect} from "@/main/autoreg/accounts/store/effects/autoRegOwnerUpdate.effect";
import {AutoRegDeletedGroupAccEffect} from "@/main/autoreg/accounts/store/effects/autoRegDeletedGroupAcc.effect";
import {FbToolsService} from "@/main/autoreg/services/fb-tools.service";
import {AutoRegFbGroupEffect} from "@/main/autoreg/accounts/store/effects/autoRegFbGroup.effect";
import {AutoRegFbAddEffect} from "@/main/autoreg/accounts/store/effects/autoRegFbAdd.effect";
import {AutoRegFbAddGroupEffect} from "@/main/autoreg/accounts/store/effects/autoRegFbAddGroup.effect";
import {AutoRegReLoginEffect} from "@/main/autoreg/accounts/store/effects/autoRegReLogin.effect";
import {AutoregRoutingModule} from "@/main/autoreg/autoreg-routing.module";
import {RegistrationModule} from "@/main/autoreg/registration/registration.module";
import {AddFarmEffect} from "@/main/autoreg/registration/store/effects/addFarm.effect";
import {StatsModule} from "@/main/autoreg/stats/stats.module";
import {AutoRegUpdateSelfieEffect} from "@/main/autoreg/accounts/store/effects/autoRegUpdateSelfie.effect";
import {AutoRegCreateAPEffect} from "@/main/autoreg/accounts/store/effects/autoRegCreateAP.effect";
import {AutoRegChangeAccEffect} from "@/main/autoreg/accounts/store/effects/autoRegChangeAcc.effect";
import {AutoRegCreateAPItemEffect} from "@/main/autoreg/accounts/store/effects/autoRegCreateAPItem.effect";
import {IntegrationToScenumEffect} from "@/main/autoreg/registration/store/effects/integrationToScenum.effect";
import {GetRegEffect} from "@/main/autoreg/registration/store/effects/getReg.effect";
import {UpdateRegerEffect} from "@/main/autoreg/registration/store/effects/updateReger.effect";
import {GetConsumablesEffect} from "@/main/autoreg/registration/store/effects/getConsumables.effect";
import {GetStatisticsEffect} from "@/main/autoreg/stats/store/effects/getStatistics.effect";
import {GetStatisticsTodayEffect} from "@/main/autoreg/stats/store/effects/getStatisticsToday.effect";
import {GetStatisticsAllEffect} from "@/main/autoreg/stats/store/effects/getStatisticsAll.effect";
import {ProcessService} from "@/main/autoreg/services/process.service";
import {RegerService} from "@/main/autoreg/services/reger.service";
import {AutoRegGetGroupEffect} from "@/main/autoreg/accounts/store/effects/autoRegGetGroup.effect";
import {AccountsModule} from "@/main/autoreg/accounts/accounts.module";
import {AutoRegAddToGroupEffect} from "@/main/autoreg/accounts/store/effects/autoRegAddToGroup.effect";
import {AutoRegSetOwnerEffect} from "@/main/autoreg/accounts/store/effects/autoRegSetOwner.effect";
import {AutoRegCreateGroupEffect} from "@/main/autoreg/accounts/store/effects/autoRegCreateGroup.effect";
import {AutoRegSetOwnerSystemEffect} from "@/main/autoreg/accounts/store/effects/autoRegSetOwnerSystem.effect";
import {AutoRegDeletedGroupEffect} from "@/main/autoreg/accounts/store/effects/autoRegDeletedGroup.effect";
import {AutoRegGetProxyEffect} from "@/main/autoreg/accounts/store/effects/autoRegGetProxy.effect";
import {SyncToScenumEffect} from "@/main/autoreg/registration/store/effects/syncToScenum.effect";
import {DownloadEmailsEffect} from "@/main/autoreg/registration/store/effects/downloadEmails.effect";



@NgModule({
  declarations: [
    AutoregComponent
  ],
  imports: [
    AutoregRoutingModule,
    CommonModule,
    EffectsModule
      .forFeature(
        [
          AutoRegAccEffect,
          AutoRegProcessItemsEffect,
          AutoRegTerminateEffect,
          AutoRegProcessPauseEffect,
          AutoRegProcessPlayEffect,
          AutoRegAccDeletedEffect,
          AutoRegAccUpdateEffect,
          TableEffects,
          AutoRegOwnerUpdateEffect,
          AutoRegDeletedGroupAccEffect,
          AutoRegFbGroupEffect,
          AutoRegFbAddEffect,
          AutoRegFbAddGroupEffect,
          AutoRegReLoginEffect,
          AddFarmEffect,
          AutoRegUpdateSelfieEffect,
          AutoRegCreateAPEffect,
          AutoRegChangeAccEffect,
          AutoRegCreateAPItemEffect,
          IntegrationToScenumEffect,
          GetRegEffect,
          UpdateRegerEffect,
          GetConsumablesEffect,
          GetStatisticsEffect,
          GetStatisticsTodayEffect,
          GetStatisticsAllEffect,
          AutoRegGetGroupEffect,
          AutoRegCreateGroupEffect,
          AutoRegDeletedGroupEffect,
          AutoRegAddToGroupEffect,
          AutoRegSetOwnerEffect,
          AutoRegSetOwnerSystemEffect,
          AutoRegGetProxyEffect,
          SyncToScenumEffect,
          DownloadEmailsEffect
        ]),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AccountsModule,
    RegistrationModule,
    StatsModule
  ],

  providers: [
    AutoRegService,
    FbToolsService,
    ProcessService,
    RegerService
  ]
})
export class AutoregModule {
}
