import {createAction, props} from "@ngrx/store";
import {ActionsType} from "@/main/auto-gpt/store/actions.type";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";

export const getMessageChatAction = createAction (
  ActionsType.CHAT_GPT_MESSAGE_GET
)

export const getMessageChatActionSuccess = createAction (
  ActionsType.CHAT_GPT_MESSAGE_GET_SUCCESS,
  props<{response: DefaultResponseType}>()
)

export const getMessageChatActionFailure = createAction (
  ActionsType.CHAT_GPT_MESSAGE_GET_FAILURE,
  props<{response: DefaultResponseType}>()
)

export const getMessageChatActionError = createAction (
  ActionsType.CHAT_GPT_MESSAGE_GET_ERROR,
  props<{error: HttpErrorResponse}>()
)
