import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainRoutingModule} from "@/main/main-routing.module";
import {MainComponent} from "@/main/main.component";
import {HeaderComponent} from "@/main/layout/header/header.component";
import {FooterComponent} from "@/main/layout/footer/footer.component";
import {MenuSidebarComponent} from "@/main/layout/menu-sidebar/menu-sidebar.component";
import {BlankComponent} from "@/main/pages/blank/blank.component";
import {ProfileComponent} from "@/main/pages/profile/profile.component";
import {DashboardComponent} from "@/main/pages/dashboard/dashboard.component";
import {MessagesComponent} from "@/main/layout/header/messages/messages.component";
import {NotificationsComponent} from "@/main/layout/header/notifications/notifications.component";
import {LanguageComponent} from "@/main/layout/header/language/language.component";
import {MainMenuComponent} from "@/main/pages/main-menu/main-menu.component";
import {SubMenuComponent} from "@/main/pages/main-menu/sub-menu/sub-menu.component";
import {MenuItemComponent} from "@/main/layout/menu-sidebar/menu-item/menu-item.component";
import {ControlSidebarComponent} from "@/main/layout/control-sidebar/control-sidebar.component";
import {Page404Component} from "@/main/pages/errors/page404/page404.component";
import {Page500Component} from "@/main/pages/errors/page500/page500.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ProfabricComponentsModule} from "@profabric/angular-components";
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {ActionsModule} from "@/main/actions/actions.module";
import {NgxPermissionsModule} from "ngx-permissions";
import {SharedModule} from "@/shared/shared.module";
import {ToolsModule} from "@/main/tools/tools.module";
import {AutoregModule} from "@/main/autoreg/autoreg.module";
import {AuthModule} from "@/auth/auth.module";
import {AutoGptModule} from "@/main/auto-gpt/auto-gpt.module";
import {StatsModule} from "@/main/autoreg/stats/stats.module";
import {UsersModule} from "@/users/users.module";
import {AccountsModule} from "@/main/autoreg/accounts/accounts.module";
import {MatButtonModule} from "@angular/material/button";
import {CartModule} from "@/main/cart/cart.module";

@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    DashboardComponent,
    MessagesComponent,
    NotificationsComponent,
    LanguageComponent,
    MainMenuComponent,
    SubMenuComponent,
    MenuItemComponent,
    ControlSidebarComponent,
    Page404Component,
    Page500Component
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    ReactiveFormsModule,
    ProfabricComponentsModule,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    ActionsModule,
    StatsModule,
    NgxPermissionsModule.forChild(),
    SharedModule,
    FormsModule,
    ToolsModule,
    AutoregModule,
    AuthModule,
    AutoGptModule,
    AccountsModule,
    MatButtonModule,
    UsersModule,
    CartModule
  ]
})
export class MainModule {
}
