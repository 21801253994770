import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectCurrentRoute} from "@/store/state";
import {pageLoaderSelector} from "@/ui/store/selectors";
import {autoRegProcessItemsActions} from "@/main/autoreg/registration/store/actions/AutoRegProcessItems.actions";


@Component({
  selector: 'app-autoreg',
  templateUrl: './autoreg.component.html',
  styleUrls: ['./autoreg.component.scss']
})

export class AutoregComponent implements OnInit {

  public currentRoute$: Observable<any>;
  public pageLoader$: Observable<boolean>;
  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.initValue();
    this.store.dispatch(autoRegProcessItemsActions());
  }

  private initValue(): void {
    this.currentRoute$ = this.store.pipe(select(selectCurrentRoute));
    this.pageLoader$ = this.store.pipe(select(pageLoaderSelector));
  }

}

