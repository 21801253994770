import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {DefaultResponseType} from "@/shared/types/defaultResponse.type";
import {HttpErrorResponse} from "@angular/common/http";
import {
  autoRegProcessItemsActions, autoRegProcessItemsErrorActions, autoRegProcessItemsFailureActions,
  autoRegProcessItemsSuccessActions
} from "@/main/autoreg/registration/store/actions/AutoRegProcessItems.actions";
import {ProcessService} from "@/main/autoreg/services/process.service";
import {Store} from "@ngrx/store";
import {pageLoaderAction} from "@/ui/store/actions/pageLoader.action";
import {ToastrService} from "ngx-toastr";
import {updateButtonStateAction} from "@/main/autoreg/registration/store/actions/updateButtonState.action";
import {setProcessesAction} from "@/main/autoreg/accounts/store/actions/setProcesses.action";

@Injectable()
export class AutoRegProcessItemsEffect {
  autoRegProcess$ = createEffect(() => this.actions$.pipe(
    ofType(autoRegProcessItemsActions),
    switchMap(() => {
      return this.processService.getAutoRegProcesses().pipe(
        map((response: DefaultResponseType) => {
          if (response.status === 'Success') {
            this.store.dispatch(setProcessesAction({payload: response.data}))
            return autoRegProcessItemsSuccessActions({response})
          } else if (response.status === 'Failure') {
            return autoRegProcessItemsFailureActions({response})
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400 || 422) {
            return of(autoRegProcessItemsErrorActions({error}));
          } else if (error.status === 500 || 501) {
            return of(autoRegProcessItemsErrorActions({error}));
          } else {
            return of(autoRegProcessItemsErrorActions({error}));
          }
        })
      );
    })
  ))

  autoRegProcessSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegProcessItemsSuccessActions),
      tap((response: {response: DefaultResponseType}): void => {

        this.store.dispatch(updateButtonStateAction({stateButton: false}))
        this.store.dispatch(pageLoaderAction({load: false}))
      })
    ), {dispatch: false}
  )

  autoRegProcessFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegProcessItemsFailureActions),
      tap((response: {response: DefaultResponseType}): void => {

        this.store.dispatch(updateButtonStateAction({stateButton: false}))
        this.store.dispatch(pageLoaderAction({load: false}))
        console.error(`Данные для процессов не получены. Ошибка ${response.response.details.msg}`)
        this.toaStr.error(response.response.details.msg)
      })
    ), {dispatch: false}
  )

  autoRegProcessError$ = createEffect(
    () => this.actions$.pipe(
      ofType(autoRegProcessItemsErrorActions),
      tap(({error}): void => {
        this.store.dispatch(pageLoaderAction({load: false}))
        if (error.status === 400 || 422) {
          this.toaStr.error('Ошибка при получении списка процессов')
          console.error(`Данные для процессов не получены. Ошибка ${error.message}`)
        } else if (error.status === 500 || 501) {
          console.error(`Данные для процессов не получены. Ошибка сервера`)
        }
      })
    ), {dispatch: false}
  )


  constructor(private processService: ProcessService,
              private actions$: Actions,
              private store: Store,
              private toaStr: ToastrService) {

  }
}
