<ng-container *ngIf="type === typePopUp.DEL_PROCESS">
  <div class="deleted">
    <h4 *ngIf="type === typePopUp.DEL_PROCESS">Вы действительно хотите завершить процесс {{nameProcess}}?</h4>
    <div class="actions">
      <button class="m-2 w-100" mat-raised-button color="warn" (click)="noConstellation()">Отмена</button>

      <button class="m-2 w-100" mat-raised-button color="primary"
              [disabled]="buttonLoader$ | async"
              (click)="okConstellation()"
      >
        <span *ngIf="!(buttonLoader$ | async)">Да</span>
        <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === typePopUp.REGISTER_NEW_USER">
  <div class="deleted">
    <h4>Зарегистрировать пользователя {{user}}?</h4>
    <div class="actions">
      <button class="m-2 w-100" mat-raised-button color="warn" (click)="noConstellation()">Отмена</button>

      <button class="m-2 w-100" mat-raised-button color="primary"
              (click)="okConstellation()"
              [disabled]="buttonLoader$ | async"
      >
        <span *ngIf="!(buttonLoader$ | async)">Да</span>
        <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
      </button>

    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === typePopUp.LOGOUT">
  <div class="deleted">
    <h4>Вы действительно хотите выйти из ситемы?</h4>
    <div class="actions">
      <button class="m-2 w-100" mat-raised-button color="warn" (click)="noConstellation()">Отмена</button>

      <button class="m-2 w-100" mat-raised-button color="primary"
              (click)="okConstellation()"
              [disabled]="buttonLoader$ | async"
      >
        <span *ngIf="!(buttonLoader$ | async)">Да</span>
        <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === typePopUp.ACTIVATED_ACC || type === typePopUp.BLOCKED_ACC_USER || type === typePopUp.DELETED_ACC_USER">
  <div class="deleted">
    <h4 *ngIf="type === typePopUp.DELETED_ACC_USER">Удалить аккаунт пользователя {{user}}?</h4>
    <h4 *ngIf="type === typePopUp.BLOCKED_ACC_USER">Заблокировать аккаунт пользователя {{user}}?</h4>
    <h4 *ngIf="type === typePopUp.ACTIVATED_ACC">Активировать аккаунт пользователя {{user}}?</h4>
    <div class="actions">
      <button class="m-2 w-100" mat-raised-button color="warn" (click)="noConstellation()">Отмена</button>

      <button class="m-2 w-100" mat-raised-button color="primary"
              (click)="okConstellation()"
              [disabled]="buttonLoader$ | async"
      >
        <span *ngIf="!(buttonLoader$ | async)">Да</span>
        <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
      </button>

    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === typePopUp.ADD_TO_FB_TOOLS || type === typePopUp.ADD_TO_FB_TOOLS_ITEMS">
  <div class="deleted">
    <h4>Хотите импортировать данные в ФБ Тул?</h4>
    <div class="actions">
      <button class="m-2 w-100" mat-raised-button color="warn" (click)="noConstellation()">Отмена</button>
      <button class="m-2 w-100" mat-raised-button color="primary" (click)="okConstellation()">Да</button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === typePopUp.CHANGE_AUTO_REG_STATE">
  <div class="deleted">
    <h4>Изменить состояние выделенных аккаунтов на «Готов»?</h4>
    <div class="actions">
      <button class="m-2 w-100" mat-raised-button color="warn" (click)="noConstellation()">Отмена</button>
      <button class="m-2 w-100" mat-raised-button color="primary"
              [disabled]="(buttonLoader$ | async)"
              (click)="okConstellation()">
        <span *ngIf="!(buttonLoader$ | async)">Да</span>
        <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === typePopUp.CHANGE_AUTO_REG_STATE_GARBAGE">
  <div class="deleted">
    <h4>Изменить состояние выделенных аккаунтов на «Мусор»?</h4>
    <div class="actions">
      <button class="m-2 w-100" mat-raised-button color="warn" (click)="noConstellation()">Отмена</button>
      <button class="m-2 w-100" mat-raised-button color="primary"
              [disabled]="(buttonLoader$ | async)"
              (click)="okConstellation()">
        <span *ngIf="!(buttonLoader$ | async)">Да</span>
        <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === typePopUp.CHANGE_AUTO_REG_STATE_ITEM">
  <div class="deleted">
    <h4>Изменить состояние аккаунта {{account.login}} на «Готов»?</h4>
    <div class="actions">
      <button class="m-2 w-100" mat-raised-button color="warn" (click)="noConstellation()">Отмена</button>
      <button class="m-2 w-100" mat-raised-button color="primary"
              (click)="okConstellation()"
              [disabled]="(buttonLoader$ | async)"
      >
        <span *ngIf="!(buttonLoader$ | async)">Да</span>
        <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
      </button>

    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === typePopUp.DELETED_OWNER_AUTO_REG_ITEM || type === typePopUp.DELETED_OWNER_AUTO_REG_ITEMS">
  <div class="deleted">
    <h4 *ngIf="type === typePopUp.DELETED_OWNER_AUTO_REG_ITEMS">Удалить владельца у выбранных авторегов?</h4>
    <h4 *ngIf="type === typePopUp.DELETED_OWNER_AUTO_REG_ITEM">Удалить владельца у авторега {{account.login}}?</h4>
    <div class="actions">
      <button class="m-2 w-100" mat-raised-button color="warn" (click)="noConstellation()">Отмена</button>
      <button class="m-2 w-100" mat-raised-button color="primary"
              (click)="okConstellation()"
              [disabled]="(buttonLoader$ | async)"
      >
        <span *ngIf="!(buttonLoader$ | async)">Да</span>
        <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
      </button>

    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === typePopUp.RESTORE_AUTO_REG || type === typePopUp.RESTORE_AUTO_REG_ITEMS">
  <div class="deleted">
    <h4 *ngIf="type === typePopUp.RESTORE_AUTO_REG_ITEMS">Восстановить выделенные аккаунты?</h4>
    <h4 *ngIf="type === typePopUp.RESTORE_AUTO_REG">Восстановить аккаунт {{account.login}}?</h4>
    <div class="actions">
      <button class="m-2 w-100" mat-raised-button color="warn" (click)="noConstellation()">Отмена</button>
      <button class="m-2 w-100" mat-raised-button color="primary"
              (click)="okConstellation()"
              [disabled]="(buttonLoader$ | async)"
      >
        <span *ngIf="!(buttonLoader$ | async)">Да</span>
        <span *ngIf="(buttonLoader$ | async)"><app-button-loader></app-button-loader></span>
      </button>

    </div>
  </div>
</ng-container>


