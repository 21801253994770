<button class="sort-button" mat-icon-button [matMenuTriggerFor]="menu" >
  <i class="fa-solid fa-filter"></i>
</button>

<mat-menu #menu="matMenu" xPosition="before" class="menu">
  <button mat-menu-item class="menu-sort-item"
          (click)="otherFilter(filter.COOKIE)"
          [class.active]="(filterValue$ | async)?.otherFilters?.includes(filter.COOKIE)"
  >
    <span class="icon"><i class="fa-solid fa-check"></i></span>
    <span>Только с Cookie</span>
  </button>

  <button mat-menu-item class="menu-sort-item"
          (click)="otherFilter(filter.TWF)"
          [class.active]="(filterValue$ | async)?.otherFilters?.includes(filter.TWF)"
  >
    <span class="icon"><i class="fa-solid fa-check"></i></span>
    <span>Только с 2FA</span>
  </button>

  <button mat-menu-item class="menu-sort-item"
          (click)="otherFilter(filter.NO_TWF)"
          [class.active]="(filterValue$ | async)?.otherFilters?.includes(filter.NO_TWF)"
  >
    <span class="icon"><i class="fa-solid fa-check"></i></span>
    <span>Без 2FA</span>
  </button>

  <button mat-menu-item class="menu-sort-item"
          (click)="otherFilter(filter.FB_TOOLS)"
          [class.active]="(filterValue$ | async)?.otherFilters?.includes(filter.FB_TOOLS)"
  >
    <span class="icon"><i class="fa-solid fa-check"></i></span>
    <span>Для FB-Tools</span>
  </button>

  <button mat-menu-item class="menu-sort-item"
          (click)="otherFilter(filter.CREATE_PROFILE_AP)"
          [class.active]="(filterValue$ | async)?.otherFilters?.includes(filter.CREATE_PROFILE_AP)"
  >
    <span class="icon"><i class="fa-solid fa-check"></i></span>
    <span>Для профиля в AP</span>
  </button>

</mat-menu>
