import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ImkloComponent} from "@/main/actions/imklo/imklo.component";
import {GeoComponent} from "@/main/actions/imklo/geo/geo.component";
import {DomainsComponent} from "@/main/actions/imklo/domains/domains.component";

const routes: Routes = [
  {
    path: 'imklo',
    component: ImkloComponent
  },
  {
    path: 'imklo/geo',
    component: GeoComponent
  },
  {
    path: 'imklo/domains',
    component: DomainsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImkloRoutingModule { }
